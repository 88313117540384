import React from 'react';
import { Collapse, Col, Row, Empty, Skeleton } from 'antd';
import QRCode from 'qrcode.react';
import { useOrderByIdsQuery } from 'stores/query/ordersQuery';

const { Panel } = Collapse;

const PreviewQROrders = ({ orders }) => {
  const { data } = useOrderByIdsQuery(orders);

  return (
    <Row style={{ marginTop: '15px' }}>
      {!data ? (
        <Col span={24}>
          <Skeleton active />
        </Col>
      ) : (
        <Col span={24}>
          <Collapse>
            {data ? (
              data.map(({ odooDataOrder, id }) => (
                <Panel header={`Orden - ${odooDataOrder?.name}`} key={id}>
                  <QRCode value={id} />
                </Panel>
              ))
            ) : (
              <Empty description="Sin Ordenes" />
            )}
          </Collapse>
        </Col>
      )}
    </Row>
  );
};
export default PreviewQROrders;
