import { Button, Result } from 'antd';
import React from 'react';

const ErrorFallback = ({ error }) => {
  return (
    <>
      <Result
        status="500"
        title="Disculpa, a ocurrido un error"
        subTitle={error}
        extra={
          <Button onClick={() => (window.location.href = '/')} type="primary">
            Inicio
          </Button>
        }
      />
    </>
  );
};

export default ErrorFallback;
