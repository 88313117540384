import moment from 'moment';
import { printPaymentTypes } from 'utils/utils';

/// Factory

export const ordersReportFactoryG = (
  roadmaps = [],
  routes = [],
  riders = [],
  orders = []
) =>
  orders.map((order) => {
    const odooDataOrder = order?.odooDataOrder;
    const rider = riders.find((riderItem) => riderItem.id === order.riderId);
    const proco = rider?.proco || '';
    const roadmap = roadmaps.find((roadmapItem) =>
      roadmapItem.ordersIds?.includes(order.id)
    );
    const route = routes.find(
      (routeItem) => routeItem.id === order?.addressDetails?.routeId
    );
    const deliverStep = order?.steps?.find(
      (step) => step.label === 'delivered.label'
    );
    const orderTime = moment.unix(order?.date).format('LT') || '';
    const riderAssignedLog = roadmap?.log?.find(
      (log) => log.description === 'riderAssignedManually'
    );
    const riderAssignedDate =
      moment.unix(riderAssignedLog?.date).format('hh:mm A') || '';
    const totalDl = Number(order?.ref || 0).toFixed(2);
    /* Cant of products */
    const products = odooDataOrder?.products || [];
    const roadmapDate = roadmap?.log?.filter((r) => r.type === 'delivered');
    const dateOrders = [];
    if (roadmapDate) {
      roadmapDate.forEach((element, index) => {
        if (index === 0) {
          dateOrders.push({
            order: element.order,
            date: riderAssignedLog?.date
          });
        } else {
          dateOrders.push({
            order: element.order,
            date: roadmapDate[index - 1]?.date
          });
        }
      });
    }

    const dateOrderRider = dateOrders.find(
      (e) => e.order === order.odooDataOrder?.name
    );
    const cantSku = products?.length || 0;
    //
    const totalProducts = products?.reduce(
      (prev, next) => (prev += next.quantity),
      0
    );
    return {
      date: order.odooDataOrder?.create_date?.substring(0, 10) || '',
      riderAssignedDate,
      departureDate: dateOrderRider
        ? moment.unix(dateOrderRider?.date).format('LT')
        : '',
      deliverDate: deliverStep
        ? moment.unix(deliverStep?.date).format('LT')
        : '',
      rider: rider?.name || '',
      dni: rider?.dni || '',
      proco: proco || '',
      route: route?.name || '',
      roadmap: roadmap?.roadmapNumber || '',
      order: order.odooDataOrder?.name || '',
      orderTime,
      client: order?.clientName || '',
      phone: order?.addressDetails?.phone || '',
      totalProducts,
      cantSku,
      amount: order?.odooDataOrder?.amount_total || '',
      totalDl,
      couponCode: order?.discount?.code || '',
      couponAmount: order?.discount?.totalBs || '',
      paymentMethod: order?.payment?.type || '',
      paymentValidation: order?.payment?.validated ? 'X' : '',
      delivered: order?.statusCode === 3 ? 'X' : '',
      crossdocking:
        order?.steps?.filter((step) => step.label === 'scanned.label').length >
        1
          ? 'X'
          : '',
      noDelivered: order?.statusCode === 7 ? 'X' : '',
      partialOrder: order?.excludedProducts ? 'X' : '',
      excludedProduts:
        order?.excludedProducts?.products
          ?.map((product) => product?.name)
          .join(', ') || '',
      crossDockingMotive: roadmap?.feedback?.description || '',
      noDeliveredMotive: order?.cancellationReason || '',
      feedback: order?.feedback?.description || '',
      distance: order?.traveledKms || '',
      observations:
        order?.observations
          ?.map((observation) => observation.description)
          .join(', ') || '',
      // I want printDate to be printHour instead.
      printDate: order?.printedInvoiceDate
        ? moment.unix(order?.printedInvoiceDate).format('LT')
        : '-',
      printedManually: order?.printedManually ? 'X' : '-',
      company: order?.company ? order.company : '',
      email: order?.customizedInvoice?.email || ''
    };
  });

export const deliveryOrdersReportFactoryG = (
  roadmaps = [],
  routes = [],
  riders = [],
  orders = []
) =>
  orders
    .filter(
      (item) => item?.statusCode === 3 && item?.status?.type === 'delivery'
    )
    .map((order) => {
      const odooDataOrder = order?.odooDataOrder;
      const rider = riders.find((riderItem) => riderItem.id === order.riderId);
      const roadmap = roadmaps.find((roadmapItem) =>
        roadmapItem?.ordersIds?.includes(order.id)
      );
      const route = routes.find(
        (routeItem) => routeItem.id === roadmap?.routeId
      );
      const departureStep = order?.steps?.find(
        (step) => step?.label === 'onTheWay.label'
      );
      const deliverStep = order?.steps?.find(
        (step) => step?.label === 'delivered.label'
      );
      /* Cant of products */
      const products = odooDataOrder?.products || [];
      const cantSku = products?.length || 0;
      //
      const totalProducts = products.reduce(
        (prev, next) => (prev += next.quantity),
        0
      );
      const paymentType = printPaymentTypes(order?.payment);
      const totalBs = Number(odooDataOrder?.amount_total || 0).toFixed(2);
      const totalDl = Number(order?.ref || 0).toFixed(2);
      const date = odooDataOrder?.create_date?.substring(0, 10) || '';
      const orderTime = moment.unix(order?.date).format('LT') || '';
      return {
        date,
        order: odooDataOrder.name || '',
        roadmap: roadmap?.roadmapNumber || '',
        route: route?.name || '',
        client: order?.clientName || '',
        cantSku,
        totalProducts,
        paymentType,
        totalBs,
        totalDl,
        orderTime,
        invoiceTime: '', // while empty
        rider: rider?.name || '',
        departureDate: departureStep
          ? moment.unix(departureStep?.date).format('LT')
          : '',
        deliverDate: deliverStep
          ? moment.unix(deliverStep?.date).format('LT')
          : '',
        email: order.customizedInvoice.email || ''
      };
    });
