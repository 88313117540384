import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getModules as getModulesFb } from 'firebase/api';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getModules = createAsyncThunk(
  '@modulesSlide/getModules',
  async () => await getModulesFb()
);

export const modulesSlide = createSlice({
  name: 'modulesSlide',
  initialState,
  reducers: {
    setModulesLoading: (state) => {
      state.loading = true;
    },
    setModules: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setModulesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getModules.pending]: (state) => {
      state.loading = true;
    },
    [getModules.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getModules.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setModulesLoading,
  setModules,
  setModulesError
} = modulesSlide.actions;

export default modulesSlide.reducer;
