import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Table } from 'antd';
import { useOrdersQuery } from 'stores/query/ordersQuery';
import { mobilePaymentFactory } from 'pages/CommerceToPerson/utils';
import { getBanksData } from 'stores/selectors/banks';
import MobilePaymentFilter from './MobilePaymentFilter';
import useColumnSearch from 'hooks/useColumnSearch';
import moment from 'moment';

const MobilePayment = () => {
  const searchFilter = useColumnSearch();
  const columns = [
    {
      title: 'N° de Orden',
      dataIndex: 'order',
      key: 'order',
      fixed: 'left',
      sorter: (a, b) =>
        Number(a.order?.replace(/\D/g, '')) -
        Number(b.order?.replace(/\D/g, ''))
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      fixed: 'left',
      ...searchFilter('client')
    },
    {
      title: 'Fecha',
      dataIndex: 'operationDate',
      key: 'operationDate',
      sorter: (a, b) => {
        return (
          moment(a.operationDate, 'dd/mm/yyyy').unix() -
          moment(b.operationDate, 'dd/mm/yyyy').unix()
        );
      }
    },
    {
      title: 'Tipo de Pago',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Banco',
      dataIndex: 'bank',
      key: 'bank'
    },
    {
      title: 'Cedula',
      dataIndex: 'dni',
      key: 'dni'
    },
    {
      title: 'Telefono',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Monto total Bs',
      dataIndex: 'amountTotalBs',
      key: 'amountTotalBs'
    },
    {
      title: 'Monto pagado',
      dataIndex: 'amountPaidCash',
      key: 'amountPaidCash'
    },
    {
      title: 'Vuelto',
      dataIndex: 'moneyReturned',
      key: 'moneyReturned'
    }
  ];
  const bankList = useSelector(getBanksData);
  const { data: ordersData } = useOrdersQuery();
  const mobilePaymentStore = mobilePaymentFactory(ordersData, bankList);
  const [MobilePaymentData, setMobilePaymentData] =
    useState(mobilePaymentStore);

  const handleFilter = (record) => {
    const { order, client, minTotalAmountBs, maxTotalAmountBs } = record;

    const dataFiltered = mobilePaymentStore
      .filter((item) => {
        if (!order) return true;
        return item.orderId === order;
      })
      .filter((item) => {
        if (!client) return true;
        return item.clientId === client;
      })
      .filter((item) => {
        const amountTotalBs = Number(
          item.amountTotalBs.replace('BsF', '').replaceAll(',', '.')
        );

        if (!!minTotalAmountBs && !maxTotalAmountBs) {
          return amountTotalBs >= minTotalAmountBs;
        }
        if (!minTotalAmountBs && !!maxTotalAmountBs) {
          return amountTotalBs <= maxTotalAmountBs;
        }
        if (!!minTotalAmountBs && !!maxTotalAmountBs) {
          return (
            amountTotalBs >= minTotalAmountBs &&
            amountTotalBs <= maxTotalAmountBs
          );
        }
        return true;
      });

    setMobilePaymentData(dataFiltered);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <MobilePaymentFilter
            handleFilter={handleFilter}
            mobilePaymentData={MobilePaymentData}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={MobilePaymentData}
            scroll={{ x: 1300 }}
            rowKey="idMobilePayment"
          />
        </Col>
      </Row>
    </>
  );
};

export default MobilePayment;
