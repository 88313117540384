import { createSelector } from '@reduxjs/toolkit';
import { getProcosData } from './proco';

const selectSelf = (state) => state;

export const getRiders = createSelector(selectSelf, (state) => state.riders);

export const getRidersData = createSelector(
  getRiders,
  getProcosData,
  (riders, procos) => {
    let filteredRiders = riders?.data;

    if (procos?.length) {
      filteredRiders = riders?.data?.map((rider) => {
        const riderProco = procos?.find((proco) =>
          proco?.riders?.includes(rider?.id)
        );
        if (riderProco) {
          return { ...rider, proco: riderProco?.name };
        }
        return rider;
      });
    }

    return filteredRiders;
  }
);

export const isRidersLoading = createSelector(
  getRiders,
  (riders) => riders.loading
);

export const hasRidersError = createSelector(
  getRiders,
  (riders) => riders.error
);

export const hasRidersData = createSelector(
  getRiders,
  (riders) => riders.data.length > 0
);

export const isRidersLoaded = createSelector(
  isRidersLoading,
  hasRidersData,
  (isLoading) => !isLoading
);

export const getRiderById = (id) =>
  createSelector(getRidersData, (riders) =>
    riders.find((rider) => rider.id === id)
  );

export const getRidersTokensNotification = createSelector(
  getRidersData,
  (riders) =>
    riders
      .filter((rider) => rider?.tokenNotification)
      .map(({ id, tokenNotification }) => ({
        id,
        token: tokenNotification
      }))
);

export const getRidersByProcoName = (name) =>
  createSelector(getRidersData, (riders) =>
    riders.filter((rider) => {
      return rider?.proco?.toLowerCase() === name.toLowerCase();
    })
  );
