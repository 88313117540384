import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import rootReducer from './reducers';

import { pricesApi } from './query/pricesQuery';
import { ordersApi } from './query/ordersQuery';
import { configApi } from './query/configQuery';

const APIS = [pricesApi.middleware, ordersApi.middleware, configApi.middleware];

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [
      thunkMiddleware,
      ...getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(APIS)
    ],
    preloadedState,
    enhancers: []
  });

  // NOTE: its required to detect refetch onFocus RTK query
  setupListeners(store.dispatch);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}
