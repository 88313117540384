import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  commitCashMethod,
  setEditing,
  setPaymentDetails,
  setPaymentMethod,
  updateStep
} from 'stores/actions/checkout';
import {
  getBills,
  getPayment,
  getSummaryTotals
} from 'stores/selectors/checkout';
import { checkoutSteps } from '../constants';

const useSetMethods = () => {
  const dispatch = useDispatch();
  const { total, totalRef } = useSelector(getSummaryTotals);
  const { bills } = useSelector(getBills);
  const { editing } = useSelector(getPayment);

  const handleCommitMethods = useCallback(
    (method, details, bank) => {
      dispatch(setPaymentMethod({ method }));
      dispatch(setPaymentDetails({ details, bank }));
      dispatch(setEditing(false));
      dispatch(updateStep({ mode: 'add', stepName: checkoutSteps[2] }));
    },
    [dispatch]
  );

  const saveCashMethod = useCallback(
    (cashback) => {
      dispatch(
        commitCashMethod({
          bills: bills.map((d) => ({ amount: d.amount, code: d.code })),
          cashback
        })
      );
      dispatch(setEditing(false));
      dispatch(updateStep({ mode: 'add', stepName: checkoutSteps[2] }));
    },
    [bills, dispatch]
  );

  return {
    cash: saveCashMethod,
    bolivarCash: handleCommitMethods,
    pagomovil: handleCommitMethods,
    total,
    totalRef,
    editing
  };
};

export default useSetMethods;
