import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const invoicesSlide = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoicesLoading: (state) => {
      state.loading = true;
    },
    setInvoices: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setInvoicesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setInvoicesLoading,
  setInvoices,
  setInvoicesError
} = invoicesSlide.actions;

export default invoicesSlide.reducer;
