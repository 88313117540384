import API from './config';

const pingPrinter = () => API.get('http://localhost:5000/api/ping');

export const printInvoice = (values) => API.post('http://localhost:5000/api/cola/factura', values);

export const printCreditNote = (values) => API.post('http://localhost:5000/api/cola/nota-credito', values);

export default {
  pingPrinter,
  printInvoice,
  printCreditNote
};
