import React from 'react';
import { Select } from 'antd';
import { formatPriceToBsF, formatPriceToDollar, sortOrders } from 'utils/utils';

const DOCUMENT_TYPE = ['V', 'E', 'J'];
const C2P_STATUS = [
  {
    label: 'Exitoso',
    value: 'SUCCESS'
  },
  {
    label: 'En proceso',
    value: null
  },
  {
    label: 'Fallido',
    value: 'FAILED'
  },
  {
    label: 'All',
    value: 'ALL'
  }
];
const OPERATIONS_FROM = [
  {
    label: 'Admin',
    value: true
  },
  {
    label: 'Automatica',
    value: null
  },
  {
    label: 'All',
    value: false
  }
];

const Option = Select;

export const selectDniBefore = (
  <Select defaultValue="V" className="select-before">
    {DOCUMENT_TYPE.map((item, idx) => (
      <Option key={idx} value={item}>
        {item}
      </Option>
    ))}
  </Select>
);

export const renderStatus = (
  <Select style={{ width: 100 }} placeholder="Select" defaultValue="ALL">
    {C2P_STATUS.map((item, idx) => (
      <Option key={idx} value={item.value}>
        {item.label}
      </Option>
    ))}
  </Select>
);

export const renderOperationsFrom = (
  <Select style={{ width: 150 }} placeholder="Select" defaultValue={false}>
    {OPERATIONS_FROM.map((item, idx) => (
      <Option key={idx} value={item.value}>
        {item.label}
      </Option>
    ))}
  </Select>
);

export const renderBankSelect = (banks) => (
  <Select
    style={{ width: 200 }}
    placeholder="Select"
    filterOption={(input, option) =>
      option.children.toLowerCase().includes(input.toLowerCase())
    }
    allowClear
    showSearch
  >
    {banks?.map((bank, idx) => (
      <Option key={idx} value={bank?.code}>
        {bank?.name}
      </Option>
    ))}
  </Select>
);

export const renderOrdersSelect = (ordersOption) => (
  <Select
    placeholder="Select"
    filterOption={(input, option) =>
      option.children.toLowerCase().includes(input.toLowerCase())
    }
    allowClear
    showSearch
  >
    {ordersOption?.map((order, idx) => (
      <Option key={idx} value={order?.id}>
        {order?.name}
      </Option>
    ))}
  </Select>
);

export const parseDataFilter = (record) => {
  const {
    bank = null,
    dni = null,
    documentType,
    order: orderId = null,
    status = null,
    fromAdmin = false
  } = record;
  const dniParsed = dni ? `${documentType}${dni}` : null;
  return {
    bank,
    dni: dniParsed,
    orderId,
    status,
    fromAdmin
  };
};

export const commerceToPersonFactory = (
  ordersData = [],
  resCommerceToPersonData = []
) =>
  resCommerceToPersonData.map((item) => {
    const order = ordersData.find((el) => el.id === item.orderId) || {};
    const orderName = order?.name || '-';
    const totalAmount = order?.amount_total || 0;
    const orderId = order?.id;
    return {
      ...item,
      orderId,
      order: orderName,
      totalAmount,
      client: order?.client || {}
    };
  }) || [];

const getOrderTypes = (payment) => {
  let output;
  const hasType = Object.prototype.hasOwnProperty.call(payment, 'type');
  if (!hasType && Object.entries(payment).length > 0) {
    output = payment.methods.map((item) => item.type);
  } else if (hasType) output = payment?.type.split(',');
  else output = [''];

  return output;
};

export const cashReportFactory = (orders = []) => {
  const ordersUsd = orders.filter((order) => {
    const payment = order?.payment || {};
    const orderTypes = getOrderTypes(payment);
    if (orderTypes.includes('cash')) return true;
    return false;
  });

  const cashReportData = ordersUsd.map((order) => {
    let payment = order?.payment;
    const hasPaymentMix = Object.prototype.hasOwnProperty.call(
      payment,
      'methods'
    );
    payment = hasPaymentMix
      ? payment.methods.find((pay) => pay.type === 'cash')
      : order?.payment;

    const paymentDetails = payment.details;

    const bills = paymentDetails ? paymentDetails.bills : [];

    const billsDenomination =
      typeof bills === 'object' ? bills.map((bill) => bill.amount) : [];
    const billsCode =
      typeof bills === 'object' ? bills.map((bill) => bill.code) : [];
    const billsPhoto =
      typeof bills === 'object'
        ? bills.map((bill) => bill.image).filter((bill) => bill !== undefined)
        : [];
    const operationDate = new Date(order?.date?.toDate()).toLocaleDateString(
      'es-US'
    );
    const amountTotalUsd = order?.ref || 0;
    const amountTotalBs = order?.odooDataOrder?.amount_total || 0;
    const amountPaidCash =
      (hasPaymentMix ? payment?.amount : paymentDetails?.amount) || 0;
    const rate = order?.rate;
    const differenceInUsd = amountTotalUsd - amountPaidCash;
    const differenceInBs = amountTotalBs - amountPaidCash * rate;

    return {
      orderId: order?.id,
      order: order?.name,
      clientId: order?.client?.id,
      client: order?.client.name,
      operationDate,
      amountTotalBs: formatPriceToBsF(amountTotalBs),
      amountTotalUsd: formatPriceToDollar(amountTotalUsd),
      amountPaidCash: formatPriceToDollar(amountPaidCash),
      billsPhoto,
      billsPhotoCsv: billsPhoto.join(','),
      billsDenomination,
      billsDenominationCsv: billsDenomination.join(','),
      billsCode,
      billsCodeCsv: billsCode.join(','),
      rate,
      differenceInUsd: formatPriceToDollar(differenceInUsd),
      differenceInBs: formatPriceToBsF(differenceInBs),
      payment: order?.payment,
      igtf: order?.IGTF
    };
  });

  return sortOrders(cashReportData);
};

export const mobilePaymentFactory = (orders = [], banksList = []) => {
  const ordersPagoMovil = orders.filter((order) => {
    if (!order.payment) return false;
    if (order.payment.type) {
      return order.payment.type
        .replace(/\s/g, '')
        .split(',')
        .includes('pagomovil');
    }
    return false;
  });

  const mobilePaymentReport = ordersPagoMovil.map((order) => {
    let paymentDetails = order?.payment?.details || order?.payment;
    const operationDate = new Date(order?.date?.toDate()).toLocaleDateString(
      'es-US'
    );
    let amountPaidCash = paymentDetails.amount || 0;
    const amountTotalBs = order?.odooDataOrder?.amount_total || 0;
    let moneyReturned =
      order?.payment?.aDevolver?.vuelto || amountPaidCash - amountTotalBs || 0;

    if (order.payment.methods) {
      const detallesPM = order.payment.methods.filter(
        (p) => p.type === 'pagomovil'
      )[0];
      paymentDetails = detallesPM.details;
      amountPaidCash = detallesPM.amount || detallesPM.details.amount || 0;
      moneyReturned = order?.payment?.aDevolver?.vuelto || 0;
    }

    const dni = paymentDetails.dni
      ? paymentDetails.dni.includes('V')
        ? paymentDetails.dni
        : `V${paymentDetails.dni}`
      : '-';
    const bank =
      banksList.find((item) => item.code === paymentDetails.bank)?.name || '-';

    const hasMethods = order?.payment?.hasOwnProperty('methods');
    const hasMixedPayment = hasMethods && order.payment.methods.length > 1;
    return {
      orderId: order?.id,
      order: order?.name,
      clientId: order?.client?.id,
      client: order?.client.name,
      phone: order?.client?.phone,
      bank,
      type: hasMixedPayment ? 'Mixto' : 'Simple',
      dni,
      operationDate,
      amountTotalBs: formatPriceToBsF(amountTotalBs),
      amountPaidCash: formatPriceToBsF(amountPaidCash),
      moneyReturned: formatPriceToBsF(moneyReturned)
    };
  });

  return sortOrders(mobilePaymentReport);
};
