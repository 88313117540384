import React from 'react';
import { Card } from 'antd';
import styles from './CardContainer.module.scss';

const CardContainer = (props) => {
    return (
        <div className={styles.cardContainer}>
            <Card {...props} className={styles.cardChild}>
                {props.children}
            </Card>
        </div>
    );
};

export default CardContainer;