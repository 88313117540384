import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button, DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import { updateOrder } from 'firebase/api';
import { showNotification } from 'stores/actions/notification';
import { printCreditNote } from 'services/billing';

const PrintCreditNoteModal = ({ cancel, order, refetch }) => {
  const [invoiceDate, setInvoiceDate] = React.useState('');
  const [invoiceNumber, setInvoiceNumber] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);
  const dispatch = useDispatch();
  const { id: orderId, fiscalPrinter } = order;

  const handleConfirm = async () => {
    try {
      const body = {
        hasCreditNote: true
      };
      const fiscalPrinterBody = {
        ...fiscalPrinter,
        fechaFactura: invoiceDate.date,
        serial: 'EOM0000097',
        nroFactura: Number(invoiceNumber)
      };
      const response = await printCreditNote(fiscalPrinterBody);
      if (!response.data) {
        throw new Error();
      }
      dispatch(
        showNotification({
          type: 'success',
          message: 'Éxito',
          content: 'Imprimiendo nota de crédito...'
        })
      );
      await updateOrder(orderId, body);
      refetch();
      cancel();
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: 'Ocurrió un error al reimprimir la factura'
        })
      );
    }
  };

  const checkInvoice = () => {
    if (invoiceNumber.length < 5) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  React.useEffect(() => {
    checkInvoice();
    // eslint-disable-next-line
  }, [invoiceNumber]);

  return (
    <div>
      <p>Numero de factura para la nota de crédito</p>
      <Input
        placeholder="Numero de la factura"
        type="number"
        onChange={(e) => setInvoiceNumber(e.target.value)}
      />
      <DatePicker
        style={{ width: '100%', marginTop: '15px' }}
        showTime
        use12Hours
        format="YYYY-MM-DD HH:mm"
        placeholder="Fecha de la factura"
        onChange={(date, dateString) => {
          const body = { date: dateString };
          setInvoiceDate(body);
        }}
      />
      <div style={{ marginTop: '15px' }}>
        <Button onClick={cancel} style={{ marginRight: '5px' }}>
          Cancelar
        </Button>
        <Button
          onClick={handleConfirm}
          type="primary"
          style={{ marginLeft: '5px' }}
          disabled={disabled}
        >
          Imprimir
        </Button>
      </div>
    </div>
  );
};

// add proptypes
PrintCreditNoteModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fiscalPrinter: PropTypes.object
  }).isRequired,
  cancel: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

export default PrintCreditNoteModal;
