import moment from 'moment';
import { useCallback, useState } from 'react';

const useRangeDateFilter = () => {
  const dateFrom = moment();
  const dateTo = moment();
  const FORMAT = ['DD/MM/YYYY h:mm:ss a', 'DD/MM/YY h:mm:ss a'];
  const FORMATFORMAT = 'YYYY-MM-DD h:mm:ss a';

  const lastSevenDays = moment(dateFrom.subtract(4, 'days'), FORMAT);
  lastSevenDays.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  });
  const today = moment(dateTo, FORMAT);
  today.set({
    hour: 23,
    minute: 59,
    second: 0,
    millisecond: 0
  });

  const [range, setRange] = useState({
    from: null,
    to: null,
    fromParsed: null,
    toParsed: null,
    defaultFrom: lastSevenDays,
    defaultTo: today
  });

  const handleRange = useCallback((arg) => {
    if (arg) {
      const from = arg[0];
      const to = arg[1];
      const formattedFrom = from.format(FORMATFORMAT);
      const formattedTo = to.format(FORMATFORMAT);
      const newTime = moment(formattedTo).add(24, 'hours').format(FORMATFORMAT);
      if (formattedFrom === formattedTo) {
        setRange({
          ...range,
          from,
          to,
          fromParsed: formattedFrom,
          toParsed: newTime
        });
      }
      setRange({
        ...range,
        from,
        to,
        fromParsed: from.format(FORMATFORMAT),
        toParsed: newTime
      });
    }
    // eslint-disable-next-line
  }, []);

  // NOTE: manage default value
  const hasRange = !!(range?.from && range?.to);
  const lastSevenDaysDefault = hasRange
    ? { from: new Date(range.fromParsed), to: new Date(range.toParsed) }
    : { from: new Date(range.defaultFrom), to: new Date(range.defaultTo) };

  return {
    handleRange,
    range,
    lastSevenDaysDefault,
    hasRange
  };
};

export default useRangeDateFilter;
