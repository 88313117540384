import { useEffect, useState } from 'react';
import useGetAdmins from './useGetAdmins';
import useGetRoles from './useGetRoles';

const useGetWarehouseman = () => {
  const { data: adminsData } = useGetAdmins();
  const { data: rolesData } = useGetRoles();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (adminsData.length > 0 && rolesData.length > 0) {
      const { id: roleId } = rolesData.find((role) => role.name === 'Auxiliar picking') || {};
      const warehouseman = adminsData.filter((admin) => admin.roleId === roleId);
      setData(warehouseman);
    }
  }, [adminsData, rolesData]);

  return {
    data
  };
};

export default useGetWarehouseman;
