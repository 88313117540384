import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const getUsersFromStore = createSelector(
  selectSelf,
  (state) => state.users
);

export const getUsersData = createSelector(
  getUsersFromStore,
  (users) => users.data
);

export const isUsersLoading = createSelector(
  getUsersFromStore,
  (users) => users.loading
);

export const hasUsersError = createSelector(
  getUsersFromStore,
  (users) => users.error
);

export const hasUsersData = createSelector(
  getUsersData,
  (users) => users.length > 0
);

export const isUsersLoaded = createSelector(
  isUsersLoading,
  hasUsersData,
  (isLoading) => !isLoading
);

const parseUsers = (d) => ({
  ...d,
  dni: d.dni || '-',
  phone: d.phone || '-'
});

export const getUsersList = createSelector(getUsersFromStore, (users) => {
  if (users.list.loading === false) {
    return {
      ...users.list,
      data: users.list.data.map(parseUsers)
    };
  }
  return users.list;
});

export const getAdminsList = createSelector(getUsersFromStore, (users) => {
  if (users.admins.loading === false) {
    return {
      ...users.admins,
      data: users.admins.data.map(parseUsers)
    };
  }
  return users.admins;
});

export const getUsersWithWallet = createSelector(
  getUsersFromStore,
  (users) => users.wallet
);

export const getUsersWithRei = createSelector(
  getUsersFromStore,
  (users) => users.preWallet
);

export const getOneUser = (userId) =>
  createSelector(
    getUsersFromStore,
    selectSelf,
    (users, state) =>
      users.list.data.find((user) => user?.id === userId) ||
      users.admins.data.find((user) => user?.id === userId) ||
      state.riders.data.find((user) => user?.id === userId)
  );

export const getUserMessageToken = createSelector(getUsersData, (users) =>
  users
    .filter(
      (user) =>
        user?.tokenNotification &&
        !user.notificationSettings?.disabledAll &&
        user.notificationSettings?.push.prescription
    )
    .map(({ id, tokenNotification }) => ({
      id,
      token: tokenNotification
    }))
);

export const getUsersByIds = (userIds) =>
  createSelector(getUsersData, (users) =>
    users.filter((user) => userIds.includes(user.id))
  );
