import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Button, Spin } from 'antd';
import styles from './DeleteSpecialCategory.module.scss';

const DeleteSpecialCategory = ({ cancelForm, handleDeleteSpecialCategory, current }) => {
  const { loading } = useSelector((state) => state.loader);
  return (
    <div className={styles.container}>
      <span className={styles.m_bottom_1}>
        Desea eliminar esta categoria especial?
      </span>
      <p className={styles.m_bottom_1}>{current.name}</p>

      <div className={styles.actions_button}>
        {loading ? (
          <Spin />
        ) : (
          <>
            <Button
              type="primary"
              htmlType="submit"
              id="DeleteSpecialCategory"
              onClick={() => handleDeleteSpecialCategory(current.id)}
              loading={loading}
            >
              Eliminar categoria especial
            </Button>

            <Button
              id="cancel"
              type="text"
              htmlType="button"
              onClick={cancelForm}
              loading={loading}
            >
              Cancelar
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

DeleteSpecialCategory.propTypes = {
  cancelForm: PropTypes.func.isRequired,
  handleDeleteSpecialCategory: PropTypes.func.isRequired,
  current: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default DeleteSpecialCategory;