import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from '../../firebase';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getAnnouncementsConfig = createAsyncThunk(
  '@marketing/getAnnouncementsConfig',
  async () => firebase.getAnnouncementsConfig()
);

export const createAnnouncementsConfig = createAsyncThunk(
  '@marketing/createAnnouncementsConfig',
  async ({ body }) => firebase.createAnnouncementConfig(body)
);

export const updateAnnouncementsConfig = createAsyncThunk(
  '@marketing/updateAnnouncementsConfig',
  async ({ id, body }) => {
    await firebase.updateAnnouncementConfig(id, body)
  }
);

export const deleteAnnouncementConfig = createAsyncThunk(
  '@marketing/deleteAnnouncementConfig',
  async (id) => {
    await firebase.deleteAnnouncementConfig(id)
  }
);

export const announcementsConfigSlide = createSlice({
  name: 'announcementsConfig',
  initialState,
  reducers: {},
  extraReducers: {
    [getAnnouncementsConfig.pending]: (state) => {
      state.loading = true;
    },
    [getAnnouncementsConfig.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getAnnouncementsConfig.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createAnnouncementsConfig.pending]: (state) => {
      state.loading = true;
    },
    [createAnnouncementsConfig.fulfilled]: (state) => {
      state.loading = false;
    },
    [createAnnouncementsConfig.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateAnnouncementsConfig.pending]: (state) => {
      state.loading = true;
    },
    [updateAnnouncementsConfig.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateAnnouncementsConfig.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteAnnouncementConfig.pending]: (state) => {
      state.loading = true;
    },
    [deleteAnnouncementConfig.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteAnnouncementConfig.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  }
});

export default announcementsConfigSlide.reducer;