import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMessagesConfig as getMessagesConfigFb } from 'firebase/api';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getMessagesConfig = createAsyncThunk(
  '@messagesConfig/getMessagesConfig',
  async () => getMessagesConfigFb()
);

export const MessagesConfigSlide = createSlice({
  name: 'messagesConfig',
  initialState,
  reducers: {
    setMessagesConfigLoading: (state) => {
      state.loading = true;
    },
    setMessagesConfig: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setMessagesConfigError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getMessagesConfig.pending]: (state) => {
      state.loading = true;
    },
    [getMessagesConfig.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getMessagesConfig.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setMessagesConfigLoading,
  setMessagesConfig,
  setMessagesConfigError
} = MessagesConfigSlide.actions;

export default MessagesConfigSlide.reducer;
