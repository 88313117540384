import { useEffect, useState } from 'react';
import useFirebaseContext from './useFirebaseContext';

const useGetAdmins = () => {
  const firebase = useFirebaseContext();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    try {
      const getAdmins = async () => {
        const res = await firebase.getAdmins();
        setData(res);
      };
      getAdmins();
    } catch (err) {
      setError(err);
    }
  }, [firebase]);

  return {
    data,
    error
  };
};

export default useGetAdmins;
