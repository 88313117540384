import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import { Button, Col, List, Row, Table } from 'antd';
import useUnmount from 'hooks/useOnMount';
import { getProfileId } from 'stores/selectors/profile/selector';
import styles from './OrderPacking.module.scss';
import { columns } from './helpers';
import moment from 'moment';
import { updateOrder } from 'firebase/api';
import useGetRoutesHook from 'hooks/componentHooks/useGetRoutesHook';

const OrderPacking = ({ order, close, packingStartTime }) => {
  const userLogged = useSelector(getProfileId);
  const { odooDataOrder, customizedInvoice } = order;
  const [productsSelected, setProductsSelect] = useState([]);
  const procoName = useSelector((state) => state.profile.proco);
  const [proco] = useState(procoName || 'No hay proco asignado.');
  const [isCompletedOrder, setIsCompletedOrder] = useState(false);
  const hasAllSelectedProducts =
    productsSelected?.length === odooDataOrder.products?.length;
  const [routeName, setRouteName] = useState('');
  const { getRoutes, routesData } = useGetRoutesHook();

  const formatDate = moment.unix(order?.date).format('DD-MM-YYYY hh:mm');

  const subtotal = order?.total - order?.tax;

  const address =
    order?.addressDetails?.city +
    ', ' +
    order?.addressDetails?.street +
    ', ' +
    order?.addressDetails?.house;

  const regex = /[A-Z]+[1-9]{1,2}/g;
  const sortedProducts = odooDataOrder.products?.slice().sort((a, b) => {
    const movesA = a.moves_location_id?.split('/')[2];
    const movesB = b.moves_location_id?.split('/')[2];
    if (
      a.moves_location_id === '' ||
      !a.moves_location_id ||
      isNaN(parseInt(movesA[1]))
    ) {
      return 1;
    }
    if (
      b.moves_location_id === '' ||
      !b.moves_location_id ||
      isNaN(parseInt(movesB[1]))
    ) {
      return -1;
    }
    const aParts = [...movesA.matchAll(regex)];
    const bParts = [...movesB.matchAll(regex)];
    const res =
      parseInt(aParts[0][0].substring(1)) - parseInt(bParts[0][0].substring(1));
    if (res !== 0) {
      return (
        parseInt(aParts[0][0].substring(1)) -
        parseInt(bParts[0][0].substring(1))
      );
    }
    return (
      parseInt(aParts[1][0].substring(1)) - parseInt(bParts[1][0].substring(1))
    );
  });

  const onSetPackingProcess = async (process = 'unpacked') => {
    try {
      const hasPacking = process !== 'unpacked';
      let body;
      if (hasPacking) {
        body = {
          onPacking: process,
          packagingDetail: {
            ...order?.packagingDetail,
            packingStartTime,
            packingEndTime: new Date(),
            warehouseman: userLogged,
            proco
          }
        };
      } else {
        body = {
          onPacking: process,
          packagingDetail: {
            packingStartTime: null,
            packingEndTime: null,
            warehouseman: null,
            proco: null
          }
        };
      }
      await updateOrder(order?.id, body);
      setIsCompletedOrder(true);
      close();
    } catch (err) {
      console.log(err);
    }
  };

  const getRouteName = () => {
    const r = routesData.find(
      (route) => route.id === order?.addressDetails?.routeId
    )?.name;
    setRouteName(r || 'Pickup');
  };

  React.useEffect(() => {
    if (routesData?.length === 0) getRoutes();
    getRouteName();
    // eslint-disable-next-line
  }, []);

  useUnmount(() => {
    if (!isCompletedOrder) {
      onSetPackingProcess();
    }
  });

  const orderDetails = [
    `Fecha: ${formatDate}`,
    `Nombre:  ${customizedInvoice?.fullName || order?.clientName}`,
    `Cedula: ${customizedInvoice.dni || customizedInvoice?.dni}`,
    `Telefono: ${customizedInvoice?.phone} - ${order?.addressDetails?.phone}`,
    `Direccion: ${address}`,
    `Metodo de Pago: ${order?.payment?.type}`
  ];

  const paymentDetails = [
    {
      title: 'SUBTOTAL:',
      value: subtotal.toFixed(2)
    },
    {
      title: 'IVA:',
      value: order?.tax
    },
    {
      title: 'TOTAL:',
      value: order?.total
    },
    {
      title: 'TOTAL USD:',
      value: order?.ref?.toFixed(2) || 0
    }
  ];

  const rowSelection = {
    type: 'checkbox',
    onChange: (_, selectedRows) => {
      setProductsSelect(selectedRows);
    },
    ...productsSelected
  };

  return (
    <Row gutter={[16, 32]}>
      <Col span={4} offset={18}>
        <div className={styles.header}>
          {routeName && (
            <div className={styles.routeName}>RUTA: {routeName}</div>
          )}
          <QRCode renderAs="svg" size={64} value={order?.id} />
        </div>
      </Col>
      <Col span={12}>
        <List
          style={{
            background: 'white',
            fontWeight: 'bold',
            marginTop: '2rem'
          }}
          dataSource={orderDetails}
          header={
            <div className={styles.name}>
              Orden: #{order?.odooDataOrder?.name}
            </div>
          }
          bordered
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </Col>
      <Col span={12}>
        <List
          style={{
            background: '#ffffff',
            fontWeight: 'bold',
            marginTop: '2rem'
          }}
          dataSource={paymentDetails}
          bordered
          renderItem={(item) => (
            <List.Item>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.value}>{item.value}</div>
            </List.Item>
          )}
        />
        <div style={{ marginTop: '9px' }}>
          <h1 className={styles.proco}>Proco: {proco}</h1>
        </div>
      </Col>

      <Col span={24}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={sortedProducts}
          rowSelection={rowSelection}
        />
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24} offset={9}>
            <Button
              type="primary"
              disabled={!hasAllSelectedProducts || !proco}
              onClick={() => {
                onSetPackingProcess('packed');
              }}
            >
              Completar Orden Seleccionadas
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OrderPacking;
