/* eslint-disable */
import { useEffect, useState } from 'react';
import { ROLES } from 'firebase/collections';
import { db } from 'firebase/firebaseConfig';
import { collection, onSnapshot, query } from 'firebase/firestore';

function useGetRoles() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      setLoading(true);
      const listen = query(collection(db, ROLES))
      onSnapshot(listen, (querySnapshot) => {
        const output = querySnapshot.docs.map(doc => ({
          ... doc.data(),
          id: doc.id,
        }))
        setData(output);
      })

      setLoading(false);
      return () => {
        listen
      }
  }, []);

  return {
    data,
    loading
  };
}
export default useGetRoles;
