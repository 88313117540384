import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBanners as getBannersFb } from 'firebase/api';
import firebase from '../../firebase';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getBanners = createAsyncThunk('@banners/getBanners', () =>
  getBannersFb()
);

export const createBanner = createAsyncThunk(
  '@banners/createBanner',
  async (pData) => {
    const { body } = pData;
    return await firebase.createBanner(body);
  }
);

export const updateBanner = createAsyncThunk(
  '@banners/updateBanner',
  async (pData) => {
    const { id, body } = pData;
    return await firebase.updateBanner(id, body);
  }
);

export const updateBannerWithoutFeedback = createAsyncThunk(
  '@banners/updateBannerWithoutFeedback',
  async (pData) => {
    const { id, body } = pData;
    await firebase.updateBanner(id, body);
    return body;
  }
);

export const BannersSlide = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    forceUpdateBannersInLocal: (state, action) => {
      const current = [...state.data];
      const index = current.findIndex((d) => d.id === action.payload.id);
      state.data[index] = action.payload;
    }
  },
  extraReducers: {
    [getBanners.pending]: (state) => {
      state.loading = true;
    },
    [getBanners.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getBanners.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createBanner.pending]: (state) => {
      state.loading = true;
    },
    [createBanner.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createBanner.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateBanner.pending]: (state) => {
      state.loading = true;
    },
    [updateBanner.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateBanner.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateBannerWithoutFeedback.rejected]: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { forceUpdateBannersInLocal } = BannersSlide.actions;

export default BannersSlide.reducer;
