import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Tooltip } from 'antd';
import { httpsCallable } from 'firebase/functions';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from 'stores/actions/notification';
import { loadingWithSpinner, loaded } from 'stores/actions/loader';
import { printInvoice } from 'services/billing';
import { searchProducts } from 'stores/actions/products';
import ProductOption from './ProductOption';
import { ReactComponent as RemoveIcon } from 'assets/images/remove_circle.svg';
import styles from './EditOrder.module.scss';
import { modifyOrder } from 'stores/actions/orders';
import { functions } from 'firebase/firebaseConfig';

const EditOrder = ({ cancel, order, markAsPrinted }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loader.loading);
  const { odooDataOrder } = order;
  const [products, setProducts] = React.useState([...order.products]);
  const [wereProductsEdited, setWereProductsEdited] = React.useState(false);
  const [productOptions, setProductOptions] = React.useState([]);
  const [showProductDetails, setShowProductDetails] = React.useState(false);

  const handleModifyOrder = async () => {
    try {
      dispatch(loadingWithSpinner());
      const finalOrder = {
        orderId: order.id,
        products
      };
      await dispatch(modifyOrder(finalOrder));
      dispatch(loaded());
      dispatch(
        showNotification({
          type: 'success',
          message: 'Exito',
          content: 'Orden actualizada exitosamente'
        })
      );
      const completeOrder = httpsCallable(functions, 'odoo-completeOrder');
      const invoiceData = await completeOrder({ orderId: order.id });
      await printInvoice(invoiceData.data);
      await markAsPrinted(order);
      dispatch(
        showNotification({
          type: 'success',
          message: 'Exito',
          content: 'Imprimiendo factura...'
        })
      );
      cancel();
    } catch (err) {
      dispatch(loaded());
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: err.message
        })
      );
    }
  };

  const searchProduct = async (value) => {
    const res = await dispatch(searchProducts({ query: value }));
    setProductOptions(res.payload.data[0]);
    setShowProductDetails(true);
  };

  return (
    <div>
      <div className={styles.container}>
        <span className={styles.orderNumber}>Orden #{odooDataOrder.name}</span>
      </div>
      <div className={styles.container}>
        <span className={styles.addProducts}>
          Agregar nuevos productos a la orden
        </span>
        <Input.Search
          placeholder="Buscar producto"
          enterButton
          onSearch={(e) => searchProduct(e)}
        />
      </div>
      <div>
        {showProductDetails && (
          <ProductOption
            product={productOptions}
            setProduct={setProductOptions}
            setShow={setShowProductDetails}
            products={products}
            setProducts={setProducts}
            setWereProductsEdited={setWereProductsEdited}
          />
        )}
      </div>
      <div>
        <span className={styles.productsTitle}>Productos</span>
        {products.map((product, idx) => {
          const newProduct = { ...product };
          return (
            <div key={product?.id || idx} className={styles.container}>
              <div className={styles.productContainer}>
                <div className={styles.leftColumn}>
                  <img
                    className={styles.img}
                    src={product.image}
                    alt={product.name}
                  />
                </div>
                <div className={styles.rightColumn}>
                  <div>
                    <div className={styles.titleFlex}>
                      <div>
                        <span className={styles.data}>{product.name}</span>
                      </div>
                      <div>
                        <Tooltip title="Eliminar producto">
                          <button
                            className={styles.removeButton}
                            disabled={products.length === 1}
                            type="button"
                            onClick={() => {
                              const newProducts = products.filter(
                                (p) => p.id !== product.id
                              );
                              setProducts(newProducts);
                              setWereProductsEdited(true);
                            }}
                          >
                            <RemoveIcon />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                    <div>
                      <span className={styles.subTitle}>
                        Precio unitario:{' '}
                        <span className={styles.data}>Bs. {product.price}</span>
                      </span>
                    </div>
                    <div>
                      <span className={styles.subTitle}>
                        Cantidad actual:{' '}
                        <span className={styles.data}>
                          {order?.products[idx]?.quantity || '1'}
                        </span>
                      </span>
                      <input
                        type="number"
                        min="1"
                        placeholder="Nueva cantidad"
                        className={styles.input}
                        onChange={(e) => {
                          if (e.target.value === '') {
                            newProduct.quantity =
                              order?.products[idx]?.quantity;
                          }
                          newProduct.quantity = parseInt(e.target.value);
                          setProducts(
                            [...products].map((p) =>
                              p.id === product.id ? newProduct : p
                            )
                          );
                          setWereProductsEdited(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {idx !== products.length - 1 && (
                <div className={styles.separator} />
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.separator} />
        <div className={styles.buttonContainer}>
          <Button
            className={styles.cancelButton}
            disabled={loading}
            onClick={cancel}
          >
            Cancelar
          </Button>
          <Button
            className={styles.confirmButton}
            disabled={loading || !wereProductsEdited}
            onClick={handleModifyOrder}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </div>
  );
};

EditOrder.propTypes = {
  cancel: PropTypes.func.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    odooDataOrder: PropTypes.shape({
      name: PropTypes.string
    }),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        price: PropTypes.number,
        quantity: PropTypes.number
      })
    )
  }).isRequired
};

export default EditOrder;
