/* eslint-disable max-len */
import {
  collection,
  query,
  where,
  getDocs,
  writeBatch,
  doc,
  getDoc,
  documentId,
  updateDoc,
  deleteDoc,
  addDoc,
  collectionGroup,
  orderBy,
  serverTimestamp,
  limit,
  deleteField,
  Timestamp
} from 'firebase/firestore';
import {
  getAuth,
  signInWithEmailAndPassword,
  getIdToken,
  signOut as signOutFb,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword as createUserWithEmailAndPasswordFb
} from 'firebase/auth';
import { setToken } from 'firebase/utils/token';
import {
  ADDRESSES,
  ADMIN,
  ADMINS,
  BANNERS,
  BIKES,
  COMMISSIONS,
  CONFIG,
  DEPARTAMENTS,
  MESSAGES,
  MODULES,
  NOTIFICATIONS,
  ORDERS,
  PERMISSIONS,
  PRODUCT_COMPANIES,
  RIDERS,
  ROADMAPS,
  ROLES,
  ROUTES,
  USERS,
  PROCO,
  PICKING,
  CAMPAIGNS,
  SCHEDULED_CAMPAIGNS
} from 'firebase/collections';
import {
  getArrayCollection,
  getFirstElementArrayCollection
} from 'firebase/utils/parsers';
import { chunk } from 'lodash';
import { httpsCallable } from 'firebase/functions';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString
} from 'firebase/storage';
import { db, auth, functions, storage } from '../firebaseConfig';
import { hasChildren } from '../../utils/utils';

const getCollectionRef = (collectionName, id) => doc(db, collectionName, id);

export const getAdminByEmail = async (email) => {
  const adminRef = await getDocs(
    query(collection(db, ADMINS), where('email', '==', email))
  );
  const snapshot = await adminRef;
  const data = getArrayCollection(snapshot);
  if (!snapshot.size) throw new Error('Usuario no existe');

  return data[0];
};

export const signOut = () => signOutFb(auth);

export const login = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    const admin = await getAdminByEmail(email);
    const { currentUser } = getAuth();
    const token = await getIdToken(currentUser);
    setToken(token);
    return { ...admin, token };
  } catch (error) {
    console.log('error', error);
    signOut();
    return error?.message;
  }
};

export const getClientToken = async (email, password) => {
  try {
    const authObj = await signInWithEmailAndPassword(auth, email, password);
    const token = await authObj.user.getIdToken();

    setToken(token);

    return {
      token
    };
  } catch (error) {
    console.log('error', error);
    signOut();
    return error?.message;
  }
};

export const createUserWithEmailAndPassword = (email, password) =>
  createUserWithEmailAndPasswordFb(auth, email, password);

export const resetPassword = (email) => sendPasswordResetEmail(auth, email);

export const multiOperation = async (items) => {
  const batch = writeBatch(db);
  const blocksLength = 499; // limit of batch operation at the same time.

  const blocks = chunk(items, blocksLength);

  blocks.forEach((block) => {
    block.forEach(({ id, body, operation, model, children }) => {
      if (hasChildren(children)) {
        const parentRef = getCollectionRef(model, id);
        const childrenRef = getDoc(parentRef);

        if (children.operation !== 'delete') {
          batch[children.operation](childrenRef, children.body);
        } else {
          batch[children.operation](childrenRef);
        }
      } else {
        const ref = getCollectionRef(model, id);
        if (operation !== 'delete') {
          batch[operation](ref, body);
        } else {
          batch[operation](ref);
        }
      }
    });
  });

  await batch.commit();
};

export const getAdmins = async () => {
  const adminsRef = await getDocs(query(collection(db, ADMINS)));
  const snapshot = adminsRef;

  return getArrayCollection(snapshot);
};

export const getAdminById = async (id) => {
  const adminRef = await getDocs(
    query(collection(db, ADMINS), where(documentId(), '==', id))
  );
  const snapshot = await adminRef;

  return getFirstElementArrayCollection(snapshot);
};

export const verifiedAdminExists = async (admin) => {
  const { email, dni } = admin;
  const adminRef = await getDocs(
    query(
      collection(db, ADMINS),
      where('emaild', '==', email),
      where('dni', '==', dni)
    )
  );
  const snapshot = await adminRef;

  if (snapshot.size > 0) return true;

  return false;
};

export const updateAdmin = async (adminId, adminFields) => {
  const adminRef = doc(db, ADMINS, adminId);
  await updateDoc(adminRef, adminFields);
};

export const deleteAdmin = async (adminId) => {
  const adminRef = doc(db, ADMINS, adminId);
  await deleteDoc(adminRef);
};

export const getRoles = async () => {
  const rolesRef = await getDocs(query(collection(db, ROLES)));
  const snapshot = await rolesRef;

  return getArrayCollection(snapshot);
};

export const getPermissions = async () => {
  const permissionsRef = await getDocs(query(collection(db, PERMISSIONS)));
  const snapshot = await permissionsRef;

  getArrayCollection(snapshot);
};

export const getPermissionsByRole = async (id) => {
  const roleRef = await getDocs(
    query(collection(db, ROLES), where(documentId(), '==', id))
  );
  const snapshot = await roleRef;
  return getFirstElementArrayCollection(snapshot);
};

export const createRole = async (data) => {
  await addDoc(collection(db, ROLES), data);
};

export const updateRole = async (data, roleId) => {
  const roleRef = doc(db, ROLES, roleId);
  await updateDoc(roleRef, data);
};

export const checkRolesInAdmins = async (roleId) => {
  const adminRef = await getDocs(
    query(collection(db, ADMINS), where('roleId', '==', roleId))
  );
  const snapshot = await adminRef;
  if (snapshot.size > 0) return true;

  return false;
};

export const deleteRole = async (id) => {
  const roleRef = doc(db, ROLES, id);
  await deleteDoc(roleRef);
};

export const getAllUsers = async () => {
  const usersRef = await getDocs(query(collection(db, USERS)));
  const snapshot = await usersRef;

  return getArrayCollection(snapshot);
};

export const getUsersLimited = async (queryLimit) => {
  const usersRef = await getDocs(
    query(collection(db, USERS), limit(queryLimit))
  );
  const snapshot = await usersRef;
  return getArrayCollection(snapshot);
};

export const getOneUser = async (email) => {
  const userRef = await getDocs(
    query(collection(db, USERS), where('email', '==', email))
  );
  const snapshot = await userRef;

  return getArrayCollection(snapshot);
};

export const getAdminsByIds = async (usersIds) => {
  const usersRef = await getDocs(
    query(collection(db, ADMINS), where('uid', 'in', usersIds))
  );
  const snapshot = usersRef;
  return getArrayCollection(snapshot);
};

export const getUserById = async (userId) => {
  const userRef = await doc(db, USERS, userId);
  const snapshot = await getDoc(userRef);

  return snapshot.data();
};

export const getUsersByIds = async (usersIds) => {
  const usersRef = await getDocs(
    query(collection(db, USERS), where(documentId(), 'in', usersIds))
  );
  const snapshot = usersRef;
  return getArrayCollection(snapshot);
};

export const getUsersWithWallet = async () => {
  const usersRef = await getDocs(
    query(collection(db, USERS), where('wallet', '!=', ''))
  );
  const snapshot = usersRef;
  const docs = [];
  snapshot.forEach((doc) => {
    const data = doc.data();
    const lastUpdateDate = data.wallet.lastAdminUpdate?.date
      ? data.wallet.lastAdminUpdate?.date.toDate()
      : new Date(10);
    const formattedData = {
      ...data,
      wallet: {
        ...data.wallet,
        creditTop: data.wallet.creditTop / 100, // format creditTop value by dividing it by 100
        credit: data.wallet.credit / 100, // format credit value by dividing it by 100
        lastUpdateAdmin: `${lastUpdateDate.getFullYear()}-${
          lastUpdateDate.getMonth() + 1
        }-${lastUpdateDate.getDate()}`,
        lastUpdateAdminOwner: data.wallet.lastAdminUpdate?.by || 'No sabemos',
        lastUpdateAdminOverwritted: data.wallet.lastAdminUpdate?.replace
          ? 'Reemplazado'
          : 'Acumulado'
      }
    };
    docs.push({
      id: doc.id,
      ...formattedData
    });
  });
  return docs;
};

export const getUsersWithRei = async () => {
  const usersRef = await getDocs(
    query(collection(db, USERS), where('preWallet', '!=', ''))
  );
  const snapshot = usersRef;
  const docs = [];
  snapshot.forEach((doc) => {
    const data = doc.data();
    const lastUpdateDate = data.preWallet.lastAdminUpdate?.date
      ? data.preWallet.lastAdminUpdate?.date.toDate()
      : new Date(10);
    const formattedData = {
      ...data,
      preWallet: {
        ...data.preWallet,
        creditTop: data.preWallet.creditTop / 100, // format creditTop value by dividing it by 100
        credit: data.preWallet.credit / 100, // format credit value by dividing it by 100
        lastUpdateAdmin: `${lastUpdateDate.getFullYear()}-${
          lastUpdateDate.getMonth() + 1
        }-${lastUpdateDate.getDate()}`,
        lastUpdateAdminOwner: data.preWallet.lastAdminUpdate?.by || 'No sabemos',
        lastUpdateAdminOverwritted: data.preWallet.lastAdminUpdate?.replace
          ? 'Reemplazado'
          : 'Acumulado'
      }
    };
    docs.push({
      id: doc.id,
      ...formattedData
    });
  });
  return docs;
};

export const updateUser = async (userId, userFields) => {
  const userRef = doc(db, USERS, userId);
  await updateDoc(userRef, userFields);
};

export const searchProducts = ({
  // eslint-disable-next-line no-shadow
  query = '',
  pageSize = 100,
  actualPage = 1,
  companyId = 1,
  category,
  recommended,
  offers,
  type,
  sort,
  stock = null
}) => {
  return httpsCallable(
    functions,
    'es-search'
  )({
    query,
    company_id: companyId,
    pageSize,
    actualPage,
    category,
    recommended,
    type,
    sort,
    offers,
    stock
  });
};

export const searchProductsByIds = (ids) =>
  httpsCallable(functions, 'es-searchById')({ ids });

/* Bikes */

export const createBike = async (bike) => {
  const { id } = await addDoc(collection(db, BIKES), bike);
  return id;
};

export const getBikes = async () => {
  const bikesRef = await getDocs(query(collection(db, BIKES)));
  const snapshot = await bikesRef;

  return getArrayCollection(snapshot);
};

export const updateBike = async (bikeId, { brand, description, plate }) => {
  const bikeRef = doc(db, BIKES, bikeId);
  await updateDoc(bikeRef, {
    brand,
    description,
    plate
  });
};

export const deleteBike = (bikeId) => deleteDoc(doc(db, BIKES, bikeId));

/* Riders */

export const getRiders = async () => {
  const ridersRef = await getDocs(query(collection(db, RIDERS)));
  const snapshot = await ridersRef;
  return getArrayCollection(snapshot);
};

export const getRiderById = async (riderId) => {
  const riderRef = doc(db, RIDERS, riderId);
  const snapshot = await getDoc(riderRef);
  return snapshot.data();
};

export const updateRider = async (riderId, data) => {
  const riderRef = await doc(db, RIDERS, riderId);
  return updateDoc(riderRef, data).then(() => ({ status: 200 }));
};

/* Departments API */

export const getDepartments = async () => {
  const departmentsRef = await getDocs(query(collection(db, DEPARTAMENTS)));
  const snapshot = await departmentsRef;

  return getArrayCollection(snapshot);
};

export const getDepartmentByRole = async (id) => {
  const departmentRef = await doc(db, DEPARTAMENTS, id);
  const snapshot = await getDoc(departmentRef);
  return snapshot.data();
};

export const createDepartment = async (record) =>
  addDoc(collection(db, DEPARTAMENTS), record);

export const updateDepartment = async (record) => {
  const { key } = record;
  const departmentRef = await doc(db, DEPARTAMENTS, key);
  await updateDoc(departmentRef, {
    name: record.name,
    active: record.active
  });
};

export const deleteDepartment = async (id) => {
  await deleteDoc(doc(db, DEPARTAMENTS, id));
};

export const checkDepartmentInAdmins = async (id) => {
  const adminRef = await getDocs(
    query(collection(db, ADMINS), where('departmentId', '==', id))
  );
  const snapshot = await adminRef;
  if (snapshot.size > 0) return true;

  return false;
};

/* Images Api */

export const uploadImage = async (path, imageB64, userAvatar, userId) => {
  const imageName = userId;
  const destination = `${path}/${imageName}`;

  /* delete to clean exists */
  if (userAvatar) {
    const pictureRef = ref(storage, userAvatar);
    await deleteObject(pictureRef);
  }
  const imageRef = ref(storage, destination);

  const uploadTask = uploadString(imageRef, imageB64, 'data_url');

  return uploadTask.then((snapshot) =>
    getDownloadURL(snapshot.ref).then((url) => ({ url, photo: imageB64 }))
  );
};

/* Category Box Image Upload */

export const uploadImageCategory = async (file, categoryName, index = 0) => {
  const storageRef = ref(
    storage,
    `categories/${categoryName}/item_${index}_${file.name}`
  );
  const snapshot = await uploadBytes(storageRef, file);
  return getDownloadURL(snapshot.ref);
};

/* Addresses API */

export const getAddresses = async () => {
  const addressesRef = await query(collectionGroup(db, ADDRESSES));
  const snapshot = await getDocs(addressesRef);
  return getArrayCollection(snapshot);
};

export const createAddress = async (userId, address) => {
  const addressesRef = await doc(db, USERS, userId);
  const colRef = await collection(addressesRef, ADDRESSES);
  await addDoc(colRef, address);
};

export const updateAddress = async (addressId, address) => {
  const addressRef = await doc(db, ADDRESSES, addressId);
  await updateDoc(addressRef, address);
};

export const deleteAddress = async (userId, addressId) => {
  const colRef = await doc(db, `${USERS}/${userId}/${ADDRESSES}/${addressId}`);
  updateDoc(colRef, { deletedAt: Date.now() });
};

export const getUserAddress = async (userId) => {
  const addressesRef = await getDocs(
    query(collection(db, USERS, userId, ADDRESSES))
  );
  const snapshot = addressesRef;
  return getArrayCollection(snapshot);
};

export const getAddressById = async (addressId) => {
  const addressRef = await getDocs(
    query(collectionGroup(db, ADDRESSES, addressId))
  );
  const snapshot = addressRef;
  return getArrayCollection(snapshot);
};

/* Routes API */
export const getRoutes = async () => {
  const routesRef = await getDocs(
    query(collection(db, ROUTES), orderBy('name', 'asc'))
  );
  const snapshot = await routesRef;
  return getArrayCollection(snapshot);
};

export const createRoute = async (route) => {
  const createdAt = new Date();
  await addDoc(collection(db, ROUTES), { ...route, createdAt });
};

export const updateRoute = async (routeId, route) => {
  const routeRef = await doc(db, ROUTES, routeId);
  await updateDoc(routeRef, route);
};

/* Orders API */

export const updateOrders = async (ids) => {
  const orders = ids.map((id) => ({
    id,
    model: ORDERS,
    operation: 'update',
    body: { statusCode: 0 }
  }));

  await multiOperation(orders);
};

export const updateOrdersCrossdocking = async (ids) => {
  const orders = ids.map((id) => ({
    id,
    model: ORDERS,
    operation: 'update',
    body: { statusCode: 1 }
  }));

  await multiOperation(orders);
};

export const cancelMultipleOrders = async (orders) => {
  orders.forEach((order) => {
    const orderId = order.id;
    const body = {
      // updated to complete status code to satisfy leo's requirement.
      statusCode: 3,
      status: {
        text: 'completed',
        type: order.status.type
      }
    };
    updateOrder(orderId, body);
  });
};

export const updateOrder = async (id, body) => {
  const orderRef = doc(db, ORDERS, id);
  await updateDoc(orderRef, body);
};

export const getOrders = async () => {
  const ordersRef = await getDocs(query(collection(db, ORDERS)));
  const snapshot = await ordersRef;

  return getArrayCollection(snapshot);
};

export const getPharmaOrders = async () => {
  const ordersRef = await getDocs(
    query(
      collection(db, ORDERS),
      where('statusCode', '==', -2),
      orderBy('odooDataOrder.name', 'asc')
    )
  );
  const snapshot = ordersRef;
  return getArrayCollection(snapshot);
};

export const getOrdersLimited = async (queryLimit) => {
  const ordersRef = await getDocs(
    query(collection(db, ORDERS), orderBy('date', 'desc'), limit(queryLimit))
  );
  const snapshot = await ordersRef;
  return getArrayCollection(snapshot);
};

export const getOrdersByStatusCodeLimited = async (queryLimit, statusCode) => {
  const ordersRef = await getDocs(
    query(
      collection(db, ORDERS),
      orderBy('date', 'desc'),
      limit(queryLimit),
      where('statusCode', '==', statusCode)
    )
  );
  const snapshot = await ordersRef;
  return getArrayCollection(snapshot);
};

export const getOrdersByUser = async (clientId) => {
  const ordersRef = await getDocs(
    query(collection(db, ORDERS), where('clientId', '==', clientId))
  );
  const snapshot = await ordersRef;

  return getArrayCollection(snapshot);
};

export const getOrdersByRange = async (from, to) => {
  const ordersRef = await getDocs(
    query(
      collection(db, ORDERS),
      where('date', '>=', from),
      where('date', '<=', to),
      orderBy('date', 'desc')
    )
  );
  const snapshot = await ordersRef;

  return getArrayCollection(snapshot);
};

export const getOrdersIdWallet = async (from, to) => {
  const ordersRef = await getDocs(
    query(
      collectionGroup(db, 'walletMovements'),
      where('createAt', '>=', from),
      where('createAt', '<=', to),
      orderBy('createAt', 'desc')
    )
  );
  const snapshot = await ordersRef;

  return getArrayCollection(snapshot);
};

export const getOrdersIdRei = async (from, to) => {
  const ordersRef = await getDocs(
    query(
      collectionGroup(db, 'preWalletMovements'),
      where('createAt', '>=', from),
      where('createAt', '<=', to),
      orderBy('createAt', 'desc')
    )
  );
  const snapshot = await ordersRef;

  return getArrayCollection(snapshot);
};

export const getOrderById = async (orderId) => {
  const ordersRef = await getDocs(
    query(collection(db, ORDERS), where('odooDataOrder.name', '==', orderId))
  );
  const snapshot = await ordersRef;
  return getArrayCollection(snapshot);
};

export const getOrderByIds = async (orderIds) => {
  const blocks = chunk(orderIds);
  const resOrders = Promise.all(
    blocks.map(async (block) => {
      const docs = await getDocs(
        query(collection(db, ORDERS), where(documentId(), 'in', block))
      );
      const snapshot = await docs;
      return getArrayCollection(snapshot);
    })
  );

  return resOrders.then((orders) =>
    orders.reduce((prev, next) => prev.concat(next))
  );
};

export const getUserByIdsPower = async (userIds) => {
  const blocks = chunk(userIds);
  const resUsers = Promise.all(
    blocks.map(async (block) => {
      const docs = await getDocs(
        query(collection(db, USERS), where(documentId(), 'in', block))
      );
      const snapshot = await docs;
      return getArrayCollection(snapshot);
    })
  );
  return resUsers.then((users) =>
    users.reduce((prev, next) => prev.concat(next))
  );
};

export const getOrdersByCoupon = async (couponId) => {
  const ordersRef = await getDocs(
    query(collection(db, ORDERS), where('discount.code', '==', couponId))
  );

  const snapshot = await ordersRef;

  return getArrayCollection(snapshot);
};

export const getOrdersWithoutRoadmap = async () => {
  const orders = (
    await getDocs(
      query(
        collection(db, ORDERS),
        where('statusCode', 'not-in', [3, 4, 5, 7, -1, -2])
      )
    )
  ).docs.map((OR) => ({
    id: OR.id,
    parent: OR.ref.parent?.parent?.id,
    statusCode: 0,
    ...OR.data()
  }));
  const RMs = (
    await getDocs(
      query(collection(db, ROADMAPS), where('status', '!=', 'completed'))
    )
  ).docs.map((RM) => RM.data());
  let ORsWORMs = [];
  if (RMs.length === 0) {
    ORsWORMs = orders;
  }
  for (const order of orders) {
    if (!RMs.find((RM) => RM.ordersIds.includes(order.id))) {
      ORsWORMs.push(order);
    }
  }
  return ORsWORMs;
};

/* Roadmaps API */

export const deleteRoadmaps = async (roadmapId) => {
  await deleteDoc(doc(db, ROADMAPS, roadmapId));
};

export const getFinishedRoadmaps = async () => {
  const roadmapsRef = await getDocs(
    query(
      collection(db, ROADMAPS),
      where('status', '==', 'completed'),
      orderBy('createdAt', 'desc'),
      limit(150)
    )
  );
  const snapshot = roadmapsRef;
  return getArrayCollection(snapshot);
};

export const getRoadmapByNumber = async (roadmapNumber) => {
  const roadmapRef = await getDocs(
    query(collection(db, ROADMAPS), where('roadmapNumber', '==', roadmapNumber))
  );
  const snapshot = roadmapRef;
  return getArrayCollection(snapshot);
};

export const getRoadmaps = async () => {
  const roadmapsRef = await getDocs(query(collection(db, ROADMAPS)));
  const snapshot = roadmapsRef;
  return getArrayCollection(snapshot);
};

/* Config API */

/// Messages Config API

export const createMessageConfig = async (record) => {
  const messageConfigRef = await doc(db, CONFIG, ADMIN);
  const colRef = await collection(messageConfigRef, MESSAGES);
  addDoc(colRef, record);
};

export const updateMessageConfig = async (id, record) => {
  const messageConfigRef = await doc(db, CONFIG, ADMIN, MESSAGES, id);

  await updateDoc(messageConfigRef, record);
};

export const deleteConfigMessage = async (id) => {
  deleteDoc(doc(db, CONFIG, 'admin', MESSAGES, id));
};

export const getMessagesConfig = async () => {
  const messageConfigRef = await getDocs(
    query(collection(db, CONFIG, ADMIN, MESSAGES))
  );
  const snapshot = await messageConfigRef;

  return getArrayCollection(snapshot);
};

/// Config Commissions

export const getCommissionsConfig = async () => {
  const commissionsConfigRef = await getDocs(
    query(collection(db, CONFIG, ADMIN, COMMISSIONS))
  );
  const snapshot = await commissionsConfigRef;

  return getArrayCollection(snapshot);
};

export const createCommissionConfig = async (record) => {
  const createAt = serverTimestamp();
  const output = { ...record, createAt };
  const commissionConfigRef = await doc(db, CONFIG, ADMIN);
  const colRef = await collection(commissionConfigRef, COMMISSIONS);

  addDoc(colRef, output);
};

export const updateCommissionConfig = async (pId, record) => {
  const { id, key, parent, ...resData } = record;
  const createAt = serverTimestamp();
  const output = { ...resData, createAt };

  const commissionConfigRef = await doc(db, CONFIG, ADMIN, COMMISSIONS, pId);

  updateDoc(commissionConfigRef, output);
};

export const deleteCommissionConfig = async (id) => {
  const commissionConfigRef = await doc(db, CONFIG, ADMIN, COMMISSIONS, id);
  await deleteDoc(commissionConfigRef);
};

/* Config Product Companies */

export const getProductCompanies = async () => {
  const productCompaniesRef = await getDocs(
    query(collection(db, CONFIG, ADMIN, PRODUCT_COMPANIES))
  );
  const snapshot = await productCompaniesRef;
  return getArrayCollection(snapshot);
};

/* Config Picking */

export const getPickingConfig = async () => {
  const pickingConfigSnapshot = await getDoc(doc(db, CONFIG, PICKING));
  return pickingConfigSnapshot.data();
};

export const updatePickingConfig = async (record) => {
  const pickingConfigRef = doc(db, CONFIG, PICKING);
  await updateDoc(pickingConfigRef, record);
};

// Roadmaps

export const updateRoadmap = async (roadmapId, roadmap) => {
  const roadmapRef = doc(db, ROADMAPS, roadmapId);
  await updateDoc(roadmapRef, roadmap);
};

// Notifications

export const getNotifications = async () => {
  const notificationRef = await getDocs(query(collection(db, NOTIFICATIONS)));
  return getArrayCollection(notificationRef);
};

export const createNotification = (notification) =>
  addDoc(collection(db, NOTIFICATIONS), notification);

// Roles Config

export const getModules = async () => {
  const modulesRef = await getDocs(
    query(collection(db, CONFIG, ADMIN, MODULES))
  );
  const snapshot = modulesRef;

  return getArrayCollection(snapshot);
};

// Banners
export const getBanners = async () => {
  const bannersRef = await getDocs(
    query(collection(db, CONFIG, ADMIN, BANNERS))
  );
  const snapshot = bannersRef;
  return getArrayCollection(snapshot);
};

export const deleteBanner = async (id) => {
  const bannerRef = await doc(db, CONFIG, ADMIN, BANNERS, id);
  await deleteDoc(bannerRef);
};

/* Function to valid if the user is container in whitelist to limit listener */

export const includeLimits = async () => {
  const userEmail = await getAuth().currentUser?.email;
  const { roleId } = await getAdminByEmail(userEmail);
  const configDoc = await doc(db, CONFIG, ADMIN);
  const configData = await getDoc(configDoc);
  const { userListenerOrders = [] } = await configData.data();

  if (userListenerOrders.includes(roleId)) return true;

  return false;
};

/* Commerce to Person */

export const getCommerceToPerson = async (justAdmin = false, from, to) => {
  const q = justAdmin
    ? query(
        collection(db, CONFIG, 'PagoMovil', 'PMDevueltos'),
        where('fromAdmin', '==', true)
      )
    : query(
        collection(db, CONFIG, 'PagoMovil', 'PMDevueltos'),
        where('fecha', '>=', from),
        where('fecha', '<=', to)
      );
  const colRef = await getDocs(q);
  const snapshot = await colRef;

  return getArrayCollection(snapshot);
};

export const createCommerceToPerson = async (record) => {
  const createAt = serverTimestamp();
  const output = { ...record, fecha: createAt };
  const docRef = await doc(db, CONFIG, 'PagoMovil');
  const colRef = await collection(docRef, 'PMDevueltos');

  addDoc(colRef, output);
};

// Procos
export const getProcoByUserId = async (userId) => {
  const procoRef = await getDocs(
    query(collection(db, PROCO), where('users', 'array-contains', userId))
  );
  const snapshot = await procoRef;
  return snapshot.docs.map((doc) => doc.data());
};

export const getAllProcos = async () => {
  const procosRef = await getDocs(query(collection(db, PROCO)));
  const snapshot = procosRef;

  return getArrayCollection(snapshot);
};

export const createProco = async (proco) => {
  await addDoc(collection(db, PROCO), proco);
};

export const getRiderProcos = async () => {
  const procoRef = await getDocs(
    query(collection(db, PROCO), where('type', '==', 'rider'))
  );
  const snapshot = procoRef;
  return getArrayCollection(snapshot);
};

export const getWarehouseProcos = async () => {
  const procoRef = await getDocs(
    query(collection(db, PROCO), where('type', '==', 'almacen'))
  );
  const snapshot = procoRef;
  return getArrayCollection(snapshot);
};

export const removeProductsByOrder = (body) =>
  httpsCallable(functions, 'odoo-removeProducts')(body);

export const getPaymentMethodsFb = async () => {
  const docRef = doc(db, CONFIG, 'paymentMethods');
  const docSnap = await getDoc(docRef);
  return docSnap.data();
};

/* eslint-disable max-len */

export const getShoppingCart = async (userId) => {
  const shoppingCartRef = await getDocs(
    query(collection(db, USERS, userId, 'shopCart'))
  );
  const snapshot = await shoppingCartRef;
  return getArrayCollection(snapshot);
};

export const cleanShoppingCartToUser = async (userId, docId, updateFields) => {
  const targetRef = doc(db, USERS, userId, 'shopCart', docId);
  return updateDoc(targetRef, {
    ...updateFields,
    lastUpdate: new Date().getTime()
  });
};

// Get calculatedCart Prices

export const getRealCartPrices = async (email) =>
  httpsCallable(functions, 'users-calculateCart')(email);

export const blockCashback = async (order, user) => {
  try {
    const body = {
      'payment.aDevolver': deleteField(),
      'payment.logs': [
        {
          message: `Cashback bloqueado por ${user.name}`,
          timestamp: Date.now(),
          admin: {
            id: user.id,
            name: user.name,
            dni: user.dni
          }
        }
      ]
    };
    const orderRef = doc(db, ORDERS, order?.id);
    await updateDoc(orderRef, body);
  } catch (error) {
    console.log(error);
  }
};

// Marketing - SMS

export const getCampaigns = async () => {
  const campaignsRef = await getDocs(query(collection(db, CAMPAIGNS)));
  return getArrayCollection(campaignsRef);
};

export const createCampaign = async (campaign) => {
  const { date } = campaign;
  const TimeStampDate = Timestamp.fromDate(date);
  const output = { ...campaign, date: TimeStampDate };
  await addDoc(collection(db, CAMPAIGNS), output);
};

export const getScheduledCampaigns = async () => {
  const campaignsRef = await getDocs(
    query(collection(db, SCHEDULED_CAMPAIGNS), orderBy('date', 'asc'))
  );
  return getArrayCollection(campaignsRef);
};

export const createScheduledCampaign = async (campaign) => {
  const { date } = campaign;
  const TimeStampDate = Timestamp.fromDate(date);
  const output = { ...campaign, date: TimeStampDate };
  await addDoc(collection(db, SCHEDULED_CAMPAIGNS), output);
};

// Checkout - Create Order

export const createOrder = async (payload) =>
  httpsCallable(functions, 'odoo-createOrderAdmin')(payload);

export const exchangePhotos = async (productsToExchange) =>
  httpsCallable(functions, 'products-exchangePhotos')({ productsToExchange });

export const removePhoto = async (body) =>
  httpsCallable(functions, 'products-removePhotos')(body);
