import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageHeader } from 'antd';
import { useSelector } from 'react-redux';
import useSetHeaderProps from 'hooks/useSetHeaderProps';

const HeaderPages = () => {
  const {
    title,
    subTitle,
    fallbackRoute,
    showBackArrow,
    extraButtons,
    showHeader
  } = useSelector((state) => state.header);
  const setHeaderProps = useSetHeaderProps();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const splitLocation = location.pathname
      .split('/')
      .filter((item) => item.length > 0);

    setRoutes([
      { path: '/', breadcrumbName: t('menu.home') },
      ...splitLocation.map((route) => {
        const pathName = `menu.${route}`;
        const routeName = i18n.exists(pathName) ? t(pathName) : route;
        return {
          path: `/${route}`,
          breadcrumbName: fallbackRoute || routeName
        };
      })
    ]);

    setHeaderProps({
      extraButtons: [],
      title: ''
    });
  }, [location, i18n, fallbackRoute, setHeaderProps, t]);

  const onBack = () => history.goBack();

  const itemRender = ({ breadcrumbName }) => <span>{breadcrumbName}</span>;

  const renderTitle = () =>
    title || (routes.length >= 2 && routes[1]?.breadcrumbName);

  return (
    showHeader && (
      <PageHeader
        onBack={showBackArrow || routes.length >= 3 ? onBack : undefined}
        breadcrumb={{ routes, itemRender }}
        title={renderTitle()}
        subTitle={subTitle}
        extra={extraButtons}
      />
    )
  );
};

export default HeaderPages;
