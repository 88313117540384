import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteRoadmaps as deleteRoadmapsFb,
  updateRoadmap,
  multiOperation,
  getFinishedRoadmaps,
  getRoadmapByNumber,
  updateOrder,
  updateRider
} from 'firebase/api';
import { ROADMAPS, RIDERS } from 'firebase/collections';
import moment from 'moment';
import { getProfileId } from 'stores/selectors/profile/selector';
import { v4 as uuidv4 } from 'uuid';

export const initialState = {
  data: [],
  loading: false,
  error: false,
  done: {
    loading: false,
    error: false,
    data: []
  }
};

export const cancelOrdersIntoRoadmap = createAsyncThunk(
  '@roadmaps/cancelOrdersIntoRoadmap',
  async (roadmap) => {
    const { id, ...body } = roadmap;
    await updateRoadmap(id, body);
  }
);

export const deleteRoadmaps = createAsyncThunk(
  '@roadmaps/deleteRoadmaps',
  async (roadmapId) => {
    await deleteRoadmapsFb(roadmapId);
  }
);

export const transferRoadmaps = createAsyncThunk(
  '@roadmaps/transferRoadmaps',
  async ({ rmParent, roadmapsToUpdate, riderToUpdate }, context) => {
    const userId = getProfileId(context.getState());
    const toBatch = roadmapsToUpdate.map((rm) => {
      const body =
        rm.ordersLeft.length > 0
          ? {
              ordersIds: rm.ordersLeft
            }
          : {
              log: [
                ...rm.log,
                {
                  id: uuidv4(),
                  type: 'close',
                  description: 'roadmapClosedManually',
                  target: '',
                  date: moment().unix()
                }
              ],
              status: 'completed', // FIXME: Create new status for those roadmaps that have been closed like -> closed
              feedback: {
                date: moment().unix(),
                text: '',
                userId
              }
            };

      return {
        id: rm.id,
        model: ROADMAPS,
        operation: 'update',
        body
      };
    });

    const toBatchWithParentRoadmap = [
      ...toBatch,
      {
        id: rmParent.id,
        model: ROADMAPS,
        operation: 'update',
        body: rmParent.body
      },
      {
        id: riderToUpdate.id,
        model: RIDERS,
        operation: 'update',
        body: riderToUpdate.body
      }
    ];

    await multiOperation(toBatchWithParentRoadmap);
  }
);

export const mergeRoadmaps = createAsyncThunk(
  '@roadmaps/mergeRoadmaps',
  async ({ roadmapMerged, roadmapsToUpdate }, context) => {
    const userId = getProfileId(context.getState());
    const toBatch = roadmapsToUpdate.map((rm) => {
      const body = {
        ordersIds: [],
        log: [
          ...rm.log,
          {
            id: uuidv4(),
            type: 'close',
            description: 'roadmapMerged',
            target: '',
            date: moment().unix()
          }
        ],
        status: 'completed',
        feedback: {
          date: moment().unix(),
          text: '',
          userId
        }
      };
      return {
        id: rm.id,
        model: ROADMAPS,
        operation: 'update',
        body
      };
    });

    const toBatchWithParentRoadmap = [
      ...toBatch,
      {
        id: roadmapMerged.id,
        model: ROADMAPS,
        operation: 'update',
        body: {
          ordersIds: roadmapMerged.ordersIds
        }
      }
    ];
    await multiOperation(toBatchWithParentRoadmap);
  }
);

export const mergeWithActiveRoadmap = createAsyncThunk(
  '@roadmaps/mergeRoadmaps',
  async ({ activeRoadmap, waitingRoadmap }, context) => {
    const userId = getProfileId(context.getState());

    const newActiveRoadmap = {
      ordersIds: [...activeRoadmap?.ordersIds, ...waitingRoadmap?.ordersIds]
    };
    const newWaitingRoadmap = {
      log: [
        ...waitingRoadmap.log,
        {
          id: uuidv4(),
          type: 'close',
          description: 'roadmapClosedManually',
          target: '',
          date: moment().unix()
        }
      ],
      status: 'completed',
      feedback: {
        date: moment().unix(),
        text: '',
        userId
      }
    };

    try {
      waitingRoadmap.ordersIds.forEach((order) => {
        const body = { statusCode: 1, riderId: activeRoadmap.riderId };
        updateOrder(order, body);
      });

      await updateRoadmap(activeRoadmap.id, newActiveRoadmap);
      await updateRoadmap(waitingRoadmap.id, newWaitingRoadmap);
    } catch (err) {
      console.log(err);
    }
  }
);

export const reassignOrderWithoutRoadmap = createAsyncThunk(
  '@roadmaps/mergeRoadmaps',
  async ({ mergeAutomatically, orderToUpdate, roadmapToUpdate }, context) => {
    // Merge automatically --> set order statusCode to 0.
    if (mergeAutomatically) {
      updateOrder(orderToUpdate.id, {
        statusCode: -1
      }).then(() => {
        return updateOrder(orderToUpdate.id, {
          statusCode: 0,
          waitingForUser: false
        });
      });
    } else {
      // Merge manually by selecting one available roadmap.
      const newRoadmap = {
        ordersIds: [...roadmapToUpdate.ordersIds, orderToUpdate.id]
      };

      // Update order with new parameter and rider information
      const newOrder = {
        riderId: roadmapToUpdate.riderId,
        statusCode: roadmapToUpdate.status !== 'waiting' ? 2 : 1,
        waitingForUser: false
      };
      await updateRoadmap(roadmapToUpdate.id, newRoadmap);
      await updateOrder(orderToUpdate.id, newOrder);
    }
  }
);

export const getDoneRoadmaps = createAsyncThunk(
  '@roadmaps/getDoneRoadmaps',
  async () => {
    const roadmaps = await getFinishedRoadmaps();
    return roadmaps;
  }
);

export const getRoadmap = createAsyncThunk(
  '@roadmaps/getRoadmap',
  async ({ roadmapNumber }) => {
    const roadmap = await getRoadmapByNumber(roadmapNumber);
    return roadmap;
  }
);

export const removeOrderFromRoadmap = createAsyncThunk(
  '@roadmaps/removeOrderFromRoadmap',
  async ({ order, roadmap, orders = [] }, context) => {
    const { ordersIds } = roadmap;
    const userId = getProfileId(context.getState());
    const newOrdersIds = ordersIds.filter((orderId) => orderId !== order?.id);
    const newOrders = orders.filter((pOrder) => pOrder.id !== order?.id);
    const nonDeliveredOrders = newOrders.filter(
      (order) => order.statusCode !== 3
    );

    const newOrder = {
      statusCode: 0,
      waitingForUser: true,
      steps: [
        ...order.steps,
        {
          date: moment().unix(),
          id: uuidv4(),
          isDone: false,
          label: 'removedFromRoadmap.label',
          message: 'removedFromRoadmap.message'
        }
      ]
    };

    // Log if the roadmap delivered all orders beside order to be removed.
    const newRoadmapClosed = {
      ordersIds: newOrdersIds,
      log: [
        ...roadmap.log,
        {
          id: uuidv4(),
          type: 'close',
          description: 'roadmapClosedManually',
          target: roadmap.roadmapNumber,
          date: moment().unix()
        }
      ],
      status: 'completed',
      feedback: {
        date: moment().unix(),
        text: 'Closed roadmap after removing order from roadmap.',
        userId
      }
    };

    // Log if the roadmap still has undelivered orders after removing order. (wont close roadmap.)
    const newRoadmap = {
      ordersIds: newOrdersIds,
      log: [
        ...roadmap.log,
        {
          id: uuidv4(),
          type: 'close',
          description: 'removedOrderFromRM',
          target: order.odooDataOrder.name,
          date: moment().unix()
        }
      ]
    };

    try {
      // Checks if all orders of the roadmap where delivered to close it automatically.
      if (nonDeliveredOrders.length > 0) {
        await updateRoadmap(roadmap.id, newRoadmap);
      } else {
        await updateRoadmap(roadmap.id, newRoadmapClosed);
        if (!roadmap?.riderId) return;
        await updateRider(roadmap?.riderId, { status: 'free' });
      }
      await updateOrder(order.id, newOrder);
    } catch (err) {
      console.log(err);
    }
  }
);

export const roadmapsSlide = createSlice({
  name: 'roadmaps',
  initialState,
  reducers: {
    setRoadmapsLoading: (state) => {
      state.loading = true;
    },
    setRoadmaps: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setRoadmapsError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setDoneRoadmapsLoading: (state) => {
      state.done.loading = true;
    },
    setDoneRoadmaps: (state, action) => {
      state.done.loading = false;
      state.done.data = action.payload;
    },
    setDoneRoadmapsError: (state, action) => {
      state.done.loading = false;
      state.done.error = action.payload;
    }
  },
  extraReducers: {
    [transferRoadmaps.pending]: (state) => {
      state.loading = true;
    },
    [transferRoadmaps.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [transferRoadmaps.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [cancelOrdersIntoRoadmap.pending]: (state) => {
      state.loading = true;
    },
    [cancelOrdersIntoRoadmap.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [cancelOrdersIntoRoadmap.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteRoadmaps.pending]: (state) => {
      state.loading = true;
    },
    [deleteRoadmaps.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteRoadmaps.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [mergeRoadmaps.pending]: (state) => {
      state.loading = true;
    },
    [mergeRoadmaps.fulfilled]: (state) => {
      state.loading = false;
    },
    [mergeRoadmaps.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getDoneRoadmaps.pending]: (state) => {
      state.done.loading = true;
    },
    [getDoneRoadmaps.fulfilled]: (state, action) => {
      state.done.loading = false;
      state.done.data = action.payload;
    },
    [getDoneRoadmaps.rejected]: (state, action) => {
      state.done.loading = false;
      state.done.error = action.payload;
    },
    [getRoadmap.pending]: (state) => {
      state.done.loading = true;
    },
    [getRoadmap.fulfilled]: (state, action) => {
      state.done.loading = false;
      state.done.data = [...state.done.data, ...action.payload];
    },
    [getRoadmap.rejected]: (state, action) => {
      state.done.loading = false;
      state.done.error = action.payload;
    },
    [removeOrderFromRoadmap.pending]: (state) => {
      state.loading = true;
    },
    [removeOrderFromRoadmap.fulfilled]: (state) => {
      state.loading = false;
    },
    [removeOrderFromRoadmap.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setRoadmapsLoading,
  setRoadmaps,
  setRoadmapsError,
  setDoneRoadmaps,
  setDoneRoadmapsError,
  setDoneRoadmapsLoading
} = roadmapsSlide.actions;

export default roadmapsSlide.reducer;
