/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  query,
  collection,
  where,
  onSnapshot,
  orderBy
} from 'firebase/firestore';
import { db } from 'firebase/firebaseConfig';
import { ORDERS } from 'firebase/collections';

const useGetPackingOrders = () => {
  const [data, setData] = React.useState([]);

  const getPackingOrders = async () => {
    const listen = query(
      collection(db, ORDERS),
      where('onPacking', '==', 'unpacked'),
      where('statusCode', '==', 0),
      orderBy('odooDataOrder.name', 'asc')
    );
    return onSnapshot(listen, (querySnapshot) => {
      const resOrders = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }));
      setData(resOrders);
    });
  };

  return { ordersData: data, getPackingOrders };
};

export default useGetPackingOrders;
