import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { showNotification } from 'stores/actions/notification';
import styles from './ProductOption.module.scss';

const ProductOption = ({
  product,
  setProduct,
  setShow,
  products,
  setProducts,
  setWereProductsEdited
}) => {
  const dispatch = useDispatch();
  const [quantity] = React.useState(1);
  const handleConfirm = () => {
    const newProduct = {
      ...product,
      quantity
    };
    const enoughStock = product.qty_available >= quantity;
    const exists = products.find((p) => p.id === product.id);
    if (!exists && enoughStock) {
      setProducts([...products, newProduct]);
      dispatch(
        showNotification({
          type: 'success',
          message: 'Exito',
          content: 'Producto agregado a la orden'
        })
      );
      setWereProductsEdited(true);
    } else {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: 'No hay suficiente stock'
        })
      );
    }
    setShow(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <img src={product.image} alt={product.name} className={styles.img} />
      </div>
      <div className={styles.rightContainer}>
        <div>
          <span className={styles.subTitle}>Nombre del producto:</span>
          <p className={styles.data}>{product.name}</p>
        </div>
        <div>
          <span className={styles.subTitle}>Cantidad disponible:</span>
          <p className={styles.data}>{product.qty_available} Unds</p>
        </div>
        <div>
          <span className={styles.subTitle}>Precio unitario:</span>
          <p className={styles.data}>Bs. {product.price}</p>
        </div>
        <div className={styles.separator} />
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.cancel}
            onClick={() => {
              setShow(false);
              setProduct({});
            }}
          >
            Cancelar
          </Button>
          <Button className={styles.confirm} onClick={handleConfirm}>
            Agregar producto
          </Button>
        </div>
      </div>
    </div>
  );
};

ProductOption.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    qty_available: PropTypes.number,
    image: PropTypes.string
  }).isRequired,
  setProduct: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number,
      quantity: PropTypes.number,
      qty_available: PropTypes.number,
      image: PropTypes.string
    })
  ).isRequired,
  setProducts: PropTypes.func.isRequired
};

export default ProductOption;
