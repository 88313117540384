import React, { useState } from 'react';
import { Button, Radio } from 'antd';
import { getBills, getSteps } from 'stores/selectors/checkout';
import { useDispatch, useSelector } from 'react-redux';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { removeBill, setEditing } from 'stores/actions/checkout';
import PropTypes from 'prop-types';
import styles from '../CreateOrder.module.scss';
import Cash from './methods/Cash';
import useSetMethods from './useSetMethods';
import Efectivo from './methods/Efectivo';
import PagoMovil from './methods/PagoMovil';
import { checkoutSteps } from '../constants';

const options = [
  {
    label: 'Efectivo',
    value: 'cash'
  },
  {
    label: 'Pago movil',
    value: 'pagomovil'
  },
  {
    label: 'BolivarCash',
    value: 'bolivarCash'
  }
];

const BillsCards = ({ item, dollar }) => {
  const dispatch = useDispatch();

  const deleteItem = () => {
    dispatch(removeBill(item?.id));
  };

  return (
    <div className={styles.billsCards}>
      <p>
        {dollar ? '$' : 'Bs.'}
        {item?.amount}
      </p>{' '}
      | {item.code && <p>{item?.code}</p>}
      <div className={styles.deleteButton}>
        <CloseCircleOutlined onClick={deleteItem} />
      </div>
    </div>
  );
};

const PaymentMethods = ({ totalInCart }) => {
  const [SelectedMethod, setSelectedMethod] = useState(null);
  const saveMethods = useSetMethods();
  const { bills, totalOfBills } = useSelector(getBills);
  const [cashbackMode, setCashbackMode] = useState(false);
  const dispatch = useDispatch();
  const steps = useSelector(getSteps);

  const buttonHandler = ({ target: { value } }) => {
    setSelectedMethod(value);
  };

  const methods = {
    cash: (
      <Cash
        onSave={saveMethods.cash}
        totalInCart={totalInCart}
        cashbackMode={cashbackMode}
        setCashbackMode={setCashbackMode}
      />
    ),
    bolivarCash: (
      <Efectivo onSave={saveMethods.bolivarCash} totalInCart={totalInCart} />
    ),
    pagomovil: (
      <PagoMovil totalInCart={totalInCart} onSave={saveMethods.pagomovil} />
    )
  };

  const reEdit = () => {
    dispatch(setEditing(true));
  };

  return (
    <>
      <p className={styles.titles}>
        Metodo de pago
        {steps.includes(checkoutSteps[2]) && (
          <CheckOutlined
            className={[
              styles.billingDataSaved,
              styles.appearingAnimation
            ].join(' ')}
          />
        )}
      </p>
      <div className={styles.paymentMethodsContainer}>
        <Radio.Group
          options={options}
          onChange={buttonHandler}
          value={SelectedMethod}
          optionType="button"
          disabled={!saveMethods.editing}
        />
        <div className={styles.section}>
          {!saveMethods.editing && (
            <div className={styles.noEdit}>
              <p>
                Metodo de pago elegido:{' '}
                {options.filter((d) => d.value === SelectedMethod)[0].label}
              </p>
              <Button className={styles.confirmButton__accept} onClick={reEdit}>
                <p className={styles.text}>Editar</p>
              </Button>
            </div>
          )}
          {SelectedMethod && saveMethods.editing && (
            <>{methods[SelectedMethod]}</>
          )}
          {SelectedMethod === 'cash' &&
            Boolean(bills.length) &&
            saveMethods.editing &&
            !cashbackMode && (
              <>
                <p className={styles.titles2}>Billetes:</p>
                <div className={styles.billsContainer}>
                  {bills.map((item) => (
                    <BillsCards
                      key={item.id}
                      dollar={SelectedMethod === 'cash'}
                      item={item}
                    />
                  ))}
                </div>
                <p className={styles.titles2}>
                  El total de billetes es: {totalOfBills}$
                </p>
              </>
            )}
        </div>
      </div>
    </>
  );
};

BillsCards.propTypes = {
  item: PropTypes.object.isRequired,
  dollar: PropTypes.bool.isRequired
};

PaymentMethods.propTypes = {
  totalInCart: PropTypes.object.isRequired
};

export default PaymentMethods;
