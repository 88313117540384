import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductCategories } from 'stores/actions/products';
import {
  createBanner,
  forceUpdateBannersInLocal,
  getBanners,
  updateBanner
} from 'stores/actions/banners';
import { bannersByModule, bannersLoading } from 'stores/selectors/banners';
import { loaded, loadingWithSpinner } from 'stores/actions/loader';
import { getBase64 } from 'utils/utils';
import { showNotification } from 'stores/actions/notification';
import { getSpecialCategories } from 'stores/actions/specialCategories';
import { uploadImage } from 'firebase/api';
import BannerManager from './BannerManager';
import BannerPreview from './BannerPreview';

const Banners = ({ target }) => {
  const dispatch = useDispatch();
  const isBannerLoading = useSelector(bannersLoading);
  const servicesLoaded = isBannerLoading;

  const [module, setModule] = useState({
    key: '',
    value: '',
    children: ''
  });
  const [imagePreview, setImagePreview] = useState({
    alt: '',
    link: '',
    externalLink: '',
    src: '',
    endDate: ''
  });
  const [imageTabType, setImageTabType] = useState('Images');

  const banners = useSelector(bannersByModule(module.children, target));

  const handleModule = (pModule) => {
    setModule(pModule);
    setImagePreview({
      alt: '',
      link: '',
      externalLink: '',
      src: '',
      endDate: ''
    });
  };

  const handleImagePreview = (field, value) => {
    setImagePreview({
      ...imagePreview,
      [field]: value
    });
  };

  const handleTabs = (pTab) => {
    setImageTabType(pTab);
  };

  const uploadImageToStorage = async (pRecord) => {
    dispatch(loadingWithSpinner());
    let imagesType = pRecord[imageTabType.toLocaleLowerCase()];
    if (imagesType) {
      const newImages = [];
      for (const image in imagesType) {
        let imageToUpload = imagesType[image];
        if (imageToUpload.src.search('firebase') === -1) {
          const resUpload = await uploadImage(
            'banners',
            imageToUpload.src,
            null,
            imageToUpload.name
          );
          imageToUpload = { ...imageToUpload, src: resUpload.url };
        }
        newImages.push(imageToUpload);
      }
      imagesType = newImages;
    }
    const newObject = {
      ...pRecord,
      [imageTabType.toLocaleLowerCase()]: imagesType
    };
    return newObject;
  };

  const handleSaveImage = async (pImage, imagesInModule) => {
    try {
      const currentImageInModule = await uploadImageToStorage(imagesInModule);
      const { id } = currentImageInModule;
      const request = { body: { ...currentImageInModule, target } };
      const action = () =>
        id
          ? dispatch(updateBanner({ id: banners.id, ...request }))
          : dispatch(createBanner(request));

      action();
      dispatch(forceUpdateBannersInLocal(currentImageInModule));
      dispatch(loaded());
    } catch (error) {
      console.log(error);
      dispatch(loaded());
    }
  };

  const handleUploadFile = async (pFile, imagesInModule) => {
    const TYPES_ALLOWED = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];
    const { status, originFileObj, name: imageOriginName, type } = pFile.file;
    if (status === 'done') {
      if (!TYPES_ALLOWED.includes(type)) {
        dispatch(
          showNotification({
            type: 'warning',
            message: 'Tipo de archivo no valido'
          })
        );
        return;
      }
      const fileBase64 = await getBase64(originFileObj);
      const imagePreload = {
        module: module.children,
        imageType: imageTabType,
        alt: '',
        link: '',
        externalLink: '',
        endDate: '',
        src: fileBase64,
        name: imageOriginName
      };

      /// add current file loaded into files saved
      const filesLoaded = { ...imagesInModule };
      const fileByImageType =
        filesLoaded[imageTabType.toLocaleLowerCase()] || null;
      let newFileByImageType;
      if (fileByImageType) {
        newFileByImageType = [
          ...fileByImageType,
          {
            src: imagePreload.src,
            alt: imagePreload.alt,
            link: imagePreload.link,
            endDate: imagePreload.endDate,
            externalLink: imagePreload.externalLink,
            name: imageOriginName
          }
        ];

        /// inner join beetween filed loaded and new files
        filesLoaded[imageTabType.toLocaleLowerCase()] = newFileByImageType;
        dispatch(forceUpdateBannersInLocal(filesLoaded));
      } else {
        // If not exists any images by module
        const templateImageRecord = {
          name: module.children,
          carousel: [],
          images: []
        };
        templateImageRecord[imageTabType.toLowerCase()] = [
          {
            src: imagePreload.src,
            alt: imagePreload.alt,
            link: imagePreload.link,
            externalLink: imagePreload.externalLink,
            endDate: imagePreload.endDate,
            name: imageOriginName
          }
        ];
      }
    }
  };

  const handleSaveDataFile = async (fileData, imagesInModule) => {
    try {
      const { alt, link, externalLink, endDate, src } = fileData;
      const refImageType = imageTabType.toLowerCase();
      const findFileToUpdate = imagesInModule[refImageType].find(
        (record) => record.src === src
      );
      const fileToUpdate = {
        ...findFileToUpdate,
        alt,
        link,
        externalLink,
        endDate
      };
      const imagesLoadedByModuleCopy = {
        ...imagesInModule,
        [refImageType]: imagesInModule[refImageType].map((record) =>
          record.src === src ? fileToUpdate : record
        )
      };

      // update link and alt data
      const fileId = banners.id;
      const { id, ...body } = imagesLoadedByModuleCopy;
      await dispatch(updateBanner({ id: fileId, body }));
      dispatch(
        showNotification({
          type: 'success',
          message: 'Success'
        })
      );
      dispatch(forceUpdateBannersInLocal(imagesLoadedByModuleCopy));
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: error
        })
      );
    }
  };

  const handleRemoveImage = async (fileData, imagesInModule) => {
    try {
      const { src } = fileData;
      const refImageType = imageTabType.toLowerCase();
      const imagesLoadedByModuleCopy = {
        ...imagesInModule,
        [refImageType]: imagesInModule[refImageType].filter(
          (record) => record.src !== src
        )
      };

      /// Check if image is loaded in storage to before can delete
      const removeFromStorage = src.search('firebase') > 0;
      if (removeFromStorage) {
        const fileId = banners.id;
        const { id, ...body } = imagesLoadedByModuleCopy;
        await dispatch(updateBanner({ id: fileId, body }));
      }
      dispatch(forceUpdateBannersInLocal(imagesLoadedByModuleCopy));
      dispatch(
        showNotification({
          type: 'success',
          message: 'Success'
        })
      );
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: error
        })
      );
    }
  };

  const fetchData = useCallback(() => {
    dispatch(fetchProductCategories());
    dispatch(getBanners());
    dispatch(getSpecialCategories());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <BannerPreview src={imagePreview.src} />
        </Col>
        <Col span={24}>
          <Skeleton active loading={servicesLoaded}>
            {!servicesLoaded && (
              <BannerManager
                handleModule={handleModule}
                module={module}
                handleImagePreview={handleImagePreview}
                handleTabs={handleTabs}
                imageTabType={imageTabType}
                imagesLoadedByModule={banners}
                handleSaveImage={(e) => handleSaveImage(e, banners)}
                handleSaveDataFile={(e) => handleSaveDataFile(e, banners)}
                handleRemoveImage={(e) => handleRemoveImage(e, banners)}
                handleUploadFile={(e) => handleUploadFile(e, banners)}
              />
            )}
          </Skeleton>
        </Col>
      </Row>
    </>
  );
};

export default Banners;
