import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getBanksData } from 'stores/selectors/banks';
import { useOrdersByRangeQuery } from 'stores/query/ordersQuery';
import useRangeDateFilter from 'hooks/useRangeDateFilter';
import runTranslate from './formRules';

const Option = Select;
const DOCUMENT_TYPE = ['V', 'E', 'J'];
const LINES = ['414', '424', '416', '426', '412'];

const CommerceToPersonForm = ({ onCancel, onSubmit, isLoading }) => {
  const { lastSevenDaysDefault } = useRangeDateFilter();
  const { data: ordersData, isLoading: ordersLoading } = useOrdersByRangeQuery(lastSevenDaysDefault);
  const [clientInfo, setClientInfo] = useState({
    name: '',
    dni: '',
    email: '',
    phone: ''
  });
  const initialState = {
    bank: null,
    dni: null,
    phone: null,
    amount: null,
    order: null,
    reason: null
  };
  const [formRules] = useState(() => runTranslate());
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const banks = useSelector(getBanksData);
  const ordersOption = ordersData
    ?.filter((order) => order?.odooDataOrder?.name)
    .map((order) => ({
      id: order?.id,
      name: order?.odooDataOrder?.name || '-'
    }));
  const formEL = useRef(null);

  const selectDniBefore = (
    <Select defaultValue="V" className="select-before">
      {DOCUMENT_TYPE.map((item, idx) => (
        <Option key={idx} value={item}>
          {item}
        </Option>
      ))}
    </Select>
  );
  const selectLineNumber = (
    <Select defaultValue="424" className="select-before">
      {LINES.map((item, idx) => (
        <Option key={idx} value={item}>
          {item}
        </Option>
      ))}
    </Select>
  );
  const handleChangeOrders = (orderId) => {
    const order = ordersData.find((order) => order.id === orderId);
    setClientInfo(order?.partner);
  };
  const parseBodyRequest = (record) => ({
    banco: record.bank,
    cedula: `${record.documentType}${record.dni}`,
    telefono: `${record.lineNumber}${record.phone}`,
    montoDevuelto: Number(record.amount).toFixed(2),
    motivo: record.reason,
    orderId: record.order,
    fromAdmin: true
  });

  const handleSubmit = () => {
    formEL.current.submit();
  };
  const onFinish = async (record) => {
    const res = parseBodyRequest(record);
    await onSubmit(res);
    onCancel();
  };

  return (
    <>
      <Form
        form={form}
        name="proco"
        onFinish={onFinish}
        scrollToFirstError
        labelCol={{ xs: { span: 24 }, sm: { span: 8 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
        initialValues={initialState}
        ref={formEL}
      >
        <Form.Item
          name="order"
          label={t('common.fields.order')}
          rules={formRules.orderRules}
        >
          <Select
            placeholder="Select"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            allowClear
            showSearch
            onChange={(order) => handleChangeOrders(order)}
            loading={ordersLoading}
          >
            {ordersOption?.map((order, idx) => (
              <Option key={idx} value={order?.id}>
                {order?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="bank"
          label={t('common.fields.bank')}
          rules={formRules.bankRules}
        >
          <Select
            placeholder="Select"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            allowClear
            showSearch
          >
            {banks?.map((bank, idx) => (
              <Option key={idx} value={bank?.code}>
                {bank?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="dni"
          label={t('common.fields.dni')}
          rules={formRules.dniRules}
        >
          <Input
            addonBefore={(
              <Form.Item name="documentType" noStyle initialValue="V">
                {selectDniBefore}
              </Form.Item>
            )}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label={t('common.fields.phone')}
          rules={formRules.phoneRules}
          help="Ej: 424+1234567"
        >
          <Input
            addonBefore={(
              <Form.Item name="lineNumber" noStyle initialValue="424">
                {selectLineNumber}
              </Form.Item>
            )}
          />
        </Form.Item>
        <Form.Item
          name="amount"
          label={t('common.fields.amount')}
          rules={formRules.amountRules}
        >
          <InputNumber precision={2} />
        </Form.Item>
        <Form.Item name="reason" label={t('common.fields.reason')}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 22 }}>
          <Popconfirm
            title={`Esta seguro de realizar la transaccion dirigida a ${clientInfo?.name}
            con numero de cedula ${clientInfo?.dni}
            `}
            // @ts-ignore
            onConfirm={() => handleSubmit()}
            okText="Yes"
            cancelText="No"
          >
            <Button disabled={ordersLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
      <Divider />
      <Row>
        <Col>
          <Descriptions title="User Info">
            <Descriptions.Item label="Nombre">
              {clientInfo?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Cedula">
              {clientInfo?.dni}
            </Descriptions.Item>
            <Descriptions.Item label="Correo">
              {clientInfo?.email}
            </Descriptions.Item>
            <Descriptions.Item label="Telefono">
              {clientInfo?.phone}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  );
};

CommerceToPersonForm.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
};

export default CommerceToPersonForm;
