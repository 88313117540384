import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  id: '',
  dni: '',
  name: '',
  phone: '',
  roleId: '',
  departmentId: '',
  email: '',
  address: {},
  avatar: '',
  createAt: '',
  updateAt: '',
  deleteAt: '',
  isDelete: '',
  isLogged: false,
  accessToken: '',
  role: {}
};

export const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      return { ...state, ...action.payload };
    },
    editProfile: (state, action) => {
      return { ...state, ...action.payload };
    },
    cleanProfile: () => {
      return { ...initialState };
    }
  }
});

export const { setProfile, cleanProfile, editProfile } = ProfileSlice.actions;

export default ProfileSlice.reducer;
