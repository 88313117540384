import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getRoadmaps as getRoadmapsX,
  getDoneRoadmaps as getDoneRoadmapsX
} from 'stores/selectors/roadmaps';
import {
  setRoadmaps,
  getRoadmap,
  setDoneRoadmaps,
  setDoneRoadmapsLoading,
  setDoneRoadmapsError
} from 'stores/actions/roadmaps';
import { query, where, collection, onSnapshot, doc } from 'firebase/firestore';
import { db } from 'firebase/firebaseConfig';
import { getFinishedRoadmaps } from 'firebase/api';
import { ROADMAPS } from 'firebase/collections';
import { showNotification } from 'stores/actions/notification';
import useGetRidersHook from './useGetRidersHook';
import useGetRoutesHook from './useGetRoutesHook';

const useGetRoadmapsHook = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(getRoadmapsX);
  const { data: doneRoadmaps, loading: loadingDoneRoadmaps } =
    useSelector(getDoneRoadmapsX);
  const { ridersData, getRidersY } = useGetRidersHook();
  const { getRoutes } = useGetRoutesHook();

  const [roadmap, setRoadmap] = React.useState();

  const getRoadmaps = async () => {
    const { payload: riderData } = await getRidersY();
    const { payload: routes } = await getRoutes();
    const listen = query(
      collection(db, ROADMAPS),
      where('status', 'in', ['inprogress', 'waiting'])
    );
    return onSnapshot(listen, (querySnapshot) => {
      const output = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        routeName: routes.find((route) => route.id === doc.data().routeId)
          ?.name,
        id: doc.id,
        rider: riderData.find((ride) => ride.uid === doc.data().riderId) || {}
      }));
      dispatch(setRoadmaps(output));
    });
  };

  const getDoneRoadmaps = async () => {
    try {
      dispatch(setDoneRoadmapsLoading());
      const { payload: routes } = await getRoutes();
      const roadmaps = await getFinishedRoadmaps();
      const formatedRoadmaps = roadmaps.map((roadmap) => ({
        ...roadmap,
        routeName: routes.find((route) => route.id === roadmap.routeId)?.name,
        rider: ridersData.find((ride) => ride.uid === roadmap.riderId) || {}
      }));
      dispatch(setDoneRoadmaps(formatedRoadmaps));
    } catch (err) {
      dispatch(setDoneRoadmapsError(err));
      dispatch(showNotification(err));
    }
  };

  const getOneRoadmap = async (roadmapNumber) => {
    const x = await dispatch(getRoadmap({ roadmapNumber }));
    return x.payload;
  };

  const viewOneRoadmap = async (roadmapId) => {
    const { payload: riderData } = await getRidersY();
    const { payload: routes } = await getRoutes();
    return onSnapshot(doc(db, ROADMAPS, roadmapId), (doc) => {
      const complete = {
        ...doc.data(),
        id: doc.id,
        rider: riderData.find((r) => r.id === doc?.data().riderId),
        route: routes.find((ro) => ro.id === doc?.data().routeId)
      };
      setRoadmap(complete);
    });
  };

  return {
    getRoadmaps,
    getDoneRoadmaps,
    getOneRoadmap,
    roadmapsData: data,
    roadmapsLoading: loading,
    doneRoadmaps,
    loadingDoneRoadmaps,
    viewOneRoadmap,
    roadmap
  };
};

export default useGetRoadmapsHook;
