import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const getSpecialCategories = createSelector(selectSelf, (state) => state.specialCategories);

export const getSpecialCategoriesData = createSelector(
  getSpecialCategories,
  (specialCategories) => specialCategories?.data || {}
)

export const isSpecialCategoriesLoading = createSelector(
  getSpecialCategories,
  (specialCategories) => specialCategories.loading
)

export const hasSpecialCategoriesError = createSelector(
  getSpecialCategories,
  (specialCategories) => specialCategories.error
)
