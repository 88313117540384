import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const getCommissionsConfig = createSelector(
  selectSelf,
  (state) => state.commissionsConfig
);

export const getCommissionsConfigData = createSelector(
  getCommissionsConfig,
  (commissionsConfig) =>
    commissionsConfig.data.map((commission) => {
      return {
        ...commission,
        key: commission.id
      };
    }) || []
);

export const isCommissionsConfigLoading = createSelector(
  getCommissionsConfig,
  (commissionsConfig) => commissionsConfig.loading
);

export const hasCommissionsConfigError = createSelector(
  getCommissionsConfig,
  (commissionsConfig) => commissionsConfig.error
);

export const hasCommissionsConfigData = createSelector(
  getCommissionsConfigData,
  (commissionsConfig) => commissionsConfig.length > 0
);

export const isCommissionsConfigLoaded = createSelector(
  isCommissionsConfigLoading,
  hasCommissionsConfigData,
  (isLoading) => !isLoading
);
