import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const getProcos = createSelector(selectSelf, (state) => state.procos);

export const getProcosData = createSelector(
  getProcos,
  (procos) => procos?.data || []
);

export const isProcosLoading = createSelector(
  getProcos,
  (procos) => procos.loading
);

export const hasProcosError = createSelector(
  getProcos,
  (procos) => procos.error
);

export const getWarehouseProcos = createSelector(
  getProcosData,
  (procos) => procos.filter((proco) => proco?.type === 'almacen')
    .map((item) => ({
      ...item,
      warehousemanCount: item.users.length
    }))
);

export const getRidersProcos = createSelector(
  getProcosData,
  (procos) => procos.filter((proco) => proco?.type === 'rider')
);

export const getProcoById = (procoId) => createSelector(
  getProcosData,
  (procos) => procos?.find((p) => p?.id === procoId) || {}
);
