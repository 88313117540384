import React from 'react';
import ReactExport from 'react-data-export';
import { Col, Row, DatePicker, Button } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash-es';
import { ordersApi } from 'stores/query/ordersQuery';
import useDisclosure from 'hooks/useDisclousure';
import useAsyncDownload from 'hooks/useAsyncDownload';
import { pickingReportFactory } from './utils';
import { getWarehouseProcos } from 'stores/selectors/proco';
import { getAdmins } from 'firebase/api';
import { getProcos as getProcosX } from 'stores/actions/procos';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const PickingReport = () => {
  const {
    isOpen: fetching,
    onOpen: fetchingOn,
    onClose: fetchingOff
  } = useDisclosure();
  const { isReady, asyncDownloadToggle } = useAsyncDownload();
  const dispatch = useDispatch();
  const procos = useSelector(getWarehouseProcos);

  const getProcos = async () => await dispatch(getProcosX());
  React.useEffect(() => {
    getProcos();
    // eslint-disable-next-line
  }, []);

  const summarizeData = React.useCallback(
    (data) => [
      ...sortBy(data, ['order']),
      {
        date: '',
        order: '',
        client: '',
        proco: '',
        packingStartTime: '',
        packingEndTime: ''
      }
    ],
    []
  );

  const [filteredOrdersData, setFilteredOrdersData] = React.useState([]);

  const [dataPickerFilter, setDatePickerFilter] = React.useState([]);
  const hasFilters = dataPickerFilter.length === 2;

  const handleChange = (dates) => {
    if (!dates) {
      setDatePickerFilter([]);
      return;
    }
    setDatePickerFilter([
      moment(dates[0]?.toString()),
      moment(dates[1]?.toString())
    ]);
  };

  const getColumns = () => [
    {
      title: 'Fecha',
      key: 'date',
      dataIndex: 'date'
    },
    {
      title: 'Orden',
      key: 'order',
      dataIndex: 'order'
    },
    {
      title: 'Cliente',
      key: 'client',
      dataIndex: 'client'
    },
    {
      title: 'Inicio Picking',
      key: 'packingStartTime',
      dataIndex: 'packingStartTime'
    },
    {
      title: 'Fin del Picking',
      key: 'packingEndTime',
      dataIndex: 'packingEndTime'
    },
    {
      title: 'Tiempo Total de Picking',
      key: 'totalPackingTime',
      dataIndex: 'totalPackingTime'
    },
    {
      title: 'Almacenista encargado',
      key: 'warehouseman',
      dataIndex: 'warehouseman'
    },
    {
      title: 'Proco',
      key: 'proco',
      dataIndex: 'proco'
    }
  ];

  const border = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' }
  };

  const alignment = { horizontal: 'center', wrapText: true };

  const dataSet = [
    {
      columns: getColumns().map((column) => ({
        title: column.title,
        width: { wch: column.title.length + 10 },
        style: {
          fill: { fgColor: { rgb: 'FFC3D69B' } },
          font: { bold: true },
          alignment,
          border
        }
      })),
      data: filteredOrdersData.map((row) =>
        Object.values(row).map((value) => ({
          value: String(value),
          style: {
            alignment,
            border
          }
        }))
      )
    }
  ];

  const handleDownload = React.useCallback(async () => {
    if (dataPickerFilter.length !== 2) return;
    fetchingOn();
    const selectedFrom = new Date(dataPickerFilter[0]).setHours(0, 0, 0, 0);
    const selectedTo = new Date(dataPickerFilter[1]).setHours(23, 59, 59, 59);
    const from = new Date(selectedFrom);
    const to = new Date(selectedTo);

    const pickers = await getAdmins();
    const { data: orders } =
      (await dispatch(
        ordersApi.endpoints.ordersByRange.initiate({ from, to })
      )) || {};
    const ordersData = pickingReportFactory(orders, pickers, procos);
    fetchingOff();
    setFilteredOrdersData(summarizeData(ordersData));
    asyncDownloadToggle();
  }, [
    dataPickerFilter,
    dispatch,
    summarizeData,
    fetchingOn,
    fetchingOff,
    asyncDownloadToggle,
    procos
  ]);

  return (
    <>
      <Row>
        <Col span={6}>
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            placeholder={['from', 'to']}
            onChange={(e) => handleChange(e)}
            ranges={{
              '2 days': [
                moment().subtract(2, 'days').startOf('day'),
                moment().endOf('day')
              ]
            }}
            value={dataPickerFilter}
            allowClear
          />
        </Col>
        <Col span={6}>
          <Button
            disabled={!hasFilters}
            loading={fetching}
            onClick={() => handleDownload()}
          >
            Descargar Reporte
          </Button>
          {isReady && (
            <ExcelFile hideElement filename="Reporte de Picking">
              <ExcelSheet dataSet={dataSet} name="Picking" />
            </ExcelFile>
          )}
        </Col>
      </Row>
    </>
  );
};

export default PickingReport;
