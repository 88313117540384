import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getRoadmapsData } from 'stores/selectors/roadmaps';

const useMergeRoadmaps = () => {
  const roadmaps = useSelector(getRoadmapsData);
  const [roadmapMerged, setRoadmapMerged] = useState({});
  const [roadmapsCancelled, setRoadmapsCancelled] = useState([]);
  const [roadmapsSelected, setRoadmapSelected] = useState([]);

  const getLastRoadmapCreated = (data) => {
    const castRoadmapsNumber = data.map((record) => ({
      number: Number(record.roadmapNumber.replace(/\D+/g, '')),
      name: record.roadmapNumber
    }));

    const roadmapsOrdered = castRoadmapsNumber.sort(
      (a, b) => b.number - a.number
    );
    return roadmapsOrdered[0]?.name;
  };

  const mergeRoadmaps = (roadmapsTable) => {
    const mainRoadmapNumber = getLastRoadmapCreated(roadmapsTable);
    const roadmapsNumberSelected = roadmapsTable.map(
      (item) => item.roadmapNumber
    );
    const othersRoadmaps = roadmaps
      .filter((item) => roadmapsNumberSelected.includes(item.roadmapNumber))
      .filter((item) => item.roadmapNumber !== mainRoadmapNumber);
    const mainRoadmap = roadmaps.find(
      (record) => record.roadmapNumber === mainRoadmapNumber
    );
    const remainingOrders = othersRoadmaps.reduce(
      (acc, el) => [...acc, ...el.ordersIds],
      []
    );
    const castRoadmapMerged = {
      ...mainRoadmap,
      ordersIds: [...mainRoadmap.ordersIds, ...remainingOrders]
    };

    setRoadmapMerged(castRoadmapMerged);
    setRoadmapsCancelled(othersRoadmaps);
    setRoadmapSelected(roadmapsTable);
  };

  const resetRoadmapsMerged = () => {
    setRoadmapMerged({});
    setRoadmapsCancelled([]);
    setRoadmapSelected([]);
  };

  return {
    mergeRoadmaps,
    roadmapMerged,
    roadmapsCancelled,
    resetRoadmapsMerged,
    roadmapsSelected,
    setRoadmapMerged
  };
};

export default useMergeRoadmaps;
