import React from 'react';

const { Badge } = require('antd');
const { useTranslation } = require('react-i18next');

const OrderStatusBadge = ({ status }) => {
  const { t } = useTranslation();

  const statusesColor = {
    [-2]: 'processing',
    [-1]: 'processing',
    0: 'default',
    1: 'warning',
    2: 'processing',
    3: 'success',
    4: 'warning',
    5: 'success',
    6: 'default',
    7: 'error'
  };

  const statuses = [
    {
      code: [-2],
      text: t('common.orderStatus.steps.prescription.title'),
    },
    {
      code: [-1],
      text: t('common.orderStatus.steps.payment.title'),
    },
    {
      code: [0, 0.5],
      text: t('common.orderStatus.steps.ordered.title'),
    },
    {
      code: [1],
      text: t('common.orderStatus.steps.inQueue.title'),
    },
    {
      code: [2],
      text: t('common.orderStatus.steps.transit.title'),
    },
    {
      code: [2.5],
      text: t('common.orderStatus.steps.arrived.title'),
    },
    {
      code: [3],
      text: t('common.orderStatus.steps.delivered.title'),
    },
    {
      code: [4],
      text: t('common.orderStatus.steps.waitingPickUp.title'),
    },
    {
      code: [5],
      text: t('common.orderStatus.steps.pickup.title'),
    },
    {
      code: [6],
      text: t('common.orderStatus.steps.reassigned.title'),
    },
    {
      code: [7],
      text: t('common.orderStatus.steps.cancelled.title'),
    }
  ];

  const state = statuses.filter((step) => step.code.includes(status))[0];

  return (
    <div>
      <Badge status={statusesColor[status]} />
      {(status ? state?.text : t('common.orderStatus.ordered')) || '-'}
    </div>
  );
};

export default OrderStatusBadge;
