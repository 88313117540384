import API from './config';
import {
  GET_INVOICES,
  CREATE_CLIENT,
  GET_PRODUCTS_ODOO,
  GET_CATEGORIES
} from './urls';

const getInvoices = () => API.get(GET_INVOICES);
const getInvoicesById = (id) => API.get(`${GET_INVOICES}?id=${id}`);
const createClient = (body) => API.post(CREATE_CLIENT, body);
const getProducts = (params) => API.get(GET_PRODUCTS_ODOO, { params });
const getCategories = () => API.get(GET_CATEGORIES);
const getAllProducts = (params) => API.get(`${process.env.REACT_APP_ODOO_API}/apis/products/true/1/all/all`, {
  params
});
const getPurchaseOrder = () => null;
const getPurchaseOrderLine = () => null;
const getContacts = () => null;
const getContactsBanks = () => null;
const validateCart = (body) => API.post(`${process.env.REACT_APP_ODOO_API}/apis/checkInventary`, body);

export default {
  getInvoices,
  getInvoicesById,
  createClient,
  getProducts,
  getAllProducts,
  getCategories,
  getPurchaseOrder,
  getPurchaseOrderLine,
  getContacts,
  getContactsBanks,
  validateCart
};
