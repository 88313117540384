import React from 'react';
import { Button, Col, Tag, Row } from 'antd';

const RenderAvailableRoadmaps = ({
  selectedMergeType,
  mergeTypes,
  mergeableRoadmaps = [],
  selectedMergeableRoadmap,
  setSelectedMergeableRoadmap
}) => {
  return (
    selectedMergeType === mergeTypes[1] && (
      <>
        <Row justify={'start'} gutter={[5, 18]}>
          {mergeableRoadmaps.map((roadmap, idx) => (
            <Col key={idx} span={6}>
              <Tag
                style={{ borderRadius: '20px' }}
                color={`${selectedMergeableRoadmap === roadmap ? 'blue' : ''}`}
              >
                <Button
                  type="text"
                  onClick={() => setSelectedMergeableRoadmap(roadmap)}
                >
                  {roadmap?.id}
                </Button>
              </Tag>
            </Col>
          ))}
        </Row>
      </>
    )
  );
};

export default RenderAvailableRoadmaps;
