import { isObject } from 'lodash';
import get from 'lodash/get';
import moment from 'moment';

// eslint-disable-next-line
export const parseToString = (element) => typeof element !== 'string' ? String(element) : element;

export const sorterData = (key) => (a, b) => {
  const valueA = get(a, key);
  const valueB = get(b, key);

  if (Number.isNaN(Date.parse(valueA)) === false) {
    return new Date(valueA) - new Date(valueB);
  }

  return parseToString(valueA).localeCompare(parseToString(valueB));
};

export const sorterDate = (key) => (a, b) => {
  const valueA = get(a, key);
  const valueB = get(b, key);

  const dateA = moment(
    isObject(valueA) ? valueA?.seconds * 1000 : valueA
  ).valueOf();
  const dateB = moment(
    isObject(valueB) ? valueB?.seconds * 1000 : valueB
  ).valueOf();

  return dateA - dateB;
};

export const sorterNumbers = (key) => (a, b) => {
  const valueA = get(a, key);
  const valueB = get(b, key);

  return valueA - valueB;
};
