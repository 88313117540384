import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Button, Input, Space } from 'antd';
import Highlighter from 'react-highlight-words';

import React, { useRef, useState } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

const FilterDropdownCp = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  searchInputRef,
  handleSearch,
  handleReset,
  setSearch
}) => (
  <div style={{ padding: 8 }}>
    <Input
      ref={searchInputRef}
      placeholder={`Search ${dataIndex}`}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
      style={{ marginBottom: 8, display: 'block' }}
    />
    <Space>
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90 }}
      >
        Search
      </Button>
      <Button
        onClick={() => handleReset(clearFilters)}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
      <Button
        type="link"
        size="small"
        onClick={() => {
          confirm();
          setSearch({
            text: selectedKeys[0],
            column: dataIndex
          });
        }}
      >
        Filter
      </Button>
    </Space>
  </div>
);

const useColumnSearch = () => {
  const [search, setSearch] = useState({
    text: '',
    column: ''
  });
  const searchInputRef = useRef();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearch({
      text: selectedKeys[0],
      column: dataIndex
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearch({ text: '', column: '' });
  };

  return (dataIndex) => ({
    filterDropdown: (props) => (
      <FilterDropdownCp
        {...props}
        setSearch={setSearch}
        searchInputRef={searchInputRef}
        handleSearch={handleSearch}
        handleReset={handleReset}
        dataIndex={dataIndex}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const getter = get(record, dataIndex);
      return getter
        ? getter.toString().toLowerCase().includes(value.toLowerCase())
        : '';
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef?.current?.select(), 100);
      }
    },
    render: (text) => (isEqual(search.column, dataIndex) ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[search.text]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ))
  });
};

FilterDropdownCp.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  dataIndex: PropTypes.node.isRequired,
  searchInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired
};

export default useColumnSearch;
