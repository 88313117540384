import { Tabs } from 'antd';
import React from 'react';
import Banners from './Banners';

const BannersTabs = () => {

  const { TabPane } = Tabs;

  return (
    <Tabs>
      <TabPane tab="Web" key="1">
        <Banners target='web' />
      </TabPane>
      <TabPane tab="Responsive" key="2">
        <Banners target='responsive' />
      </TabPane>
      <TabPane tab="Mobile" key="3">
        <Banners target='mobile' />
      </TabPane>
    </Tabs>
  )
}

export default BannersTabs