import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { checkoutSteps } from 'components/CreateOrderDrawer/constants';
import { v4 as uuid } from 'uuid';
import { roundDecimals } from 'utils/utils';
import { createOrder as createOrderFB } from 'firebase/api';
import { showNotification } from './notification';

const recalculateBills = (array) => {
  let total = 0;
  array.forEach((bill) => {
    total += parseInt(bill?.amount);
  });
  return total;
};

const getValidBills = (bills, totalRef) => {
  const total = parseInt(totalRef);
  const valid = recalculateBills(bills);
  return valid > total && valid < total + 10 && bills.length > 0;
};

export const initialState = {
  // order data
  webOrApp: 'admin',
  customer: null,
  invoiceData: null,
  address: null,
  shipping: {
    type: 'delivery',
    details: null
  },
  successfullData: null,
  paymentStatus: {
    loading: false,
    result: null,
    message: ''
  },
  payment: {
    method: '',
    details: {
      bank: null,
      amount: 0
    },
    isConfirmed: false,
    type: '',
    validatingBills: {
      bills: [],
      totalOfBills: 0,
      isValidAmount: false
    },
    editing: true
  },
  // config data
  availableDates: [],
  loadingAvailableDates: false,
  config: null,
  // created order
  createdOrder: null,
  // status
  success: false,
  processingOrder: false,
  step: [],
  // order feedback
  failures: 0,
  cashback: false,
  cashbackAmount: undefined,
  unavailableProducts: null,
  error: null,
  errorFeedback: null
};

export const createOrder = createAsyncThunk(
  'checkout/createOrder',
  async (data, thunkApi) => {
    // check if data retrived from server is valid
    const prescriptions = thunkApi
      .getState()
      .cartValidation.data.data.products2.filter(
        (prod) => prod?.product_type === 'prescripcion'
      )
      .map((product) => ({
        product: product?.id
      }));

    const payload = {
      webOrApp: 'admin',
      addressId: thunkApi.getState().checkout.address.id,
      clientId: thunkApi.getState().checkout.customer.clientId,
      clientName: thunkApi.getState().checkout.customer.clientName,
      prescriptions,
      odooOrder: {
        product_list: thunkApi
          .getState()
          .cartValidation.data.data.products2.map((p) => ({
            product_id: p.id,
            product_uom_qty: p.cantidad,
            subtotal: roundDecimals(p.subtotal * p.cantidad),
            tax: roundDecimals(p.tax * p.cantidad)
          }))
      },
      payment: {
        methods: [
          {
            method:
              thunkApi.getState().checkout.payment.method.method ||
              thunkApi.getState().checkout.payment.method,
            details:
              thunkApi.getState().checkout.payment.details ||
              thunkApi.getState().checkout.payment.method.details,
            isConfirmed:
              thunkApi.getState().checkout.payment.method.method !==
              'pagomovil',
            type:
              thunkApi.getState().checkout.payment.type ||
              thunkApi.getState().checkout.payment.method.type,
            cashback:
              thunkApi.getState().checkout.payment.method.cashback ||
              thunkApi.getState().checkout.payment.details.cashback
          }
        ],
        cashback:
          thunkApi.getState().checkout.payment.method.cashback ||
          thunkApi.getState().checkout.payment.details.cashback
      },
      shipping: {
        type: thunkApi.getState().checkout.address.type
      },
      subtotal: thunkApi.getState().cartValidation.data.data.subtotal,
      tax: thunkApi.getState().cartValidation.data.data.taxes,
      ref: thunkApi.getState().cartValidation.data.data.ref,
      type: thunkApi.getState().checkout.address.type,
      coupon: '',
      customizedInvoice: thunkApi.getState().checkout.customer
        ? {
            ...thunkApi.getState().checkout.customer,
            name: thunkApi.getState().checkout.customer.clientName || ''
          }
        : null
    };

    const response = await createOrderFB(payload);

    return {
      ...payload,
      createdOrder: response.data,
      cashback: response.data.cashBack,
      status: response.data.message === 'Order succesfully created' ? 200 : 400
    };
  }
);

export const checkCashValidAmounts = createAsyncThunk(
  'checkout/payment/cash/checkValidAmounts',
  async (param, thunkApi) => {
    let result = 'invalid';
    const { bills } = thunkApi.getState().checkout.payment.validatingBills;
    if (getValidBills(bills, param.totalRef)) {
      result = 'valid';
      if (recalculateBills(bills) > param.totalRef) result = 'cashback';
      thunkApi.dispatch(setIsValidCashAmounts(true));
    } else {
      result = 'invalid';
      thunkApi.dispatch(setIsValidCashAmounts(false));
      thunkApi.dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: 'Monto invalido.'
        })
      );
    }
    return result;
  }
);

export const AdminCheckoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    cleanCheckout: (state) => {
      state.address = initialState.address;
      state.shipping = initialState.shipping;
      state.payment = initialState.payment;
      state.customer = initialState.customer;
      state.success = undefined;
      state.successfullData = null;
      state.paymentStatus = {
        loading: false,
        result: null,
        message: ''
      };
      state.failures = initialState.failures;
      state.cashback = initialState.cashback;
      state.cashbackAmount = initialState.cashbackAmount;
      state.contactSupport = initialState.contactSupport;
    },
    setCustomer: (state, action) => {
      const { customer } = action.payload;
      state.customer = customer;
    },
    setInvoiceData: (state, action) => {
      state.invoiceData = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setPaymentMethod: (state, action) => {
      const { method } = action.payload;
      state.failures = 0;
      state.payment = {
        ...state.payment,
        method,
        details: initialState.payment.details
      };
    },
    setPaymentDetails: (state, action) => {
      const { details } = action.payload;
      state.payment = {
        ...state.payment,
        details
      };
    },
    resetPaymentMethod: (state) => {
      state.failures = 0;
      state.payment = initialState.payment;
    },
    addBill: (state, action) => {
      const currentBills = [...state.payment.validatingBills.bills].concat([
        { ...action.payload, id: uuid() }
      ]);
      state.payment.validatingBills.totalOfBills =
        recalculateBills(currentBills);
      state.payment.validatingBills.bills = currentBills;
      state.payment.validatingBills.isValidAmount = false;
      state.payment = {
        ...state.payment,
        method: '',
        details: null,
        isConfirmed: false,
        type: ''
      };
    },
    removeBill: (state, action) => {
      // action.payload must be a bill id
      const currentBills = [...state.payment.validatingBills.bills].filter(
        (d) => d.id !== action.payload
      );
      state.payment.validatingBills.totalOfBills =
        recalculateBills(currentBills);
      state.payment.validatingBills.bills = currentBills;
      state.payment.validatingBills.isValidAmount = false;
      state.payment = {
        ...state.payment,
        method: '',
        details: null,
        isConfirmed: false,
        type: ''
      };
    },
    setIsValidCashAmounts: (state, action) => {
      state.payment.validatingBills.isValidAmount = action.payload;
    },
    commitCashMethod: (state, action) => {
      if (state.payment.validatingBills.isValidAmount) {
        state.payment.method = 'cash';
        state.payment.isConfirmed = true;
        state.payment.type = 'cash';
        state.payment.details = {
          bills: action.payload.bills,
          comments: '',
          dni: null,
          cashback: action.payload.cashback.cashback
        };
      }
    },
    setEditing: (state, action) => {
      state.payment = {
        ...state.payment,
        editing: action.payload
      };
      if (action.payload) {
        state.step = [...state.step].filter((d) => d !== checkoutSteps[2]);
      }
    },
    updateStep: (state, { payload: { mode, stepName } }) => {
      if (mode === 'add') {
        state.step = [...state.step].concat([stepName]);
      }
      if (mode === 'remove') {
        state.step = [...state.step].filter((d) => d !== stepName);
      }
    },
    clearErrors: (state) => {
      state.error = null;
    },
    clearCheckoutState: (state) => {
      const keys = Object.keys(initialState);
      keys.forEach((key) => {
        state[key] = initialState[key];
      });
    }
  },
  extraReducers: {
    [createOrder.pending]: (state) => {
      state.processingOrder = true;
      state.success = undefined;
    },
    [createOrder.fulfilled]: (state, action) => {
      state.processingOrder = false;
      state.createdOrder = action.payload;
      state.cashbackAmount = action.payload.cashback || undefined;
      state.success = true;
    },
    [createOrder.rejected]: (state, action) => {
      state.processingOrder = false;
      state.success = false;
      state.error = action.error;
    }
  }
});

export const {
  cleanCheckout,
  setCustomer,
  setAddress,
  setInvoiceData,
  setPaymentDetails,
  resetPaymentMethod,
  setPaymentMethod,
  addBill,
  removeBill,
  setIsValidCashAmounts,
  commitCashMethod,
  setEditing,
  updateStep,
  clearErrors,
  clearCheckoutState
} = AdminCheckoutSlice.actions;

export default AdminCheckoutSlice.reducer;
