import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from '../../firebase';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getSpecialCategories = createAsyncThunk(
  '@specialCategories/get',
  async () => firebase.getSpecialCategories()
);

export const createSpecialCategory = createAsyncThunk(
  '@specialCategories/create',
  async (data) => await firebase.createSpecialCategory(data)
);

export const updateSpecialCategory = createAsyncThunk(
  '@specialCategories/update',
  async ({ id, data }) => await firebase.updateSpecialCategory(id, data)
);

export const deleteSpecialCategory = createAsyncThunk(
  '@specialCategoriess/delete',
  async (id) => await firebase.deleteSpecialCategory(id)
)

export const specialCategoriesSlide = createSlice({
  name: 'specialCategories',
  initialState,
  reducers: {
    setSpecialCategoriesLoading: (state) => {
      state.loading = true;
    },
    setSpecialCategories: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setSpecialCategoriesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getSpecialCategories.pending]: (state) => {
      state.loading = true;
    },
    [getSpecialCategories.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getSpecialCategories.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createSpecialCategory.pending]: (state) => {
      state.loading = true;
    },
    [createSpecialCategory.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createSpecialCategory.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteSpecialCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteSpecialCategory.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteSpecialCategory.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateSpecialCategory.pending]: (state) => {
      state.loading = true;
    },
    [updateSpecialCategory.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateSpecialCategory.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setSpecialCategoriesLoading, setSpecialCategories, setSpecialCategoriesError } = specialCategoriesSlide.actions;

export default specialCategoriesSlide.reducer;