import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  orders: [],
  count: 0,
  hasCountInStore: false
};

export const ordersIncomingSlice = createSlice({
  name: 'ordersIncoming',
  initialState,
  reducers: {
    setOrdersIncoming: (state, action) => {
      state.orders = action.payload;

      if (!state.hasCountInStore) {
        state.hasCountInStore = true;
        state.count = state.orders.length;
      }
    },
    resetOrdersIncomingCount: (state) => {
      state.count = state.orders.length;
    }
  },
  extraReducers: {}
});

export const {
  setOrdersIncoming,
  resetOrdersIncomingCount
} = ordersIncomingSlice.actions;

export default ordersIncomingSlice.reducer;
