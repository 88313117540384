import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Row,
  Col,
  Space,
  Tag,
  Button,
  Form,
  Input,
  Popconfirm,
  Drawer
} from 'antd';
import useFirebaseContext from 'hooks/useFirebaseContext';
import { DeleteOutlined } from '@ant-design/icons';
import MessageForm from 'components/Forms/MessageForm/MessageForm';
import { showNotification } from 'stores/actions/notification';
import { getMessagesConfig } from 'stores/actions/messagesConfig';
import {
  messagesConfigData,
  messagesConfigLoading
} from 'stores/selectors/messagesConfig';

import useSetHeaderProps from 'hooks/useSetHeaderProps';
import { useTranslation } from 'react-i18next';
import Can from 'components/Can';
import { MESSAGES } from 'components/Can/modules';
import {
  createMessageConfig as createMessageConfigFb,
  deleteConfigMessage as deleteConfigMessageFb,
  updateMessageConfig as updateMessageConfigFb
} from 'firebase/api';

const Messages = () => {
  const dispatch = useDispatch();
  const firebase = useFirebaseContext();
  const { t } = useTranslation();

  const setHeaderProps = useSetHeaderProps();
  const dataTypeReceiver = useSelector(messagesConfigData);

  const EditableContext = React.createContext(null);

  const [isLoading, setIsloading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [receiver, setReceiver] = useState(null);
  const loading = useSelector(messagesConfigLoading);

  const getDataMessages = useCallback(() => {
    dispatch(getMessagesConfig(firebase));
  }, [dispatch, firebase]);

  const updateMessageConfig = useCallback(
    async (id, data) => {
      try {
        await updateMessageConfigFb(id, data);
      } catch (error) {
        dispatch(
          showNotification({
            type: 'error',
            message: 'Error',
            content: error.message
          })
        );
      }
    },
    [dispatch]
  );

  const createMessageConfig = useCallback(
    async (data) => {
      try {
        await createMessageConfigFb(data);
      } catch (error) {
        dispatch(
          showNotification({
            type: 'error',
            message: 'Error',
            content: error.message
          })
        );
      }
    },
    [dispatch]
  );

  const deleteMessageConfig = useCallback(
    async (id) => {
      setIsloading(true);
      await deleteConfigMessageFb(id);
      setIsloading(false);
      getDataMessages();
    },
    [getDataMessages]
  );

  const deleteMessageType = useCallback(
    async (record, messages) => {
      const resMessageConfig = messages.find((item) => {
        const resTypes = item.types;
        return resTypes.find((element) => element.id === record.id);
      });
      const resTypes = resMessageConfig.types.filter(
        (element) => element.id !== record.id
      );
      const typeUpdated = {
        name: resMessageConfig.name,
        id: resMessageConfig.id,
        types: resTypes
      };
      setIsloading(true);
      await updateMessageConfig(typeUpdated.id, typeUpdated);
      setIsloading(false);
      getDataMessages();
    },
    [getDataMessages, updateMessageConfig]
  );

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    /* clean value */
    if (record?.name === '-') {
      record.name = '';
    }

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex]
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        dispatch(
          showNotification({
            type: 'warning',
            message: 'warning',
            content: errInfo
          })
        );
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`
            }
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const handlerSave = useCallback(
    async (record) => {
      if (!receiver) {
        dispatch(
          showNotification({
            type: 'error',
            message: 'Error',
            content: 'Debe selecionar un receptor'
          })
        );
        return;
      }
      const isUpdate = !!record.hasOwnProperty('id');
      setIsloading(true);
      if (isUpdate) {
        await updateMessageConfig(record.id, record);
      } else {
        await createMessageConfig(record);
      }

      await getDataMessages();
      setIsloading(false);
      setDrawerOpen(false);
    },
    [
      dispatch,
      receiver,
      getDataMessages,
      createMessageConfig,
      updateMessageConfig
    ]
  );

  const columnsTypeReceiver = [
    {
      title: t('pages.messageConfig.table.receivers'),
      key: 'name',
      dataIndex: 'name',
      width: '90%',
      editable: true
    },
    {
      title: t('pages.messageConfig.table.action'),
      key: 'action',
      dataIndex: 'action',
      render: (_, record) =>
        dataTypeReceiver.length >= 1 && (
          <>
            <Space>
              <Can I="delete" a={MESSAGES}>
                <Popconfirm
                  title={t('pages.messageConfig.deleteMessage')}
                  onConfirm={() => deleteMessageConfig(record.id)}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Can>
            </Space>
          </>
        )
    }
  ];

  const getColumnsReceiver = () =>
    columnsTypeReceiver.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handlerSave
        })
      };
    });

  const expandedRowRender = (record) => {
    const messageTypesData = record.types;
    const recordSelected = dataTypeReceiver.filter(
      (item) => item.id === record.id
    )[0];

    const getTypeChanged = (item, type) => {
      const resNewType = recordSelected.types.filter(
        (element) => element.id !== item.id
      );
      resNewType.push(type);
      return { ...recordSelected, types: resNewType };
    };

    const columnsMessageTypes = [
      {
        id: 'name',
        title: t('pages.messageConfig.table.types'),
        dataIndex: 'name',
        width: '40%',
        editable: true
      },
      {
        id: 'messages',
        title: t('pages.messageConfig.table.messages'),
        dataIndex: 'messages',
        render: (element) => (
          <Space size="middle">
            {element?.map((item) => (
              <Tag key={item.id} closable={false} color="blue">
                {item.text}
              </Tag>
            ))}
          </Space>
        )
      },
      {
        title: t('pages.messageConfig.table.action'),
        key: 'action',
        dataIndex: 'action',
        render: (_, record) => (
          <>
            <Space>
              <Can I="delete" a={MESSAGES}>
                <Popconfirm
                  title={t('pages.messageConfig.deleteMessage')}
                  onConfirm={() => deleteMessageType(record, dataTypeReceiver)}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Can>
            </Space>
          </>
        )
      }
    ];
    const getColumnsTypes = () =>
      columnsMessageTypes.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: (type) => handlerSave(getTypeChanged(record, type))
          })
        };
      });

    return (
      <>
        <Table
          columns={getColumnsTypes()}
          rowKey="id"
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell
            }
          }}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={messageTypesData}
          pagination={false}
        />
      </>
    );
  };

  useEffect(() => {
    getDataMessages();
  }, [getDataMessages]);

  useEffect(() => {
    setHeaderProps({
      extraButtons: [
        <Fragment key={0}>
          <Can I="create" a={MESSAGES}>
            <Button type="primary" onClick={() => setDrawerOpen(true)}>
              {/* <Button type="primary" onClick={openPortal}> */}
              {t('pages.messageConfig.createEdit')}
            </Button>
          </Can>
        </Fragment>
      ]
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <Table
            rowKey="id"
            columns={getColumnsReceiver()}
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell
              }
            }}
            rowClassName={() => 'editable-row'}
            bordered
            expandable={{ expandedRowRender }}
            dataSource={dataTypeReceiver}
            loading={isLoading || loading}
          />
        </Col>
      </Row>
      <Row>
        <Drawer
          visible={drawerOpen}
          size="large"
          onClose={() => setDrawerOpen(false)}
        >
          <MessageForm
            handlerSave={(record) => handlerSave(record)}
            setReceiver={setReceiver}
          />
        </Drawer>
      </Row>
    </>
  );
};

export default Messages;
