import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Dropdown,
  Layout,
  Menu
} from 'antd';
import { isMobile } from 'react-device-detect';
import SignOutButton from 'components/SignOutButton/SignOutButton';
import PageHeader from 'components/PageHeader';
import Alerts from 'components/Alerts';
import logoIcon from 'assets/images/logo2.svg';
// import useHasAllSubscription from 'hooks/useHasAllSubscription';

import SubMenu from 'antd/lib/menu/SubMenu';
import { getProfileParsed } from 'stores/selectors/profile/selector';
import { UserOutlined } from '@ant-design/icons';
import { useAbility } from '@casl/react';
import { ACCOUNT } from 'constants/routes';
import { AbilityContext } from 'components/Can';
import ErrorBoundary from 'components/ErrorFallback/ErrorBoundary';
import styles from './Dashboard.module.scss';
import menu, { isCommonModule } from './menu';

const { Header, Content, Sider } = Layout;

const DashBoard = ({ children }) => {
  // const hasAllDataLoaded = useHasAllSubscription();
  const profile = useSelector(getProfileParsed);
  const ability = useAbility(AbilityContext);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const goToRoute = (route) => () => history.push(route);

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, []);

  const renderMenuItems = (items) =>
    items.map((item) => {
      if (!ability.can('read', item.label) && !isCommonModule(item.label)) {
        return null;
      }
      const hasChildren = item.children.length > 0;
      const hasIconProp = item.hasOwnProperty('icon');
      const iconCp = hasIconProp ? (
        React.createElement(item.icon)
      ) : (
        <img className={styles.icon} src={item.src} alt={item.label} />
      );

      if (hasChildren) {
        return (
          <SubMenu key={item.key} icon={iconCp} title={t(`menu.${item.label}`)}>
            {renderMenuItems(item.children)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item
          role="listitem"
          key={item.key}
          icon={iconCp}
          onClick={goToRoute(item.key)}
        >
          {t(`menu.${item.label}`)}
        </Menu.Item>
      );
    });

  const dropdownMenu = (
    <Menu>
      <Menu.Item
        icon={<UserOutlined />}
        key={ACCOUNT}
        onClick={goToRoute(ACCOUNT)}
      >
        {t('menu.myAccount')}
      </Menu.Item>
      <SignOutButton key="signOut" />
    </Menu>
  );

  return (
    <Layout>
      <Sider
        collapsible={!isMobile}
        collapsed={collapsed}
        onCollapse={setCollapsed}
      >
        <img src={logoIcon} alt="logo" className={styles.logo} />
        <Menu
          role="list"
          theme="dark"
          defaultSelectedKeys={[location.pathname]}
          mode="inline"
          selectedKeys={[location.pathname]}
        >
          {renderMenuItems(menu)}
        </Menu>
      </Sider>
      <Layout
        style={{
          minHeight: '100vh',
          height: '100%',
          overflow: 'auto'
        }}
      >
        <Header className={styles.header}>
          {/* Shows Alerts only if user has permissions to reading orders data. */}
          {ability.can('read', 'orders-parent') && <Alerts />}
          <Dropdown overlay={dropdownMenu} trigger={['click']}>
            <Avatar src={profile.srcImgProfile} />
          </Dropdown>
        </Header>
        <Content style={{ padding: '24px' }}>
          <PageHeader />
          <ErrorBoundary>{children}</ErrorBoundary>
          {ability.can('read', 'orders-parent') && <Alerts mode="float" />}
        </Content>
      </Layout>
    </Layout>
  );
};

DashBoard.propTypes = {
  children: PropTypes.node.isRequired
};

export default DashBoard;
