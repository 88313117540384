/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */

/* Compat sdk 9 */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/messaging';
import { httpsCallable } from 'firebase/functions';

import {
  sendPasswordResetEmail,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from 'firebase/auth';
import chunk from 'lodash/chunk';
import { setToken } from './utils/token';
import {
  ADMINS,
  ROADMAPS,
  ROUTES,
  ORDERS,
  RIDERS,
  NOTIFICATIONS,
  ADDRESSES,
  USERS,
  COMMISSIONS_BY_RIDERS,
  CONFIG,
  PROCO,
  FINANCE,
  MAIL,
  TEMPLATES,
  COUPONS,
  SPECIAL_CATEGORIES,
  ANNOUNCEMENTS_CONFIG,
  CATEGORY_BOX,
  CATEGORY_PRODUCT
} from './collections';
import { getArrayCollection } from './utils/parsers';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

const hasChildren = (obj = {}) => Object.keys(obj).length > 0 || false;
class Firebase {
  constructor() {
    firebase.initializeApp(config);

    // *** Firebase Api's  ***
    this.auth = getAuth();
    this.firestore = firebase.firestore();
    this.storage = firebase.storage();
    // this.messaging = firebase.messaging();
  }

  async resetPassword(email) {
    return sendPasswordResetEmail(this.auth, email);
  }

  getCollectionRef(collection) {
    return this.firestore.collection(collection);
  }

  async getCollectionData(collection) {
    const colRef = await this.getCollectionRef(collection);
    const snapshot = await colRef.get();
    return getArrayCollection(snapshot);
  }

  async setCollectionData(collection, body) {
    const colRef = await this.getCollectionRef(collection);
    return colRef.add(body);
  }

  async multiOperation(items) {
    const batch = this.firestore.batch();
    const blocksLength = 499; // limit of batch operation at the same time.

    const blocks = chunk(items, blocksLength);

    blocks.forEach((block) => {
      block.forEach(({ id, body, operation, model, children }) => {
        if (hasChildren(children)) {
          const parentRef = this.getCollectionRef(model).doc(id);
          const childrenRef = parentRef.collection(children.model).doc();

          if (children.operation !== 'delete') {
            batch[children.operation](childrenRef, children.body);
          } else {
            batch[children.operation](childrenRef);
          }
        } else {
          const ref = this.getCollectionRef(model).doc(id);

          if (operation !== 'delete') {
            batch[operation](ref, body);
          } else {
            batch[operation](ref);
          }
        }
      });
    });

    await batch.commit();
  }

  // *** Auth API ***

  getAuth() {
    return this.auth;
  }

  createUserWithEmailAndPassword(email, password) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  signInWithEmailAndPassword(email, password) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  signOut() {
    return this.auth.signOut();
  }

  // *** Admins Api ***

  async getAdmins() {
    const adminsRef = await this.firestore.collection(ADMINS);
    const snapshot = await adminsRef.get();

    const admins = [];
    snapshot.forEach((element) => {
      admins.push({
        ...element.data(),
        id: element.id
      });
    });
    return admins;
  }

  async getAdminById(id) {
    const adminRef = await this.firestore.collection(ADMINS);
    const snapshot = await adminRef.doc(`${id}`).get();

    return {
      ...snapshot.data(),
      id
    };
  }

  async login(email, password) {
    try {
      const auth = await this.signInWithEmailAndPassword(email, password);
      const admin = await this.getAdminByEmail(email);
      const token = await auth.user.getIdToken();
      setToken(token);
      return admin;
    } catch (error) {
      if (this.auth.currentUser) {
        this.auth.signOut();
      }
      return error?.message;
    }
  }

  async getAdminByEmail(email) {
    const adminRef = await this.firestore.collection(ADMINS);
    const snapshot = await adminRef.where('email', '==', email).get();
    const collection = [];

    if (!snapshot.size) throw new Error('Usuario no existe');
    snapshot.forEach((element) => {
      collection.push({
        ...element.data(),
        id: element.id
      });
    });

    return collection[0]; // Founded Admin
  }

  async verifiedAdminExists(admin) {
    const adminRef = await this.firestore.collection('admins');
    const byEmail = adminRef.where('email', '==', admin.email).get();
    const byDNI = adminRef.where('dni', '==', admin.dni).get();
    const [byEmailSnapshot, byDNISnapshot] = await Promise.all([
      byEmail,
      byDNI
    ]);
    const errors = [];
    const output = {
      exists: false,
      errors: []
    };

    if (byEmailSnapshot.size > 0) {
      errors.push('email');
    }
    if (byDNISnapshot.size > 0) {
      errors.push('ci');
    }

    if (errors.length > 0) {
      output.exists = true;
      output.errors = errors;
    }

    return output;
  }

  async createAdmin(admin) {
    const { password, ...restDataAdmin } = admin;
    const resCollection = await this.firestore
      .collection('admins')
      .add(restDataAdmin);
    if (resCollection.id) {
      this.createUserWithEmailAndPassword(admin.email, admin.password);
    }
  }

  async updateAdmin(adminId, adminFields) {
    const adminRef = this.firestore.collection('admins').doc(adminId);
    await adminRef.update(adminFields);
  }

  async deleteAdmin(adminId) {
    const adminRef = this.firestore.collection('admins').doc(adminId);
    await adminRef.delete();
  }

  /* Roles And Permissions API */

  async getRoles() {
    const rolesRef = await this.firestore.collection('roles');
    const snapshot = await rolesRef.get();

    const roles = [];
    snapshot.forEach((element) => {
      roles.push({
        ...element.data(),
        id: element.id
      });
    });
    return roles;
  }

  async getPermissions() {
    const permissionsRef = await this.firestore.collection('permissions');
    const snapshot = await permissionsRef.get();

    const permissions = [];
    snapshot.forEach((element) => {
      permissions.push({
        ...element.data(),
        id: element.id
      });
    });
    return permissions;
  }

  async getPermissionsByRole(id) {
    const roleRef = await this.firestore.collection('roles');
    const snapshot = await roleRef.doc(id).get();
    return snapshot.data();
  }

  async getPermissionsByRolePermissions(permissionIds) {
    const permissionsRef = await this.firestore.collection('permissions');
    const snapshot = await permissionsRef.get();

    const permissions = [];
    snapshot.forEach((document) => {
      if (permissionIds.includes(document.id)) {
        permissions.push(document.data().name);
      }
    });
    return permissions;
  }

  async createRole(data) {
    await this.firestore.collection('roles').add(data);
  }

  async updateRole(data, roleId) {
    const roleRef = this.firestore.collection('roles').doc(roleId);
    await roleRef.update(data);
  }

  async deleteRole(id) {
    await this.firestore.collection('roles').doc(id).delete();
  }

  subscriptionGetRoles() {
    return this.getCollectionRef('roles');
  }

  // *** Users Api ***
  async createUser(user) {
    const { password, ...resDataClient } = user;
    const { id } = await this.firestore.collection('users').add(resDataClient);
    return id;
  }

  async getAllUsers() {
    const usersRef = await this.firestore.collection('users');
    const snapshot = await usersRef.get();

    const users = [];
    snapshot.forEach((document) => {
      users.push({ key: document.id, ...document.data() });
    });

    return users;
  }

  async getUserByEmail(email) {
    const usersRef = this.firestore.collection('users');
    const snapshot = await usersRef.where('email', '==', email).get();
    const collection = [];

    snapshot.forEach((element) => {
      collection.push({
        ...element.data()
      });
    });

    const { confirm, ...user } = collection[0];
    return user;
  }

  async getUserById(userId) {
    const usersRef = await this.firestore.collection('users');
    const snapshot = await usersRef.doc(`${userId}`).get();

    return snapshot.data();
  }

  async updateUser(userId, userFields) {
    const userRef = this.firestore.collection('users').doc(userId);
    await userRef.update(userFields);
  }

  subscriptionGetUsers() {
    return this.getCollectionRef('users');
  }

  async setUsersNotification(notifications) {
    return this.multiOperation(notifications);
  }

  /* Products Api */
  async searchProducts({
    query = '',
    pageSize = 100,
    actualPage = 1,
    companyId = 1,
    category,
    recommended,
    offers,
    type,
    sort
  }) {
    return httpsCallable(
      this.functions,
      'es-search'
    )({
      query,
      company_id: companyId,
      pageSize,
      actualPage,
      category,
      recommended,
      type,
      sort,
      offers
    });
  }

  async searchProductsByIds(ids) {
    return httpsCallable(this.functions, 'es-searchById')({ ids });
  }

  async createProduct(product) {
    return this.firestore.collection('products').add({
      name: product.name,
      description: product.description,
      manufacturer: product.manufacturer,
      category: product.category,
      components: product.components,
      imageUrl: product.imageUrl ? product.imageUrl : ''
    });
  }

  async updateProduct(productId, product) {
    return this.firestore
      .collection('products')
      .doc(productId)
      .update({
        name: product.name,
        description: product.description,
        manufacturer: product.manufacturer,
        category: product.category,
        components: product.components,
        imageUrl: product.imageUrl ? product.imageUrl : ''
      });
  }

  async deleteProduct(productId) {
    return this.firestore.collection('products').doc(productId).delete();
  }

  async getProduct(productId) {
    return this.firestore.collection('products').doc(productId).get();
  }

  async getAllProducts(orderBy) {
    let ref = this.firestore.collection('products');
    if (orderBy) ref = ref.orderBy(orderBy);
    return ref.get().then((snapshot) => {
      const products = [];
      snapshot.forEach((document) => {
        if (!document.data().deleted) {
          products.push({ key: document.id, ...document.data() });
        }
      });
      return products;
    });
  }

  async getProductById(productId) {
    const productsRef = await this.firestore.collection('products');
    const snapshot = await productsRef.doc(productId).get();

    return snapshot.data();
  }

  /* Bikes */
  async createBike(bike) {
    const { id } = await this.firestore.collection('bikes').add(bike);
    return id;
  }

  async getBikeById(bikeId) {
    const bikesRef = await this.firestore.collection('bikes');
    const snapshot = await bikesRef.doc(`${bikeId}`).get();

    return snapshot.data();
  }

  async getBikes() {
    const bikesRef = this.firestore.collection('bikes');
    const snapshot = await bikesRef.get();

    const bikes = [];
    snapshot.forEach((document) => {
      bikes.push({ id: document.id, ...document.data() });
    });

    return bikes;
  }

  async updateBike(bikeId, { brand, description, plate }) {
    return this.firestore.collection('bikes').doc(bikeId).update({
      brand,
      description,
      plate
    });
  }

  async deleteBike(bikeId) {
    return this.firestore.collection('bikes').doc(bikeId).delete();
  }

  /* Riders */

  async registerRider(rider) {
    await createUserWithEmailAndPassword(
      this.auth,
      rider.email,
      'BienvenidoRider'
    );
    return this.createRider(rider);
  }

  async createRider(rider) {
    return this.firestore.collection('riders').add(rider);
  }

  async getRiders() {
    const ridersRef = await this.firestore.collection('riders');
    const snapshot = await ridersRef.get();

    const riders = [];
    snapshot.forEach((document) => {
      riders.push({
        id: document.id,
        ...document.data()
      });
    });

    return riders;
  }

  async getRiderById(riderId) {
    const riderRef = await this.firestore.collection('riders');
    const snapshot = await riderRef.doc(`${riderId}`).get();

    return snapshot.data();
  }

  updateRider(riderId, data) {
    return this.firestore
      .collection('riders')
      .doc(riderId)
      .update(data)
      .then(() => ({ status: 200 }));
  }

  async deleteRider(riderId) {
    return this.firestore.collection('riders').doc(riderId).delete();
  }

  /* Departments API */

  async getDepartments() {
    const departmentsRef = await this.firestore.collection('departments');
    const snapshot = await departmentsRef.get();

    const departments = [];
    snapshot.forEach((document) => {
      departments.push({
        id: document.id,
        ...document.data()
      });
    });

    return departments;
  }

  async getDepartmentByRole(id) {
    const departmentRef = this.firestore.collection('departments');
    const snapshot = await departmentRef.doc(`${id}`).get();
    return snapshot.data();
  }

  async createDepartment(record) {
    await this.firestore.collection('departments').add(record);
  }

  async updateDepartment(record) {
    const { key } = record;
    await this.firestore.collection('departments').doc(key).update({
      name: record.name,
      active: record.active
    });
  }

  async deleteDepartment(id) {
    await this.firestore.collection('departments').doc(id).delete();
  }

  async checkDepartmentInAdmins(id) {
    const admins = await this.getAdmins();
    const exists = admins.find((item) => item.departmentId === id);
    return Boolean(exists);
  }

  /* Images Api */

  async uploadImage(path, imageB64, userAvatar, userId) {
    const metadata = {
      contentType: 'image/jpeg'
    };
    const imageName = userId;
    const destination = `${path}/${imageName}.jpg`;

    /* delete to clean exists */
    if (userAvatar) {
      const pictureRef = await this.storage.refFromURL(userAvatar);
      pictureRef.delete();
    }

    return this.storage
      .ref()
      .child(destination)
      .putString(imageB64, 'data_url', metadata)
      .then(
        (docRef) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          this.storage
            .ref()
            .child(docRef.metadata.fullPath)
            .getDownloadURL()
            .then((url) => ({ url, photo: imageB64 }))
        // eslint-disable-next-line function-paren-newline
      );
  }

  /* Addresses API */

  async getAddresses() {
    const addressesRef = await this.getCollectionGroup(ADDRESSES);

    return addressesRef.get().then((snapshot) => {
      const addresses = [];
      snapshot.forEach((document) => {
        addresses.push({
          id: document.id,
          ...document.data()
        });
      });

      return addresses;
    });
  }

  async createAddress(userId, address) {
    await this.getCollectionRef(USERS)
      .doc(userId)
      .collection(ADDRESSES)
      .add(address);
  }

  async updateAddress(addressId, address) {
    const addressRef = this.firestore.collection('addresses').doc(addressId);
    await addressRef.update(address);
  }

  async deleteAddress(userId, addressId) {
    return this.getCollectionRef(USERS)
      .doc(userId)
      .collection(ADDRESSES)
      .doc(addressId)
      .update({ deletedAt: Date.now() });
  }

  /* Routes API */
  async getRoutes() {
    const routesRef = await this.firestore.collection('routes');

    return routesRef
      .orderBy('name', 'asc')
      .get()
      .then((snapshot) => {
        const routes = [];
        snapshot.forEach((document) => {
          routes.push({
            id: document.id,
            ...document.data()
          });
        });

        return routes;
      });
  }

  async createRoute(route) {
    const createdAt = new Date();
    this.firestore.collection('routes').add({ ...route, createdAt });
  }

  async updateRoute(routeId, route) {
    const routeRef = this.firestore.collection('routes').doc(routeId);
    await routeRef.update(route);
  }

  async deleteRoute(routeId) {
    return this.firestore.collection('routes').doc(routeId).delete();
  }

  /* Orders API */

  async getOrders() {
    const ordersRef = await this.firestore.collection('orders');
    const addressRef = await this.getAddresses();

    return ordersRef.get().then((snapshot) => {
      const orders = [];
      snapshot.forEach((document) => {
        orders.push({
          id: document.id,
          address: addressRef.find(
            (address) => address.id === document.data().addressId
          ),
          ...document.data()
        });
      });

      return orders;
    });
  }

  /* Function to valid if the user is container in whitelist to limit listener */

  async includeLimits() {
    const userEmail = await this.getAuth().currentUser?.email;
    const { roleId } = await this.getAdminByEmail(userEmail);
    const configData = await this.firestore
      .collection(CONFIG)
      .doc('admin')
      .get();
    const { userListenerOrders = [] } = await configData.data();

    if (userListenerOrders.includes(roleId)) return true;

    return false;
  }

  subscriptionGetOrders() {
    return this.getCollectionRef(ORDERS);
  }

  async updateOrders(ids) {
    const orders = ids.map((id) => ({
      id,
      model: ORDERS,
      operation: 'update',
      body: { statusCode: 0 }
    }));

    await this.multiOperation(orders);
  }

  async updateOrder(id, body) {
    const routeRef = this.firestore.collection(ORDERS).doc(id);
    await routeRef.update(body);
  }

  /* Roadmaps API */

  async getRoadmaps() {
    const roadmapsRef = this.firestore.collection('roadmaps');

    const riders = await this.getRiders();
    const routes = await this.getRoutes();
    const orders = await this.getOrders();

    return roadmapsRef.get().then((snapshot) => {
      const roadmaps = [];
      snapshot.forEach((document) => {
        roadmaps.push({
          id: document.id,
          rider: riders.find((rider) => rider.id === document.data().riderId),
          route: routes.find((route) => route.id === document.data().routeId),
          orders: document
            .data()
            .ordersIds.map((order) =>
              orders.find((fOrder) => order === fOrder.id)
            ),
          ...document.data()
        });
      });

      return roadmaps;
    });
  }

  async deleteRoadmaps(roadmapId) {
    const roadmapsRef = this.firestore.collection('roadmaps').doc(roadmapId);
    await roadmapsRef.delete();
  }

  /* Commission by Rider API */

  async getCommissionsByRider() {
    const commissionByRider = this.firestore.collection('commissionsByRider');

    commissionByRider.get().then((snapshot) => {
      const commissions = snapshot?.map((document) => ({
        id: document.id,
        ...document
      }));

      snapshot.forEach((document) => {
        commissions.push({
          id: document.id,
          ...document
        });
      });

      return commissions;
    });
  }

  /* Config API */

  /// Messages Config API

  async createMessageConfig(record) {
    await this.firestore
      .collection('config')
      .doc('admin')
      .collection('messages')
      .add(record);
  }

  async updateMessageConfig(id, record) {
    const messageConfigRef = this.firestore
      .collection('config')
      .doc('admin')
      .collection('messages')
      .doc(id);
    await messageConfigRef.update(record);
  }

  async deleteConfigMessage(id) {
    const configMessageRef = this.firestore
      .collection('config')
      .doc('admin')
      .collection('messages')
      .doc(id);
    await configMessageRef.delete();
  }

  async getMessagesConfig() {
    const messagesConfigRef = await this.firestore
      .collection('config')
      .doc('admin')
      .collection('messages');
    const snapshot = await messagesConfigRef.get();

    const messagesConfig = [];
    snapshot.forEach((document) => {
      messagesConfig.push({
        ...document.data(),
        id: document.id
      });
    });
    return messagesConfig;
  }

  /// Config Commissions

  async getCommissionsConfig() {
    const commissionsConfigRef = await this.firestore
      .collection('config')
      .doc('admin')
      .collection('commissions');
    const snapshot = await commissionsConfigRef.get();

    const commissionsConfig = [];
    snapshot.forEach((document) => {
      commissionsConfig.push({
        ...document.data(),
        id: document.id
      });
    });
    return commissionsConfig;
  }

  async createCommissionConfig(record) {
    const createAt = firebase.firestore.FieldValue.serverTimestamp();
    const output = { ...record, createAt };
    await this.firestore
      .collection('config')
      .doc('admin')
      .collection('commissions')
      .add(output);
  }

  async updateCommissionConfig(pId, record) {
    const { id, ...resData } = record;
    const createAt = firebase.firestore.FieldValue.serverTimestamp();
    const output = { ...resData, createAt };

    const commissionConfigRef = await this.firestore
      .collection('config')
      .doc('admin')
      .collection('commissions')
      .doc(pId);
    await commissionConfigRef.update(output);
  }

  async deleteCommissionConfig(id) {
    const commissionConfigRef = this.firestore
      .collection('config')
      .doc('admin')
      .collection('commissions')
      .doc(id);
    await commissionConfigRef.delete();
  }

  /* Config payment methods */

  async getPaymentMethodsConfig() {
    const paymentMethodsRef = await this.firestore
      .collection('config')
      .doc('paymentMethods');

    const snapshot = await paymentMethodsRef.get();

    return snapshot.data().methods;
  }

  /* Config Product Categories */
  async getProductCompanies() {
    const productCompaniesRef = await this.firestore
      .collection('config')
      .doc('admin')
      .collection('productCompanies');
    const snapshot = await productCompaniesRef.get();

    const productCompaniesConfig = [];
    snapshot.forEach((document) => {
      productCompaniesConfig.push({
        ...document.data(),
        id: document.id
      });
    });
    return productCompaniesConfig;
  }

  /// Roadmaps

  async updateRoadmap(roadmapId, roadmap) {
    const roadmapRef = this.firestore.collection(ROADMAPS).doc(roadmapId);
    await roadmapRef.update(roadmap);
  }

  subscriptionGetRoadmaps() {
    return this.getCollectionRef(ROADMAPS);
  }

  subscriptionGetRiders() {
    return this.getCollectionRef(RIDERS);
  }

  subscriptionGetRoutes() {
    return this.getCollectionRef(ROUTES);
  }

  subscriptionGetCommissionsByRider() {
    return this.getCollectionRef(COMMISSIONS_BY_RIDERS);
  }

  subscriptionGetConfig() {
    return this.getCollectionRef(CONFIG);
  }

  subscriptionGetAddresses() {
    return this.getCollectionRef(ADDRESSES);
  }

  // notifications

  async getNotifications() {
    return this.getCollectionData(NOTIFICATIONS);
  }

  async createNotification(notification) {
    return this.setCollectionData(NOTIFICATIONS, notification);
  }

  // Roles Config

  async getModules() {
    const modulesRef = await this.firestore
      .collection('config')
      .doc('admin')
      .collection('modules');
    const snapshot = await modulesRef.get();

    const modules = [];
    snapshot.forEach((document) => {
      modules.push({
        ...document.data(),
        id: document.id
      });
    });
    return modules;
  }

  // Banners
  async getBanners() {
    const bannersRef = await this.firestore
      .collection('config')
      .doc('admin')
      .collection('banners');
    const snapshot = await bannersRef.get();

    const banners = [];
    snapshot.forEach((document) => {
      banners.push({
        ...document.data(),
        id: document.id
      });
    });
    return banners;
  }

  async createBanner(pBanner) {
    await this.firestore
      .collection('config')
      .doc('admin')
      .collection('banners')
      .add(pBanner);
  }

  async updateBanner(pBannerId, pBanner) {
    await this.firestore
      .collection('config')
      .doc('admin')
      .collection('banners')
      .doc(pBannerId)
      .update(pBanner);
  }

  /* PMDevueltos */
  async getCommerceToPerson() {
    const commerceToPersonRef = await this.firestore
      .collection('config')
      .doc('PagoMovil')
      .collection('PMDevueltos');
    const snapshot = await commerceToPersonRef.get();

    return getArrayCollection(snapshot);
  }

  async createCommerceToPerson(record) {
    this.firestore
      .collection('config')
      .doc('PagoMovil')
      .collection('PMDevueltos')
      .add(record);
  }

  // Banks
  async getBanks() {
    const banksRef = await this.firestore
      .collection('config')
      .doc('admin')
      .collection('bankList');
    const snapshot = await banksRef.get();

    return getArrayCollection(snapshot);
  }

  /* Procos API */
  subscriptionGetProcos() {
    return this.getCollectionRef(PROCO);
  }

  async getProcos() {
    const procosRef = this.firestore.collection(PROCO);
    const snapshot = await procosRef.get();

    return getArrayCollection(snapshot);
  }

  async createProco(proco) {
    this.firestore.collection('proco').add(proco);
  }

  async updateProco(procoId, data) {
    return this.firestore.collection('proco').doc(procoId).update(data);
  }

  async deleteProco(procoId) {
    return this.firestore.collection('proco').doc(procoId).delete();
  }

  /// Finance
  async getPricesBackup() {
    const priceRef = await this.firestore
      .collection(FINANCE)
      .where('reportType', '==', 'price');
    const snapshot = await priceRef.get();

    const price = [];
    snapshot.forEach((document) => {
      price.push({
        id: document.id,
        ...document.data()
      });
    });

    return price;
  }

  async createPriceBackup(price) {
    this.firestore.collection(FINANCE).add(price);
  }

  async updatePriceBackup(priceId, data) {
    return this.firestore.collection(FINANCE).doc(priceId).update(data);
  }

  getCollectionGroup(collection) {
    return this.firestore.collectionGroup(collection);
  }

  // MAILS
  async createMail(mail) {
    this.firestore.collection(MAIL).add(mail);
  }

  // TEMPLATES
  async getTemplates() {
    const templatesRef = await this.firestore.collection(TEMPLATES);
    const snapshot = await templatesRef.get();
    return getArrayCollection(snapshot);
  }

  // COUPONS
  async getCoupons() {
    const couponsRef = await this.firestore.collectionGroup(COUPONS);
    const snapshot = await couponsRef.get();
    return getArrayCollection(snapshot);
  }

  async createCoupon(data) {
    await this.firestore.collection(COUPONS).doc(data.code).set(data);
  }

  async updateCoupon(id, fields) {
    const couponsRef = this.firestore.collection(COUPONS).doc(id);
    await couponsRef.update(fields);
  }

  // CATEGORIES BOX
  async getCategoriesBox() {
    const categoriesRef = this.firestore.collection(CATEGORY_BOX);
    // .where('available', '==', true);
    const snapshot = await categoriesRef.get();
    return getArrayCollection(snapshot);
  }

  /**
   * @param {firebase.firestore.DocumentData} data
   */
  async createCategoryBox(data) {
    try {
      await this.firestore.collection(CATEGORY_BOX).add(data);
    } catch (error) {
      console.log('🚀 ~ Firebase ~ createCategoryBox ~ error:', error);
    }
  }

  /**
   * @param {string} id
   * @param {firebase.firestore.UpdateData} data
   */
  async updateCategoryBox(id, data) {
    await this.firestore.collection(CATEGORY_BOX).doc(id).update(data);
  }

  /**
   * @param {string} id
   */
  async deleteCategoryBox(id) {
    await this.firestore.collection(CATEGORY_BOX).doc(id).delete();
  }

  // CATEGORIES PRODUCTS
  async getCategoriesProducts() {
    const categoriesRef = this.firestore.collection(CATEGORY_PRODUCT);
    const snapshot = await categoriesRef.get();
    return getArrayCollection(snapshot);
  }

  /**
   * @param {firebase.firestore.DocumentData} data
   */
  async createCategoryProduct(data) {
    try {
      console.log(data);
      await this.firestore.collection(CATEGORY_PRODUCT).add(data);
    } catch (error) {
      console.log('🚀 ~ Firebase ~ createCategoryBox ~ error:', error);
    }
  }

  /**
   * @param {string} id
   * @param {firebase.firestore.UpdateData} data
   */
  async updateCategoryProduct(id, data) {
    try {
      await this.firestore.collection(CATEGORY_PRODUCT).doc(id).update(data);
    } catch (error) {
      console.log('🚀 ~ Firebase ~ `createCategoryProduct` ~ error:', error);
    }
  }

  /**
   * @param {string} id
   */
  async deleteCategoryProduct(id) {
    await this.firestore.collection(CATEGORY_PRODUCT).doc(id).delete();
  }

  // SPECIAL CATEGORIES
  async getSpecialCategories() {
    const categoriesRef = await this.firestore
      .collection(CONFIG)
      .doc('categories')
      .collection(SPECIAL_CATEGORIES);
    const snapshot = await categoriesRef.get();
    return getArrayCollection(snapshot);
  }

  async createSpecialCategory(data) {
    await this.firestore
      .collection(CONFIG)
      .doc('categories')
      .collection(SPECIAL_CATEGORIES)
      .add(data);
  }

  async updateSpecialCategory(id, data) {
    await this.firestore
      .collection(CONFIG)
      .doc('categories')
      .collection(SPECIAL_CATEGORIES)
      .doc(id)
      .update(data);
  }

  async deleteSpecialCategory(id) {
    await this.firestore
      .collection(CONFIG)
      .doc('categories')
      .collection(SPECIAL_CATEGORIES)
      .doc(id)
      .delete();
  }

  // SHOPPING CART
  async getShoppingCart(id) {
    const shopCart = await this.firestore
      .collection(USERS)
      .doc(id)
      .collection('shopCart');
    const snapshot = await shopCart.get();
    return getArrayCollection(snapshot)[0];
  }

  // *** Marketing (announcementConfig) Api ***

  async getAnnouncementsConfig() {
    const categoriesRef = await this.firestore
      .collection(CONFIG)
      .doc('admin')
      .collection(ANNOUNCEMENTS_CONFIG);
    const snapshot = await categoriesRef.get();
    return getArrayCollection(snapshot);
  }

  async createAnnouncementConfig(data) {
    await this.firestore
      .collection(CONFIG)
      .doc('admin')
      .collection(ANNOUNCEMENTS_CONFIG)
      .add(data);
  }

  async updateAnnouncementConfig(id, data) {
    await this.firestore
      .collection(CONFIG)
      .doc('admin')
      .collection(ANNOUNCEMENTS_CONFIG)
      .doc(id)
      .update(data);
  }

  async deleteAnnouncementConfig(id) {
    await this.firestore
      .collection(CONFIG)
      .doc('admin')
      .collection(ANNOUNCEMENTS_CONFIG)
      .doc(id)
      .delete();
  }

  async removeProductsByOrder(body) {
    return this.functions.httpsCallable('odoo-removeProducts')(body);
  }
}

export default new Firebase();
