// eslint-disable-next-line import/prefer-default-export
export const hasData = (snapshot) => snapshot.empty;

export const getArrayCollection = (snapshot) => {
  if (hasData(snapshot)) return [];

  const collection = [];
  snapshot.forEach((element) => {
    if (element.ref.parent.parent?.id) {
      collection.push({
        id: element.id,
        parent: element.ref.parent.parent?.id,
        ...element.data()
      });
    } else {
      collection.push({
        id: element.id,
        ...element.data()
      });
    }
  });
  return collection;
};

export const getFirstElementArrayCollection = (snapshot) => {
  const collection = getArrayCollection(snapshot);
  return collection[0];
};
