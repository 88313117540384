import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPermissions as getPermissionsFb } from 'firebase/api';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getPermissions = createAsyncThunk(
  '@permissions/getPermissions',
  async () => getPermissionsFb()
);

export const permissionsSlide = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissionsLoading: (state) => {
      state.loading = true;
    },
    setPermissions: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setPermissionsError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getPermissions.pending]: (state) => {
      state.loading = true;
    },
    [getPermissions.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getPermissions.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setPermissionsLoading,
  setPermissions,
  setPermissionsError
} = permissionsSlide.actions;

export default permissionsSlide.reducer;
