import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'services';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const fetchStatistics = createAsyncThunk(
  '@statistics/fetchStatistics',
  async () => {
    const { data } = await API.dashboard.getStatistics();
    return data;
  }
);

export const statisticsSlide = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatisticsLoading: (state) => {
      state.loading = true;
    },
    setStatistics: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setStatisticsError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [fetchStatistics.pending]: (state) => {
      state.loading = true;
    },
    [fetchStatistics.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchStatistics.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setStatisticsLoading,
  setStatistics,
  setStatisticsError
} = statisticsSlide.actions;

export default statisticsSlide.reducer;
