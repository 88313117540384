import { parseUserDataInOrders } from 'stores/selectors/orders';
import { getNonEmptyOrders } from 'utils/utils';

export const pharmacyOrdersFactory = (orders = [], users = [], riders = []) => {
  const nonEmptyOrders = getNonEmptyOrders(orders);
  const ordersParsed = nonEmptyOrders
    .map((order) => {
      const partner = users.find((user) => user.id === order.clientId) || '';
      const rider = riders.find((riderItem) => riderItem.id === order.riderId) || '';
      return {
        ...parseUserDataInOrders(order),
        partner,
        rider
      };
    })
    .filter((order) => !order.partner.support);

  return ordersParsed.map((order) => ({
    ...order,
    products: order?.products && order.products.map((product) => ({
      ...product,
      orderId: order.id,
      orderName: order.name
    }))
  })).filter(
    (order) => (order.statusCode === -2)
  );
};
