import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Form, Input, Select, Divider, Space, Button } from 'antd';
import PropTypes from 'prop-types';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {
  messagesConfigData,
  messagesConfigLoading
} from 'stores/selectors/messagesConfig';
import { useTranslation } from 'react-i18next';

const MessageForm = ({ handlerSave, setReceiver }) => {
  const dataTypeReceiver = useSelector(messagesConfigData);
  const isLoading = useSelector(messagesConfigLoading);

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [newReceiver, setNewReceiver] = useState({
    receiver: '',
    type: ''
  });

  const [receivers] = useState(dataTypeReceiver.map((item) => item.name));
  const [receiverTypes, setReceiverTypes] = useState({
    id: null,
    types: [],
    name: ''
  });
  const [messages, setMessages] = useState({
    messages: [],
    type: null
  });

  const { Option } = Select;

  const hasMessages = Object.keys(messages).length > 0;
  const initialMessages = hasMessages
    ? messages
    : {
        messages: [],
        type: null
      };

  const handlerMessage = (record) => {
    const { type } = messages;

    const newMessages = record.messages.map((message) => {
      if (!message.hasOwnProperty('id')) {
        message.id = uuidv4();
      }
      return message;
    });

    const resRecord = receiverTypes.types.find(
      (element) => element.name === type
    );

    const newRecord = { ...resRecord, messages: newMessages };

    const newElementType = receiverTypes.types.filter(
      (element) => element.name !== type
    );
    newElementType.push(newRecord);

    const output = {
      ...receiverTypes,
      types: newElementType
    };

    setReceiverTypes(output);
    setMessages({ ...messages, messages: newMessages });
    return output;
  };

  const handlerReceiver = (receiver) => {
    const resReceiver = dataTypeReceiver.find((item) => item.name === receiver);
    const resReceiverTypes = resReceiver
      ? resReceiver
      : {
          name: receiver,
          types: []
        };
    setReceiver(receiver);
    setReceiverTypes(resReceiverTypes);
  };

  const handlerTypes = (type) => {
    const resDataMessages = receiverTypes.types.find((item) => {
      return item.name === type;
    });
    const resMessages = {
      messages: resDataMessages?.messages,
      type
    };

    setMessages(resMessages);
    form.setFieldsValue(resMessages);
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 24 }
  };

  const onNewRecordChange = (event) => {
    if (event.target.name === 'receiver') {
      setNewReceiver({
        ...newReceiver,
        receiver: event.target.value
      });
    } else {
      setNewReceiver({
        ...newReceiver,
        type: event.target.value
      });
    }
  };

  const addNewRecord = (element) => {
    const resReceiver = receivers;
    if (element === 'receiver') {
      resReceiver.push(newReceiver.receiver);
      setReceiverTypes(resReceiver);
      setNewReceiver({
        ...newReceiver,
        receiver: ''
      });
    } else {
      const newTypes = {
        ...receiverTypes,
        types: [
          ...receiverTypes.types,
          { id: uuidv4(), name: newReceiver.type, messages: [] }
        ]
      };
      setReceiverTypes(newTypes);
      setNewReceiver({
        ...newReceiver,
        type: ''
      });
    }
  };

  const onFinish = async (record) => {
    const newRecord = handlerMessage(record);
    await handlerSave(newRecord);

    form.resetFields();
  };

  const renderFormList = () => {
    return (
      <Form
        name="messages"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={initialMessages}
        form={form}
        layout="horizontal"
        {...formItemLayout}
        preserve={false}
      >
        <Form.List name="messages">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => {
                return (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8, width: '100%' }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'text']}
                      fieldKey={[fieldKey, 'text']}
                      shouldUpdate
                    >
                      <Input style={{ width: '100%' }} id="text" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                );
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {t('pages.messageConfig.addMessage')}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={isLoading}>
                  {t('pages.messageConfig.save')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    );
  };
  return (
    <>
      <Divider orientation="left">
        {t('pages.messageConfig.table.receivers')} {' / '}
        {t('pages.messageConfig.table.types')}
      </Divider>
      <Space size="large">
        <Select
          style={{ width: 240 }}
          placeholder={t('pages.messageConfig.table.receivers')}
          onChange={(item) => handlerReceiver(item)}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input
                  name="receiver"
                  style={{ flex: 'auto' }}
                  onChange={(e) => onNewRecordChange(e)}
                  value={newReceiver.receiver}
                />
                <a
                  href="##"
                  style={{
                    flex: 'none',
                    padding: '8px',
                    display: 'block',
                    cursor: 'pointer'
                  }}
                  onClick={() => addNewRecord('receiver')}
                >
                  <PlusOutlined />
                  {t('pages.messageConfig.add')}
                </a>
              </div>
            </div>
          )}
        >
          {receivers.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
        <Select
          style={{ width: 240 }}
          onChange={(item) => handlerTypes(item)}
          placeholder={t('pages.messageConfig.table.types')}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input
                  name="type"
                  style={{ flex: 'auto' }}
                  onChange={(e) => onNewRecordChange(e)}
                  value={newReceiver.type}
                />
                <a
                  href="##"
                  style={{
                    flex: 'none',
                    padding: '8px',
                    display: 'block',
                    cursor: 'pointer'
                  }}
                  onClick={() => addNewRecord('type')}
                >
                  <PlusOutlined />
                  {t('pages.messageConfig.add')}
                </a>
              </div>
            </div>
          )}
        >
          {receiverTypes.types?.map((item) => (
            <Option key={item.name}>{item.name}</Option>
          ))}
        </Select>
      </Space>
      <Divider orientation="left">
        {t('pages.messageConfig.table.messages')}
      </Divider>
      {renderFormList()}
    </>
  );
};

MessageForm.propTypes = {
  handlerSave: PropTypes.func.isRequired,
  setReceiver: PropTypes.func.isRequired
};

export default MessageForm;
