/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { getBanksData } from 'stores/selectors/banks';
import { CSVLink } from 'react-csv';
import { formatPrice } from 'utils/utils';
import CardContainer from 'components/CardContainer';
import RangeDateFilter from 'components/RangeDateFilter';
import {
  parseDataFilter,
  renderBankSelect,
  renderOperationsFrom,
  renderOrdersSelect,
  renderStatus,
  selectDniBefore
} from '../utils';

const CommerceToPersonFilter = ({
  handleFilter,
  commerceToPersonData,
  handleRange,
  ordersData
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const banks = useSelector(getBanksData);
  const ordersOption = ordersData
    ?.filter((order) => order?.odooDataOrder?.name)
    .map((order) => ({
      id: order?.id,
      name: order?.odooDataOrder?.name || '-'
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const onFinish = (values) => {
    const dataFiltered = parseDataFilter(values);
    handleFilter(dataFiltered);
  };

  const parseReportData = () =>
    commerceToPersonData?.map((order) => ({
      order: order?.order || '-',
      name: order?.client?.name || '-',
      bank: banks.find((bank) => bank.code === order.banco)?.name,
      dni: order.cedula,
      phone: order.telefono,
      total: formatPrice(order.totalAmount),
      returnAmount: formatPrice(order.montoDevuelto),
      status: order.status || 'IN PROCESS',
      type: order.fromAdmin ? 'C2P Manual' : 'C2P Automatico',
      reason: order.motivo
    }));

  const headers = [
    { label: 'Numero de Order', key: 'order' },
    { label: 'Nombre del Cliente', key: 'name' },
    { label: 'Banco Destino', key: 'bank' },
    { label: 'Cedula', key: 'dni' },
    { label: 'Telefono', key: 'phone' },
    { label: 'Total Orden', key: 'total' },
    { label: 'Monto Transferido', key: 'returnAmount' },
    { label: 'Estado', key: 'status' },
    { label: 'Tipo', key: 'type' },
    { label: 'Motivo', key: 'reason' }
  ];

  return (
    <CardContainer>
      <Form form={form} name="filter" onFinish={onFinish} scrollToFirstError>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item name="bank" label={t('common.fields.bank')}>
              {renderBankSelect(banks)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="dni" label={t('common.fields.dni')}>
              <Input
                addonBefore={
                  <Form.Item name="documentType" noStyle initialValue="V">
                    {selectDniBefore}
                  </Form.Item>
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="order" label={t('common.fields.order')}>
              {renderOrdersSelect(ordersOption)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="status" label={t('common.fields.status')}>
              {renderStatus}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="fromAdmin"
              label={t('pages.commerceToPerson.fields.operationFrom')}
            >
              {renderOperationsFrom}
            </Form.Item>
          </Col>
          <Col span={8}>
            <RangeDateFilter hasDefault onChange={(arg) => handleRange(arg)} />
          </Col>
          <Col span={2}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button type="primary">
              <CSVLink
                filename="Reporte C2P.csv"
                data={parseReportData() || []}
                headers={headers}
              >
                Descargar Reporte
              </CSVLink>
            </Button>
          </Col>
        </Row>
      </Form>
    </CardContainer>
  );
};

CommerceToPersonFilter.propTypes = {
  commerceToPersonData: PropTypes.array,
  handleFilter: PropTypes.func.isRequired,
  handleRange: PropTypes.func.isRequired,
  ordersData: PropTypes.array
};

CommerceToPersonFilter.defaultProps = {
  commerceToPersonData: null,
  ordersData: null
};

export default CommerceToPersonFilter;
