import React, { Fragment, useEffect, useState } from 'react';
import {
  createAnnouncementsConfig,
  deleteAnnouncementConfig,
  getAnnouncementsConfig,
  updateAnnouncementsConfig
} from 'stores/actions/announcementsConfig';
import { useDispatch, useSelector } from 'react-redux';
import {
  announcementsConfigLoading,
  announcementsData
} from 'stores/selectors/announcementsConfig';
import { Button, Drawer, Image, Popconfirm, Space, Switch, Table } from 'antd';
import {
  CheckCircleTwoTone,
  DeleteOutlined,
  EditOutlined,
  MinusCircleTwoTone
} from '@ant-design/icons';
import useSetHeaderProps from 'hooks/useSetHeaderProps';
import AnnoucementConfigForm from 'components/Forms/AnnouncementConfigForm';
import { getBase64 } from 'utils/utils';
import Can from 'components/Can';
import { ANNOUNCEMENTS_CONFIG } from 'firebase/collections';
import { showNotification } from 'stores/actions/notification';
import { loadingWithSpinner, loaded } from 'stores/actions/loader';
import { uploadImage } from 'firebase/api';

const INITIAL_VALUES = {
  device: '',
  route: '',
  images: [],
  title: '',
  text: '',
  active: false
};

const AnnouncementConfig = () => {
  const dispatch = useDispatch();
  const setHeaderProps = useSetHeaderProps();

  const announcements = useSelector(announcementsData);
  const announcementsIsLoading = useSelector(announcementsConfigLoading);
  const [activeForm, setActiveForm] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState([]);
  const [currentAnnouncement, setCurrentAnnouncement] =
    useState(INITIAL_VALUES);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      title: 'Dispositivo',
      dataIndex: 'device',
      key: 'device'
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true
    },
    {
      title: 'Texto',
      dataIndex: 'text',
      key: 'text',
      ellipsis: true
    },
    {
      title: 'Ruta / Path',
      dataIndex: 'route',
      key: 'route'
    },
    {
      title: 'Activo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (flag) =>
        flag ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <MinusCircleTwoTone twoToneColor="#eb2f96" />
        )
    },
    {
      title: 'Accion',
      dataIndex: 'Action',
      align: 'center',
      render: (_, record) => (
        <>
          <Space>
            <Can I="delete" a={ANNOUNCEMENTS_CONFIG}>
              <Popconfirm
                title="Borrar"
                // onConfirm={() => handleDelete(record.key)}
              >
                <DeleteOutlined />
              </Popconfirm>
            </Can>
            {/* <Can I="update" a={ANNOUNCEMENTS_CONFIG}> */}
            <Switch
              defaultChecked={record.active}
              onChange={() => handlerActive(record)}
            />
            {/* </Can> */}
            <EditOutlined onClick={() => handleEditForm(record)} />
          </Space>
        </>
      )
    }
  ];

  const runAfterCrud = () => {
    setActiveForm(false);
    setCurrentAnnouncement(INITIAL_VALUES);
    dispatch(loaded());
    dispatch(
      showNotification({
        type: 'success',
        message: 'Operación Exitosa'
      })
    );
    setIsLoading(false);
  };
  const handleEditForm = (record) => {
    setActiveForm(true);
    setCurrentAnnouncement(record);
    setImagesLoaded(record.images);
  };

  const handlerActive = async (record) => {
    const { id, parent, ...recordToUpdate } = record;
    recordToUpdate.active = !record.active;
    try {
      await dispatch(
        updateAnnouncementsConfig({ id: record.id, body: recordToUpdate })
      );
      fetchData();
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };
  const expandedRowRender = (record) => {
    const columnsDetails = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Image',
        dataIndex: 'src',
        key: 'src',
        render: (value) =>
          value ? (
            <Image width={50} src={value} style={{ cursor: 'pointer' }} />
          ) : (
            '-'
          )
      },
      {
        title: 'Texto Alternativo',
        dataIndex: 'alt',
        key: 'alt'
      },
      {
        title: 'Link',
        dataIndex: 'link',
        key: 'link'
      }
    ];
    return <Table columns={columnsDetails} dataSource={record.images} />;
  };

  const handleSaveImages = (fileData) => {
    const { alt, link, src } = fileData;
    const findFileToUpdate = imagesLoaded.find((record) => record.src === src);
    const fileToUpdate = { ...findFileToUpdate, alt, link };
    const newRecords = imagesLoaded.map((item) => {
      if (item.src === fileToUpdate.src) return fileToUpdate;
      else return item;
    });

    setCurrentAnnouncement({
      ...currentAnnouncement,
      images: newRecords
    });
    setImagesLoaded(newRecords);
  };

  const handleUploadFile = async (pFile) => {
    const { status, originFileObj, name: imageOriginName } = pFile.file;

    if (status === 'done') {
      const fileBase64 = await getBase64(originFileObj);
      const imagePreload = [
        {
          alt: '-',
          link: '-',
          src: fileBase64,
          name: imageOriginName
        }
      ];

      setCurrentAnnouncement({
        ...currentAnnouncement,
        images: [...imagesLoaded, ...imagePreload]
      });
      setImagesLoaded([...imagesLoaded, ...imagePreload]);
    }
  };
  const uploadImageToStorage = async (pRecord) => {
    const images = [];
    if (pRecord) {
      for (let image in pRecord) {
        const imageToUpload = pRecord[image];
        if (imageToUpload.src.search('firebase') === -1) {
          const resUpload = await uploadImage(
            'announcementsConfig',
            imageToUpload.src,
            null,
            imageToUpload.name
          );
          imageToUpload.src = resUpload.url;
          images.push(imageToUpload);
          break;
        }
        images.push(imageToUpload);
      }
    }

    return images;
  };

  const onSubmit = async (data, id = null) => {
    dispatch(loadingWithSpinner());
    setIsLoading(true);
    const active = id
      ? { active: currentAnnouncement.active }
      : { active: false };
    const images = currentAnnouncement.images || [];
    const castImages =
      images?.length > 0 && (await uploadImageToStorage(images));
    const castData = {
      device: data.device,
      route: data.route || '',
      images: castImages || [],
      title: data.title || '',
      text: data.text || '',
      ...active
    };
    const action = async () =>
      id
        ? await dispatch(updateAnnouncementsConfig({ id, body: castData }))
        : await dispatch(createAnnouncementsConfig({ body: castData }));

    try {
      await action();
      await fetchData();

      runAfterCrud();
    } catch (error) {
      dispatch(loaded());
      setIsLoading(false);
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };
  const onDelete = async (id) => {
    dispatch(loadingWithSpinner());
    setIsLoading(true);
    try {
      await dispatch(deleteAnnouncementConfig(id));
      await fetchData();

      runAfterCrud();
    } catch (error) {
      dispatch(loaded());
      setIsLoading(false);
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };

  const handleRemoveImage = (image) => {
    const { src } = image;
    const newImages = currentAnnouncement.images.filter(
      (record) => record.src !== src
    );
    const newCurrentAnnouncement = {
      ...currentAnnouncement,
      images: newImages
    };

    setImagesLoaded(newImages);
    setCurrentAnnouncement(newCurrentAnnouncement);
  };

  const fetchData = async () => await dispatch(getAnnouncementsConfig());

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHeaderProps({
      extraButtons: [
        <Fragment key={0}>
          <Button
            name="create-modal"
            type="primary"
            onClick={() => {
              setActiveForm(true);
            }}
          >
            Crear
          </Button>
        </Fragment>
      ]
    });
  }, [setHeaderProps]);

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={announcements}
        expandable={{ expandedRowRender }}
        loading={announcementsIsLoading}
      />
      <Drawer
        visible={activeForm}
        onClose={() => {
          setActiveForm(false);
          setImagesLoaded([]);
          setCurrentAnnouncement(INITIAL_VALUES);
        }}
        placement="bottom"
      >
        <AnnoucementConfigForm
          handleRemoveImage={handleRemoveImage}
          isLoading={isLoading}
          data={currentAnnouncement}
          onSubmit={onSubmit}
          handleSaveImages={handleSaveImages}
          imagesLoaded={imagesLoaded}
          onDelete={onDelete}
          handleUploadFile={handleUploadFile}
        />
      </Drawer>
    </>
  );
};

export default AnnouncementConfig;
