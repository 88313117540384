import React, { useCallback, useEffect, useState } from 'react';
import useColumnSearch from 'hooks/useColumnSearch';
import { Button, Col, Popconfirm, Row, Space, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getWarehouseProcos } from 'stores/selectors/proco';
import Can from 'components/Can';
import ProcoStorageForm from './ProcoStorageForm';
import { closeModal, openModal } from 'stores/actions/modal';
import { getProcos as getProcosX } from 'stores/actions/procos';
import useFirebaseContext from 'hooks/useFirebaseContext';
import { showNotification } from 'stores/actions/notification';
import { STORAGE } from 'components/Can/modules';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import useDisclosure from 'hooks/useDisclousure';
import ProcoStorageDetails from './ProcoStorageDetails';
import { messageError } from './helpers';
import { createProco } from 'firebase/api';

const StorageProco = () => {
  const firebase = useFirebaseContext();
  const searchFilter = useColumnSearch();
  const data = useSelector(getWarehouseProcos);
  const dispatch = useDispatch();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const [procoStorageId, setProcoStorageId] = useState(null);

  const handleOpenDetails = (id) => {
    setProcoStorageId(id);
    onToggle();
  };

  const getProcos = async () => await dispatch(getProcosX());

  useEffect(() => {
    getProcos();
    // eslint-disable-next-line
  }, []);
  const getColumns = () => [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ...searchFilter('name'),
      render: (name, record) => (
        <>
          <a onClick={() => handleOpenDetails(record.id)} href="#/">
            {name}
          </a>
        </>
      )
    },
    {
      title: '# de Almacenistas',
      dataIndex: 'warehousemanCount',
      key: 'warehousemanCount'
    },
    {
      title: 'Acciones',
      key: 'action',
      render: (proco) => (
        <>
          <Space size="middle" key={proco.id}>
            <Can I="update-proco" a={STORAGE}>
              <EditOutlined onClick={() => openProcoFormModal(proco)} />
            </Can>
            <Can I="delete-proco" a={STORAGE}>
              {proco?.users?.length > 0 && (
                <Popconfirm
                  onConfirm={() => onDeleteProco(proco.id)}
                  title="Borrar"
                  okText="Borrar"
                  cancelText="No"
                >
                  <DeleteOutlined />
                </Popconfirm>
              )}
            </Can>
          </Space>
        </>
      )
    }
  ];

  const onDeleteProco = async (id) => {
    try {
      await firebase.deleteProco(id);
      getProcos();
      dispatch(
        showNotification({
          type: 'success',
          content: 'Proco eliminado'
        })
      );
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };

  const validatingStorageProco = useCallback(
    (newStorageProco) => {
      const newAssistants = newStorageProco?.users || [];
      const existingAssistants = data
        .filter((record) => record.id !== newStorageProco?.id)
        .reduce((prev, next) => {
          prev = [...prev, ...next.users];
          return prev;
        }, []);
      const sameAssistants = newAssistants.some((item) =>
        existingAssistants.includes(item)
      );
      const existingProcosName = data
        .filter((record) => record.id !== newStorageProco?.id)
        .reduce((prev, next) => {
          prev = [...prev, ...next.name.split(',')];
          return prev;
        }, []);
      const sameProcosName = existingProcosName.includes(newStorageProco?.name);

      // Check same assistans in the others proco
      if (sameAssistants) {
        throw messageError['sameAssistants'];
      }
      // Check at least one assistans
      if (newAssistants.length === 0) {
        throw messageError['withoutAssistan'];
      }
      // Check same name proco
      if (sameProcosName) throw messageError['sameProcosName'];
      // Check required name proco
      if (!newStorageProco?.name) throw messageError['withoutName'];

      return;
    },
    [data]
  );
  const onCreateStorageProco = useCallback(
    async (values = {}) => {
      const isEditMode = values.hasOwnProperty('id');
      const { id, ...body } = values;
      const action = async () =>
        isEditMode
          ? await firebase.updateProco(values.id, body)
          : await createProco(body);
      try {
        ///Checking before Create / Update
        validatingStorageProco(values);
        await action();
        getProcos();
        dispatch(closeModal());
        dispatch(
          showNotification({
            type: 'success',
            content: isEditMode ? 'Proco actualizado' : 'Proco creado'
          })
        );
      } catch (error) {
        if (error?.type) {
          dispatch(
            showNotification({
              type: 'warning',
              content: error.message
            })
          );
        }
        dispatch(
          showNotification({
            type: 'error',
            content: 'Hubo un error al crear el proco'
          })
        );
      }
    },
    // eslint-disable-next-line
    [dispatch, firebase, validatingStorageProco]
  );

  const openProcoFormModal = useCallback(
    (warehouseman) => {
      const modalContent = {
        component: ProcoStorageForm,
        props: {
          warehouseman,
          onCancel: () => dispatch(closeModal()),
          onSubmit: (values) => onCreateStorageProco(values)
        }
      };

      dispatch(
        openModal({
          content: modalContent,
          buttons: []
        })
      );
    },
    [dispatch, onCreateStorageProco]
  );

  useEffect(() => {
    //Clean when onClose
    if (!isOpen) {
      setProcoStorageId(null);
    }
  }, [isOpen]);

  return (
    <>
      <Row>
        <Col span={24} offset={22}>
          <Can I="create-proco" a={STORAGE}>
            <Button
              style={{ marginBottom: 16 }}
              type="primary"
              onClick={() => openProcoFormModal()}
            >
              Crear
            </Button>
          </Can>
        </Col>
      </Row>
      <Table columns={getColumns()} dataSource={data} rowKey="id" />
      <ProcoStorageDetails
        onClose={onClose}
        isOpen={isOpen}
        procoStorageId={procoStorageId}
      />
    </>
  );
};

export default StorageProco;
