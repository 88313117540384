import React, { useState } from 'react';
import {
  Badge,
  Button,
  Divider,
  Popconfirm,
  Space,
  Table,
  Tooltip
} from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import useColumnSearch from 'hooks/useColumnSearch';
import { sorterData, sorterDate } from 'utils/table';
import {
  BarcodeOutlined,
  CheckSquareOutlined,
  PrinterOutlined,
  RestOutlined
} from '@ant-design/icons';
import { formatPriceToBsF } from 'utils/utils';
import { ORDERS } from 'components/Can/modules';
import Can from 'components/Can';
import CancelOrder from 'pages/Orders/CancelOrder';
import { closeModal, openModal } from 'stores/actions/modal';
import PaymentTypeDetail from 'pages/Orders/PaymentTypeDetail/PaymentTypeDetail';
import usePrintOrderPdf from 'hooks/usePrintOrderPdf';
import DiscountDetails from 'pages/Orders/Orders/DiscountDetails';
import PaymentType from 'components/PaymentType/PaymentType';
import { Timestamp } from 'firebase/firestore';
import { AnonymousUser } from 'components/AnonymousUser';
import RangeDateFilter from 'components/RangeDateFilter';
import useRangeDateFilter from 'hooks/useRangeDateFilter';
import {
  useOrdersByRangeQuery,
  useUpdateOrderMutation
} from 'stores/query/ordersQuery';
import {
  ordersCheckedPickupFactory,
  ordersOrderedPickupFactory
} from '../helpers';
import { getOrderByIds } from 'firebase/api';

const PrintValidation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { printPdf } = usePrintOrderPdf();
  const { lastSevenDaysDefault, handleRange } = useRangeDateFilter();
  const {
    data: ordersData,
    refetch,
    isLoading
  } = useOrdersByRangeQuery(lastSevenDaysDefault, {
    refetchOnFocus: true,
    pollingInterval: 120000
  });
  const [updateOrderFb] = useUpdateOrderMutation();
  const [showDiscountDetails, setShowDiscountDetails] = useState(false);
  const [discountDetail, setDiscountDetail] = useState(null);
  const searchFilter = useColumnSearch();
  const statusOrderToCancel = [0, 1, 4];

  const [ordersSelected, setOrdersSelected] = useState([]);
  const [selectedValidatedOrders, SetselectedValidatedOrders] = useState([]);
  const ordersByStatusOrdered = ordersOrderedPickupFactory(ordersData);
  const ordersCheckedByStatusOrdered = ordersCheckedPickupFactory(ordersData);

  const statuses = {
    sale: {
      text: t('pages.orders.statuses.sale'),
      type: 'success'
    },
    cancel: {
      text: t('pages.orders.statuses.cancel'),
      type: 'error'
    },
    send: {
      text: t('pages.orders.statuses.send'),
      type: 'processing'
    },
    default: {
      text: 'default',
      type: 'default'
    }
  };

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setOrdersSelected(selectedRows, ...ordersSelected);
    }
  };

  const handleValidatedOrdersSelection = (_, selectedRow) => {
    SetselectedValidatedOrders(selectedRow, ...selectedValidatedOrders);
  };

  const onConfirm = async ({ id }) => {
    const order = ordersData.find((record) => record.id === id);
    const orderInfo = {
      id,
      body: {
        statusCode: 4,
        steps: [
          ...order.steps,
          {
            date: Timestamp.now(),
            id: uuidv4(),
            isDone: false,
            label: 'ordered.label',
            message: 'ordered.message'
          }
        ]
      }
    };
    await updateOrderFb(orderInfo);
  };

  const onPrint = async (orders) => {
    const ids = orders.map((ord) => ord.id);
    const orderIds = await getOrderByIds(ids);
    printPdf(orderIds);
  };

  const handleCancelarOrden = (order) => {
    const modalContent = {
      component: CancelOrder,
      props: {
        order,
        cancel: () => dispatch(closeModal())
      }
    };

    dispatch(
      openModal({
        content: modalContent,
        buttons: [],
        title: `Cancelar orden - Nº ${order?.odooDataOrder.name} `
      })
    );
  };

  const handlePaymentDetail = (order) => {
    const modalContent = {
      component: PaymentTypeDetail,
      props: {
        order,
        cancel: () => dispatch(closeModal())
      }
    };

    dispatch(
      openModal({
        content: modalContent,
        buttons: [],
        title: t('pages.orders.table.paymentTypeDetail')
      })
    );
  };

  const handleDiscountDetails = (record) => {
    setDiscountDetail(record);
    setShowDiscountDetails(!showDiscountDetails);
  };

  const getColumns = () => [
    {
      title: t('pages.orders.table.num'),
      dataIndex: 'name',
      key: 'name',
      sorter: sorterData('name'),
      ...searchFilter('name')
    },
    {
      title: t('pages.orders.table.client'),
      dataIndex: 'partner',
      key: 'partner',
      sorter: sorterData('partner'),
      ...searchFilter('partner'),
      render: (_, record) => (
        <AnonymousUser
          user={record}
          customizedInvoice={record.customizedInvoice}
        />
      )
    },
    {
      title: t('pages.orders.table.date'),
      dataIndex: 'date_order',
      key: 'date_order',
      sorter: sorterDate('date_order'),
      ...searchFilter('date_order')
    },
    {
      title: t('pages.orders.table.paymentType'),
      dataIndex: 'paymentType',
      key: 'paymentType',
      sorter: sorterData('paymentType'),
      ...searchFilter('paymentType'),
      render: (_, record) => (
        <PaymentType data={record} handlePaymentCash={handlePaymentDetail} />
      )
    },
    {
      title: t('pages.orders.table.total'),
      dataIndex: 'amount_total',
      key: 'amount_total',
      sorter: sorterData('amount_total'),
      ...searchFilter('amount_total'),
      render: (value) => formatPriceToBsF(value)
    },
    {
      title: t('pages.orders.table.discount'),
      dataIndex: 'discount',
      key: 'discount',
      align: 'center',
      render: (record, order) =>
        record?.code ? (
          <BarcodeOutlined onClick={() => handleDiscountDetails(order)} />
        ) : (
          'Sin Descuento'
        )
    },
    {
      title: t('pages.orders.table.state'),
      dataIndex: 'state',
      key: 'state',
      sorter: sorterData('state'),
      ...searchFilter('state'),
      render: (text) => (
        <Space>
          <Badge status={statuses[text]?.type || statuses.default.type} />
          {statuses[text]?.text || statuses.default.text}
        </Space>
      )
    }
  ];

  const getColumnsOrdered = () => [
    ...getColumns(),
    {
      title: 'Accion',
      dataIndex: 'id',
      key: 'id',
      render: (_, order) => (
        <Space>
          <Can I="print" a={ORDERS}>
            <Tooltip placement="top" title="Imprimir Order de Entrega">
              <PrinterOutlined
                onClick={() => onPrint([order])}
                style={{ fontSize: '1.2rem' }}
              />
            </Tooltip>
          </Can>
          {(order.statusCode === 1 || order.statusCode === 0) && (
            <Can I="update" a={ORDERS}>
              <Popconfirm
                title="¿Está seguro de querer validar esta orden?"
                onConfirm={() => onConfirm(order)}
                okText="Confirmar"
                cancelText="No"
              >
                <Tooltip placement="top" title="Validar Orden">
                  <CheckSquareOutlined style={{ fontSize: '1.2rem' }} />
                </Tooltip>
              </Popconfirm>
            </Can>
          )}
          {statusOrderToCancel.includes(order.statusCode) && (
            <Can I="cancel" a={ORDERS}>
              <Tooltip placement="top" title="Cancelar Orden">
                <RestOutlined
                  onClick={() => handleCancelarOrden(order)}
                  style={{ fontSize: '1.2rem' }}
                />
              </Tooltip>
            </Can>
          )}
        </Space>
      )
    }
  ];

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: t('pages.orders.table.name'),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: t('pages.orders.table.quantity'),
        dataIndex: 'quantity',
        key: 'quantity'
      },
      {
        title: t('pages.orders.table.priceUnit'),
        dataIndex: 'price_unit',
        key: 'price_unit',
        render: (value) => formatPriceToBsF(value)
      },
      {
        title: t('pages.orders.table.priceSubtotal'),
        dataIndex: 'price_total',
        key: 'price_total',
        render: (value) => formatPriceToBsF(value)
      }
    ];

    return (
      <Table
        columns={columns}
        dataSource={record.products}
        rowKey="id"
        pagination={false}
      />
    );
  };

  return (
    <>
      <Divider orientation="left" />
      <RangeDateFilter
        hasDefault
        onChange={(arg) => handleRange(arg)}
        onRefresh={() => refetch()}
      />
      <Divider orientation="left">Por Validar</Divider>
      <Button
        type="primary"
        onClick={() => onPrint(ordersSelected)}
        disabled={ordersSelected.length > 0 ? false : true}
        style={{ marginBottom: 5 }}
      >
        Imprimir Ordenes por Validar seleccionadas
      </Button>
      <Table
        columns={getColumnsOrdered()}
        expandedRowRender={expandedRowRender}
        dataSource={ordersByStatusOrdered}
        loading={isLoading}
        rowKey="id"
        rowSelection={{
          type: ordersSelected,
          ...rowSelection
        }}
      />
      <Divider orientation="left">Validados</Divider>
      <Button
        type="primary"
        onClick={() => onPrint(selectedValidatedOrders)}
        disabled={!selectedValidatedOrders.length}
        style={{ marginBottom: 5 }}
      >
        Imprimir Ordenes Validadas seleccionadas
      </Button>
      <Table
        columns={getColumnsOrdered()}
        expandedRowRender={expandedRowRender}
        dataSource={ordersCheckedByStatusOrdered}
        loading={isLoading}
        rowKey="id"
        rowSelection={{
          type: selectedValidatedOrders,
          onChange: handleValidatedOrdersSelection
        }}
      />
      {showDiscountDetails && (
        <DiscountDetails
          visible={showDiscountDetails}
          onClose={handleDiscountDetails}
          data={discountDetail}
        />
      )}
    </>
  );
};

export default PrintValidation;
