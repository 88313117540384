import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getBankByCode } from 'stores/selectors/banks';

const Bank = ({ code }) => {
  const bank = useSelector(getBankByCode(code));
  return <>{bank?.name}</>;
};

Bank.propTypes = {
  code: PropTypes.string
};

export default Bank;
