import React from 'react';
import { Button, Select, Row } from 'antd';
import { closeModal } from 'stores/actions/modal';
import { useDispatch } from 'react-redux';
import { reassignOrderWithoutRoadmap } from 'stores/actions/roadmaps';
import { showNotification } from 'stores/actions/notification';
import RenderAvailableRoadmaps from './RenderAvailableRoadmaps';

const ReassignOrderToRoadmap = ({
  mergeableRoadmaps = [],
  orderToUpdate,
  onRefreshOrders
}) => {
  const dispatch = useDispatch();
  const mergeTypes = ['Reasignar Automaticamente', 'Reasignar Manualmente'];
  const [selectedMergeType, setSelectedMergeType] = React.useState('');
  const [selectedMergeableRoadmap, setSelectedMergeableRoadmap] =
    React.useState();

  const reassign = async () => {
    if (selectedMergeType === mergeTypes[0]) {
      dispatch(
        reassignOrderWithoutRoadmap({ mergeAutomatically: true, orderToUpdate })
      );
      await onRefreshOrders()
    } else {
      dispatch(
        reassignOrderWithoutRoadmap({
          mergeAutomatically: false,
          orderToUpdate,
          roadmapToUpdate: selectedMergeableRoadmap
        })
      );
      await onRefreshOrders()
    }
    dispatch(
      showNotification({
        type: 'success',
        message: 'Info',
        content: 'Orden reasignada exitosamente!'
      })
    );
    await onRefreshOrders();
    dispatch(closeModal());
  };

  return (
    <>
      <Select
        size="middle"
        style={{ marginBottom: '15px' }}
        dropdownMatchSelectWidth
        defaultValue="Seleccione forma de reasignar"
        onSelect={(e) => setSelectedMergeType(e)}
      >
        {mergeTypes.map((type) => (
          <Select.Option value={type}>{type}</Select.Option>
        ))}
      </Select>
      {RenderAvailableRoadmaps({
        selectedMergeType,
        mergeTypes,
        mergeableRoadmaps,
        selectedMergeableRoadmap,
        setSelectedMergeableRoadmap
      })}
      <Row justify="end" gutter={[8, 2]} style={{ marginTop: '15px' }}>
        <Button
          type="primary"
          style={{ marginRight: '15px' }}
          onClick={() => reassign()}
          disabled={
            selectedMergeType !== mergeTypes[0] &&
            selectedMergeableRoadmap === undefined
          }
        >
          Reasignar
        </Button>
        <Button onClick={() => dispatch(closeModal())}>Cancelar</Button>
      </Row>
    </>
  );
};

export default ReassignOrderToRoadmap;
