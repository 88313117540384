import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AnonymousUser = ({ user, customizedInvoice }) => {
  const { clientName } = user;
  const userExists = Boolean(user?.customizedInvoice?.email);
  return userExists ? (
    <span>{clientName}</span>
  ) : (
    <Tooltip title="Anónimo" color="gold" key="gold">
      <span>
        <>{customizedInvoice?.name}</>
      </span>
    </Tooltip>
  );
};

AnonymousUser.propTypes = {
  user: PropTypes.object.isRequired,
  customizedInvoice: PropTypes.object.isRequired
};

export default AnonymousUser;
