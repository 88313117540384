import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const messagesConfig = createSelector(
  selectSelf,
  (state) => state.messagesConfig
);

export const messagesConfigData = createSelector(
  messagesConfig,
  (record) => record.data
);

export const messagesConfigLoading = createSelector(
  messagesConfig,
  (record) => record.loading
);

export const hasMessagesConfigError = createSelector(
  messagesConfig,
  (record) => record.error
);

export const getMessageReceivers = createSelector(messagesConfigData, (data) =>
  data.map(({ id, name }) => ({ id, name }))
);

export const getSelectedReceiver = (id) =>
  createSelector(
    messagesConfigData,
    (receivers) => receivers.find((receiver) => receiver.id === id) || {}
  );
