import React, { useEffect, useCallback } from 'react';
import { Button, Form, Input, Select, Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { searchProducts } from 'stores/actions/products';
import { debounce } from 'lodash';
import readXlsxFile from 'read-excel-file';
import styles from './SpecialCategoryForm.module.scss';
import { showNotification } from 'stores/actions/notification';

const SpecialCategoriesForm = ({ onSubmit, formActive, currentData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const editMode = Object.values(currentData).length > 0;
  const productsSelected = form.getFieldValue('products');
  const initialValues = editMode
    ? {
        name: currentData.name,
        products: currentData.products.map((item) => ({
          label: item.name,
          value: item.id
        }))
      }
    : {
        name: '',
        products: []
      };
  const fetchProductList = useCallback(
    async (name) => {
      const reqBody = {
        query: name
      };
      const { payload: data } = await dispatch(searchProducts(reqBody));
      return data;
    },
    [dispatch]
  );

  const onFinish = (data) => {
    let dataParsed = {};
    if (editMode) {
      dataParsed = {
        ...currentData,
        name: data.name,
        products: data.products.map((record) => ({
          id: record.value,
          name: record.label
        }))
      };
    } else {
      dataParsed = {
        ...data,
        active: true,
        products: data.products.map((record) => ({
          id: record.value,
          name: record.label
        }))
      };
    }
    onSubmit(dataParsed);
  };
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value) => {
      setFetching(true);
      const { data } = (await fetchProductList(value)) || [];

      const newOptions = data.map((product) => ({
        label: product.name,
        value: product.id
      }));
      setOptions(newOptions);
      setFetching(false);
    };

    return debounce(loadOptions, 1000);
  }, [fetchProductList]);

  const productFileSchema = {
    'Producto/ID': {
      prop: 'value',
      type: Number,
      required: true
    },
    Nombre: {
      prop: 'label',
      type: String,
      required: true
    }
  };

  const readExcelFile = (file) => {
    try {
      readXlsxFile(file, { schema: productFileSchema }).then(
        ({ rows, errors }) => {
          if (errors.length > 0) {
            dispatch(
              showNotification({
                type: 'error',
                message: 'Ha ocurrido un error!'
              })
            );
          } else {
            form.setFieldsValue({
              products: rows
            });
          }
        }
      );
    } catch (err) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error al leer el archivo',
          content: err.message
        })
      );
    }
  };

  const handleUpload = (file) => {
    if (!file) return;
    readExcelFile(file);
  };

  useEffect(() => {
    if (!formActive) {
      form.resetFields();
    } else {
      const currentValues = editMode
        ? initialValues
        : {
            name: form.getFieldValue('name'),
            products: form.getFieldValue('products')
          };

      form.setFieldsValue(currentValues);
    }
  }, [formActive, form, editMode, initialValues]);

  return (
    <Form
      layout="vertical"
      form={form}
      name="specialCaterogies"
      onFinish={onFinish}
      scrollToFirstError
      initialValues={initialValues}
    >
      <Form.Item
        name="name"
        label="Nombre"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese un nombre'
          }
        ]}
      >
        <Input style={{ width: 600 }} />
      </Form.Item>
      <Form.Item name="products" label="Productos">
        <Select
          className={styles.input}
          defaultValue={['']}
          mode="multiple"
          labelInValue
          filterOption={false}
          onSearch={debounceFetcher}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          options={options}
          value={productsSelected}
          placement="topLeft"
          style={{ width: 600 }}
        />
      </Form.Item>
      <Form.Item>
        <Upload
          multiple={false}
          maxCount={1}
          beforeUpload={(e) => {
            handleUpload(e);
            return false;
          }}
          accept=".xlsx"
        >
          <Button
            icon={<UploadOutlined />}
            type="dashed"
            style={{ width: 600, height: 100 }}
          >
            Cargue o arrastre un archivo excel
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        {editMode ? (
          <Button type="primary" htmlType="submit" style={{ width: 600 }}>
            Actualizar
          </Button>
        ) : (
          <Button type="primary" htmlType="submit" style={{ width: 600 }}>
            Crear
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default SpecialCategoriesForm;
