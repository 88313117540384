import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from '../../firebase';
import API from 'services';
import { getPaymentMethodsFb } from 'firebase/api';

export const initialState = {
  data: {
    PagoMovil: {
      commerceToPerson: []
    },
    admin: {
      banks: []
    },
    paymentMethods: []
  },
  loading: false,
  error: false
};

export const getPaymentMethodsConfig = createAsyncThunk(
  '@config/getPaymentMethodsConfig',
  async () => {
    const methods = await getPaymentMethodsFb();
    return methods;
  }
);

export const getCommerceToPerson = createAsyncThunk(
  '@config/getCommerceToPerson',
  async () => firebase.getCommerceToPerson()
);

export const getBanks = createAsyncThunk('@config/getBanks', async () => {
  const { data } = await API.banks.getBanks();
  return data?.result?.bancos || [];
});

export const configSlide = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfigLoading: (state) => {
      state.loading = true;
    },
    setConfig: (state, action) => {
      state.data = { ...state.data, ...action.payload };
      state.loading = false;
    },
    setConfigError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getPaymentMethodsConfig.pending]: (state) => {
      state.loading = true;
    },
    [getPaymentMethodsConfig.fulfilled]: (state, action) => {
      state.data.paymentMethods = action.payload;
      state.loading = false;
    },
    [getPaymentMethodsConfig.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getCommerceToPerson.pending]: (state) => {
      state.loading = true;
    },
    [getCommerceToPerson.fulfilled]: (state, action) => {
      state.data.PagoMovil.commerceToPerson = action.payload;
      state.loading = false;
    },
    [getCommerceToPerson.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getBanks.pending]: (state) => {
      state.loading = true;
    },
    [getBanks.fulfilled]: (state, action) => {
      state.data.admin.banks = action.payload;
      state.loading = false;
    },
    [getBanks.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setConfigLoading, setConfigError, setConfig } =
  configSlide.actions;

export default configSlide.reducer;
