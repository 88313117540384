import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  SyncOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import RoadmapAssignManually from './RoadmapAssignManually';
import { closeModal, openModal } from 'stores/actions/modal';
import {
  updateRider,
  updateRoadmap,
  cancelMultipleOrders,
  getOrderByIds,
  updateOrder,
  updateOrdersCrossdocking
} from 'firebase/api';
import { showNotification } from 'stores/actions/notification';
import RoadmapsAssign from './RoadmapsAssign';
import { ordersApi } from 'stores/query/ordersQuery';
import CancelOrder from 'pages/Orders/CancelOrder';
import usePrintOrderPdf from 'hooks/usePrintOrderPdf';
import CancelOrderWaiting from './CancelOrder/CancelOrderWaiting';
import { getProfileId } from 'stores/selectors/profile/selector';
import PreviewQROrders from './PreviewQROrders';

const Utils = () => {
  const dispatch = useDispatch();
  const statusCodeToCancel = [0, 1, 2];
  const { printPdf, getPaymentMethod } = usePrintOrderPdf();
  const userId = useSelector(getProfileId);

  const statuses = {
    inprogress: {
      text: 'inProgress',
      color: 'processing',
      icon: <SyncOutlined spin />
    },
    completed: {
      text: 'roadmapClosed',
      color: 'success',
      icon: <CheckCircleOutlined />
    },
    waiting: {
      text: 'waiting',
      color: 'default',
      icon: <ClockCircleOutlined />
    }
  };

  const printInvoice = async ({ roadmapNumber, route, ordersIds }) => {
    const orders = await getOrderByIds(ordersIds);
    await getPaymentMethod();
    printPdf(orders, roadmapNumber, route);
  };

  const assignRider = async (roadmap, newRoadmap, newRider) => {
    try {
      // Iterate over the orders to update them to status 1
      for (const orderId of roadmap?.ordersIds) {
        const newOrder = { statusCode: 1 };
        await updateOrder(orderId, newOrder);
      }

      // Assign the Roadmap and the rider
      await updateRoadmap(roadmap.id, newRoadmap);
      await updateRider(newRoadmap.riderId, newRider);

      dispatch(
        showNotification({
          type: 'success',
          content: 'Rider asignado satisfactoriamente'
        })
      );
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
    dispatch(closeModal());
  };

  const assignRiderManually = async (
    roadmap,
    newRoadmap,
    newRider,
    oldRider,
    orders
  ) => {
    try {
      await updateRoadmap(roadmap.id, newRoadmap);
      await updateRider(roadmap.riderId, oldRider);
      await updateRider(newRoadmap.riderId, newRider);
      await updateOrdersCrossdocking(orders);
      dispatch(
        showNotification({
          type: 'success',
          content: 'Rider asignado satisfactoriamente'
        })
      );
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
    dispatch(closeModal());
  };

  const openAssignModal = (roadmap) => {
    const modalContent = {
      component: RoadmapsAssign,
      props: {
        roadmap,
        assignRider,
        cancel: () => dispatch(closeModal())
      }
    };

    dispatch(
      openModal({
        content: modalContent,
        buttons: [],
        title: 'Asignacion de Rider'
      })
    );
  };

  const assignManuallyNewRider = (roadmap) => {
    const modalContent = {
      component: RoadmapAssignManually,
      props: {
        roadmap,
        assignRiderManually,
        cancel: () => dispatch(closeModal())
      }
    };

    dispatch(
      openModal({
        content: modalContent,
        buttons: [],
        title:
          'Chequeo de Ordenes y Asignacion de Rider Manualmente (Crossdocking)'
      })
    );
  };

  const cancelOrder = async (roadmap) => {
    let orders = await getOrderByIds(roadmap?.ordersIds);
    orders = orders.find((order) =>
      statusCodeToCancel.includes(order.statusCode)
    );
    if (!orders) {
      dispatch(
        showNotification({
          type: 'error',
          content: 'No hay ordenes en proceso (CODE: 2)'
        })
      );
    } else {
      const modalContent = {
        component: CancelOrder,
        props: {
          roadmap,
          cancel: () => dispatch(closeModal()),
          statusCode: statusCodeToCancel
        }
      };
      dispatch(
        openModal({
          content: modalContent,
          buttons: [],
          title: 'Cancelación de orden'
        })
      );
    }
  };

  const cancelWaitingOrder = (roadmap) => {
    const modalContent = {
      component: CancelOrderWaiting,
      props: {
        roadmap,
        cancel: () => dispatch(closeModal())
      }
    };

    dispatch(
      openModal({
        content: modalContent,
        buttons: [],
        title: 'Cancelación de orden'
      })
    );
  };

  const onCloseRoadmap = async (roadmap, feedback = '') => {
    const newRoadmap = {
      log: [
        ...roadmap.log,
        {
          id: uuidv4(),
          type: 'close',
          description: 'roadmapCompletedManually',
          target: '',
          date: moment().unix()
        }
      ],
      status: 'completed',
      feedback: {
        date: moment().unix(),
        text: feedback,
        userId
      }
    };

    const newRider = {
      status: 'free'
    };

    try {
      const { data: ordersData } = await dispatch(
        ordersApi.endpoints.orderByIds.initiate(roadmap.ordersIds)
      );
      await updateRoadmap(roadmap.id, newRoadmap);
      await updateRider(roadmap.riderId, newRider);
      // updated bellow function to complete rather than cancel. statusCode 3 instead of 7.
      await cancelMultipleOrders(ordersData);
      dispatch(
        showNotification({
          type: 'success',
          content: 'Roadmap completado manualmente satisfactoriamente'
        })
      );
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
    dispatch(closeModal());
  };

  const showQRCode = (roadmap) => {
    const modalContent = {
      component: PreviewQROrders,
      props: {
        orders: roadmap.ordersIds || [],
        cancel: () => dispatch(closeModal())
      }
    };

    dispatch(
      openModal({
        content: modalContent,
        buttons: []
      })
    );
  };

  return {
    statuses,
    printInvoice,
    assignRider,
    assignRiderManually,
    openAssignModal,
    cancelOrder,
    cancelWaitingOrder,
    onCloseRoadmap,
    showQRCode,
    assignManuallyNewRider
  };
};

export default Utils;
