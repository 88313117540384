import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const getCommissionsByRider = createSelector(
  selectSelf,
  (state) => state.commissionsByRider
);

export const getCommissionsByRiderData = createSelector(
  getCommissionsByRider,
  (commissionsByRider) =>
    commissionsByRider.data.map((commission, idx) => {
      return {
        ...commission,
        key: idx
      };
    }) || []
);

export const isCommissionsByRiderLoading = createSelector(
  getCommissionsByRider,
  (commissionsByRider) => commissionsByRider.loading
);

export const hasCommissionsByRiderError = createSelector(
  getCommissionsByRider,
  (commissionsByRider) => commissionsByRider.error
);
