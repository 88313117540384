import React, { useState } from 'react';
import { Select, Form, Input, Button, Radio } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { numericMask } from 'utils/utils';
import { getBanks } from 'stores/actions/config';
import styles from '../../CreateOrder.module.scss';

const { Option } = Select;
const { Item } = Form;
const numberPrefix = ['0414', '0424', '0416', '0426', '0412'];
const dniTypes = ['V', 'E', 'J', 'G', 'P'];

function PagoMovil({ onSave, totalInCart }) {
  const dispatch = useDispatch();
  const [Mode, setMode] = useState('');
  const [form] = Form.useForm();
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);

  React.useEffect(() => {
    dispatch(getBanks()).then((result) => setBanks(result.payload));
    // eslint-disable-next-line
  }, []);

  const renderBanks = () =>
    banks.map((bank) => (
      <Option key={bank.codigo} value={bank.codigo}>
        {bank.nombre}
      </Option>
    ));

  const receivingBank = (e) => {
    setMode(e.target.value);
  };

  const onFinish = (v) => {
    onSave({
      method: 'pagomovil',
      details: {
        ...v,
        phone: v.prefix + v.phone,
        amount: totalInCart.data.data.total,
        destination: Mode === '1' ? 'plaza' : 'amiga',
        bank: selectedBank
      },
      type: 'pagomovil',
      cashback: {
        banco: selectedBank,
        cedula: `${v.dniType}${v.dni}`,
        telefono: `${v.prefix}${v.phone}`
      }
    });
  };

  return (
    <>
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-around',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <p style={{ marginBottom: '0' }}>Seleccione un banco *</p>
        <Radio.Group
          value={Mode}
          buttonStyle="outline"
          onChange={receivingBank}
        >
          <div>
            <Radio.Button style={{ marginRight: '10px' }} value="1">
              Bancoplaza
            </Radio.Button>
            <Radio.Button value="2">Bancamiga</Radio.Button>
          </div>
        </Radio.Group>
      </div>
      <Form
        form={form}
        name="cash-payment"
        onFinish={onFinish}
        scrollToFirstError
        style={{ maxWidth: '600px', margin: 'auto' }}
        initialValues={{
          bank: banks[0] && banks[0].bank,
          dni: '',
          phone: '',
          prefix: numberPrefix[0],
          dniType: dniTypes[0]
        }}
      >
        <Item
          name="bank"
          className={styles.fullInput}
          rules={[
            {
              required: true,
              message: 'Seleccione el banco destino'
            }
          ]}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder="Banco"
            disabled={Mode === ''}
            className={styles.input}
            onChange={(value) => {
              setSelectedBank(value);
            }}
          >
            {renderBanks()}
          </Select>
        </Item>
        <div className={styles.doubleInputContainer}>
          <Item
            name="prefix"
            className={styles.prefix}
            rules={[
              {
                required: true,
                message: 'Campo obligatorio'
              }
            ]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className={styles.input}
            >
              {numberPrefix.map((prefix) => (
                <Option key={prefix} value={prefix}>
                  {prefix}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name="phone"
            className={styles.halfInput}
            rules={[
              {
                required: true,
                message: '7 caracteres son obligatorios'
              }
            ]}
          >
            <Input
              onKeyPress={numericMask}
              name="phone"
              maxLength={7}
              minLength={7}
              placeholder="Telefono"
            />
          </Item>
        </div>
        <div className={styles.doubleInputContainer}>
          <Item
            name="dniType"
            className={styles.prefix}
            rules={[
              {
                required: true,
                message: 'Campo obligatorio'
              }
            ]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className={styles.input}
              placeholder="V"
            >
              {dniTypes.map((prefix) => (
                <Option key={prefix} value={prefix}>
                  {prefix}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name="dni"
            className={styles.halfInput}
            rules={[
              {
                required: true,
                message: 'Minimo 6 caracteres'
              }
            ]}
          >
            <Input
              maxLength={10}
              onKeyPress={numericMask}
              name="dni"
              placeholder="Numero de cedula"
            />
          </Item>
        </div>
        <div>
          <Button htmlType="submit" disabled={Mode === ''}>
            <p>Guardar</p>
          </Button>
        </div>
      </Form>
    </>
  );
}

PagoMovil.propTypes = {
  userId: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  totalInCart: PropTypes.object.isRequired
};

PagoMovil.defaultProps = {
  userId: ''
};

export default PagoMovil;
