import { createSelector } from '@reduxjs/toolkit';
import { getSpecialCategoriesData } from './specialCategories';

const selectSelf = (state) => state;

export const getProducts = createSelector(
  selectSelf,
  (state) => state.products
);

export const getProductsData = createSelector(
  getProducts,
  (products) => products?.data
);

export const getTotalCountProducts = createSelector(
  getProducts,
  (products) => products?.count
);

export const getProductById = (productId) =>
  createSelector(getProductsData, (products) => {
    const productById = products.find(
      (product) => Number(product.id) === Number(productId)
    );
    return productById;
  });

export const getProductCompaniesData = createSelector(
  getProducts,
  (product) => {
    const productCompanies = product.productCompanies.map((company) => {
      return {
        value: company.companyId,
        label: company.label
      };
    });
    return productCompanies;
  }
);

export const getProductCategoriesData = createSelector(
  getProducts,
  (product) => {
    const parserCategories = (tree) => {
      const renameKeys = ({ ...obj }, newKeys) => {
        const keyValues = Object.keys(obj).map((key) => {
          let newKey = null;
          if (key === 'name') {
            newKey = newKeys.name;
          } else if (key === 'id') {
            newKey = newKeys.id;
          } else newKey = key;

          if (key === 'children') {
            obj[key] = obj[key].map((obj) => renameKeys(obj, newKeys));
          }
          return {
            [newKey]: obj[key]
          };
        });
        return Object.assign({}, ...keyValues);
      };

      const keysMap = {
        name: 'title',
        id: 'value'
      };

      const renamedObj = tree.map((category) => renameKeys(category, keysMap));

      return renamedObj;
    };

    const categories =
      (product?.productCategories &&
        product?.productCategories?.categoryTree &&
        product.productCategories.categoryTree) ||
      [];

    let output = parserCategories(categories);
    output = [...output, { value: 1, title: 'ALL' }];

    return output.sort();
  }
);

export const getProductCategoriesToBanners = createSelector(
  getProducts, getSpecialCategoriesData,
  (product, categoriesData) => {
    const parserCategories = (tree) => {
      const renameKeys = ({ ...obj }, newKeys) => {
        const keyValues = Object.keys(obj).map((key) => {
          let newKey = null;
          if (key === 'name') {
            newKey = newKeys.name;
          } else if (key === 'id') {
            newKey = newKeys.id;
          } else newKey = key;

          if (key === 'children') {
            obj[key] = obj[key].map((obj) => renameKeys(obj, newKeys));
          }
          return {
            [newKey]: obj[key]
          };
        });
        return Object.assign({}, ...keyValues);
      };

      const keysMap = {
        name: 'title',
        id: 'value'
      };

      const renamedObj = tree.map((category) => renameKeys(category, keysMap));

      return renamedObj;
    };

    const categories =
      (product?.productCategories &&
        product?.productCategories?.categoryTree &&
        product.productCategories.categoryTree) ||
      [];

    const specialCategories = parserCategories(categoriesData);
    const simpleCategories = parserCategories(categories);
    const allCategories = [...specialCategories, ...simpleCategories];
    let output = allCategories;

    output = [...output, { value: 'home', title: 'Home' }, {value: 'home 2', title: 'Home 2'}];

    return output.sort();
  }
);

export const isProductsLoading = createSelector(
  getProducts,
  (products) => products.loading
);

export const hasProductsError = createSelector(
  getProducts,
  (products) => products.error
);

export const getProductsInStock = createSelector(getProductsData, (products) =>
  products.filter((item) => item.qty_available > 0)
);
