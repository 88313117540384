import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const ordersFilterDropdown = (data) => {
  return (
    <Select
      showSearch
      style={{ width: 200 }}
      placeholder="Buscar"
      optionFilterProp="children"
      allowClear
    >
      {data.map((order, index) => (
        <Option key={index} value={order?.id}>
          {' '}
          {order?.name}
        </Option>
      ))}
    </Select>
  );
};

export const clientsFilterDropdown = (data) => {
  return (
    <Select
      showSearch
      style={{ width: 200 }}
      placeholder="Buscar"
      optionFilterProp="children"
      allowClear
    >
      {data.map((order, index) => (
        <Option key={index} value={order?.id}>
          {' '}
          {order?.name}
        </Option>
      ))}
    </Select>
  );
};
export const headers = [
  { label: 'Numero de Order', key: 'order' },
  { label: 'Nombre del Cliente', key: 'client' },
  { label: 'Fecha', key: 'operationDate' },
  { label: 'Monto Total Bs', key: 'amountTotalBs' },
  { label: 'Monto Total USD', key: 'amountTotalUsd' },
  { label: 'Monto Pagado en Cash', key: 'amountPaidCash' },
  { label: 'Diferencia en USD', key: 'differenceInUsd' },
  { label: 'Diferencia en Bolivares', key: 'differenceInBs' },
  { label: 'Foto Billetes', key: 'billsPhotoCsv' },
  { label: 'Denominacion Billetes', key: 'billsDenominationCsv' },
  { label: 'Codigo Billetes', key: 'billsCodeCsv' }
];
