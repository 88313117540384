import React from 'react';
import * as ROUTES from 'constants/routes';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const PublicRoute = ({
  component: Component,
  isLogged,
  accessToken,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (!isLogged || !accessToken ? (
      <Component {...props} />
    ) : (
      <Redirect to={ROUTES.HOME} />
    ))}
  />
);

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
  isLogged: PropTypes.bool.isRequired
};

export default PublicRoute;
