import React from 'react';
import { Col, Row, Tag, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loadingWithSpinner, loaded } from 'stores/actions/loader';
import { mergeWithActiveRoadmap } from 'stores/actions/roadmaps';
import { showNotification } from 'stores/actions/notification';
import { closeModal } from 'stores/actions/modal';

const MergeRoadmapsModal = ({
  mergeableActiveRoadmaps = [],
  setSelectedMergeableRoadmap,
  selectedMergeableRoadmap,
  cancel
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedRoadmap, setSelectedRoadmap] = React.useState('');

  const onSubmit = async () => {
    dispatch(loadingWithSpinner());
    try {
      await dispatch(
        // @ts-ignore
        mergeWithActiveRoadmap({
          activeRoadmap: selectedRoadmap,
          waitingRoadmap: selectedMergeableRoadmap
        })
      );
      setSelectedMergeableRoadmap([]);
      dispatch(loaded());
      dispatch(closeModal());
    } catch (error) {
      dispatch(loaded());
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };

  const handleSelectRoadmap = (roadmap) => {
    selectedRoadmap === roadmap
      ? setSelectedRoadmap('')
      : setSelectedRoadmap(roadmap);
  };
  return (
    <Col>
      <Row>
        {mergeableActiveRoadmaps && mergeableActiveRoadmaps.map((roadmap, idx) => (
          <div key={idx}>
            <Tag
              style={{ borderRadius: '5px' }}
              color={`${selectedRoadmap === roadmap ? 'blue' : ''}`}
            >
              <Button type="text" onClick={() => handleSelectRoadmap(roadmap)}>
                {roadmap?.roadmapNumber}
              </Button>
            </Tag>
          </div>
        ))}
      </Row>
      <Row style={{marginTop: '20px'}}>
        <Col span={4} offset={16}>
          <Button onClick={cancel}>{t('common.button.cancel')}</Button>
        </Col>
        <Col span={4}>
          <Button onClick={onSubmit} type="primary">
            {t('pages.roadmaps.button.assign')}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default MergeRoadmapsModal;
