// @ts-nocheck
import { Ability, AbilityBuilder } from '@casl/ability';
import menu from '../../pages/DashBoard/menu';

const buildPermissions = (permissions) => {
  const parentPermissions = [];
  const allowedModules = permissions.map((permission) => permission.module);
  menu.forEach((record) => {
    const label = record.permission;
    const hasChildren = record.children.length > 0;

    if (allowedModules.includes(label) && hasChildren) {
      parentPermissions.push({
        module: `${label}-parent`,
        actions: ['read']
      });
    }
  });
  return [...permissions, ...parentPermissions];
};
export function updateAbility(ability, permissions) {
  const { can, rules } = new AbilityBuilder(Ability);
  const newPermissions = buildPermissions(permissions);
  newPermissions.forEach((permission) => {
    permission.actions.forEach((action) => can(action, permission.module));
  });
  ability.update(rules);
}

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  // eslint-disable-next-line no-underscore-dangle
  return item.__type;
}

const ability = new Ability([], { subjectName });

export default ability;
