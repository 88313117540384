// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { getArrayCollection } from 'firebase/utils/parsers';
import { ORDERS } from 'firebase/collections';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from 'firebase/firebaseConfig';
import { getNonEmptyOrders } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { setOrdersIncoming } from 'stores/actions/ordersIncoming';
import useFirebaseAuthentication from './useAuthentication';

const useGetOrdersFromToday = () => {
  const currentUser = useFirebaseAuthentication();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  /// Get yesterday day
  const timeStamp = new Date().getTime();
  const yesterdayTimeStamp = timeStamp - 24 * 60 * 60 * 1000;
  const startYesterdayDate = new Date(yesterdayTimeStamp);
  startYesterdayDate.setHours(0, 0, 0, 0);

  useEffect(() => {
    if (Object.keys(currentUser).length > 0) {
      setLoading(true);
      onSnapshot(
        query(collection(db, ORDERS), where('date', '>', startYesterdayDate)),
        (snapshot) => {
          const resOrders = getArrayCollection(snapshot.docs);
          const orders = getNonEmptyOrders(resOrders);
          dispatch(setOrdersIncoming(orders));
          setData(orders);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [currentUser]);

  return { data, loading };
};

export default useGetOrdersFromToday;
