import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  Row,
  Col,
  Tree,
  Select,
  Typography,
  Button,
  Divider,
  Input
} from 'antd';

import { getProfileName } from 'stores/selectors/profile/selector';
import { useTranslation } from 'react-i18next';
import useGetRidersHook from 'hooks/componentHooks/useGetRidersHook';
import { getOrderByIds } from 'firebase/api';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const RoadmapsAssignManually = ({ roadmap, cancel, assignRiderManually }) => {
  const [orders, setOrders] = useState([]);
  const username = useSelector(getProfileName);
  const [feedback, setFeedback] = useState('');
  const [selectedRider, setSelectedRider] = useState();
  const { getFreeRiders } = useGetRidersHook();
  const [isDisabled, setIsDisabled] = React.useState(true);

  const riders = getFreeRiders();

  const { t } = useTranslation();

  const fetchOrders = async () => {
    setOrders(await getOrderByIds(roadmap?.ordersIds));
  };

  const options = orders.map((order) => ({
    title: order.odooDataOrder.name,
    key: order.odooDataOrder.id,
    disabled: [3, 5].includes(order.statusCode)
  }));

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (selectedRider !== undefined) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedRider]);

  const getFeedback = (e) => {
    e.preventDefault();
    setFeedback(e.target.value);
  };

  const onSelect = (id) => {
    const selectedValue = riders.find((rider) => rider.uid === id);
    setSelectedRider(selectedValue);
  };

  const onSubmit = () => {
    const newRoadmap = {
      log: [
        ...roadmap.log,
        {
          id: uuidv4(),
          type: 'start',
          description: 'riderAssignedManually',
          target: selectedRider,
          date: moment().unix()
        },
        {
          id: uuidv4(),
          type: 'delivery',
          description: 'inProgress',
          target: selectedRider,
          date: moment().unix()
        }
      ],
      status: 'inprogress',
      feedback: {
        date: moment().unix(),
        description: feedback,
        createdBy: username
      },
      riderId: selectedRider.uid,
      isOnDelivery: false,
    };

    const newRider = {
      status: 'busy'
    };

    const oldRider = {
      status: 'free'
    };

    const ordersToUpdate = orders
      .filter((order) => [0, 1, 2].includes(order.statusCode))
      .map((order) => order.id);

    assignRiderManually(
      roadmap,
      newRoadmap,
      newRider,
      oldRider,
      ordersToUpdate
    );
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col
          span={8}
          offset={4}
          style={{ height: '40vh', overflowY: 'scroll' }}
        >
          <Text strong>{t('menu.orders')}</Text>
          <Tree treeData={options} />
        </Col>
        <Col span={8}>
          <Text strong>{t('menu.riders')}</Text>
          <Select
            style={{ width: '200px' }}
            showSearch
            placeholder="Search a Rider"
            onChange={(e) => {
              onSelect(e);
            }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            value={selectedRider?.name || {}}
          >
            {riders.map((rider, idx) => (
              <Option key={idx} value={rider.uid}>
                {rider.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Divider orientation="left" />
        <Col style={{ width: '100%' }}>
          <TextArea
            placeholder="Se cambia el rider debiado a..."
            onChange={getFeedback}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={4} offset={16}>
          <Button onClick={cancel}>{t('common.button.cancel')}</Button>
        </Col>
        <Col span={4}>
          <Button disabled={isDisabled} onClick={onSubmit} type="primary">
            {t('pages.roadmaps.button.assign')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default RoadmapsAssignManually;
