import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';

const DiscountDetails = ({ onClose, visible, data }) => {

  const { discount } = data;
  return (
    <Drawer
      title={`Detalle de descuento - Orden N ${data.name}`}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={400}
    >
      <p>
        Codigo: {discount.code}
      </p>
      <p>
        Monto: {discount.totalBs}
      </p>
    </Drawer>
  );
};

DiscountDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired
};

export default DiscountDetails;
