import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from '../../firebase';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getCoupons = createAsyncThunk('@coupons/getCoupons', async () =>
  firebase.getCoupons()
);

export const createCoupon = createAsyncThunk(
  '@coupons/create',
  async (data) => await firebase.createCoupon(data)
);

export const updateCoupon = createAsyncThunk(
  '@coupons/update',
  async ({ id, body }) => await firebase.updateCoupon(id, body)
);

export const couponsSlide = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    setCouponsLoading: (state) => {
      state.loading = true;
    },
    setCoupons: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setCouponsError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getCoupons.pending]: (state) => {
      state.loading = true;
    },
    [getCoupons.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getCoupons.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createCoupon.pending]: (state) => {
      state.loading = true;
    },
    [createCoupon.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createCoupon.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateCoupon.pending]: (state) => {
      state.loading = true;
    },
    [updateCoupon.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateCoupon.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setCouponsLoading, setCoupons, setCouponsError } =
  couponsSlide.actions;

export default couponsSlide.reducer;
