import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const ordersFilterDropdown = (data) => {
  return (
    <Select
      showSearch
      style={{ width: 200 }}
      placeholder="Buscar"
      optionFilterProp="children"
      allowClear
    >
      {data.map((order, index) => (
        <Option key={index} value={order?.id}>
          {' '}
          {order?.name}
        </Option>
      ))}
    </Select>
  );
};

export const clientsFilterDropdown = (data) => {
  return (
    <Select
      showSearch
      style={{ width: 200 }}
      placeholder="Buscar"
      optionFilterProp="children"
      allowClear
    >
      {data.map((order, index) => (
        <Option key={index} value={order?.id}>
          {' '}
          {order?.name}
        </Option>
      ))}
    </Select>
  );
};
export const headers = [
  { label: 'Numero de Order', key: 'order' },
  { label: 'Nombre del Cliente', key: 'client' },
  { label: 'Fecha', key: 'operationDate' },
  { label: 'Banco', key: 'bank' },
  { label: 'Cedula', key: 'dni' },
  { label: 'Monto Total Bs', key: 'amountTotalBs' },
  { label: 'Monto Pagado', key: 'amountPaidCash' },
  { label: 'Vuelto', key: 'moneyReturned' }
];
