import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Table, Form, Input, Popconfirm, Space, Switch } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { showNotification } from 'stores/actions/notification';
import useFirebaseContext from 'hooks/useFirebaseContext';
import useSetHeaderProps from 'hooks/useSetHeaderProps';

import Can from 'components/Can';
import { DEPARTMENTS } from 'components/Can/modules';
import {
  checkDepartmentInAdmins,
  deleteDepartment,
  getDepartments as getDepartmentsFb,
  updateDepartment
} from 'firebase/api';
import styles from './Departments.module.scss';

const Departments = () => {
  const firebase = useFirebaseContext();
  const setHeaderProps = useSetHeaderProps();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const EditableContext = React.createContext(null);

  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getDepartments = useCallback(async () => {
    setIsLoading(true);
    const resDepartments = await getDepartmentsFb();
    const dataDepartments = resDepartments.map((item) => ({
      key: item.id,
      name: item.name,
      active: item.active
    }));

    setDepartments(dataDepartments);
    setIsLoading(false);
  }, []);

  const handlerAdd = useCallback(() => {
    const newDepartment = [
      ...departments,
      { key: '', name: '-', active: false }
    ];
    setDepartments(newDepartment);
  }, [departments]);

  const handlerSave = async (row) => {
    if (row.name !== '') {
      setIsLoading(true);

      try {
        if (row.key !== '') {
          await updateDepartment(row);
        } else {
          const newData = { name: row.name, active: false };
          await firebase.createDepartment(newData);
        }
        getDepartments();
      } catch (error) {
        dispatch(
          showNotification({
            type: 'error',
            message: 'Error',
            content: error.message
          })
        );
      }
    }
  };

  const handlerActive = async (record, status) => {
    setIsLoading(true);
    try {
      record.active = status;
      await updateDepartment(record);
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    try {
      const someAdminHasIt = await checkDepartmentInAdmins(id);
      if (someAdminHasIt) {
        dispatch(
          showNotification({
            type: 'warning',
            message: 'Warning',
            content: t('pages.departments.messages.department-in-use')
          })
        );
      } else {
        await deleteDepartment(id);
      }
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
    getDepartments();
  };

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      /* clean value */
      if (record?.name === '-') {
        record.name = '';
      }
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex]
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        dispatch(
          showNotification({
            type: 'warning',
            message: 'warning',
            content: errInfo
          })
        );
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const columns = [
    {
      title: t('pages.departments.table.name'),
      dataIndex: 'name',
      width: '60%',
      editable: true
    },
    {
      title: t('pages.departments.table.actions'),
      dataIndex: 'Action',
      align: 'center',
      render: (_, record) =>
        departments.length >= 1 && (
          <>
            {record.key !== '' ? (
              <Space>
                <Can I="delete" a={DEPARTMENTS}>
                  <Popconfirm
                    title={t('pages.departments.messages.delete')}
                    onConfirm={() => {
                      handleDelete(record.key);
                    }}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Can>
                <Can I="update" a={DEPARTMENTS}>
                  <Switch
                    defaultChecked={record.active}
                    onChange={(status) => handlerActive(record, status)}
                  />
                </Can>
              </Space>
            ) : null}
          </>
        )
    }
  ];

  const getColumns = () =>
    columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handlerSave
        })
      };
    });

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  useEffect(() => {
    setHeaderProps({
      extraButtons: [
        <Fragment key={0}>
          <Can I="create" a={DEPARTMENTS}>
            <Button
              onClick={handlerAdd}
              type="primary"
              style={{ marginBottom: 16 }}
            >
              {t('pages.departments.button.add')}
            </Button>
          </Can>
        </Fragment>
      ]
    });
  }, [setHeaderProps, t, handlerAdd]);

  return (
    <div className={styles.container}>
      <Table
        columns={getColumns()}
        loading={isLoading}
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell
          }
        }}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={departments}
        pagination={{
          total: departments.length || 0,
          showSizeChanger: true
        }}
      />
    </div>
  );
};

export default Departments;
