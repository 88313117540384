import { renderToStaticMarkup } from 'react-dom/server';
import Canvg from 'canvg';

function encodeSvg(reactElement) {
  const svg = renderToStaticMarkup(reactElement)
    .replace(/\r?\n|\r/g, '')
    .trim();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const svgWithCanva = Canvg.fromString(ctx, svg);
  svgWithCanva.start();
  return canvas.toDataURL('image/png');
}

export default encodeSvg;
