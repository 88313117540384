import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  multiOperation,
  getUsersLimited,
  getOneUser as getOneUserFb,
  getAdmins as getAdminsFb,
  getDepartments,
  getUsersWithWallet as getUsersWithWalletFb,
  getUsersWithRei as getUsersWithReiFb
} from 'firebase/api';
import { USERS, NOTIFICATIONS } from '../../firebase/collections';
export const initialState = {
  data: [],
  loading: false,
  error: false,
  list: {
    loading: false,
    data: [],
    error: false
  },
  admins: {
    loading: false,
    data: [],
    error: false
  },
  wallet: {
    loading: false,
    data: [],
    error: false
  },
  preWallet: {
    loading: false,
    data: [],
    error: false
  }
};

export const setUserNotification = createAsyncThunk(
  '@users/setUserNotification',
  async ({ ids, content }) => {
    const users = ids.map((id) => ({
      id,
      model: USERS,
      children: {
        model: NOTIFICATIONS,
        operation: 'set',
        body: content
      }
    }));

    return multiOperation(users);
  }
);

export const getUsers = createAsyncThunk('@users/getUsers', async () => {
  const data = await getUsersLimited(1000);
  const newData = data?.map((user) => ({
    ...user,
    type: user?.departmentId ? 'Internos' : 'Externos'
  }));
  return newData;
});

export const getAdmins = createAsyncThunk('@users/getAdmins', async () => {
  const data = await getAdminsFb();
  const departments = await getDepartments();
  const newData = data?.map((user) => ({
    ...user,
    city: user?.address?.city || '',
    municipality: user?.address?.municipality || '',
    state: user?.address?.state || '',
    street: user?.address?.street || '',
    department:
      departments?.find((department) => department.id === user?.departmentId)
        ?.name || '-',
    type: user?.departmentId ? 'Internos' : 'Externos'
  }));
  return newData;
});

export const getOneUser = createAsyncThunk(
  '@users/getOneUser',
  async ({ email }) => {
    const data = await getOneUserFb(email);
    const newData = data?.map((user) => ({
      ...user,
      type: user?.department ? 'Internos' : 'Externos'
    }));
    return newData;
  }
);

export const getUsersWithWallet = createAsyncThunk(
  '@users/getUsersWithWallet',
  async () => {
    return await getUsersWithWalletFb();
  }
);

export const getUsersWithRei = createAsyncThunk(
  '@users/getUsersWithRei',
  async () => {
    return await getUsersWithReiFb();
  }
);

export const usersSlide = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersLoading: (state) => {
      state.loading = true;
    },
    setUsers: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setUsersError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [setUserNotification.pending]: (state) => {
      state.loading = true;
    },
    [setUserNotification.fulfilled]: (state) => {
      state.loading = false;
    },
    [setUserNotification.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getUsers.pending]: (state) => {
      state.list.loading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.list.loading = false;
      state.list.data = action.payload;
    },
    [getUsers.rejected]: (state, action) => {
      state.list.error = action.payload;
      state.list.loading = false;
    },
    [getOneUser.pending]: (state) => {
      state.list.loading = true;
    },
    [getOneUser.fulfilled]: (state, action) => {
      state.list.loading = false;
      state.list.data = [...state.list.data, ...action.payload];
    },
    [getOneUser.rejected]: (state, action) => {
      state.list.error = action.payload;
      state.list.loading = false;
    },
    [getAdmins.pending]: (state) => {
      state.admins.loading = true;
    },
    [getAdmins.fulfilled]: (state, action) => {
      state.admins.loading = false;
      state.admins.data = action.payload;
    },
    [getAdmins.rejected]: (state, action) => {
      state.admins.loading = false;
      state.admins.error = action.payload;
    },
    [getUsersWithWallet.pending]: (state) => {
      state.wallet.loading = true;
    },
    [getUsersWithWallet.fulfilled]: (state, action) => {
      state.wallet.loading = false;
      state.wallet.data = action.payload;
    },
    [getUsersWithWallet.rejected]: (state, action) => {
      state.wallet.loading = false;
      state.wallet.error = action.payload;
    },
    [getUsersWithRei.pending]: (state) => {
      state.preWallet.loading = true;
    },
    [getUsersWithRei.fulfilled]: (state, action) => {
      state.preWallet.loading = false;
      state.preWallet.data = action.payload;
    },
    [getUsersWithRei.rejected]: (state, action) => {
      state.preWallet.loading = false;
      state.preWallet.error = action.payload;
    }
  }
});

export const { setUsersLoading, setUsers, setUsersError } = usersSlide.actions;

export default usersSlide.reducer;
