import React from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { loadingWithSpinner, loaded } from 'stores/actions/loader';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SIGNIN } from 'constants/routes';
import { cleanProfile } from 'stores/actions/profile';
import { showNotification } from 'stores/actions/notification';
import { signOut } from 'firebase/api';

const SignOutButton = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const signOutHandler = async () => {
    try {
      dispatch(loadingWithSpinner());
      await signOut();
      dispatch(cleanProfile());
      window.sessionStorage.clear();
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    } finally {
      dispatch(loaded());
      history.push(SIGNIN);
    }
  };
  return (
    <Menu.Item {...props} icon={<LogoutOutlined />} onClick={signOutHandler}>
      {t('menu.signOut')}
    </Menu.Item>
  );
};

SignOutButton.propTypes = {
  props: PropTypes.objectOf(PropTypes.shape({}))
};

SignOutButton.defaultProps = {
  props: undefined
};

export default SignOutButton;
