import { createSelector } from '@reduxjs/toolkit';
import { getOrdersData } from './orders';
import { getRidersData } from './riders';
import { getProcosData } from './proco';
import { getRoutesData } from './routes';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import _ from 'lodash';

const selectSelf = (state) => state;

export const getRoadmaps = createSelector(
  selectSelf,
  (state) => state.roadmaps
);

export const getDoneRoadmaps = createSelector(
  getRoadmaps,
  (roadmaps) => roadmaps.done || []
);

export const getRoadmapsData = createSelector(
  getRoadmaps,
  (roadmaps) => roadmaps.data || []
);

export const getAllRoadmaps = createSelector(getRoadmaps, (roadmaps) => [
  ...roadmaps.data,
  ...roadmaps.done.data
]);

export const isRoadmapsLoading = createSelector(
  getRoadmaps,
  (roadmaps) => roadmaps.loading
);

export const hasRoadmapsError = createSelector(
  getRoadmaps,
  (roadmaps) => roadmaps.error
);

export const hasRoadmapsData = createSelector(
  getRoadmapsData,
  (roadmaps) => roadmaps.length > 0
);

export const isRoadmapsLoaded = createSelector(
  isRoadmapsLoading,
  hasRoadmapsData,
  (isLoading) => !isLoading
);

export const getRoadmapsDataTable = createSelector(
  getRoadmapsData,
  getRoutesData,
  getRidersData,
  getProcosData,
  getOrdersData,
  (roadmaps, routes, riders, procos, orders) =>
    roadmaps.map((roadmap) => ({
      ...roadmap,
      rider: {
        ...riders.find((rider) => rider.id === roadmap.riderId),
        proco:
          procos?.find((proco) =>
            proco?.riders?.includes(
              riders.find((rider) => rider.id === roadmap.riderId)?.id
            )
          ) || '-'
      },
      route: routes.find((route) => route.id === roadmap.routeId),
      orders:
        (roadmap.hasOwnProperty('ordersIds') &&
          roadmap.ordersIds.map((order) =>
            orders.find((fOrder) => order === fOrder.id)
          )) ||
        []
    })) || []
);

export const getRoadmapByNumber = (number) =>
  createSelector(
    getAllRoadmaps,
    getRoutesData,
    getRidersData,
    getOrdersData,
    (roadmaps, routes, riders, orders) => {
      const foundedRoadmap = roadmaps.find(
        (roadmap) => roadmap.roadmapNumber === number
      );
      return {
        ...(foundedRoadmap || {}),
        rider:
          riders.find((rider) => rider.id === foundedRoadmap?.riderId) || {},
        route: routes.find((route) => route.id === foundedRoadmap.routeId),
        orders:
          foundedRoadmap.ordersIds.map((order) => {
            const foundOrder = orders.find((fOrder) => order === fOrder?.id);
            return {
              ...foundOrder,
              subtotal: foundOrder?.total,
              total: foundOrder?.total + foundOrder?.tax
            };
          }) || []
      } || {};
    }
  );

export const getWaitingRoadmaps = createSelector(
  getRoadmapsData,
  (roadmaps) => roadmaps.filter((roadmap) => roadmap.status === 'waiting') || []
);

export const getRoadmapByOrder = (orderId) =>
  createSelector(getWaitingRoadmaps, (roadmaps) =>
    roadmaps.find((roadmap) => roadmap.ordersIds.includes(orderId))
  );

export const getRoadmapsToUpdate = (ordersIds, mainRoadmap) =>
  createSelector(getWaitingRoadmaps, (roadmaps) => {
    const roadmapsWithoutMain = roadmaps.filter(
      (roadmap) => roadmap.id !== mainRoadmap
    );
    return ordersIds.map((orderId) => {
      const secondaryRoadmap = roadmapsWithoutMain.find((roadmap) =>
        roadmap.ordersIds.includes(orderId)
      );

      return {
        id: secondaryRoadmap.id,
        log: secondaryRoadmap.log,
        ordersLeft: secondaryRoadmap.filter((rm) => rm.id !== orderId)
      };
    });
  });

export const getRoadmapToCancelOrders = (
  roadmapNumber,
  orders = [],
  isWaiting = false //flag "waiting" to manage orders are to be removed
) =>
  createSelector(getRoadmapsData, (roadmaps) => {
    const roadmapBefore = roadmaps.find(
      (roadmap) => roadmap.roadmapNumber === roadmapNumber
    );
    let roadmapAfter;
    if (roadmapBefore) {
      const logsCancelAction = orders.map(() => {
        return {
          id: uuidv4(),
          type: 'close',
          description: 'orderCancelledManually',
          target: '',
          date: moment().unix()
        };
      });

      const ordersIdsCancelled = orders.map((order) => order?.id);
      const newOrders = _.difference(
        roadmapBefore?.ordersIds,
        ordersIdsCancelled
      );

      roadmapAfter = {
        ...roadmapBefore,
        ordersIds: newOrders,
        log: [...roadmapBefore?.log, ...logsCancelAction]
      };
    }

    return roadmapAfter;
  });
