import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { parseUserDataInOrders, scapeLatters } from 'stores/selectors/orders';
import { formatPriceToDollar } from 'utils/utils';

export const columns = [
  {
    title: 'Cantidad',
    dataIndex: 'quantity',
    key: 'cantidad',
    align: 'center'
  },
  {
    title: 'Ubicación',
    dataIndex: 'moves_location_id',
    render: (record) => record?.split('/')[2] || 'No Asignado'
  },
  {
    title: 'Laboratorio',
    dataIndex: 'laboratory',
    key: 'laboratorio'
  },
  {
    title: 'Producto',
    dataIndex: 'name',
    key: 'producto'
  },
  {
    title: 'Monto',
    dataIndex: 'price_total',
    key: 'monto',
    render: (amount) => <>{formatPriceToDollar(amount)}</>
  },
  Table.SELECTION_COLUMN
];

export const packingFactory = (
  orders = [],
  users = [],
  riders = [],
  routes = []
) => {
  const ordersParsed = orders
    .filter(
      (order) =>
        order.hasOwnProperty('odooDataOrder') &&
        Object.keys(order.odooDataOrder).length > 0
    )
    .sort(
      (a, b) =>
        scapeLatters(b.odooDataOrder.name) - scapeLatters(a.odooDataOrder.name)
    );

  return ordersParsed
    .map((order) => {
      const partner = users.find((user) => user.id === order.clientId) || '';
      const rider =
        riders.find((riderItem) => riderItem.id === order.riderId) || '';
      const routeFound =
        routes.find((route) => route.id === order?.addressDetails?.routeId) ||
        '';
      return {
        ...parseUserDataInOrders(order),
        name: order?.odooDataOrder?.name,
        routeName: routeFound?.name,
        partner,
        rider
      };
    })
    .map((order) => {
      let date = order?.date.toDate();
      date = moment(date).format('DD-MM-YYYY HH:mm');
      return {
        ...order,
        details: {
          date,
          clientName: order?.clientName,
          dni: order?.partner?.dni,
          phone: order?.partner?.phone,
          phoneAddress: order?.addressDetails?.phone,
          address: order?.addressDetails?.zone,
          payMethod: order?.paymentType
        },
        payment: {
          subtotal: order?.subtotal,
          tax: order?.tax,
          total: order?.total,
          totalUsd: order?.ref
        }
      };
    })
    .filter(
      (order) =>
        (order.statusCode === 0 || order.statusCode === 4) &&
        order?.onPacking === 'unpacked'
    );
};
