import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Popconfirm, Table } from 'antd';
import { searchProductsByIds } from 'stores/actions/products';
import { isProductsLoading } from 'stores/selectors/products';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import {
  getSpecialCategories,
  updateSpecialCategory
} from 'stores/actions/specialCategories';
import Can from 'components/Can';
import { SPECIAL_CATEGORIES } from 'components/Can/modules';

const ProductsDrawer = ({ onClose, visible, categoryId, products, title }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const loading = useSelector(isProductsLoading);
  const [ids, setIds] = useState(products.map((product) => product.id));

  const fetchProducts = async () => {
    const { payload } = await dispatch(searchProductsByIds(ids));
    setData(payload.data);
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, [ids]);

  const deleteProduct = async (id) => {
    await dispatch(
      updateSpecialCategory({
        id: categoryId,
        data: { products: products.filter((product) => product.id !== id) }
      })
    );
    await dispatch(getSpecialCategories());
    setIds(ids.filter((productId) => id !== productId));
  };

  const getColumns = () => [
    {
      title: 'Imagen',
      dataIndex: 'image',
      key: 'image',
      render: (text, record) => {
        const image = record.image || 'https://via.placeholder.com/150';
        return (
          <div
            style={{
              width: '100px',
              height: '100px',
              background: '#eee',
              position: 'relative'
            }}
          >
            <img
              style={{
                position: 'absolute',
                maxHeight: '100%',
                maxWidth: '100%',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: 'auto'
              }}
              alt={record.key}
              src={image}
            />
          </div>
        );
      }
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Categoria',
      key: 'categ_route',
      dataIndex: 'categ_route',
      render: (record) => <span>{record?.split('/').slice(-1)[0] || ''}</span>
    },
    {
      title: 'Laboratorio',
      dataIndex: 'laboratory',
      key: 'laboratory'
    },
    {
      title: 'Recipe',
      dataIndex: 'product_type',
      key: 'product_type',
      render: (record) =>
        record === 'prescripcion' ? (
          <CheckCircleOutlined style={{ color: '#52c41a' }} />
        ) : (
          <CloseCircleOutlined />
        )
    },
    {
      title: 'Cantidad disponible',
      dataIndex: 'qty_available',
      key: 'qty_available'
    },
    {
      title: 'Acciones',
      key: 'action',
      render: (product) => (
        <Can I="delete" a={SPECIAL_CATEGORIES}>
          <Popconfirm
            onConfirm={() => deleteProduct(product.id)}
            title="Desea eliminar este producto de la categoria especial?"
            okText="Si"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </Can>
      )
    }
  ];

  return (
    <Drawer
      title={title}
      placement="bottom"
      onClose={onClose}
      visible={visible}
      height="80%"
    >
      <Table
        columns={getColumns()}
        dataSource={data}
        rowKey="id"
        loading={loading}
        scroll={{ y: 480 }}
      />
    </Drawer>
  );
};

ProductsDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

export default ProductsDrawer;
