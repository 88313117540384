import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';
import { roundDecimals } from 'utils/utils';

const selectSelf = (state) => state.checkout;

export const getBillingData = createSelector(
  selectSelf,
  (state) => state.invoiceData
);

export const getSummaryTotals = createDraftSafeSelector(selectSelf, (state) => {
  const { cartShop } = state;

  return {
    subtotal: cartShop?.subTotal,
    discount: 0,
    tax: cartShop?.tax,
    shipping: 0,
    usdRef: (cartShop?.subTotal / cartShop?.refs?.subTotal).toFixed(2),
    total: roundDecimals(cartShop?.amountTotal),
    totalRef: roundDecimals(cartShop?.refs?.total || 0),
    quantityProducts: cartShop?.quantityProducts
  };
});

export const getCustomer = createDraftSafeSelector(
  selectSelf,
  (state) => state.customer
);

export const orderSuccessful = createDraftSafeSelector(
  selectSelf,
  (state) => state.success
);

export const orderErrors = createDraftSafeSelector(
  selectSelf,
  (state) => state.error
);

export const orderCreated = createDraftSafeSelector(
  selectSelf,
  (state) => state.createdOrder
);

export const getSteps = createDraftSafeSelector(
  selectSelf,
  (state) => state.step
);

export const getPayment = createDraftSafeSelector(
  selectSelf,
  (state) => state.payment
);

export const getBills = createDraftSafeSelector(
  getPayment,
  (state) => state.validatingBills
);
