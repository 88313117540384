import { Button, Form, Input } from 'antd';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getBills } from 'stores/selectors/checkout';
import { CheckOutlined } from '@ant-design/icons';
import { addBill, checkCashValidAmounts } from 'stores/actions/checkout';
import styles from '../../CreateOrder.module.scss';
import CashbackForm from './CashbackForm';

const { Item } = Form;

export default function Cash({
  onSave,
  totalInCart,
  cashbackMode,
  setCashbackMode
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isValidAmount, bills } = useSelector(getBills);
  const { total, totalRef } = totalInCart.data.data;

  const checkValidAmounts = useCallback(async () => {
    const { payload: result } = await dispatch(
      checkCashValidAmounts({ totalRef: totalInCart.data?.data?.ref })
    );
    if (result === 'valid') onSave(null);
    if (result === 'cashback') {
      setCashbackMode(true);
    }
  }, [totalInCart, dispatch, onSave, setCashbackMode]);

  const handleAcceptCashback = React.useCallback(
    (values) => {
      onSave({
        cashback: {
          banco: values.bank,
          cedula: `${values.dniType}${values.dni}`,
          telefono: `${values.prefix}${values.phone}`
        }
      });
    },
    [onSave]
  );

  const handleSubmit = (v) => {
    dispatch(addBill(v));
    form.resetFields();
  };

  const addAndCheck = () => {
    form.submit();
    setTimeout(() => {
      checkValidAmounts();
    }, 150);
  };

  if (cashbackMode) {
    return (
      <div className={styles.cash}>
        <CashbackForm
          bsTotal={parseInt(total)}
          total={parseInt(totalRef)}
          handleAccept={handleAcceptCashback}
          handleCancel={() => setCashbackMode(false)}
        />
      </div>
    );
  }

  return (
    <div className={styles.cash}>
      <Form
        className={styles.container}
        form={form}
        name="cash-payment"
        onFinish={handleSubmit}
        scrollToFirstError
        initialValues={{
          amount: '',
          code: ''
        }}
      >
        <div className={styles.notes}>
          <p
            style={{
              marginBottom: '0',
              fontWeight: 'bold'
            }}
          >
            Precio total REF del carrito: {` $ ${totalInCart.data?.data?.ref}`}
          </p>
          <p>
            El monto en $ debe ser mayor al total, y no puede ser mayor a 10$
            del total de la orden
          </p>
        </div>
        <div className={styles.inputCont}>
          <Item
            name="amount"
            label="Monto"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa un monto valido'
              }
            ]}
          >
            <Input
              className={styles.fullInput}
              type="number"
              min={0}
              placeholder="Monto a pagar en efectivo"
              maxLength={30}
            />
          </Item>
        </div>
        <div className={styles.inputCont}>
          <Item
            name="code"
            label="Codigo del billete"
            rules={[
              {
                required: true,
                message: 'Colocar un codigo es obligatorio'
              }
            ]}
          >
            <Input
              className={styles.fullInput}
              placeholder="Serial del billete"
              maxLength={30}
            />
          </Item>
        </div>
        <div className={styles.buttonsContainer}>
          <Button className={styles.confirmButton} htmlType="submit">
            <p className={styles.text}>Agregar billete</p>
          </Button>
          <div className={styles.buttonsContainer__row}>
            <Button
              className={styles.confirmButton__accept}
              onClick={bills.length === 0 ? addAndCheck : checkValidAmounts}
            >
              <p className={styles.text}>Guardar</p>
            </Button>
            {isValidAmount && (
              <CheckOutlined
                className={[
                  styles.billingDataSaved,
                  styles.appearingAnimation
                ].join(' ')}
              />
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}

Cash.defaultProps = {
  onSave: () => {},
  totalInCart: {},
  cashbackMode: false,
  setCashbackMode: () => {}
};

Cash.propTypes = {
  onSave: PropTypes.func,
  totalInCart: PropTypes.object,
  cashbackMode: PropTypes.bool,
  setCashbackMode: PropTypes.func
};
