/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Col, Divider, Row, Table, Input, Space, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from 'stores/actions/notification';
import {
  getRealCartPrices as targetEmailForPrices,
  cleanCart
} from 'stores/actions/cartValidation';
import { cartPrices as totalCartData } from 'stores/selectors/cartValidation';
import { loadingWithSpinner, loaded } from 'stores/actions/loader';
import CreateOrder from 'components/CreateOrderDrawer/CreateOrder';
import useGetUsersHook from 'hooks/componentHooks/useGetUsersHook';
import { getShoppingCart, searchProductsByIds } from 'firebase/api';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/firebaseConfig';
import styles from './CartValidation.module.scss';

const CartValidation = () => {
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResults] = useState('');
  const [userResults, setUserResults] = useState('');
  const cartData = useSelector(totalCartData);
  const dispatch = useDispatch();
  const { findUser } = useGetUsersHook();
  const [shoppingCartId, setshoppingCartId] = useState(null);

  const [loadings, setLoadings] = React.useState([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 3000);
  };

  const validate = async (uid) => {
    const shoppingCartlist = await getShoppingCart(uid);
    setshoppingCartId({
      id: shoppingCartlist[0].id
    });
    const shoppingCart = shoppingCartlist[0];
    const body = {
      product_list: shoppingCart?.products?.map((product) => ({
        productId: String(product.id),
        productQty: Number(product.quantity)
      }))
    };

    const checkInventary = httpsCallable(functions, 'odoo-checkInventary');

    const { data } = await checkInventary(body);

    if (data.statusCode === 200) {
      dispatch(
        showNotification({
          type: 'success',
          message: 'Carrito Validado',
          content: 'Todos los productos estan disponibles'
        })
      );
    } else if (data.statusCode === 404) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Carrito Validado',
          content: 'Hay uno o más productos sin stock.'
        })
      );
      const array = data.products.map((e) => ({
        qtyAvailable: e.qtyAvailable,
        id: e.id
      }));

      const { data: payload } = await searchProductsByIds(
        array.map((e) => e.id)
      );

      const productsInfo = await payload.data.map((e) => ({
        name: e.name,
        id: e.id
      }));
      const modifiedArray = array.map((e) => {
        const product = shoppingCart?.products.find(
          (prod) => Number(prod.id) === Number(e.id)
        );
        const productInfo = productsInfo.find(
          (prod) => Number(prod.id) === Number(e.id)
        );
        return {
          ...e,
          carrito: product?.quantity,
          name: productInfo?.name
        };
      });
      setProducts(modifiedArray);
    } else {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Carrito Validado',
          content: 'Ha ocurrido un error al validar el carrito'
        })
      );
    }
  };

  const handleUser = async (value) => {
    setLoadingUser(true);
    setTimeout(() => {
      setLoadingUser(false);
    }, 3000);
    try {
      const { 0: user } = await findUser({ email: value });
      if (!user) {
        dispatch(
          showNotification({
            type: 'error',
            message: 'Error',
            content: 'Usuario no encontrado'
          })
        );
        return;
      }
      await validate(user?.id).then(() => {
        setSearchResults(value);
        setUserResults(user);
      });
    } catch (err) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: 'Ha ocurrido un error'
        })
      );
    }
  };

  const handlePrices = async (value) => {
    try {
      enterLoading(0);
      dispatch(loadingWithSpinner());
      dispatch(targetEmailForPrices({ email: value }));
    } catch (err) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: err.message
        })
      );
    } finally {
      dispatch(loaded());
    }
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Cantidad disponible',
      dataIndex: 'qtyAvailable',
      key: 'qtyAvailable'
    },
    {
      title: 'Cantidad carrito',
      dataIndex: 'carrito',
      key: 'carrito'
    }
  ];

  /**
   * Map data for detail product table
   */
  const dataForRealPrices = cartData?.data?.data?.products2?.map(
    (myCartProducts) => ({
      key: myCartProducts?.id,
      name: myCartProducts?.name,
      id: myCartProducts?.id,
      qty_available: myCartProducts?.qty_available,
      quantity: myCartProducts?.cantidad,
      product_type: myCartProducts?.product_type,
      price: myCartProducts?.price.toFixed(2),
      price_ref: myCartProducts?.price_ref.toFixed(2)
    })
  );

  /**
   * Map data for detail product table
   */
  const columnsForRealPrices = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Cantidad disponible',
      dataIndex: 'qty_available',
      key: 'qty_available'
    },
    {
      title: 'Cantidad en el carrito',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Preinscripcion',
      dataIndex: 'product_type',
      key: 'product_type'
    },
    {
      title: 'Precio BS',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Precio REF',
      dataIndex: 'price_ref',
      key: 'price_ref'
    }
  ];

  // Clean searchFilter
  const cleanState = () => {
    setProducts([]);
    setSearchResults(undefined);
    dispatch(cleanCart());
  };

  return (
    <div className={styles.container}>
      <Space>
        <Input.Search
          enterButton="Validar Carrito"
          placeholder="Email del Usuario"
          onSearch={(e) => handleUser(e)}
          type="email"
          allowClear
          onClick={() => cleanState()}
          loading={loadingUser}
        />
        <Button
          disabled={!searchResults}
          onClick={() => handlePrices(searchResults)}
          loading={loadings[0]}
        >
          Detalles del carrito
        </Button>
        {searchResults && cartData.data.total !== '0' && (
          <CreateOrder
            searchResults={userResults}
            cart={cartData}
            columns={columnsForRealPrices}
            dataSource={dataForRealPrices}
            shoppingCartId={shoppingCartId}
            user={userResults}
            cleanSearchFilter={cleanState}
          />
        )}
        {cartData.data.total !== '0' && (
          <>
            <p style={{ marginBottom: '0', fontWeight: 'bold' }}>
              Precio total del carrito: {`${cartData.data.data.total} BS`}
            </p>
            <p style={{ marginBottom: '0', fontWeight: 'bold' }}>
              || Precio total REF del carrito: {` $ ${cartData.data.data.ref}`}
            </p>
          </>
        )}
      </Space>
      <Divider />
      {userResults && (
        <>
          {(cartData.data.total || 0) !== '0' ? (
            <>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Table
                    columns={columnsForRealPrices}
                    dataSource={dataForRealPrices}
                    rowKey="id"
                  />
                </Col>
              </Row>
            </>
          ) : products.length !== 0 ? (
            <>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Table columns={columns} dataSource={products} rowKey="id" />
                </Col>
              </Row>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default CartValidation;
