import React from 'react';
import { useSelector } from 'react-redux';
import { getUsersData } from 'stores/selectors/users';

const AnonymousPhone = ({ order }) => {
  const { clientId, customizedInvoice } = order || {};
  const users = useSelector(getUsersData);
  const userData = users.find((user) => user.id === clientId);
  const phone = userData ? userData.phone : customizedInvoice?.phone;

  return <>{phone || 'Sin telefono..'}</>;
};

export default AnonymousPhone;
