import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilePdfOutlined } from '@ant-design/icons';
import { Image, Modal } from 'antd';

const Prescription = ({ order, product }) => {
  const [viewPdf, setViewPdf] = useState({
    active: false,
    url: ''
  });
  const hasPrescription = order.hasOwnProperty('prescriptions');
  const foundPrescription = hasPrescription
    ? order?.prescriptions.find(
        (prescription) => prescription.product === product.id
      ) || false
    : false;
  const isAPdf = foundPrescription && foundPrescription.url.search('pdf') > 0;

  return (
    <>
      {isAPdf ? (
        <FilePdfOutlined
          onClick={() =>
            setViewPdf({
              active: true,
              url: foundPrescription.url
            })
          }
        />
      ) : foundPrescription ? (
        <Image
          width={50}
          src={foundPrescription.url}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        '-'
      )}
      <>
        <Modal
          visible={viewPdf.active}
          onCancel={() =>
            setViewPdf({
              active: false,
              url: ''
            })
          }
          onOk={() =>
            setViewPdf({
              active: false,
              url: ''
            })
          }
        >
          <iframe
            title="pdf-view"
            width={350}
            height={550}
            src={viewPdf.url}
          ></iframe>
        </Modal>
      </>
    </>
  );
};

Prescription.propTypes = {
  order: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
};

export default Prescription;
