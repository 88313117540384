const TYPE = 'warning';

export const messageError = {
  sameAssistants: {
    type: TYPE,
    message: 'Ya existe algun almacenista en otro proco'
  },
  withoutAssistan: {
    type: TYPE,
    message: 'Debe incluir al menos 1 almacenista'
  },
  sameProcosName: {
    type: TYPE,
    message: 'Ya existe algun proco con este nombre'
  },
  withoutName: {
    type: TYPE,
    message: 'Debe incluir el nombre del proco'
  }
};
