import React, { useCallback } from 'react';
import { getUserAddress } from 'firebase/api';
import { Select, Space } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setAddress, updateStep } from 'stores/actions/checkout';
import { CheckOutlined } from '@ant-design/icons';
import { getSteps } from 'stores/selectors/checkout';
import { checkoutSteps } from '../constants';
import styles from '../CreateOrder.module.scss';

function AddressForm({ userId }) {
  const [userAddress, setUserAddress] = React.useState([]);
  const dispatch = useDispatch();
  const steps = useSelector(getSteps);

  const onChange = useCallback(
    (value) => {
      const addressFound = userAddress.filter((d) => d.id === value)[0];
      if (addressFound) {
        dispatch(setAddress(addressFound));
        dispatch(updateStep({ mode: 'add', stepName: checkoutSteps[1] }));
      } else {
        dispatch(updateStep({ mode: 'remove', stepName: checkoutSteps[1] }));
      }
    },
    [userAddress, dispatch]
  );

  const getUserAddresses = async () => {
    const res = await getUserAddress(userId);
    setUserAddress(res);
  };

  React.useEffect(() => {
    if (userId) getUserAddresses();
    // eslint-disable-next-line
  }, []);

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        flexDirection: 'column'
      }}
    >
      <p className={styles.titles}>
        Direccion de entrega
        {steps.includes(checkoutSteps[1]) && (
          <CheckOutlined
            className={[
              styles.billingDataSaved,
              styles.appearingAnimation
            ].join(' ')}
          />
        )}
      </p>
      <Select
        showSearch
        placeholder="Selecciona una dirección"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onChange}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={userAddress.map((address) => ({
          label: address.alias,
          value: address.id
        }))}
      />
    </Space>
  );
}

AddressForm.propTypes = {
  userId: PropTypes.string
};

AddressForm.defaultProps = {
  userId: ''
};

export default AddressForm;
