import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const getRoutes = createSelector(selectSelf, (state) => state.routes);

export const getRoutesData = createSelector(getRoutes, (routes) => routes.data);

export const isRoutesLoading = createSelector(
  getRoutes,
  (routes) => routes.loading
);

export const hasRoutesError = createSelector(
  getRoutes,
  (routes) => routes.error
);

export const hasRoutesData = createSelector(
  getRoutesData,
  (routes) => routes.length > 0
);

export const isRoutesLoaded = createSelector(
  isRoutesLoading,
  hasRoutesData,
  (isLoading) => !isLoading
);
