import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from '../../firebase';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const createMail = createAsyncThunk(
  '@mail/createMail',
  async (data) => {
    return await firebase.createMail(data);
  }
);

export const getTemplates = createAsyncThunk(
  '@mail/getTemplates',
  async () => {
    return await firebase.getTemplates();
  }
);

export const MailSlide = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    clearTemplates: (state) => {
      state.data = []
    }
  },
  extraReducers: {
    [createMail.pending]: (state) => {
      state.loading = true;
    },
    [createMail.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createMail.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getTemplates.pending]: (state) => {
      state.loading = true;
    },
    [getTemplates.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getTemplates.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { clearTemplates } = MailSlide.actions

export default MailSlide.reducer;
