import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const announcementsConfig = createSelector(selectSelf, (state) => state.announcementsConfig);

export const announcementsData = createSelector(
  announcementsConfig,
  (record) => record.data || []
);

export const announcementsConfigLoading = createSelector(
  announcementsConfig,
  (state) => state.loading || false
)
