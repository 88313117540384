import React, { useCallback, useState } from 'react';
import ReactExport from 'react-data-export';
import { Col, Row, DatePicker, Button } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { sumBy } from 'lodash-es';
import useDisclosure from 'hooks/useDisclousure';
import useAsyncDownload from 'hooks/useAsyncDownload';
import { getRoadmapsData } from 'stores/selectors/roadmaps';
import { getRidersData } from 'stores/selectors/riders';
import { ordersApi } from 'stores/query/ordersQuery';
import { ridersReportFactory } from './utils';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const RidersReports = () => {
  const {
    isOpen: fetching,
    onOpen: fetchingOn,
    onClose: fetchingOff
  } = useDisclosure();
  const { isReady, asyncDownloadToggle } = useAsyncDownload();
  const dispatch = useDispatch();

  const roadmaps = useSelector(getRoadmapsData);
  const riders = useSelector(getRidersData);

  const constructRidersData = (dates, ridersData) =>
    ridersData.map((row) => {
      let { assignedOrders } = row;
      let { assignedRoadmaps } = row;
      if (dates) {
        const from = dates[0] && moment(dates[0]);
        const to = dates[1] && moment(dates[1]);

        assignedOrders = assignedOrders.filter((order) => {
          const date = moment(
            order.odooDataOrder?.create_date.substring(0, 10)
          );
          return date.isBetween(from, to, 'days', '[]');
        });

        assignedRoadmaps = assignedRoadmaps.filter((roadmap) => {
          const date = moment(roadmap.createdAt);
          return date.isBetween(from, to, 'days', '[]');
        });
      }
      const assigned = assignedOrders.length;
      const delivered = assignedOrders.filter(
        (order) => order.statusCode === 3
      ).length;
      const canceled = assignedOrders.filter(
        (order) => order.statusCode === 7
      ).length;
      const deliverPercentage = `${(delivered / assigned || 0 * 100).toFixed(
        2
      )}%`;
      const canceledPercentage = `${(canceled / assigned || 0 * 100).toFixed(
        2
      )}%`;
      const distance = `${sumBy(assignedRoadmaps, 'totalTraveledKms') || 0} Km`;

      return {
        rider: row.rider.name,
        dni: row.rider.dni,
        proco: row.proco || '',
        assigned,
        delivered,
        canceled,
        deliverPercentage,
        canceledPercentage,
        distance
      };
    });

  const [filteredRidersData, setFilteredRidersData] = useState([]);
  const [datePickerFilter, setDatePickerFilter] = useState([]);
  const hasFilters = datePickerFilter.length === 2;

  const handleChange = (dates) => {
    if (!dates) {
      setDatePickerFilter([]);
      return;
    }

    setDatePickerFilter([
      moment(dates[0]?.toString()),
      moment(dates[1]?.toString())
    ]);
  };

  const getColumns = () => [
    {
      title: 'Rider',
      key: 'rider',
      dataIndex: 'rider'
    },
    {
      title: 'CI',
      key: 'dni',
      dataIndex: 'dni'
    },
    {
      title: 'Proco',
      key: 'proco',
      dataIndex: 'proco'
    },
    {
      title: 'Asignado',
      key: 'assigned',
      dataIndex: 'assigned'
    },
    {
      title: 'Entregado',
      key: 'delivered',
      dataIndex: 'delivered'
    },
    {
      title: 'Devoluciones',
      key: 'canceled',
      dataIndex: 'canceled'
    },
    {
      title: '% de Entregas',
      key: 'deliverPercentage',
      dataIndex: 'deliverPercentage'
    },
    {
      title: '% de Devoluciones',
      key: 'canceledPercentage',
      dataIndex: 'canceledPercentage'
    },
    {
      title: 'Km recorrido',
      key: 'distance',
      dataIndex: 'distance'
    }
  ];

  const border = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' }
  };
  const alignment = { horizontal: 'center', wrapText: true };

  const dataSet = [
    {
      columns: [
        {
          title: 'Fecha',
          style: {
            fill: { fgColor: { rgb: 'FFC3D69B' } },
            font: { bold: true },
            alignment,
            border: {
              top: { style: 'thin' },
              bottom: { style: 'thin' },
              left: { style: 'thin' },
              right: { style: 'thin', color: { rgb: 'FFC3D69B' } }
            }
          }
        },
        {
          title: '',
          style: {
            fill: { fgColor: { rgb: 'FFC3D69B' } },
            border: {
              top: { style: 'thin' },
              bottom: { style: 'thin' },
              left: { style: 'thin', color: { rgb: 'FFC3D69B' } },
              right: { style: 'thin' }
            }
          }
        }
      ],
      data: [
        [
          {
            value: 'Del',
            style: {
              fill: { fgColor: { rgb: 'FFC3D69B' } },
              font: { bold: true },
              alignment,
              border
            }
          },
          {
            value:
              datePickerFilter.length > 0
                ? moment(datePickerFilter[0]).format('DD-MM-YYYY')
                : '',
            style: { border, alignment }
          }
        ],
        [
          {
            value: 'Hasta',
            style: {
              fill: { fgColor: { rgb: 'FFC3D69B' } },
              font: { bold: true },
              alignment,
              border
            }
          },
          {
            value:
              datePickerFilter.length > 0
                ? moment(datePickerFilter[1]).format('DD-MM-YYYY')
                : '',
            style: { border, alignment }
          }
        ]
      ]
    },
    {
      ySteps: 1,
      columns: getColumns().map((column) => ({
        title: column.title,
        width: { wch: column.title.length + 10 },
        style: {
          fill: { fgColor: { rgb: 'FFC3D69B' } },
          font: { bold: true },
          alignment,
          border
        }
      })),
      data: filteredRidersData.map((row) =>
        Object.values(row).map((value) => ({
          value,
          style: {
            alignment,
            border
          }
        }))
      )
    }
  ];

  const handleDownload = useCallback(async () => {
    if (datePickerFilter.length !== 2) return;
    fetchingOn();
    const selectedFrom = new Date(datePickerFilter[0]).setHours(0, 0, 0, 0);
    const selectedTo = new Date(datePickerFilter[1]).setHours(23, 59, 59, 59);
    const from = new Date(selectedFrom);
    const to = new Date(selectedTo);
    const { data: orders } =
      (await dispatch(
        ordersApi.endpoints.ordersByRange.initiate({ from, to })
      )) || {};
    const ridersData = ridersReportFactory(roadmaps, riders, orders);
    const ridersDataBuilded = constructRidersData(datePickerFilter, ridersData);
    setFilteredRidersData(ridersDataBuilded);
    fetchingOff();
    asyncDownloadToggle();
  }, [
    datePickerFilter,
    dispatch,
    roadmaps,
    riders,
    fetchingOn,
    fetchingOff,
    asyncDownloadToggle
  ]);

  return (
    <>
      <Row>
        <Col span={6}>
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            placeholder={['from', 'to']}
            onChange={(e) => handleChange(e)}
            ranges={{
              '2 days': [
                moment().subtract(2, 'days').startOf('day'),
                moment().endOf('day')
              ]
            }}
            value={datePickerFilter}
            allowClear
          />
        </Col>
        <Col span={6}>
          <Button
            disabled={!hasFilters}
            loading={fetching}
            onClick={() => handleDownload()}
          >
            Descargar Reporte
          </Button>
          {isReady && (
            <ExcelFile hideElement filename="Reporte Riders">
              <ExcelSheet dataSet={dataSet} name="Riders" />
            </ExcelFile>
          )}
        </Col>
      </Row>
    </>
  );
};

export default RidersReports;
