import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const GoogleMapsContext = createContext(null);

const GoogleMapsProvider = ({ children }) => {
  const [map, setMap] = useState(null);

  return (
    <GoogleMapsContext.Provider
      value={{
        map,
        setMap
      }}
    >
      {children}
    </GoogleMapsContext.Provider>
  );
};

GoogleMapsProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default GoogleMapsProvider;
