import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from '../../firebase';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getCategoryProduct = createAsyncThunk(
  '@categoryProduct/get',
  async () => firebase.getCategoriesProducts()
);

export const createCategoryProduct = createAsyncThunk(
  '@categoryProduct/create',
  async (data) => firebase.createCategoryProduct(data)
);

export const updateCategoryProduct = createAsyncThunk(
  '@categoryProduct/update',
  async ({ id, data }) => firebase.updateCategoryProduct(id, data)
);

export const deleteCategoryProduct = createAsyncThunk(
  '@categoryProduct/delete',
  async (id) => firebase.deleteCategoryProduct(id)
);

export const categoryProductSlide = createSlice({
  name: 'categoryProduct',
  initialState,
  reducers: {
    setCategoryProductLoading: (state) => {
      state.loading = true;
    },
    setCategoryProduct: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setCategoryProductError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getCategoryProduct.pending]: (state) => {
      state.loading = true;
    },
    [getCategoryProduct.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getCategoryProduct.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createCategoryProduct.pending]: (state) => {
      state.loading = true;
    },
    [createCategoryProduct.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createCategoryProduct.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteCategoryProduct.pending]: (state) => {
      state.loading = true;
    },
    [deleteCategoryProduct.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteCategoryProduct.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateCategoryProduct.pending]: (state) => {
      state.loading = true;
    },
    [updateCategoryProduct.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateCategoryProduct.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setCategoryProductLoading,
  setCategoryProduct,
  setCategoryProductError
} = categoryProductSlide.actions;

export default categoryProductSlide.reducer;
