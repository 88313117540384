import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, Space } from 'antd';

const RemoveProductsForm = ({
  products = [],
  active,
  onClose,
  handleDeleteProducts
}) => {
  const [form] = Form.useForm();
  const [fetching, setFetching] = useState(false);

  const onFinish = async (data) => {
    let body = [];
    if (data) {
      body = Object.entries(data).map((element) => ({
        id: Number(element[0]),
        reason: element[1]
      }));
    }
    setFetching(true);
    await handleDeleteProducts(body);
    setFetching(false);
  };

  const initialValues = products.reduce((prev, next) => {
    const key = next.id;
    return { ...prev, [key]: '' };
  }, {});

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Productos a remover"
      visible={active}
      width={600}
      footer={null}
      closable={false}
    >
      <Form
        form={form}
        name="removeProducts"
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        preserve={false}
      >
        {products.map((product) => (
          <Form.Item
            key={product.id}
            label={product.name}
            name={product.id}
            rules={[{ required: true, message: 'Ingrese motivo' }]}
          >
            <Input.TextArea />
          </Form.Item>
        ))}
        <Form.Item>
          <Space>
            <Button disabled={fetching} danger type="primary" htmlType="submit">
              Remover productos
            </Button>
            <Button
              disabled={fetching}
              type="primary"
              onClick={() => handleClose()}
            >
              Cancelar
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

RemoveProductsForm.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleDeleteProducts: PropTypes.func.isRequired
};

export default RemoveProductsForm;
