// PRODUCTS
export const GET_PRODUCTS_ODOO = 'odoo-getProducts';
export const GET_PRODUCT_BY_ID = 'odoo-getProduct';

// CATEGORIES
export const GET_CATEGORIES = 'odoo-getCategories';

// ADMINS
export const CREATE_ADMIN = 'admin-createAdmin';
export const DELETE_ADMIN = 'admin-deleteAdmin';

// USERS
export const CREATE_USER = 'users-createUser';

// DASHBOARD STATISTICS
export const GET_STATISTICS = 'admin-getStatistics';

// RIDERS
export const DELETE_RIDER = 'models-deleteRider';
export const CREATE_RIDER = 'riders-createRider';

// ODOO
export const GET_INVOICES = 'odoo-getInvoices';

export const CREATE_CLIENT = 'odoo-createClient';

// Banks
export const BANKS = 'bancos';

// MinMax
export const MIN_MAX = 'min_max';
export const REFERRED = 'referidos';
