import React from 'react';
import ErrorFallback from '.';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      messageError: ''
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, messageError: error.message };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback error={this.state.messageError} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
