export const centerCoords = { lat: 10.497139395230374, lng: -66.8852690251743 }; // Plaza Venezuela

export const riderCoordsExample = {
  lat: 10.496532821480224,
  lng: -66.82027274422039
};

export const farmaCoords = {
  lat: 10.499185123963777,
  lng: -66.81938110776017
};
