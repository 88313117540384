import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const getNotifications = createSelector(
  selectSelf,
  (state) => state.notification
);

export const getNotificationsData = createSelector(
  getNotifications,
  (notifications) =>
    notifications.data.map((notification, idx) => {
      return {
        ...notification,
        key: idx
      };
    }) || []
);

export const isNotificationsLoading = createSelector(
  getNotifications,
  (notifications) => notifications.loading
);

export const hasNotificationsError = createSelector(
  getNotifications,
  (notifications) => notifications.error
);
