import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const banners = createSelector(selectSelf, (state) => state.banners);

export const bannersData = createSelector(
  banners,
  (record) => record.data || []
);

export const bannersByModule = (module, target) =>
  createSelector(
    bannersData,
    (record) => record.find((item) => item.name === module && item.target === target) || {}
  );

export const bannersLoading = createSelector(
  banners,
  (record) => record.loading
);
