import React from 'react';
import { Tabs } from 'antd';

import useGetRidersHook from 'hooks/componentHooks/useGetRidersHook';
import OrdersReports from './OrdersReports';
import WalletReport from './WalletReport';
import RidersReports from './RidersReports';
import DeliveryOrdersReport from './DeliveryOrdersReport';
import PickingReport from './PickingReport';
import ReiReports from './ReiReport';

const Reports = () => {
  const { TabPane } = Tabs;
  const { getRidersY } = useGetRidersHook();

  /**
   * Get riders data
   */

  React.useEffect(() => {
    getRidersY();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Tabs>
        <TabPane tab="Orders" key="1">
          <OrdersReports />
        </TabPane>
        <TabPane tab="Riders" key="2">
          <RidersReports />
        </TabPane>
        <TabPane tab="Ordenes de Entrega" key="3">
          <DeliveryOrdersReport />
        </TabPane>
        <TabPane tab="Picking" key="4">
          <PickingReport />
        </TabPane>
        <TabPane tab="Wallet" key="5">
          <WalletReport />
        </TabPane>
        <TabPane tab="Rei" key="6">
          <ReiReports />
        </TabPane>
      </Tabs>
    </>
  );
};

export default Reports;
