import React from 'react';
import { DatePicker, Space } from 'antd';
import useRangeDateFilter from 'hooks/useRangeDateFilter';
import { dateFormatList } from 'utils/utils';

const RangeDateFilter = ({ hasDefault = false, ...props }) => {
  const { RangePicker } = DatePicker;
  const { range } = useRangeDateFilter();
  const defaultValue = hasDefault && {
    defaultValue: [range.defaultFrom, range.defaultTo]
  };

  return (
    <Space>
      <RangePicker {...props} format={dateFormatList} {...defaultValue} />
    </Space>
  );
};

export default RangeDateFilter;
