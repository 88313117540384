import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRealCartPrices as getRealCartPricesFB } from 'firebase/api';

export const initialState = {
  data: {
    prescription: false,
    subtotal: '0',
    taxes: '0.00',
    total: '0',
    delivery: '0',
    ref: '0',
    descuento: 0,
    products2: [],
    discount: '0',
    discountRef: '0'
  },
  loading: false,
  error: false
};

export const getRealCartPrices = createAsyncThunk(
  '@getRealPrices/getRealCartPrices',
  async (email) => {
    const cartValidation = await getRealCartPricesFB(email);
    return cartValidation;
  }
);

export const cartValidationSlide = createSlice({
  name: 'cartValidation',
  initialState,
  reducers: {
    cleanCart: () => {
      return { ...initialState };
    }
  },
  extraReducers: {
    [getRealCartPrices.pending]: (state) => {
      state.loading = true;
    },
    [getRealCartPrices.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getRealCartPrices.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { cleanCart } = cartValidationSlide.actions;

export default cartValidationSlide.reducer;
