import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment
} from 'react';
import useSetHeaderProps from 'hooks/useSetHeaderProps';

import { Row, Col, Table, Form, Input, Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { showNotification } from 'stores/actions/notification';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCommissionsConfig,
  updateCommissionConfig,
  createCommissionConfig,
  deleteCommissionConfig
} from 'stores/actions/commissionsConfig';
import {
  getCommissionsConfigData,
  isCommissionsConfigLoading
} from 'stores/selectors/commissionsConfig';
import { openModal, closeModal } from 'stores/actions/modal';
import CommissionsForm from './CommissionsForm';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatPriceToDollar } from 'utils/utils';
import Can from 'components/Can';
import { COMMISSIONS } from 'components/Can/modules';

const Commissions = () => {
  const setHeaderProps = useSetHeaderProps();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const EditableContext = React.createContext(null);

  const isLoading = useSelector(isCommissionsConfigLoading);
  const resCommissions = useSelector(getCommissionsConfigData);

  const [commissions, setCommissions] = useState([]);

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex]
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        dispatch(
          showNotification({
            type: 'warning',
            message: 'warning',
            content: errInfo
          })
        );
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`
            }
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };
  const fetchCommissions = useCallback(async () => {
    await dispatch(getCommissionsConfig());
  }, [dispatch]);

  const columns = [
    {
      title: `${t('pages.commissions.table.from')} (Km)`,
      dataIndex: 'from',
      editable: true,
      width: '20%'
    },
    {
      title: `${t('pages.commissions.table.to')} (Km)`,
      dataIndex: 'to',
      editable: true,
      width: '20%'
    },
    {
      title: t('pages.commissions.table.commission'),
      dataIndex: 'commission',
      editable: true,
      render: (value) => formatPriceToDollar(value)
    },
    {
      title: t('pages.commissions.table.created'),
      dataIndex: 'createAt',
      render: (element) => {
        const date = new Date(element?.seconds * 1000);
        const createAt = moment(date).format('DD-MM-YYYY HH:mm');
        if (element) {
          return <>{createAt}</>;
        }
      }
    },
    {
      title: t('pages.commissions.table.action'),
      align: 'center',
      render: (_, record) =>
        commissions.length >= 1 && (
          <>
            <Can I="delete" a={COMMISSIONS}>
              <Popconfirm
                title={t('pages.commissions.messages.delete')}
                onConfirm={() => handleDelete(record.id)}
              >
                <DeleteOutlined />
              </Popconfirm>
            </Can>
          </>
        )
    }
  ];

  const getColumns = () => {
    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handlerSave
        })
      };
    });
  };

  const handlerSave = useCallback(
    async (row) => {
      try {
        if (row.hasOwnProperty('id')) {
          await dispatch(updateCommissionConfig(row));
        } else {
          await dispatch(createCommissionConfig(row));
        }
        fetchCommissions();
      } catch (error) {
        dispatch(
          showNotification({
            type: 'error',
            message: 'Error',
            content: error.message
          })
        );
      }
    },
    [dispatch, fetchCommissions]
  );

  const handlerAdd = useCallback(() => {
    const modalContent = {
      component: CommissionsForm,
      props: {
        onCancel: () => dispatch(closeModal()),
        specs: 'create',
        onSubmit: (values) => handlerSave(values)
      }
    };

    dispatch(
      openModal({
        content: modalContent,
        buttons: []
      })
    );
  }, [dispatch, handlerSave]);

  const handleDelete = useCallback(
    async (key) => {
      await dispatch(deleteCommissionConfig(key));
      fetchCommissions();
    },
    [dispatch, fetchCommissions]
  );

  useEffect(() => {
    setHeaderProps({
      showBackArrow: false,
      extraButtons: [
        <Fragment key={0}>
          <Can I="create" a={COMMISSIONS}>
            <Button
              onClick={handlerAdd}
              type="primary"
              style={{ marginBottom: 16 }}
            >
              {t('pages.commissions.button.create')}
            </Button>
          </Can>
        </Fragment>
      ]
    });
  }, [setHeaderProps, t, handlerAdd]);

  useEffect(() => {
    fetchCommissions();
  }, [fetchCommissions]);

  useEffect(() => {
    if (!isLoading) {
      setCommissions(resCommissions);
    }
  }, [resCommissions, isLoading]);

  return (
    <Row>
      <Col span={24}>
        <Table
          columns={getColumns()}
          loading={isLoading}
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell
            }
          }}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={commissions}
          pagination={{
            total: (commissions && commissions.length) || 0,
            showSizeChanger: true
          }}
        />
      </Col>
    </Row>
  );
};

export default Commissions;
