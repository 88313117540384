import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  payments: [],
  count: 0,
  hasCountInStore: false
};

export const paymentsIncomingSlice = createSlice({
  name: 'paymentsIncoming',
  initialState,
  reducers: {
    setPaymentsIncoming: (state, action) => {
      state.payments = action.payload;

      if (!state.hasCountInStore) {
        state.hasCountInStore = true;
        state.count = state.payments.length;
      }
    },
    resetPaymentsIncomingCount: (state) => {
      state.count = state.payments.length;
    }
  },
  extraReducers: {}
});

export const { setPaymentsIncoming, resetPaymentsIncomingCount } =
  paymentsIncomingSlice.actions;

export default paymentsIncomingSlice.reducer;
