import { Col, Descriptions, Row } from 'antd';
import OrderStatusBadge from 'components/OrderStatusBadge';
import PaymentType from 'components/PaymentType/PaymentType';
import moment from 'moment';
import React from 'react';
import { formatCash } from 'utils/utils';

const OrderDetail = ({ data }) => {
  const order = data[0] || {};
  const { name, state, date, amount_total = 0 } = order || {};
  const dateParsed = (date && moment(date.toDate()).format('DD-MM-YYYY')) || '';
  if (Object.keys(order).length === 0) return null;

  return (
    <Row>
      <Col span={12}>
        <Descriptions>
          <Descriptions.Item label="Orden">{name}</Descriptions.Item>
          <Descriptions.Item label="Estado">
            {<OrderStatusBadge status={state} />}
          </Descriptions.Item>
          <Descriptions.Item label="Fecha">{dateParsed}</Descriptions.Item>
          <Descriptions.Item label="Tipo de pago">
            {<PaymentType data={order} />}
          </Descriptions.Item>
          <Descriptions.Item label="Monto total">
            {formatCash(amount_total)}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default OrderDetail;
