import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Image,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Form,
  Input,
  Popconfirm,
  Tag,
  Button,
  DatePicker
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getProductCategoriesToBanners } from 'stores/selectors/products';
import {
  CheckOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  DragOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { FcVideoFile } from 'react-icons/fc';
import { CSS } from '@dnd-kit/utilities';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import {
  forceUpdateBannersInLocal,
  updateBannerWithoutFeedback
} from 'stores/actions/banners';
import UploadBanner from './UploadBanner';

const { Option } = Select;
const { TabPane } = Tabs;
const EditableContext = React.createContext(null);

const BannerManager = ({
  handleModule,
  module,
  handleImagePreview,
  handleTabs,
  imagesLoadedByModule,
  handleSaveImage,
  handleSaveDataFile,
  imageTabType,
  handleRemoveImage,
  handleUploadFile
}) => {
  const categories = useSelector(getProductCategoriesToBanners);
  const modulesOption = categories;
  const images_type = ['Carousel'];
  const dispatch = useDispatch();
  const [currentModule, setcurrentModule] = useState('');
  const [AllowChangeOrder, setAllowChangeOrder] = useState(false);

  const renderImageTable = (bannerType, currentModule, allowChangeOrder) => {
    const EditableRow = ({ index, ...props }) => {
      const [form] = Form.useForm();
      const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
      } = useSortable({
        id: props['data-row-key']
      });
      const style = {
        transform: CSS.Transform.toString(
          transform && {
            ...transform,
            scaleY: 1
          }
        ),
        transition,
        cursor: props.allowChangeOrder ? 'move' : 'pointer',
        ...(isDragging
          ? {
              position: 'relative',
              zIndex: 9999
            }
          : {})
      };

      return (
        <Form form={form} component={false}>
          <EditableContext.Provider value={form}>
            <tr
              {...props}
              ref={setNodeRef}
              // @ts-ignore
              style={style}
              {...attributes}
              {...listeners}
            />
          </EditableContext.Provider>
        </Form>
      );
    };

    const EditableCell = ({
      title,
      editable,
      children,
      dataIndex,
      record,
      handleSave,
      ...restProps
    }) => {
      const [editing, setEditing] = useState(false);
      const inputRef = useRef(null);
      const form = useContext(EditableContext);
      useEffect(() => {
        if (editing) {
          inputRef.current.focus();
        }
      }, [editing]);

      const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
          [dataIndex]: record[dataIndex]
        });
      };

      const save = async () => {
        try {
          const values = await form.validateFields();
          toggleEdit();
          handleSave({ ...record, ...values });
        } catch (errInfo) {
          console.log('Save failed:', errInfo);
        }
      };

      let childNode = children;
      const isEditable =
        (record?.src.search('firebase') === -1 && true) || false;

      if (editable) {
        childNode = editing ? (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
          >
            <Input
              disabled={isEditable}
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
            />
          </Form.Item>
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{
              paddingRight: 24
            }}
            onClick={toggleEdit}
          >
            {children}
          </div>
        );
      }

      return <td {...restProps}>{childNode}</td>;
    };

    const components = {
      body: {
        row: (props) => EditableRow({ ...props, allowChangeOrder }),
        cell: EditableCell
      }
    };
    const imageLoadedType = imagesLoadedByModule[bannerType] || [];

    if (imageLoadedType.length === 0) return;
    const columns = [
      {
        title: 'Image',
        dataIndex: 'src',
        width: 100,
        align: 'center',
        render: (src) => {
          const isVideo = src.match(/mp4/g)?.length > 0 || false;
          return isVideo ? (
            <Tag icon={<FcVideoFile />} color="#cd201f" />
          ) : (
            <Image src={src} />
          );
        }
      },
      {
        title: 'Alt',
        dataIndex: 'alt',
        editable: true,
        width: 200
      },
      {
        title: 'Link',
        dataIndex: 'link',
        editable: true,
        width: 100
      },
      {
        title: 'Link Externo',
        dataIndex: 'externalLink',
        editable: true,
        width: 100
      },
      {
        title: 'Fecha de vencimiento',
        dataIndex: 'endDate',
        width: 100,
        render: (_, element) => (
          <Space>
            <DatePicker
              placeholder={element?.endDate}
              onChange={(date, dateString) => {
                const body = { ...element, endDate: dateString };
                handleSaveDataFile(body);
              }}
            />
          </Space>
        )
      },
      {
        title: 'Action',
        width: 100,
        align: 'center',
        render: (_, element) => (
          <Space>
            <EyeOutlined
              onClick={() => handleImagePreview('src', element?.src)}
            />
            <Popconfirm
              title="Are you sure to remove this image?"
              onConfirm={() => handleRemoveImage(element)}
              okText="Yes"
              cancelText="No"
            >
              <CloseOutlined />
            </Popconfirm>
            {element?.src.search('firebase') === -1 && (
              <CheckOutlined onClick={() => handleSaveImage(element)} />
            )}
          </Space>
        )
      }
    ];
    const getColumns = () =>
      columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: handleSaveDataFile
          })
        };
      });
    const dataTable = imageLoadedType;

    const data = dataTable.map((record, idx) => ({
      ...record,
      alt: !record?.alt ? '-' : record?.alt,
      link: !record?.link ? '-' : record?.link,
      externalLink: !record?.externalLink ? '-' : record?.externalLink,
      endDate: !record?.endDate ? '-' : record?.endDate,
      key: idx
    }));

    const onDragEnd = async ({ active, over }) => {
      if (active.id !== over?.id) {
        const activeIndex = active.id;
        const overIndex = over?.id;
        const newBannerPositions = arrayMove(data, activeIndex, overIndex);
        const newImageLoadedByModule = {
          ...imagesLoadedByModule,
          carousel: newBannerPositions
        };
        dispatch(forceUpdateBannersInLocal(newImageLoadedByModule));
        await dispatch(
          updateBannerWithoutFeedback({
            id: newImageLoadedByModule.id,
            body: newImageLoadedByModule
          })
        );
      }
    };

    // eslint-disable-next-line consistent-return
    return (
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          items={data.map((d) => d.key)}
          strategy={verticalListSortingStrategy}
          disabled={!allowChangeOrder}
        >
          <Table
            components={components}
            rowClassName={() => 'editable-row'}
            // @ts-ignore
            columns={getColumns()}
            dataSource={data}
            rowKey="key"
            scroll={{ y: 240 }}
          />
        </SortableContext>
      </DndContext>
    );
  };

  const onChangeModule = (_, element) => {
    setcurrentModule(element);
    handleModule(element);
  };

  return (
    <>
      <Card>
        <Row gutter={[24, 16]}>
          <Col>
            <Space size="middle" direction="horizontal">
              <Select
                onChange={onChangeModule}
                placeholder="Select a module"
                style={{ width: 200 }}
                value={module.children}
              >
                {modulesOption.map((module, idx) => {
                  const label = module.title.toLowerCase();
                  return (
                    <Option value={module.value} key={idx}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
              <UploadBanner
                module={module}
                handleUploadFile={handleUploadFile}
              />
              {imageTabType === 'Carousel' && (
                <Button
                  type={AllowChangeOrder ? 'primary' : 'dashed'}
                  onClick={() => setAllowChangeOrder(!AllowChangeOrder)}
                  icon={
                    AllowChangeOrder ? (
                      <CloseSquareOutlined />
                    ) : (
                      <DragOutlined />
                    )
                  }
                >
                  {AllowChangeOrder
                    ? 'Cancelar modificar orden'
                    : 'Modificar orden'}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <Tabs
              activeKey={imageTabType}
              onChange={(e) => handleTabs(e)}
              tabPosition="left"
            >
              {images_type.map((item) => (
                <TabPane tab={item} key={item}>
                  {renderImageTable(
                    item.toLowerCase(),
                    currentModule,
                    AllowChangeOrder
                  )}
                </TabPane>
              ))}
            </Tabs>
          </Col>
        </Row>
      </Card>
    </>
  );
};

BannerManager.propTypes = {
  handleModule: PropTypes.func.isRequired,
  module: PropTypes.object.isRequired,
  handleImagePreview: PropTypes.func.isRequired,
  handleTabs: PropTypes.func.isRequired,
  imagesLoadedByModule: PropTypes.object,
  handleSaveImage: PropTypes.func.isRequired,
  handleSaveDataFile: PropTypes.func,
  imageTabType: PropTypes.string,
  handleRemoveImage: PropTypes.func,
  handleUploadFile: PropTypes.func
};

export default BannerManager;
