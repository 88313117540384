import 'firebase/messaging';
import { getFunctions } from 'firebase/functions';

import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// import { initializeAppCheck, ReCaptchaV3Provider } from '@firebase/app-check';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

const firebaseApp = initializeApp(config);
// const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider(process.env.REACT_APP_APP_CHECK_API_KEY)
// });
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const auth = getAuth();
const { currentUser } = auth;
const region = process.env.REACT_APP_DEPLOYMENT_REGION;
const functions = getFunctions(firebaseApp, region);

export {
  db,
  storage,
  ref,
  getDownloadURL,
  firebaseApp,
  currentUser,
  auth,
  functions
};
