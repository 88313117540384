import { Col, Row, Table, Button, Drawer, Divider } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useFirebaseContext from 'hooks/useFirebaseContext';
import {
  clearCheckoutState,
  clearErrors,
  createOrder
} from 'stores/actions/checkout';
import {
  getSteps,
  orderSuccessful,
  orderCreated,
  orderErrors
} from 'stores/selectors/checkout';
import { cartPrices as totalCartData } from 'stores/selectors/cartValidation';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { getDateFromTimestamp } from 'utils/utils';
import { showNotification } from 'stores/actions/notification';
import { cleanShoppingCartToUser } from 'firebase/api';
import AddressForm from './Address/AddressForm';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import BillingData from './BillingData/BillingData';
import styles from './CreateOrder.module.scss';

const CreateOrder = ({
  searchResults,
  cart,
  columns,
  dataSource,
  user,
  shoppingCartId,
  cleanSearchFilter
}) => {
  const [open, setOpen] = React.useState(false);
  const firebase = useFirebaseContext();
  const dispatch = useDispatch();
  const step = useSelector(getSteps);
  const success = useSelector(orderSuccessful);
  const clientOrder = useSelector(orderCreated);
  const orderData = clientOrder?.createdOrder;
  const cartData = useSelector(totalCartData);
  const errors = useSelector(orderErrors);
  const [loadings, setLoadings] = React.useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const cleanState = () => {
    dispatch(clearCheckoutState());
  };

  const showDrawer = () => {
    setOpen(true);
    cleanState();
  };
  const onClose = () => {
    setOpen(false);
    cleanSearchFilter();
    cleanState();
  };

  const handleNext = React.useCallback(
    async () => {
      enterLoading(0);
      const order = await dispatch(createOrder(firebase));
      if (order.payload.status === 200) {
        await cleanShoppingCartToUser(user?.id, shoppingCartId?.id, {
          products: []
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firebase, shoppingCartId, user]
  );

  useEffect(() => {
    if (errors) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: errors?.message
        })
      );
      setTimeout(() => {
        dispatch(clearErrors());
      }, 3000);
    }
  }, [errors, dispatch]);

  return (
    <>
      <Button
        type="primary"
        onClick={showDrawer}
        disabled={
          !cartData ||
          cartData?.data?.data?.ref === '0' ||
          cartData?.data?.data?.total === '0'
        }
      >
        Crear Orden
      </Button>
      <Drawer
        title={`Crear Orden para ${searchResults.name}`}
        placement="right"
        onClose={onClose}
        size="large"
        visible={open}
        width={1024}
      >
        {success && (
          <div className={styles.successView}>
            <div className={styles.box}>
              <CheckCircleTwoTone className={styles.icon} />
              <p className={styles.message}>Orden creada exitosamente!</p>
              <div className={styles.card}>
                <div className={styles.col}>
                  <p>Numero PIN:</p>
                  <p>Numero de orden:</p>
                  <p>Comprador:</p>
                  <p>Fecha:</p>
                  {orderData?.cashBack !== null && <p>Vuelto:</p>}
                </div>
                <div className={styles.col2}>
                  <p>{orderData?.pin}</p>
                  <p>{orderData?.order}</p>
                  <p>{user?.name}</p>
                  <p>
                    {getDateFromTimestamp(new Date().getTime()).date} |{' '}
                    {getDateFromTimestamp(new Date().getTime()).hour}
                  </p>
                  {orderData?.cashBack !== null && (
                    <p>{orderData?.cashBack} Bs.</p>
                  )}
                </div>
              </div>
              <Button
                type="primary"
                onClick={onClose}
                className={styles.newOrderButton}
              >
                Crear otra orden
              </Button>
            </div>
          </div>
        )}
        {!success && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  scroll={{ y: 240 }}
                  pagination={false}
                  rowKey="id"
                />
              </Col>
              <Col>
                <p style={{ marginBottom: '0', fontWeight: 'bold' }}>
                  Precio total del carrito: {`${cart.data?.data?.total} BS`}
                </p>
              </Col>
              <Col>
                <p
                  style={{
                    marginBottom: '0',
                    fontWeight: 'bold',
                    paddingLeft: '5px'
                  }}
                >
                  Precio total REF del carrito: {` $ ${cart.data?.data?.ref}`}
                </p>
              </Col>
            </Row>
            <Divider />
            <BillingData user={user} />
            <Divider />
            <AddressForm userId={searchResults.id} />
            <Divider />
            <PaymentMethods userId={searchResults.id} totalInCart={cart} />
            <Divider />
            <Button
              type="primary"
              loading={loadings[0]}
              onClick={handleNext}
              disabled={step.length < 3}
            >
              Crear Orden
            </Button>
          </>
        )}
      </Drawer>
    </>
  );
};

CreateOrder.propTypes = {
  searchResults: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  shoppingCartId: PropTypes.object,
  cleanSearchFilter: PropTypes.func.isRequired
};

export default CreateOrder;
