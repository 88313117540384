import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { Form, Input, Button, Select } from 'antd';
import useGetWarehouseman from 'hooks/useGetWarehouseman';
const { Option } = Select;

const ProcoStorageForm = ({ onSubmit, warehouseman = {} }) => {
  const { data: warehousemanData } = useGetWarehouseman();

  const [form] = Form.useForm();

  const hasProco = Object.keys(warehouseman).length > 0;

  const initialState = hasProco
    ? warehouseman
    : {
        name: '',
        users: []
      };

  const onFinish = async (values) => {
    const newWareHouseProco = hasProco
      ? { id: warehouseman.id, ...values }
      : { ...values, type: 'almacen' };
    await onSubmit(newWareHouseProco);
  };

  return (
    <Form
      form={form}
      name="warehouse"
      onFinish={onFinish}
      scrollToFirstError
      labelCol={{ xs: { span: 24 }, sm: { span: 8 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
      initialValues={initialState}
    >
      <Form.Item name="name" label="Nombre">
        <Input />
      </Form.Item>

      <Form.Item name="users" label="Almacenista">
        <Select
          mode="multiple"
          placeholder="Seleccione almacenista"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          allowClear
          showSearch
        >
          {warehousemanData?.map((rider) => (
            <Option value={rider?.id}>{rider?.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 15, span: 22 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

ProcoStorageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  warehouseman: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

ProcoStorageForm.defaultProps = {
  onCancel: noop
};

export default ProcoStorageForm;
