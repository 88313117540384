import React, { useEffect, useRef } from 'react';
import { Col, Descriptions, Empty, Form, Input, Row, Space, Tag } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';

const RoadmapAssignScan = ({ riders, setSelectedRider, selectedRider }) => {
  const { Search } = Input;
  const riderRef = useRef(null);

  const handlerFindRider = (values) => {
    const riderId = values.target.value.trim();
    const riderFound = riders.find((rider) => rider.uid === riderId);

    const isValid = riderFound !== undefined;

    if (isValid) {
      setSelectedRider(riderFound);
    } else {
      setSelectedRider('');
    }
  };

  useEffect(() => {
    riderRef.current.focus();
  }, []);

  return (
    <>
      <Form
        name="riderScan"
        initialValues={{ riderId: '' }}
        onChange={handlerFindRider}
      >
        <Form.Item label="Rider" name="rider">
          <Search ref={riderRef} width="300px" size="large" />
        </Form.Item>
      </Form>
      {selectedRider ? (
        <Space size="large">
          <Row justify="center">
            <Col>
              <Avatar
                src={`https://ui-avatars.com/api/?name=${selectedRider.name}`}
                size={100}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Descriptions>
                <Descriptions.Item label="Nombre">
                  {selectedRider?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Dni">
                  {selectedRider?.dni}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {selectedRider?.status === 'free' ? (
                    <Tag color="success">{selectedRider?.status}</Tag>
                  ) : (
                    <Tag color="error">{selectedRider?.status}</Tag>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Space>
      ) : (
        <Empty />
      )}
    </>
  );
};

export default RoadmapAssignScan;
