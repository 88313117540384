import React, { useCallback, useContext } from 'react';
import { Button } from 'antd';
import LoginForm from 'components/Forms/LoginForm/LoginForm';
import { HOME, FORGOT_PASSWORD } from 'constants/routes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showNotification } from 'stores/actions/notification';
import { setProfile } from 'stores/actions/profile';
import { loadingWithSpinner, loaded } from 'stores/actions/loader';
import { getToken } from 'firebase/utils/token';
import { useTranslation } from 'react-i18next';
import { AbilityContext } from 'components/Can';
import { updateAbility } from 'components/Can/ability';
import {
  getDepartments,
  getPermissionsByRole,
  login,
  getProcoByUserId
} from 'firebase/api';
import image from '../../assets/images/logo2.svg';
import styles from './Login.module.scss';

const Login = () => {
  const history = useHistory();
  const ability = useContext(AbilityContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleFormSubmit = async (formFieldValues) => {
    const { email, password } = formFieldValues;
    try {
      dispatch(loadingWithSpinner());
      const res = await login(email, password);

      if (!res.hasOwnProperty('roleId')) throw new Error(res);

      const resRole = await getPermissionsByRole(res.roleId);
      const resDepartments = await getDepartments();

      const { name, permissions } = resRole;

      const department = resDepartments.find(
        (record) => record.id === res.departmentId
      );

      updateAbility(ability, permissions);

      const proco = await getProcoByUserId(res.id);

      dispatch(
        setProfile({
          ...res,
          department: department.name,
          isLogged: true,
          accessToken: getToken(),
          role: name,
          proco: proco[0] || undefined
        })
      );

      window.sessionStorage.setItem('userId', JSON.stringify(res.id));
      dispatch(loaded());
      history.push(HOME);
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
      dispatch(loaded());
    }
  };

  const handlerForgetPassword = useCallback(async () => {
    history.push(FORGOT_PASSWORD);
  }, [history]);

  return (
    <div className={styles.container_login}>
      <img src={image} className={styles.logo} alt="logo" />
      <div className={styles.form_paper}>
        <LoginForm onSubmit={handleFormSubmit} />
      </div>
      <Button
        onClick={handlerForgetPassword}
        type="link"
        className={styles.button_forgot}
      >
        {t('auth.loginPage.forgot')}
        <span className={styles.span_password}>
          {t('auth.loginPage.password')}
        </span>
      </Button>
    </div>
  );
};

export default Login;
