import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Upload
} from 'antd';
import { GoTrashcan } from 'react-icons/go';

import { EditableCell, EditableRow } from 'components/Editables';
import { UploadOutlined } from '@ant-design/icons';
import styles from './AnnouncementConfig.module.scss';

const DEVICES = [
  {
    label: 'Responsive',
    value: 'responsive'
  },
  {
    label: 'App',
    value: 'app'
  },
  {
    label: 'Desktop',
    value: 'desktop'
  }
];

const AnnouncementConfigForm = ({
  data,
  handleSaveImages,
  onSubmit,
  isLoading,
  handleRemoveImage,
  imagesLoaded,
  onDelete,
  handleUploadFile
}) => {
  const existsRecord = data.hasOwnProperty('id');
  const [form] = Form.useForm();

  const { setFieldsValue } = form;
  const initialValues = existsRecord
    ? {
      device: data.device,
      route: data.route,
      title: data.title,
      text: data.text
    }
    : {
      device: '',
      route: '',
      title: '',
      text: ''
    };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'src',
      width: 80,
      render: (element) => <Image width={100} height={40} src={element} />
    },
    {
      title: 'Texto Alternativo',
      dataIndex: 'alt',
      editable: true,
      width: 100
    },
    {
      title: 'Link',
      dataIndex: 'link',
      editable: true,
      width: 150
    },
    {
      title: 'Remover',
      width: 55,
      align: 'center',
      render: (_, item) => (
        <GoTrashcan onClick={() => handleRemoveImage(item)} />
      )
    }
  ];
  const getColumns = () =>
    columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSaveImages
        })
      };
    });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const onFinish = (record) => {
    onSubmit(record, data?.id);
  };

  const cleanform = useCallback(() => {
    setFieldsValue(initialValues);
  }, [initialValues, setFieldsValue]);

  useEffect(() => {
    cleanform();
    // eslint-disable-next-line
  }, [existsRecord]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={10}>
          <Form
            layout="vertical"
            form={form}
            name="announcementConfigForm"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={initialValues}
          >
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  name="device"
                  label="Dispositivo"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un dispositivo'
                    }
                  ]}
                >
                  <Select style={{ width: 120 }} options={DEVICES} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="title" label="Titulo">
                  <Input style={{ width: 150 }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="route" label="Ruta">
                  <Input style={{ width: 150 }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="text" label="Texto">
                  <Input.TextArea style={{ width: 350, height: 120 }} />
                </Form.Item>
              </Col>
              <Col
                span={12}
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  flexDirection: 'column',
                  paddingLeft: '3rem'
                }}
              >
                <Form.Item>
                  <Space className={styles.buttonContainer}>
                    <div className="buttonContainer">
                      <div>
                        <Button
                          disabled={isLoading}
                          type="primary"
                          htmlType="submit"
                          size="small"
                          shape="round"
                        >
                          Crear
                        </Button>
                        <>
                          <Upload
                            customRequest={({ file, onSuccess }) => {
                              onSuccess(file);
                            }}
                            onChange={(e) => handleUploadFile(e)}
                            style={{ height: 400 }}
                            showUploadList={false}
                          >
                            <Button
                              disabled={isLoading}
                              icon={<UploadOutlined />}
                              size="small"
                              shape="round"
                            >
                              Cargar imagen
                            </Button>
                          </Upload>
                        </>
                        {existsRecord && (
                          <Popconfirm
                            onConfirm={() => onDelete(data.id)}
                            title="Estas seguro que deseas borrar este registro?"
                            okText="Si"
                            cancelText="No"
                          >
                            <Button
                              size="small"
                              disabled={isLoading}
                              type="primary"
                              danger
                              shape="round"
                            >
                              Borrar
                            </Button>
                          </Popconfirm>
                        )}
                      </div>
                    </div>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={14}>
          <Table
            components={components}
            columns={getColumns()}
            dataSource={imagesLoaded}
            rowKey="id"
            scroll={{ y: 540 }}
            locale={{
              emptyText:
                imagesLoaded.length < 1 && 'Sin images cargadas aun :( ...'
            }}
          />
        </Col>
      </Row>
    </>
  );
};

AnnouncementConfigForm.propTypes = {
  data: PropTypes.object,
  handleSaveImages: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  handleRemoveImage: PropTypes.func
};

export default AnnouncementConfigForm;
