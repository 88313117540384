import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateOrder as updateOrderFb,
  getOrdersLimited,
  getRiders,
  getOrderById,
  getRiderById,
  getOrdersByStatusCodeLimited
} from 'firebase/api';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'firebase/firebaseConfig';

export const initialState = {
  data: [],
  count: 0,
  loading: false,
  error: false,
  hasCountInStore: false
};

export const getOrders = createAsyncThunk('@orders/getOrders', async () => {
  const riders = await getRiders();
  const data = await getOrdersLimited(400);
  return data.map((order) => {
    const rider = riders.find((rider) => rider?.id === order?.riderId);
    return { ...order, rider };
  });
});

export const getOrdersByStatusCode = createAsyncThunk(
  '@orders/getOrdersByStatusCode',
  async (statusCode) => {
    const riders = await getRiders();
    const data = await getOrdersByStatusCodeLimited(400, statusCode);
    return data.map((order) => {
      const rider = riders.find((rider) => rider?.id === order?.riderId);
      return { ...order, rider };
    });
  }
);

export const getOneOrder = createAsyncThunk(
  '@orders/getOneOrder',
  async ({ orderName }) => {
    const data = await getOrderById(orderName);
    if (data?.riderId) {
      const rider = await getRiderById(data?.riderId);
      return {
        ...data,
        rider
      };
    }
    return data;
  }
);

export const updateOrder = createAsyncThunk(
  '@order/updateOrder',
  async ({ id, body }) => updateOrderFb(id, body)
);

export const onConfirmCodeOrder = createAsyncThunk(
  '@order/onConfirmCodeOrder',
  async (id) => {
    const body = {
      statusCode: 5
    };
    return updateOrderFb(id, body);
  }
);

export const cancelOrder = createAsyncThunk(
  '@order/cancelOrder',
  async (order) => {
    const { id, ...body } = order;
    await updateOrderFb(id, body);
  }
);

export const modifyOrder = createAsyncThunk(
  '@order/modifyOrder',
  async (finalOrder) => {
    const updateOrd = httpsCallable(functions, 'odoo-updateOrder');
    await updateOrd(finalOrder);
  }
);

export const orderSlide = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrdersLoading: (state) => {
      state.loading = true;
    },
    setOrders: (state, action) => {
      state.data = action.payload;
      state.loading = false;

      if (!state.hasCountInStore) {
        state.hasCountInStore = true;
        state.count = state.data.length;
      }
    },
    setOrdersCount: (state, action) => {
      state.count = action.payload;
    },
    setOrdersError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetOrdersCount: (state) => {
      state.count = state.data.length;
    }
  },
  extraReducers: {
    [getOrders.pending]: (state) => {
      state.loading = true;
    },
    [getOrders.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getOrders.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getOrdersByStatusCode.pending]: (state) => {
      state.loading = true;
    },
    [getOrdersByStatusCode.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getOrdersByStatusCode.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateOrder.pending]: (state) => {
      state.loading = true;
    },
    [updateOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateOrder.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [onConfirmCodeOrder.pending]: (state) => {
      state.loading = true;
    },
    [onConfirmCodeOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [onConfirmCodeOrder.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [cancelOrder.pending]: (state) => {
      state.loading = true;
    },
    [cancelOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [cancelOrder.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getOneOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [getOneOrder.fulfilled]: (state, action) => {
      state.data = [...state.data, ...action.payload];
      state.loading = false;
    },
    [getOneOrder.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setOrdersLoading,
  setOrders,
  setOrdersError,
  setOrdersCount,
  resetOrdersCount
} = orderSlide.actions;

export default orderSlide.reducer;
