import moment from 'moment';
import numeral from 'numeral';
import { scapeLatters } from 'stores/selectors/orders';
import PAYMENT_DICTIONARY from '../constants/payments';
import { sortedList as positionsList } from './sortedPositions';

numeral.register('locale', 'es', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: '°',
  currency: {
    symbol: 'BsF'
  }
});

const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction(...args) {
    const context = this;

    const later = () => {
      timeout = undefined;
      if (!immediate) func.apply(context, args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (immediate && !timeout) func.apply(context, args);
  };
};

export const renameKey = (object, key, newKey) => {
  const clonedObj = { ...object };
  const targetKey = clonedObj[key];

  delete clonedObj[key];

  clonedObj[newKey] = targetKey;

  return clonedObj;
};

export const getBase64 = (img) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });

export const validateIfKeyExistsIntoArr = (arr, key, value) => {
  if (!arr?.length) {
    return false;
  }

  const keys = arr?.map((obj) => obj[key] ?? '');

  return keys.includes(value);
};

export const formatCash = (value, format) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  new Intl.NumberFormat('en-US', format).format(value);

export const roundDecimals = (number, decimals = 2) => {
  const factor = 10 ** decimals;
  return Math.round(number * factor) / factor;
};
// format 2000000 into 2.000.000,00
export const formatPrice = (number) => {
  numeral.locale('es');
  return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(
    number
  );
};

export const formatPriceToBsF = (number) => `${formatPrice(number)} BsF`;

export const formatPriceToDollar = (number) => `$${formatPrice(number)}`;

export const getNameCsvFile = () => {
  const now = new Date();

  return `Precios_${now.toLocaleDateString('en-US')}`;
};

export const calculateProfit = (price, cost) => ((price - cost) / cost) * 100;

export const firebaseDateFormatter = (fbDate, format = 'DD-MM-YYYY HH:mm') => {
  if (!fbDate) return '';

  const date = fbDate.toDate();
  return moment(date).format(format);
};

export const formatPhone = (phone = '') => phone.replace('+', '');

export const printPaymentTypes = (payment) => {
  const isMixedPayment = payment?.methods?.length > 1;
  let output = '';
  if (isMixedPayment) {
    output = payment.methods.reduce((acum, next) => {
      acum += `${PAYMENT_DICTIONARY[next.type]}, `;
      return acum;
    }, []);
    output = output.slice(0, -2);
  } else {
    output = PAYMENT_DICTIONARY[payment.type];
  }
  return output;
};

export const hasChildren = (obj = {}) => Object.keys(obj).length > 0 || false;

export const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

export const sortOrders = (orders) =>
  orders.sort((a, b) => {
    const numA = a?.order?.replace(/[^\d.-]/g, '');
    const numB = b?.order?.replace(/[^\d.-]/g, '');
    return numB - numA;
  });

export const getNonEmptyOrders = (orders) =>
  orders
    .filter(
      (order) =>
        Object.prototype.hasOwnProperty.call(order, 'odooDataOrder') &&
        Object.keys(order.odooDataOrder).length > 0
    )
    .sort(
      (a, b) =>
        scapeLatters(b.odooDataOrder.name) - scapeLatters(a.odooDataOrder.name)
    );

export const numericMask = (event) => {
  const k = Number.parseInt(event.key, 10);
  if (Number.isNaN(k)) {
    event.preventDefault();
  }
};

export const getDateFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const thisDate = `${
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  }/${
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }/${date.getFullYear()}`;
  const hour = `${
    date.getHours() > 12
      ? `${date.getHours() - 12}`
      : `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}`
  }:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}${
    date.getHours() > 12 ? 'pm' : 'am'
  }`;
  return {
    date: thisDate,
    hour
  };
};

export const getTimeDifference = (createdAt, timer = false) => {
  const now = new Date();
  let roadmapDate = new Date(createdAt);
  const diffInMs = now - roadmapDate;
  let diffInMinutes;
  if (timer) {
    diffInMinutes = Math.floor(diffInMs / (1000 * 60)) + 240;
  } else {
    diffInMinutes = diffInMs / (1000 * 60);
  }

  return diffInMinutes;
};

export const sortLocations = (products) => {
  const maximumNumberOfPositions = 1180;

  // Let's make an map to answer position of a produnct in log(n)
  let pos = -1;
  const mapOfPositions = new Map(
    positionsList.map((object) => {
      pos += 1;
      return [object, pos];
    })
  );

  // Ordenamos los productos
  const sortedProducts = [...products];
  sortedProducts.sort((a, b) => {
  
    // Check if moves_location_id exists; if not, assign a high number for sorting
    const codeA = a.moves_location_id ? a.moves_location_id.split('/')[2] : null;
    const codeB = b.moves_location_id ? b.moves_location_id.split('/')[2] : null;
  
    // Then we will transform the code to a number
    const mapA = codeA ? mapOfPositions.get(codeA) : undefined;
    const mapB = codeB ? mapOfPositions.get(codeB) : undefined;
  
    // Solve not existing products (Last position=900)
    const valueA = mapA === undefined ? maximumNumberOfPositions + 1 : mapA;
    const valueB = mapB === undefined ? maximumNumberOfPositions + 1 : mapB;

    return valueA - valueB;
  });

  return sortedProducts;
};

export default {
  debounce,
  renameKey,
  getBase64,
  formatCash,
  formatPrice,
  formatPriceToBsF,
  formatPriceToDollar,
  calculateProfit,
  hasChildren,
  sortOrders,
  sortLocations,
  getNonEmptyOrders,
  getTimeDifference
};
