/* eslint-disable no-param-reassign  */

import { createSlice } from '@reduxjs/toolkit';

export const initState = {
  title: '',
  subTitle: '',
  fallbackRoute: '',
  showBackArrow: false,
  showHeader: true,
  extraButtons: []
};

export const modalSlice = createSlice({
  name: 'header',
  initialState: initState,
  reducers: {
    setHeaderProps: (state, action) => ({ ...state, ...action.payload }),
    resetProps: () => ({ ...initState })
  }
});

export const { setHeaderProps, resetProps } = modalSlice.actions;

export default modalSlice.reducer;
