import React, { useCallback } from 'react';
import { Row, Col, Form, Button, Divider, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCommissionsConfigData } from 'stores/selectors/commissionsConfig';
import { showNotification } from 'stores/actions/notification';

const CommissionsForm = ({ onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const commissions = useSelector(getCommissionsConfigData);
  const dispatch = useDispatch();

  const validatingRange = useCallback(
    (newRecord) => {
      const rangeExists =
        commissions.some(
          (item) => newRecord.from >= item.from && newRecord.from < item.to
        ) || false;
      return rangeExists;
    },
    [commissions]
  );

  const onFinish = useCallback(
    async (record) => {
      const exists = validatingRange(record);
      if (record.from > record.to) {
        dispatch(
          showNotification({
            type: 'warning',
            message: 'Warning',
            content: t('pages.commissions.messages.kilometerHigher')
          })
        );
        return;
      }
      if (exists) {
        dispatch(
          showNotification({
            type: 'warning',
            message: 'Warning',
            content: t('pages.commissions.messages.existsRange')
          })
        );
        return;
      }
      await onSubmit(record);
      onCancel();
    },
    [onSubmit, onCancel, validatingRange, t, dispatch]
  );

  return (
    <Row>
      <Col span={24}>
        <Divider orientation="left">
          {t('pages.commissions.titles.kilometres')}
        </Divider>
        <Form form={form} layout="horizontal" onFinish={onFinish}>
          <Row>
            <Col span={8}>
              <Form.Item
                name="from"
                label={t('pages.commissions.table.from')}
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={8} offset={4}>
              <Form.Item
                name="to"
                label={t('pages.commissions.table.to')}
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">
            {t('pages.commissions.table.commission')}
          </Divider>
          <Row>
            <Col span={12}>
              <Form.Item
                name="commission"
                label={t('pages.commissions.table.commission')}
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t('pages.commissions.button.add')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default CommissionsForm;
