import { combineReducers } from '@reduxjs/toolkit';

import commissionsByRider from 'stores/actions/commissionsByRider';
import modules from 'stores/actions/modules';
import statistics from 'stores/actions/statistics';
import banners from 'stores/actions/banners';
import prices from 'stores/actions/prices';
import emails from 'stores/actions/mails';
import coupons from 'stores/actions/coupons';
import specialCategories from 'stores/actions/specialCategories';
import categoryBox from 'stores/actions/categoryBox';
import categoryProduct from 'stores/actions/categoryProduct';
import announcementsConfig from 'stores/actions/announcementsConfig';
import { pricesApi } from 'stores/query/pricesQuery';
import { ordersApi } from 'stores/query/ordersQuery';
import { configApi } from 'stores/query/configQuery';
import auth from '../actions/auth';
import profile from '../actions/profile';
import notification from '../actions/notification';
import modal from '../actions/modal';
import loader from '../actions/loader';
import users from '../actions/users';
import permissions from '../actions/permissions';
import header from '../actions/header';
import orders from '../actions/orders';
import invoices from '../actions/invoices';
import roadmaps from '../actions/roadmaps';
import riders from '../actions/riders';
import routes from '../actions/routes';
import products from '../actions/products';
import messagesConfig from '../actions/messagesConfig';
import addresses from '../actions/addresses';
import commissionsConfig from '../actions/commissionsConfig';
import config from '../actions/config';
import procos from '../actions/procos';
import ordersIncoming from '../actions/ordersIncoming';
import paymentsIncoming from '../actions/paymentsIncoming';
import cartValidation from '../actions/cartValidation';
import campaigns from '../actions/smsMarketing';
import checkout from '../actions/checkout';

export default combineReducers({
  [pricesApi.reducerPath]: pricesApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [configApi.reducerPath]: configApi.reducer,
  auth,
  notification,
  modal,
  loader,
  profile,
  users,
  permissions,
  header,
  orders,
  invoices,
  roadmaps,
  riders,
  routes,
  products,
  messagesConfig,
  addresses,
  commissionsByRider,
  commissionsConfig,
  config,
  procos,
  modules,
  statistics,
  banners,
  prices,
  emails,
  coupons,
  specialCategories,
  announcementsConfig,
  ordersIncoming,
  paymentsIncoming,
  cartValidation,
  campaigns,
  checkout,
  categoryBox,
  categoryProduct
});
