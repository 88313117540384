import './config/i18n';
import './theme/global.scss';

import SnackBar from 'components/SnackBar/SnackBar';
import Spinner, { SpinnerProvider } from 'components/Spinner';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from 'stores/configureStore';
import { AbilityContext } from 'components/Can';
import ability from 'components/Can/ability';
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement
} from 'chart.js';

import FirebaseInstance, { FirebaseContext } from './firebase';
import * as serviceWorker from './serviceWorker';
import Axios from 'services/Interceptor';
import { stopReportingRuntimeErrors } from 'react-error-overlay';

if (process.env.NODE_ENV === 'development') {
  stopReportingRuntimeErrors(); // disables error overlays
}

Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement
);

const store = configureStore();

Axios.Interceptor(store);

ReactDOM.render(
  <Provider store={store}>
    <SpinnerProvider>
      <Spinner>
        <FirebaseContext.Provider value={FirebaseInstance}>
          <Router>
            <AbilityContext.Provider value={ability}>
              <App />
            </AbilityContext.Provider>
          </Router>
        </FirebaseContext.Provider>
      </Spinner>
    </SpinnerProvider>
    <SnackBar />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
