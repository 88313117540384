import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Descriptions,
  Input,
  Row,
  Select,
  Tooltip,
  Tabs
} from 'antd';
import {
  cancelOrdersIntoRoadmap,
  deleteRoadmaps,
  removeOrderFromRoadmap
} from 'stores/actions/roadmaps';
import { getRoadmapToCancelOrders } from 'stores/selectors/roadmaps';
import { showNotification } from 'stores/actions/notification';
import { printPaymentTypes } from 'utils/utils';
import {
  useOrderByIdsQuery,
  useUpdateOrderMutation
} from 'stores/query/ordersQuery';

const CancelOrderWaiting = ({ roadmap, cancel }) => {
  const [updateOrderFb] = useUpdateOrderMutation();
  const [orderSelected, setOrderSelected] = useState(null);
  const [description, setDescription] = useState('');
  const [tabSelected, setTabSelected] = useState('1');
  const dispatch = useDispatch();
  const { data: ordersData = [] } = useOrderByIdsQuery(roadmap?.ordersIds);
  const orders = ordersData;
  const { Option } = Select;

  const descriptionEmpty = description?.trim().length <= 0;

  const handleOrder = (orderId) => {
    const order = orders.find((record) => record.id === orderId);
    setOrderSelected(order);
  };
  const handleDescription = (value) => setDescription(value);
  const roadmapWithOrdersCancelled = useSelector(
    getRoadmapToCancelOrders(roadmap?.roadmapNumber, [orderSelected], true)
  );

  const [disabled, setDisabled] = React.useState(true);

  const cancelOrderr = async () => {
    const orderInfo = {
      id: orderSelected.id,
      body: {
        statusCode: 7,
        cancellationReason: description,
        status: {
          text: 'cancelled',
          type: orderSelected.status.type
        }
      }
    };
    await updateOrderFb(orderInfo);
  };

  const handleTabChange = (key) => {
    setTabSelected(key);
    setDisabled(true);
  };

  React.useEffect(() => {
    if (description.length >= 1 && description !== '' && orderSelected) {
      setDisabled(false);
    }
    if (description.length <= 1 && tabSelected === '1') {
      setDisabled(true);
    }
    if (tabSelected === '2' && orderSelected) setDisabled(false);
  }, [orderSelected, description, tabSelected]);

  const handlerCancelOrders = async () => {
    try {
      const deleteRoadmap = roadmapWithOrdersCancelled?.ordersIds.length === 0;
      cancelOrderr();
      if (deleteRoadmap) {
        await dispatch(deleteRoadmaps(roadmapWithOrdersCancelled.id));
      } else {
        await dispatch(cancelOrdersIntoRoadmap(roadmapWithOrdersCancelled));
      }
      dispatch(
        showNotification({
          type: 'success',
          content: 'Order cancelada satisfactoriamente'
        })
      );
      cancel();
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };

  const handlerRetireOrderFRM = async (order) => {
    try {
      dispatch(
        // @ts-ignore
        removeOrderFromRoadmap({
          order: order,
          roadmap,
          orders: orders
        })
      );
      dispatch(
        showNotification({
          type: 'success',
          content: 'Orden retirada del roadmap satisfactoriamente'
        })
      );
      cancel();
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };

  return (
    <>
      <Tabs defaultActiveKey="1" centered onChange={handleTabChange}>
        <Tabs.TabPane tab="Cancelar una orden" key="1">
          <Row gutter={[8, 16]}>
            <Col>
              <Select
                showSearch
                placeholder="Seleccione una Orden"
                optionFilterProp="children"
                onChange={handleOrder}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {orders.map((order, idx) => (
                  <Option key={idx} value={order.id}>
                    {`${order?.odooDataOrder?.name}`}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Descriptions title="Detalle">
                <Descriptions.Item label="Roadmap">
                  {roadmap?.roadmapNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Cliente">
                  {orderSelected?.clientName}
                </Descriptions.Item>
                <Descriptions.Item label="Tipo de pago">
                  {orderSelected && printPaymentTypes(orderSelected?.payment)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span="20">
              <>
                <p>Descripcion</p>
                <Input.TextArea
                  required
                  onChange={(e) => handleDescription(e.target.value)}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tooltip
                title={
                  descriptionEmpty && !orderSelected
                    ? 'Debe seleccionar una orden y agregar una descripcion'
                    : ''
                }
              >
                <Button
                  style={{ marginTop: '20px' }}
                  disabled={disabled}
                  type="primary"
                  onClick={() => handlerCancelOrders()}
                >
                  Cancelar Orden
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Retirar orden del roadmap" key="2">
          <Row gutter={[8, 16]}>
            <Col>
              <Select
                showSearch
                placeholder="Seleccione una Orden"
                optionFilterProp="children"
                onChange={handleOrder}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {orders.map((order, idx) => (
                  <Option key={idx} value={order.id}>
                    {`${order?.odooDataOrder?.name}`}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Descriptions title="Detalle">
                <Descriptions.Item label="Roadmap">
                  {roadmap?.roadmapNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Cliente">
                  {orderSelected?.clientName}
                </Descriptions.Item>
                <Descriptions.Item label="Tipo de pago">
                  {orderSelected && printPaymentTypes(orderSelected?.payment)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tooltip
                title={!orderSelected ? 'Debe seleccionar una orden' : ''}
              >
                <Button
                  style={{ marginTop: '20px' }}
                  type="primary"
                  disabled={disabled}
                  onClick={() => handlerRetireOrderFRM(orderSelected)}
                >
                  Retirar Orden
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

CancelOrderWaiting.propTypes = {
  roadmap: PropTypes.object,
  cancel: PropTypes.func
};

export default CancelOrderWaiting;
