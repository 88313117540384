import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createCommissionConfig as createCommissionConfigFb,
  deleteCommissionConfig as deleteCommissionConfigFb,
  getCommissionsConfig as getCommissionsConfigFb,
  updateCommissionConfig as updateCommissionConfigFb
} from 'firebase/api';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getCommissionsConfig = createAsyncThunk(
  '@commissionsConfig/getCommissionsConfig',
  async () => getCommissionsConfigFb()
);

export const updateCommissionConfig = createAsyncThunk(
  '@commissionsConfig/updateCommissionConfig',
  async (context) => {
    await updateCommissionConfigFb(context.id, context);
  }
);

export const createCommissionConfig = createAsyncThunk(
  '@commissionsConfig/createCommissionConfig',
  async (context) => {
    await createCommissionConfigFb(context);
  }
);

export const deleteCommissionConfig = createAsyncThunk(
  '@commissionsConfig/deleteCommissionConfig',
  async (context) => {
    await deleteCommissionConfigFb(context);
  }
);

export const commissionsConfigSlide = createSlice({
  name: 'commissionsConfig',
  initialState,
  reducers: {
    setCommissionsConfigLoading: (state) => {
      state.loading = true;
    },
    setCommissionsConfig: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setCommissionsConfigError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getCommissionsConfig.pending]: (state) => {
      state.loading = true;
    },
    [getCommissionsConfig.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getCommissionsConfig.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateCommissionConfig.pending]: (state) => {
      state.loading = true;
    },
    [updateCommissionConfig.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateCommissionConfig.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createCommissionConfig.pending]: (state) => {
      state.loading = true;
    },
    [createCommissionConfig.fulfilled]: (state) => {
      state.loading = false;
    },
    [createCommissionConfig.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteCommissionConfig.pending]: (state) => {
      state.loading = true;
    },
    [deleteCommissionConfig.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteCommissionConfig.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setCommissionsConfigLoading,
  setCommissionsConfig,
  setCommissionsConfigError
} = commissionsConfigSlide.actions;

export default commissionsConfigSlide.reducer;
