import React from 'react';
import { Tabs } from 'antd';

import useGetRidersHook from 'hooks/componentHooks/useGetRidersHook';
import OrdersReports from './OrdersReports';
import DeliveryOrdersReport from './DeliveryOrdersReport';

const Reports = () => {
  const { TabPane } = Tabs;
  const { getRidersY } = useGetRidersHook();

  /**
   * Get riders data
   */

  React.useEffect(() => {
    getRidersY();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Tabs>
        <TabPane tab="Orders" key="1">
          <OrdersReports />
        </TabPane>
        <TabPane tab="Ordenes de Entrega" key="2">
          <DeliveryOrdersReport />
        </TabPane>
      </Tabs>
    </>
  );
};

export default Reports;
