import React, { useContext } from 'react';
import { Typography } from 'antd';
import RegistrationForm from 'components/Forms/RegistrationForm/RegistrationForm';
import FirebaseContext from 'firebase/context';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showNotification } from 'stores/actions/notification';

import styles from './Register.module.scss';

const Register = () => {
  const firebase = useContext(FirebaseContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleFormSubmit = async (formFieldValues) => {
    const { email, password } = formFieldValues;
    try {
      await firebase.createUserWithEmailAndPassword(email, password);
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <Typography.Title level={1}>
        {t('auth.registerPage.title')}
      </Typography.Title>
      <div className={styles.formPaper}>
        <RegistrationForm onSubmit={handleFormSubmit} />
      </div>
    </div>
  );
};

export default Register;
