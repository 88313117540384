import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import useSpinnerContext from './useSpinner';

const Spinner = ({ children }) => {
  const { spinner } = useSpinnerContext();

  return (
    <Spin spinning={spinner} size="large">
      {' '}
      {children}{' '}
    </Spin>
  );
};

Spinner.propTypes = {
  children: PropTypes.node
};

Spinner.defaultProps = {
  children: null
};

export default Spinner;
