import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Form,
  Col,
  Divider,
  Row,
  Typography,
  Input,
  Radio,
  Button,
  Table,
  Skeleton,
  Select
} from 'antd';
import { CheckCircleTwoTone, SendOutlined } from '@ant-design/icons';

import {
  fetchNotifications,
  showNotification,
  sendNotification
} from 'stores/actions/notification';
import { getMessagesConfig } from 'stores/actions/messagesConfig';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMessageReceivers,
  messagesConfigLoading,
  getSelectedReceiver
} from 'stores/selectors/messagesConfig';
import { getUserMessageToken } from 'stores/selectors/users';
import {
  getNotificationsData,
  isNotificationsLoading
} from 'stores/selectors/notifications';
import { setUserNotification } from 'stores/actions/users';
import { getRidersTokensNotification } from 'stores/selectors/riders';
import { setRiderNotification } from 'stores/actions/riders';
import moment from 'moment';
import useColumnSearch from 'hooks/useColumnSearch';
import { sorterData } from 'utils/table';
import { NOTIFICATIONS } from 'components/Can/modules';
import Can from 'components/Can';

const { Paragraph } = Typography;
const { Option } = Select;

const Notifications = () => {
  const [receiver, setReceiver] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [typeMessages, setTypeMessages] = useState([]);
  const dispatch = useDispatch();
  const searchFilter = useColumnSearch();
  const loading = useSelector(messagesConfigLoading);
  const receivers = useSelector(getMessageReceivers);
  const usersToken = useSelector(getUserMessageToken);
  const ridersToken = useSelector(getRidersTokensNotification);
  const notifications = useSelector(getNotificationsData);
  const notificationLoading = useSelector(isNotificationsLoading);
  const selectedReceiver = useSelector(getSelectedReceiver(receiver));
  const [form] = Form.useForm();

  const messagesFromSelectedType = (typeId) =>
    selectedReceiver.types.find((type) => type.id === typeId).messages || [];

  useEffect(() => {
    dispatch(getMessagesConfig());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  const renderReceiversOption = () =>
    receivers.map(
      ({ id, name }) =>
        name !== 'Admins' && (
          <Radio key={id} value={id}>
            {name}
          </Radio>
        )
    );

  const clearForm = () => {
    form.resetFields();
    setSelectedType('');
    setReceiver('');
    setTypeMessages([]);
  };

  const handleSelectReceiver = (value) => {
    setReceiver(value);
    setSelectedType('');
    setTypeMessages([]);
  };

  const handleSelectType = (value) => {
    const messages = messagesFromSelectedType(value);
    setSelectedType(value);
    setTypeMessages(messages);
  };

  const handlerMessage = (body) => {
    form.setFieldsValue({ body });
  };

  const typesTokensWithOperations = {
    riders: {
      data: ridersToken,
      action: (data) => dispatch(setRiderNotification(data))
    },
    clients: {
      data: usersToken,
      action: (data) => dispatch(setUserNotification(data))
    }
  };

  const handleSendMessage = async (notification) => {
    if (!receiver) {
      dispatch(
        showNotification({
          type: 'error',
          content:
            'Debe seleccionar un tipo de usuario a cual enviar la notificacion.'
        })
      );
      return;
    }

    const hasTypes = selectedReceiver.types > 0;

    const type = hasTypes
      ? selectedReceiver.types.find((record) => record.id === selectedType).name
      : '';
    const typeReceiver = selectedReceiver.name.toLowerCase() || '';

    const usersIds = typesTokensWithOperations[typeReceiver].data.map(
      (user) => user.id
    );

    const toSend = {
      notification,
      registration_ids: typesTokensWithOperations[typeReceiver].data.map(
        (user) => user.token
      )
    };

    const config = {
      headers: {
        Authorization:
          'key=AAAAeKVh0IE:APA91bGTSAE9gq5PwblcqilpOIs12a4RGXGxOYkA8zLDedGMMljdCL4QNJkngL5EWJRTkvDY-U6YgHP4d4UjH5IlGGDcEHZtS-1M9vOUStglKU6xqTEEevGCvxoWQ_Q9KdqgA_2Nq7Qk',
        'Content-Type': 'application/json'
      }
    };

    const notificationBody = {
      ...notification,
      users: usersIds,
      to: typeReceiver,
      type,
      createdAt: new Date()
    };

    const toUser = {
      ...notification,
      createdAt: new Date(),
      readed: false,
      readedAt: null
    };

    try {
      const { action } = typesTokensWithOperations[typeReceiver];
      await axios.post('https://fcm.googleapis.com/fcm/send', toSend, config);
      dispatch(sendNotification(notificationBody));
      action({ ids: usersIds, content: toUser });
      clearForm();

      dispatch(
        showNotification({
          type: 'success',
          content: 'Mensaje enviado satisfactoriamente'
        })
      );
      dispatch(fetchNotifications());
    } catch {
      dispatch(
        showNotification({
          type: 'error',
          content:
            'Hubo un error al enviar el mensaje, intentelo de nuevo mas tarde.'
        })
      );
    }
  };

  const hasSelectedReceiver = Object.keys(selectedReceiver).length > 0;

  const typeOptions = hasSelectedReceiver
    ? selectedReceiver.types.map((type) => ({
      label: type.name,
      value: type.id
    }))
    : [];

  const emptyMessage = selectedType
    ? 'No hay mensajes'
    : 'Selecciona un tipo de mensaje';

  const getColumns = () => [
    {
      title: 'Titulo',
      key: 'title',
      dataIndex: 'title',
      ...searchFilter('title')
    },
    {
      title: 'Mensaje',
      key: 'body',
      dataIndex: 'body',
      ...searchFilter('body')
    },
    {
      title: 'Tipo',
      key: 'type',
      dataIndex: 'type',
      ...searchFilter('type'),
      sorter: sorterData('type')
    },
    {
      title: 'Dirigido a',
      key: 'to',
      dataIndex: 'to',
      ...searchFilter('to'),
      sorter: sorterData('to')
    },
    {
      title: 'Hora',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: sorterData('createdAt'),
      render: (element) => {
        const date = new Date(element?.seconds * 1000);
        const createAt = moment(date).format('DD-MM-YYYY HH:mm');
        if (element) {
          return <>{createAt}</>;
        }
      }
    },
    {
      title: 'Enviada',
      key: 'send',
      render: () => (
        <CheckCircleTwoTone
          style={{ fontSize: '20px' }}
          twoToneColor="#52c41a"
        />
      )
    }
    // {
    //   title: 'Acciones',
    //   dataIndex: 'actions',
    //   render: (_, record) => (
    //     <>
    //       <Space>
    //         <Tooltip placement="top" title="Re-enviar mensaje">
    //           <SendOutlined />
    //         </Tooltip>
    //       </Space>
    //     </>
    //   )
    // }
  ];

  return (
    <>
      <Can I="create" a={NOTIFICATIONS}>
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form form={form} layout="vertical" onFinish={handleSendMessage}>
              <Form.Item
                name="title"
                label="Titulo"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="body" label="Mensaje">
                <Input.TextArea
                  onChange={(e) => handlerMessage(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  icon={<SendOutlined />}
                  size="large"
                >
                  Enviar
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
            <Row style={{ marginBottom: 16 }}>
              <Col span={24}>
                <Paragraph>Dirigido a:</Paragraph>
                <Radio.Group
                  onChange={(e) => handleSelectReceiver(e.target.value)}
                  value={receiver}
                >
                  {loading ? <Skeleton /> : renderReceiversOption()}
                </Radio.Group>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Radio.Group
                  onChange={(e) => handleSelectType(e.target.value)}
                  options={typeOptions}
                  value={selectedType}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
              <Col span={24}>
                <Select
                  showSearch
                  allowClear
                  style={{ width: 200 }}
                  notFoundContent={emptyMessage}
                  placeholder="Selecciona una plantilla de mensaje"
                  optionFilterProp="children"
                  onChange={(_, element) => handlerMessage(element.children)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  value={null}
                >
                  {typeMessages.map((element, idx) => (
                    <Option key={idx} value={element.id}>
                      {element.text}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
      </Can>
      <Row>
        <Col span={24}>
          <Table
            columns={getColumns()}
            loading={notificationLoading}
            dataSource={notifications}
          />
        </Col>
      </Row>
    </>
  );
};

export default Notifications;
