import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createNotification, getNotifications } from 'firebase/api';

export const initialState = {
  data: [],
  loading: false,
  error: '',
  show: false,
  message: '',
  content: '',
  type: ''
};

export const fetchNotifications = createAsyncThunk(
  '@notification/fetchNotifications',
  async () => getNotifications()
);

export const sendNotification = createAsyncThunk(
  '@notification/sendNotifications',
  async (content) => createNotification(content)
);

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (state, action) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.content = action.payload.content;
      state.show = true;
    },
    clearNotification: (state) => {
      state.type = '';
      state.message = '';
      state.content = '';
      state.show = false;
    }
  },
  extraReducers: {
    [fetchNotifications.pending]: (state) => {
      state.loading = true;
    },
    [fetchNotifications.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchNotifications.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [sendNotification.pending]: (state) => {
      state.loading = true;
    },
    [sendNotification.fulfilled]: (state) => {
      state.loading = false;
    },
    [sendNotification.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  showNotification,
  clearNotification
} = notificationSlice.actions;

export default notificationSlice.reducer;
