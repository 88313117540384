import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';
import { getRidersData } from './riders';
import { getRoutesData } from './routes';
import { getUsersData } from './users';

export const scapeLatters = (serial) => Number(serial?.replace(/\D/g, ''));

const statusOrders = {
  0: 'ordered',
  1: 'queued',
  2: 'inTransit',
  3: 'finished',
  4: 'waitingPickUp',
  5: 'pickUpDone',
  6: 'reassigned',
  7: 'cancelled',
  0.5: 'packing'
};

const getBillsImages = (payment) => {
  const isCash = payment?.type?.search('cash') >= 0 || false;
  const containMethods = payment?.methods || false;
  let output;
  if (isCash && containMethods) {
    const method = payment?.methods?.find((element) => element.type === 'cash');
    output =
      method.details?.bills
        ?.map((bill) => bill.image)
        .filter((bill) => bill !== undefined) || [];
    return output;
  }
  output = payment?.details?.images || [];
  return output;
};

const getBills = (payment) => {
  const isCash = payment?.type?.search('cash') >= 0 || false;
  const containMethods = payment?.methods || false;

  if (isCash && containMethods) {
    const method = payment?.methods?.find((element) => element.type === 'cash');
    return method?.details?.bills || [];
  }
  return payment?.details?.bills || [];
};

export const parseUserDataInOrders = (data) => {
  const amountTotal = data?.total || 0;
  const tax = data?.tax || 0;
  const subtotal = amountTotal - tax || 0;
  return {
    name: data?.odooDataOrder?.name,
    partner_type: 'Externos',
    date_order: data?.odooDataOrder?.create_date?.substring(0, 16),
    amount_total: amountTotal,
    subtotal,
    state: statusOrders[data?.statusCode],
    products: data?.odooDataOrder?.products,
    feedback_order: data?.feedback?.description,
    paymentType: data?.payment?.type,
    cashImages: getBillsImages(data?.payment),
    bills: getBills(data?.payment),
    partner: {
      clientName: data?.clientName || data?.customizedInvoice?.fullname
    },
    ...data
  };
};

const selectSelf = (state) => state;

export const getOrders = createSelector(selectSelf, (state) => state.orders);

export const getOrdersData = createSelector(getOrders, (orders) => orders.data);

export const getHasCountInStore = createSelector(
  getOrders,
  (orders) => orders.hasCountInStore
);

export const getOrdersList = createSelector(getOrders, (orders) => orders.data);

export const getSaleOrders = createSelector(getOrders, (orders) =>
  orders.data.filter((order) => Number(order?.statusCode) !== 7)
);

export const getOrdersCancelledData = createSelector(getOrders, (orders) =>
  orders.data.filter((order) => Number(order?.statusCode) === 7)
);

export const getOrderById = (orderId) =>
  createSelector(getOrdersData, (orders) =>
    orders.find((order) => order.id === orderId)
  );

export const getOrdersByIds = (ordersIds) =>
  createSelector(getOrdersData, (orders) =>
    orders.filter((order) => ordersIds.includes(order.id))
  );

export const getOrderCancelled = (orderId, cancellationReason) =>
  createSelector(getOrdersData, (orders) => {
    const order = orders.find((order) => order.id === orderId);
    return {
      ...order,
      cancellationReason,
      statusCode: 7,
      status: {
        text: 'cancelled',
        type: order.status.type
      }
    };
  });

export const getOrdersCount = createSelector(
  getOrders,
  (orders) => orders.count
);

export const isOrdersLoading = createSelector(
  getOrders,
  (orders) => orders.loading
);

export const hasOrdersError = createSelector(
  getOrders,
  (orders) => orders.error
);

export const hasOrdersData = createSelector(
  getOrdersData,
  (orders) => orders.length > 0
);

export const isOrdersLoaded = createSelector(
  isOrdersLoading,
  hasOrdersData,
  (isLoading) => !isLoading
);

export const getPickUpOrders = createSelector(
  getOrdersData,
  (orders) => orders.filter((order) => order.status?.type === 'pickup') || []
);

export const getDeliveryOrders = createSelector(
  getOrdersData,
  (orders) => orders.filter((order) => order.status?.type === 'delivery') || []
);

export const getOrdersWithUsers = createSelector(
  getUsersData,
  getPickUpOrders,
  (users, orders) =>
    orders.map((order) => {
      const partner = users.find((user) => user.id === order.clientId) || '';
      return {
        ...parseUserDataInOrders(order),
        partner
      };
    })
);

export const getNonEmptyOrders = createSelector(getOrdersData, (orders) =>
  orders
    .filter(
      (order) =>
        order.hasOwnProperty('odooDataOrder') &&
        Object.keys(order.odooDataOrder).length > 0
    )
    .sort(
      (a, b) =>
        scapeLatters(b.odooDataOrder.name) - scapeLatters(a.odooDataOrder.name)
    )
);

export const getOrdersWithUserAndRiders = createSelector(
  getNonEmptyOrders,
  getUsersData,
  getRidersData,
  (orders, users, riders) =>
    orders
      .map((order) => {
        const partner = users.find((user) => user.id === order.clientId) || '';
        const rider =
          riders.find((riderItem) => riderItem.id === order.riderId) || '';
        return {
          ...parseUserDataInOrders(order),
          partner,
          rider
        };
      })
      .filter((order) => !order.partner.support)
);

export const getOrdersForPacking = createSelector(
  getNonEmptyOrders,
  getUsersData,
  getRidersData,
  (orders, users, riders) =>
    orders
      .map((order) => {
        const partner = users.find((user) => user.id === order.clientId) || '';
        const rider =
          riders.find((riderItem) => riderItem.id === order.riderId) || '';
        return {
          ...parseUserDataInOrders(order),
          partner,
          rider
        };
      })
      .filter(
        (order) =>
          (order.statusCode === 0 || order.statusCode === 4) &&
          order?.onPacking === 'unpacked'
      )
      .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
);

export const getUnprintedOrders = createSelector(
  getNonEmptyOrders,
  getUsersData,
  (orders, users) =>
    orders
      .map((order) => {
        const partner = users.find((user) => user.id === order.clientId) || '';
        return {
          ...parseUserDataInOrders(order),
          clientName: partner.name,
          partner
        };
      })
      .filter((order) => !order?.invoicePrinted)
      .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
);

export const getPrintedOrders = createSelector(
  getNonEmptyOrders,
  getUsersData,
  (orders, users) =>
    orders
      .map((order) => {
        const partner = users.find((user) => user.id === order.clientId) || '';
        return {
          ...parseUserDataInOrders(order),
          clientName: partner.name,
          partner
        };
      })
      .filter((order) => order?.invoicePrinted)
      .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
);

export const getOrderDetails = (orderId) =>
  createSelector(
    getOrdersWithUserAndRiders,
    getRoutesData,
    (orders, routes) => {
      const order = orders.find((order) => order.id === orderId);
      const route = routes.find(
        (route) => route.id === order?.addressDetails?.routeId
      );
      let date = order?.date.toDate();
      date = moment(date).format('DD-MM-YYYY HH:mm');
      return {
        name: order?.name,
        routeName: route?.name,
        details: {
          date,
          clientName: order?.clientName,
          dni: order?.partner?.dni,
          phone: order?.partner?.phone,
          phoneAddress: order?.addressDetails?.phone,
          address: order?.addressDetails?.zone,
          payMethod: order?.paymentType
        },
        products: order?.products,
        payment: {
          subtotal: order?.subtotal,
          tax: order?.tax,
          total: order?.total,
          totalUsd: order?.ref
        },
        packagingDetail: order?.packagingDetail
      };
    }
  );

export const getOrdersByUser = (user) =>
  createSelector(getNonEmptyOrders, (orders) =>
    orders
      .map((order) => ({
        ...parseUserDataInOrders(order)
      }))
      .filter((order) => order.clientId === user)
  );

export const getWhatsappOrders = createSelector(
  getSaleOrders,
  getUsersData,
  getRidersData,
  (orders, users, riders) =>
    orders
      .map((order) => {
        const partner = users.find((user) => user.id === order.clientId) || '';
        const rider =
          riders.find((riderItem) => riderItem.id === order.riderId) || '';
        return {
          ...parseUserDataInOrders(order),
          client: order.customizedInvoice?.name,
          partner,
          rider
        };
      })
      .filter((order) => order.partner.support)
);

export const getOrdersCancelledWithUserAndRiders = createSelector(
  getOrdersCancelledData,
  getUsersData,
  getRidersData,
  (orders, users, riders) =>
    orders.map((order) => {
      const partner = users.find((user) => user.id === order.clientId) || '';
      const rider =
        riders.find((riderItem) => riderItem.id === order.riderId) || '';
      return {
        ...parseUserDataInOrders(order),
        partner,
        rider
      };
    })
);

export const getOrdersByStatusOrdered = createSelector(
  getOrdersWithUsers,
  (orders) =>
    orders
      .filter((order) => order.statusCode === 0 || order.statusCode === 1)
      .sort((a, b) => scapeLatters(b.name) - scapeLatters(a.name))
);

export const getOrdersCheckedByStatusOrdered = createSelector(
  getOrdersWithUsers,
  (orders) =>
    orders
      .filter((order) => order.statusCode === 4)
      .sort((a, b) => scapeLatters(b.name) - scapeLatters(a.name))
);

export const getOrdersByStatusWaitingPickUp = createSelector(
  getOrdersWithUsers,
  (orders) =>
    orders
      .filter((order) => order.statusCode === 4)
      .sort((a, b) => scapeLatters(b.name) - scapeLatters(a.name))
);

export const getOrdersByStatusPickUpDone = createSelector(
  getOrdersWithUsers,
  (orders) =>
    orders
      .filter((order) => order.statusCode === 5)
      .sort((a, b) => scapeLatters(b.name) - scapeLatters(a.name))
);

export const getOrdersByPagoMovilPayment = createSelector(
  getOrdersData,
  (orders) =>
    orders.filter(
      (order) =>
        !order?.payment?.isConfirmed && order.payment.type === 'pagomovil'
    )
);

export const getOrdersByCashPayment = createSelector(
  getOrdersWithUserAndRiders,
  (orders) =>
    orders.filter(
      (order) => order?.payment?.type === 'cash' && !order?.payment?.validated
    )
);

export const getValidatedOrders = createSelector(
  getOrdersWithUserAndRiders,
  (orders) =>
    orders.filter(
      (order) => order.payment?.type === 'cash' && order.payment?.validated
    )
);

export const getOrdersByBankTransferPayment = createSelector(
  getOrdersData,
  (orders) =>
    orders.filter(
      (order) =>
        !order?.payment?.isConfirmed && order.payment.type === 'bankTransfer'
    )
);

export const getOrdersWithoutPaymentsConfirmation = createSelector(
  getUsersData,
  getOrdersByPagoMovilPayment,
  getOrdersByBankTransferPayment,
  (users, withPagoMovil, withBankTransfer) =>
    [...withBankTransfer, ...withPagoMovil]
      .filter((order) => order.statusCode !== 7)
      .map((order) => ({
        ...parseUserDataInOrders(order),
        partner: users.find((user) => user.id === order.clientId)
      }))
);

export const getNewOrdersCount = createSelector(
  getOrdersData,
  getOrdersCount,
  (orders, prevCount) => {
    const ordersCount = orders.length;
    return ordersCount - prevCount;
  }
);

export const getOrdersDataWithUsers = createSelector(
  getUsersData,
  getOrdersData,
  (users, orders) =>
    orders.map((order) => {
      const partner = users.find((user) => user.id === order.clientId) || '';
      return {
        ...parseUserDataInOrders(order),
        client: {
          id: partner?.id,
          name: partner?.name || '-',
          dni: partner?.dni || '-',
          phone: partner?.phone || '-',
          email: partner?.email || '-'
        }
      };
    })
);
