import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRoutes as getRoutesFb } from 'firebase/api';

export const initialState = {
  data: [],
  loading: false,
  error: false
};
export const getRoutes = createAsyncThunk('@routes/getRoutes', async () =>
  getRoutesFb()
);

export const routesSlide = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setRoutesLoading: (state) => {
      state.loading = true;
    },
    setRoutes: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setRoutesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getRoutes.pending]: (state) => {
      state.loading = true;
    },
    [getRoutes.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getRoutes.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setRoutesLoading, setRoutes, setRoutesError } =
  routesSlide.actions;

export default routesSlide.reducer;
