import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getCampaigns as getCampaignsFB,
  getScheduledCampaigns as getScheduledCampaignsFB
} from 'firebase/api';

export const initialState = {
  sheduledCampaigns: [
    {
      id: '',
      date: '',
      message: '',
      to: '',
      loading: false,
      error: false
    }
  ],
  campaigns: [
    {
      id: '',
      date: '',
      message: '',
      to: '',
      loading: false,
      error: false
    }
  ]
};

export const getCampaigns = createAsyncThunk(
  '@campaigns/getCampaigns',
  async () => {
    const campaigns = await getCampaignsFB();
    return campaigns;
  }
);

export const getScheduledCampaigns = createAsyncThunk(
  '@campaigns/getScheduledCampaigns',
  async () => {
    const scheduledCampaigns = await getScheduledCampaignsFB();
    return scheduledCampaigns;
  }
);

export const CampaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {},
  extraReducers: {
    [getCampaigns.pending]: (state) => {
      state.loading = true;
    },
    [getCampaigns.fulfilled]: (state, action) => {
      state.campaigns = action.payload;
      state.loading = false;
    },
    [getCampaigns.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getScheduledCampaigns.pending]: (state) => {
      state.loading = true;
    },
    [getScheduledCampaigns.fulfilled]: (state, action) => {
      state.sheduledCampaigns = action.payload;
      state.loading = false;
    },
    [getScheduledCampaigns.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export default CampaignsSlice.reducer;
