import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, Form, Input, Select } from 'antd';
import { numericMask } from 'utils/utils';
import { getBanks } from 'stores/actions/config';
import styles from '../../CreateOrder.module.scss';

const { Option } = Select;
const { Item } = Form;

const numberPrefix = ['0414', '0424', '0416', '0426', '0412'];

const dniTypes = ['V', 'E', 'J', 'G', 'P'];

const CashbackForm = ({ bsTotal, total, handleAccept, handleCancel }) => {
  const [form] = Form.useForm();
  const [banks, setBanks] = React.useState([]);
  const dispatch = useDispatch();
  const [selectedBank, setSelectedBank] = React.useState(null);

  const onFinish = (v) => {
    handleAccept({ ...v, bank: selectedBank });
  };

  useEffect(() => {
    dispatch(getBanks()).then((result) => setBanks(result.payload));
    // eslint-disable-next-line
  }, []);

  const renderBanks = () =>
    banks.map((bank) => (
      <Option key={bank.codigo} value={bank.codigo}>
        {bank.nombre}
      </Option>
    ));

  return (
    <Form
      form={form}
      name="cashback"
      onFinish={onFinish}
      scrollToFirstError
      initialValues={{
        dniType: dniTypes[0]
      }}
    >
      <div>
        <div className={styles.notes}>
          <p>{'Retornar al usuario el vuelto por pagomovil.'}</p>
        </div>
        <Item
          name="bank"
          className={styles.fullInput}
          rules={[
            {
              required: true,
              message: 'Seleccione el banco destino'
            }
          ]}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder="Banco"
            className={styles.input}
            onChange={(value) => {
              setSelectedBank(value);
            }}
          >
            {renderBanks()}
          </Select>
        </Item>
        <div className={styles.doubleInputContainer}>
          <Item
            name="prefix"
            className={styles.prefix}
            rules={[
              {
                required: true,
                message: 'Campo obligatorio'
              }
            ]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className={styles.input}
              placeholder="04--"
            >
              {numberPrefix.map((prefix) => (
                <Option key={prefix} value={prefix}>
                  {prefix}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name="phone"
            className={`${styles.fullInput} ${styles.phoneInput}`}
            rules={[
              {
                required: true,
                message: '7 caracteres son obligatorios'
              }
            ]}
          >
            <Input
              onKeyPress={numericMask}
              name="phone"
              maxLength={7}
              minLength={7}
              placeholder="Numero de telefono"
            />
          </Item>
        </div>
        <div className={styles.doubleInputContainer}>
          <Item
            name="dniType"
            className={styles.prefix}
            rules={[
              {
                required: true,
                message: 'Campo obligatorio'
              }
            ]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className={styles.input}
              placeholder="V"
            >
              {dniTypes.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name="dni"
            className={`${styles.fullInput} ${styles.phoneInput}`}
            rules={[
              {
                required: true,
                message: 'Minimo 6 caracteres'
              }
            ]}
          >
            <Input
              maxLength={10}
              onKeyPress={numericMask}
              name="dni"
              placeholder="Numero de cedula"
            />
          </Item>
        </div>
      </div>
      <div className={styles.footer}>
        <Button className={styles.button} htmlType="submit">
          <p className={styles.text}>Guardar</p>
        </Button>
        <Button
          style={{ width: '100px', marginLeft: '20px' }}
          onClick={handleCancel}
          className={`${styles.button} ${styles.outline}`}
        >
          <p className={styles.text}>Cancelar</p>
        </Button>
      </div>
    </Form>
  );
};

CashbackForm.propTypes = {
  handleAccept: PropTypes.func,
  handleCancel: PropTypes.func,
  total: PropTypes.number,
  bsTotal: PropTypes.number
};

CashbackForm.defaultProps = {
  handleAccept: () => {},
  handleCancel: () => {},
  total: -1,
  bsTotal: -1
};

export default CashbackForm;
