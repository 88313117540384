import React, { useState } from 'react';
import { Button, Col, Input, Row, Space, Spin, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { showNotification } from 'stores/actions/notification';
import { useUpdateOrderMutation } from 'stores/query/ordersQuery';

const CancelOrder = ({ order, cancel }) => {
  const dispatch = useDispatch();
  const [updateOrderFb, { isLoading }] = useUpdateOrderMutation();

  const [description, setDescription] = useState('');
  const descriptionEmpty = description.trim().length === 0;

  const buildOrderCancelled = () => ({
    id: order.id,
    body: {
      cancellationReason: description,
      statusCode: 7,
      status: {
        text: 'cancelled',
        type: order.status.type
      }
    }
  });
  const handleCancelOrder = async () => {
    const body = buildOrderCancelled();
    try {
      await updateOrderFb(body);
      dispatch(
        showNotification({
          type: 'success',
          content: 'Order cancelada satisfactoriamente'
        })
      );
      cancel();
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };

  const onChangeDescription = (value) => setDescription(value);

  return (
    <>
      <Spin spinning={isLoading}>
        <Row gutter={[8, 16]}>
          <Col span="20">
            <>
              <p>Descripcion</p>
              <Input.TextArea
                onChange={(e) => onChangeDescription(e.target.value)}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </>
          </Col>
          <Row>
            <Space direction="horizontal">
              <Tooltip
                title={descriptionEmpty ? 'Debe agregar una descripcion' : ''}
              >
                <Button
                  disabled={descriptionEmpty}
                  type="primary"
                  onClick={() => handleCancelOrder()}
                >
                  Cancelar Orden
                </Button>
              </Tooltip>
              <Button
                disabled={isLoading}
                onClick={() => cancel()}
                type="default"
              >
                Cerrar
              </Button>
            </Space>
          </Row>
        </Row>
      </Spin>
    </>
  );
};

export default CancelOrder;
