import { getPickingConfig, updatePickingConfig } from 'firebase/api';
import { statusResponse } from './helpers';

const {
  createApi,
  fakeBaseQuery
} = require('@reduxjs/toolkit/dist/query/react');

export const configApi = createApi({
  reducerPath: '@config',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Config'],
  endpoints: (build) => ({
    pickingConfig: build.query({
      async queryFn() {
        try {
          const pickingConfig = await getPickingConfig();
          return { data: pickingConfig };
        } catch (error) {
          return { error: error };
        }
      }
    }),
    updatePickingConfig: build.mutation({
      async queryFn(body) {
        try {
          await updatePickingConfig(body);
          return statusResponse.DONE;
        } catch (error) {
          return { error: error };
        }
      },
      invalidatesTags: ['Config']
    })
  })
});

export const { usePickingConfigQuery, useUpdatePickingConfigMutation } =
  configApi;
