import i18next from 'config/i18n';

const runTranslate = () => ({
  bankRules: [
    {
      required: true,
      message: i18next.t('common.fields.bank')
    }
  ],
  dniRules: [
    {
      required: true,
      message: i18next.t('auth.rules.dni')
    },
    {
      pattern: '^[0-9]*$',
      message: i18next.t('auth.rules.onlyNumber')
    },
    {
      min: 7,
      message: i18next.t('auth.rules.dniMinLength')
    },
    {
      max: 8,
      message: i18next.t('auth.rules.dniMaxLength')
    }
  ],
  phoneRules: [
    {
      required: true,
      message: i18next.t('auth.rules.phone')
    },
    {
      pattern: /^\d{7}$/,
      message: i18next.t('auth.rules.validPhone')
    }
  ],
  amountRules: [
    {
      required: true,
      message: i18next.t('auth.rules.amount')
    },
    {
      pattern: /^(?!0*[.,]?0+$)\d*[.,]?\d+$/,
      message: i18next.t('auth.rules.onlyNumber')
    }
  ],
  orderRules: [
    {
      required: true,
      message: i18next.t('auth.rules.order')
    }
  ]
});

i18next.on('languageChanged', () => runTranslate());

export default runTranslate;
