import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrder } from 'firebase/api';
import { showNotification } from 'stores/actions/notification';
import { printInvoice } from 'services/billing';

const PrintInvoiceValidationModal = ({ cancel, order, refetch }) => {
  const [reason, setReason] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);
  const dispatch = useDispatch();
  const { id: adminId } = useSelector((state) => state.profile);
  const { id: orderId, fiscalPrinter, reasons } = order;

  const handleConfirm = async () => {
    try {
      const body = {
        reasons: reasons
          ? [...reasons, { admin: adminId, date: new Date(), reason }]
          : [{ admin: adminId, date: new Date(), reason }]
      };
      await updateOrder(orderId, body);
      refetch();
      await printInvoice(fiscalPrinter);
      dispatch(
        showNotification({
          type: 'success',
          message: 'Exito',
          content: 'Reimprimiendo factura...'
        })
      );
      cancel();
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: 'Ocurrio un error al reimprimir la factura'
        })
      );
    }
  };

  const checkReason = () => {
    if (reason.length < 5) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  React.useEffect(() => {
    checkReason();
    // eslint-disable-next-line
  }, [reason]);

  return (
    <div>
      <p>Motivo de reimpresion de factura</p>
      <Input
        placeholder="Motivo de reimpresion"
        onChange={(e) => setReason(e.target.value)}
      />
      <div style={{ marginTop: '15px' }}>
        <Button onClick={cancel} style={{ marginRight: '5px' }}>
          Cancelar
        </Button>
        <Button
          onClick={handleConfirm}
          type="primary"
          style={{ marginLeft: '5px' }}
          disabled={disabled}
        >
          Imprimir
        </Button>
      </div>
    </div>
  );
};

// add proptypes
PrintInvoiceValidationModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fiscalPrinter: PropTypes.object,
    reasons: PropTypes.shape({
      admin: PropTypes.string,
      date: PropTypes.string,
      reason: PropTypes.string
    })
  }).isRequired,
  cancel: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

export default PrintInvoiceValidationModal;
