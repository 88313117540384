import { useState } from 'react';

const useAsyncDownload = () => {
  const [isReady, setIsReady] = useState(false);
  const asyncDownloadToggle = () => {
    setIsReady(true);
    setTimeout(() => {
      setIsReady(false);
    }, 2000);
  };

  return {
    asyncDownloadToggle,
    isReady
  };
};

export default useAsyncDownload;
