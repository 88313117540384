import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getWarehouseProcos } from 'stores/selectors/proco';
import useGetAdmins from './useGetAdmins';

const useGetUsersProcoStorage = (id) => {
  const warehousemanData = useSelector(getWarehouseProcos);
  const { data: adminData } = useGetAdmins();
  const [data, setData] = useState([]);

  useEffect(() => {
    const userIds = warehousemanData.find((item) => item.id === id)?.users || [];
    const resData = adminData.filter((admin) => userIds.includes(admin.id))
      .map((item) => ({ name: item.name, ci: item.dni, phone: item.phone }));

    setData(resData);
  }, [id, warehousemanData, adminData]);

  return {
    data
  };
};

export default useGetUsersProcoStorage;
