import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Table, Tag } from 'antd';
import { getBanks, getCommerceToPerson } from 'stores/actions/config';
import {
  useCommerceToPersonQuery,
  useOrdersQuery
} from 'stores/query/ordersQuery';
import useRangeDateFilter from 'hooks/useRangeDateFilter';
import Bank from 'components/Bank/Bank';
import CommerceToPersonFilter from './CommerceToPersonFilter';
import { commerceToPersonFactory } from '../utils';

const CommerceToPersonReport = () => {
  const { handleRange, lastSevenDaysDefault } = useRangeDateFilter();
  const { data: ordersData, isLoading: ordersLoading } = useOrdersQuery();
  const { data: resCommerceToPersonData, isLoading: commerceLoading } =
    useCommerceToPersonQuery({
      justAdmin: false,
      ...lastSevenDaysDefault
    });
  const commercePersonParsed = commerceToPersonFactory(
    ordersData,
    resCommerceToPersonData
  );

  const [commerceToPersonData, setCommerceToPersonData] = useState(null);
  const loading = ordersLoading && commerceLoading;

  const dispatch = useDispatch();

  const fetchCommerceToPerson = useCallback(async () => {
    await dispatch(getCommerceToPerson());
  }, [dispatch]);

  const fetchData = useCallback(async () => {
    await fetchCommerceToPerson();
    await dispatch(getBanks());
  }, [dispatch, fetchCommerceToPerson]);

  const handleFilter = (record) => {
    const { bank, dni, status, fromAdmin, orderId } = record || {};
    const dataFiltered = commercePersonParsed
      .filter((item) => {
        if (bank === null) return true;
        return item.banco === bank;
      })
      .filter((item) => {
        if (dni === null) return true;
        return item.cedula === dni;
      })
      .filter((item) => {
        if (fromAdmin === false) return true;
        if (fromAdmin === null) return item.fromAdmin !== true;
        return item.fromAdmin === fromAdmin;
      })
      .filter((item) => {
        if (status === null || status === 'ALL') return true;
        return item.status === status;
      })
      .filter((item) => {
        if (orderId === null) return true;
        return item.orderId === orderId;
      });

    setCommerceToPersonData(dataFiltered);
  };

  const columns = [
    {
      title: 'Cedula',
      dataIndex: 'cedula',
      key: 'cedula',
      fixed: 'left'
    },
    {
      title: 'Telefono',
      dataIndex: 'telefono',
      key: 'telefono',
      fixed: 'left'
    },
    {
      title: 'Orden',
      dataIndex: 'order',
      key: 'order'
    },
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      render: (code) => <Bank code={code} />
    },
    {
      title: 'Detalle Banco',
      dataIndex: 'numeroReferencia',
      key: 'numeroReferencia'
    },
    {
      title: 'Monto Devuelto',
      dataIndex: 'montoDevuelto',
      key: 'monto'
    },
    {
      title: 'Motivo',
      dataIndex: 'motivo',
      key: 'motivo'
    },
    {
      title: 'Estado',
      dataIndex: 'success',
      key: 'success',
      render: (status, record) => {
        const hasStatus = record.hasOwnProperty('success');
        if (!record.order) return <Tag color="red">Pago fallido</Tag>;
        return hasStatus && status ? (
          <Tag color="success">Exitoso</Tag>
        ) : hasStatus && !status ? (
          <Tag color="error">Fallido</Tag>
        ) : (
          <Tag color="processing">En Proceso...</Tag>
        );
      }
    },
    {
      title: 'Origen',
      dataIndex: 'fromAdmin',
      key: 'fromAdmin',
      render: (record) =>
        record ? (
          <Tag color="cyan">Admin</Tag>
        ) : (
          <Tag color="geekblue">Automatico</Tag>
        )
    }
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <CommerceToPersonFilter
            commerceToPersonData={commerceToPersonData}
            handleFilter={handleFilter}
            handleRange={handleRange}
            ordersData={ordersData}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={commerceToPersonData}
            scroll={{ x: 1300 }}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default CommerceToPersonReport;
