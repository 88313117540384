import { Button, Form, Input } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import CashbackForm from './CashbackForm';
import styles from '../../CreateOrder.module.scss';

const { Item } = Form;

export default function Efectivo({ onSave, totalInCart }) {
  const { total, totalRef } = totalInCart.data.data;
  const [form] = Form.useForm();
  const [cashback, setCashback] = React.useState(false);
  const [cashValues, setCashValues] = React.useState(null);

  const handleSubmit = (v) => {
    if (parseInt(v.amount, 10) > total) {
      setCashValues({
        method: 'bolivarCash',
        details: {
          ...v,
          amount: parseInt(v.amount, 10),
          comments: 'Creado desde el admin'
        },
        type: 'bolivarCash'
      });
      setCashback(true);
    } else {
      onSave({
        method: 'bolivarCash',
        details: {
          ...v,
          amount: v.amount,
          comments: 'Creado desde el admin'
        },
        type: 'bolivarCash'
      });
    }
  };

  const handleAcceptCashback = React.useCallback(
    (values) => {
      onSave({
        ...cashValues,
        cashback: {
          banco: values.bank,
          cedula: `${values.dniType}${values.dni}`,
          telefono: `${values.prefix}${values.phone}`
        }
      });
    },
    [onSave, cashValues]
  );

  if (cashback && cashValues) {
    return (
      <CashbackForm
        bsTotal={total}
        total={totalRef}
        handleAccept={handleAcceptCashback}
        handleCancel={() => setCashback(false)}
      />
    );
  }

  return (
    <div className={styles.cash}>
      <p style={{ marginBottom: '10px', fontWeight: 'bold' }}>
        Precio total del carrito: {`${totalInCart.data?.data?.total} BS`}
      </p>
      <Form
        className={styles.formContainer}
        form={form}
        name="cash-payment"
        onFinish={handleSubmit}
        scrollToFirstError
        initialValues={{
          amount: 0,
          comments: ''
        }}
      >
        <div className={styles.inputCont}>
          <Item
            name="amount"
            label="Monto"
            rules={[
              {
                required: true,
                message: 'Ingrese un monto'
              },
              {
                validator: (_, value) => {
                  if (
                    // Cannot exceed total amount + 200%
                    Number.parseInt(value || '0', 10) >=
                      Number.parseFloat(total) &&
                    Number.parseInt(value || '0', 10) <=
                      Number.parseFloat(total) * 3
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('El monto debe ser mayor o igual al total')
                  );
                }
              }
            ]}
          >
            <Input
              className={styles.fullInput}
              type="number"
              min={0}
              placeholder="Monto a pagar en efectivo"
              maxLength={30}
            />
          </Item>
        </div>
        <div className={styles.buttonsContainer__row}>
          <Button className={styles.confirmButton__accept} htmlType="submit">
            <p className={styles.text}>Guardar</p>
          </Button>
        </div>
      </Form>
    </div>
  );
}

Efectivo.defaultProps = {
  onSave: () => {}
};

Efectivo.propTypes = {
  onSave: PropTypes.func,
  totalInCart: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.shape({
        total: PropTypes.string,
        totalRef: PropTypes.string
      })
    })
  }).isRequired
};
