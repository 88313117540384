import { AbilityContext } from 'components/Can';
import { setToken } from 'firebase/utils/token';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAbility } from 'components/Can/ability';
import { loaded, loadingWithSpinner } from 'stores/actions/loader';
import { getProfileState } from 'stores/selectors/profile/selector';
import {
  getAdminByEmail,
  getDepartments,
  getPermissionsByRole,
  signOut
} from 'firebase/api';
import useFirebaseContext from './useFirebaseContext';

const useFirebaseAuthentication = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfileState);
  const ability = useContext(AbilityContext);
  const [authUser, setAuthUser] = useState({});
  const firebase = useFirebaseContext();

  const getUserDepartment = async (user) => {
    const resDepartments = await getDepartments();
    return resDepartments.find((record) => record.id === user.departmentId);
  };

  const getUserPermissions = async (user) => {
    const role = await getPermissionsByRole(user.roleId);
    return role.permissions;
  };

  const grantedPermissions = async (user) => {
    const permissions = await getUserPermissions(user);
    updateAbility(ability, permissions);
  };

  useEffect(() => {
    dispatch(loadingWithSpinner());
    const unlisten = firebase.auth.onAuthStateChanged(async (loggedUser) => {
      if (!loggedUser) {
        dispatch(loaded());
        await signOut();
        return setAuthUser({});
      }

      if (loggedUser?.email) {
        const currentUser = await getAdminByEmail(loggedUser.email);
        const accessToken = await loggedUser.getIdToken();

        const department = await getUserDepartment(currentUser);

        await grantedPermissions(currentUser);

        setAuthUser({
          ...currentUser,
          department: department.name,
          accessToken
        });
        setToken(accessToken);
      }
      dispatch(loaded());
      return authUser;
    });
    return () => {
      dispatch(loaded());
      unlisten();
    };
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [profile.email]);

  return authUser;
};

export default useFirebaseAuthentication;
