import { scapeLatters } from 'stores/selectors/orders';

export const ordersOrderedPickupFactory = (orders = []) => orders
  .filter((order) => order.statusCode === 0 || order.statusCode === 1)
  .filter((order) => order.status?.type === 'pickup')
  .sort((a, b) => scapeLatters(b.name) - scapeLatters(a.name));

export const ordersCheckedPickupFactory = (orders = []) => orders
  .filter((order) => order.statusCode === 4)
  .sort((a, b) => scapeLatters(b.name) - scapeLatters(a.name));
