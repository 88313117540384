import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSetHeaderProps from 'hooks/useSetHeaderProps';
import useGetRidersHook from 'hooks/componentHooks/useGetRidersHook';
import useGetRoadmapsHook from 'hooks/componentHooks/useGetRoadmapsHook';
import Details from 'components/Roadmaps/Details';

const RoadmapsView = () => {
  const params = useParams();
  const history = useHistory();
  const setHeaderProps = useSetHeaderProps();

  const { roadmap: roadmapData, viewOneRoadmap } = useGetRoadmapsHook();
  const { getRiders } = useGetRidersHook();

  React.useEffect(() => {
    getRiders();
    viewOneRoadmap(params.roadmapId);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setHeaderProps({
      fallbackRoute: params.roadmapId,
      paginationTable: history.location.state?.pagination || null
    });
    return () =>
      setHeaderProps({
        fallbackRoute: ''
      });
    // eslint-disable-next-line
  }, [setHeaderProps, history]);

  return (
    <div>
      {roadmapData && (
        <>
          <Details roadmapData={roadmapData} />
        </>
      )}
    </div>
  );
};
export default RoadmapsView;
