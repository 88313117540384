import API from './config';
import {
  CREATE_RIDER,
  DELETE_RIDER
} from './urls';

const createRider = (values) => API.post(CREATE_RIDER, values);

const deleteRider = (id) => API.delete(`${DELETE_RIDER}/${id}`);

export default {
  createRider,
  deleteRider
};
