import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const UploadBanner = ({ handleUploadFile, module }) => {
  const hasModule = module.children !== '' ? true : false;

  return (
    <Fragment>
      <Tooltip title={!hasModule && 'Seleccione un modulo'}>
        <Upload
          customRequest={({ file, onSuccess }) => {
            onSuccess(file, file);
          }}
          onChange={(e) => handleUploadFile(e)}
          disabled={!hasModule}
          style={{ height: 400 }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Tooltip>
    </Fragment>
  );
};

UploadBanner.propTypes = {
  handleUploadFile: PropTypes.func.isRequired,
  module: PropTypes.object
};

export default UploadBanner;
