import React from 'react';
import { Image, Space } from 'antd';

const PaymentTypeDetail = ({ order }) => {
  const images =
    order?.payment?.methods?.find((method) => method.type === 'cash')?.details
      ?.bills || [];

  return (
    <>
      {images.length > 0 &&
        images.map((image) => {
          return (
            <Space>
              {image?.image?.length > 0 ? (
                <Image
                  style={{ padding: '5px' }}
                  width={200}
                  src={image.image}
                />
              ) : (
                <>
                  <span>Serial: {image.code}</span>
                  <span>Monto: {image.amount}</span>
                </>
              )}
            </Space>
          );
        })}
    </>
  );
};

export default PaymentTypeDetail;
