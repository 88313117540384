import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getProductCompanies,
  searchProducts as searchProductsFb,
  searchProductsByIds as searchProductsByIdsFb
} from 'firebase/api';
import API from 'services';

export const initialState = {
  data: [],
  count: 0,
  loading: false,
  error: false,
  productCompanies: [],
  productCategories: []
};

export const searchProducts = createAsyncThunk(
  '@products/searchProducts',
  async (context) => {
    const { data } = await searchProductsFb(context);
    return data;
  }
);

export const searchProductsByIds = createAsyncThunk(
  '@products/searchProductsByIds',
  async (context) => {
    const { data } = await searchProductsByIdsFb(context);
    return data;
  }
);

export const fetchProductCompanies = createAsyncThunk(
  '@products/fetchProductCompanies',
  async () => await getProductCompanies()
);

export const fetchProductCategories = createAsyncThunk(
  '@products/fetchProductCategories',
  async () => {
    const { data } = await API.odoo.getCategories();
    return data;
  }
);

export const productsSlide = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsLoading: (state) => {
      state.loading = true;
    },
    setProducts: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setProductsError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [searchProducts.pending]: (state) => {
      state.loading = true;
    },
    [searchProducts.fulfilled]: (state, action) => {
      state.count = action.payload.pagination.total_results;
      state.data = action.payload.data;
      state.loading = false;
    },
    [searchProducts.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [searchProductsByIds.pending]: (state) => {
      state.loading = true;
    },
    [searchProductsByIds.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
    },
    [searchProductsByIds.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [fetchProductCompanies.pending]: (state) => {
      state.loading = true;
    },
    [fetchProductCompanies.fulfilled]: (state, action) => {
      state.productCompanies = action.payload;
      state.loading = false;
    },
    [fetchProductCompanies.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [fetchProductCategories.pending]: (state) => {
      state.loading = true;
    },
    [fetchProductCategories.fulfilled]: (state, action) => {
      state.productCategories = action.payload;
      state.loading = false;
    },
    [fetchProductCategories.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setProductsLoading, setProducts, setProductsError } =
  productsSlide.actions;

export default productsSlide.reducer;
