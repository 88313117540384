/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Routes from 'routes/Routes';
import { setProfile } from 'stores/actions/profile';
import AuthProvider from 'contexts/AuthContext/AuthContext';
import { GoogleMapsProvider } from 'contexts/GoogleMapsContext';
import useFirebaseAuthentication from 'hooks/useAuthentication';

const App = () => {
  const dispatch = useDispatch();
  const currentUser = useFirebaseAuthentication();

  useEffect(() => {
    const existUserLogged = Object.keys(currentUser).length > 0;
    if (existUserLogged) {
      dispatch(
        setProfile({
          ...currentUser,
          userId: currentUser.id,
          uid: currentUser.id,
          isLogged: true
        })
      );
    }
  }, [currentUser]);

  return (
    <GoogleMapsProvider>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </GoogleMapsProvider>
  );
};

export default App;
