import React from 'react';
import useSetHeaderProps from 'hooks/useSetHeaderProps';
import { Button, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { loadingWithSpinner, loaded } from 'stores/actions/loader';
import { showNotification } from 'stores/actions/notification';
import { storage, ref, getDownloadURL } from 'firebase/firebaseConfig';
import styles from './Finance.module.scss';

const Finance = () => {
  const dispatch = useDispatch();
  const setHeaderProps = useSetHeaderProps();

  const downloadFile = async () => {
    try {
      dispatch(loadingWithSpinner());
      // window.open(
      //   'https://storage.cloud.google.com/adan_price_data/adan_price_data_auto/adan_price_data_farmatodo.xlsx',
      //   '_blank'
      // );
      const gsReference = ref(
        storage,
        'gs://adan-farmacia.appspot.com/adan_price_data/adan_price_data_auto/adan_price_data_farmatodo.xlsx'
      );
      const url = await getDownloadURL(gsReference);
      if (url) {
        window.open(url);
      }
      dispatch(loaded());
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: error
        })
      );
    }
  };

  React.useEffect(() => {
    setHeaderProps({
      title: 'Finance'
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.cardContainer}>
      <Row justify="start">
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => downloadFile()}
        >
          Descargar Archivo
        </Button>
      </Row>
    </div>
  );
};

export default Finance;
