import React, { useState } from 'react';
import { Col, Row, Statistic, Table, Typography, DatePicker } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useColumnSearch from 'hooks/useColumnSearch';
import { formatPriceToDollar } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import { sorterData } from '../../utils/table';
import {
  getCommissionsByRiderData,
  isCommissionsByRiderLoading
} from '../../stores/selectors/commissionsByRider';

const CommissionsByRider = () => {
  const commissionsByRiderData = useSelector(getCommissionsByRiderData);
  const isLoading = useSelector(isCommissionsByRiderLoading);
  const searchFilter = useColumnSearch();
  const { t } = useTranslation();

  const [commissionsByRider, setCommissionsByRider] = useState(
    commissionsByRiderData
  );
  const [dataPickerFilter, setDatePickerFilter] = useState([]);

  const RangePicker = () => {
    const handleRange = (values) => {
      if (!values) {
        setDatePickerFilter([]);
        setCommissionsByRider(commissionsByRiderData);
        return;
      }
      const from =
        values[0] && moment(values[0].toString()).format('DD-MM-YYYY');
      const to = values[1] && moment(values[1].toString()).format('DD-MM-YYYY');

      const commissionsByRiderByRange = commissionsByRider.filter((record) => {
        const date = new Date(record.createAt?.seconds * 1000);
        const createAt = moment(date).format('DD-MM-YYYY');

        if (createAt >= from && createAt <= to) return true;
        return false;
      });
      setCommissionsByRider(commissionsByRiderByRange);
      setDatePickerFilter([
        moment(values[0]?.toString()),
        moment(values[1]?.toString())
      ]);
    };

    return (
      <Row>
        <Col>
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            placeholder={['from', 'to']}
            onChange={(e) => handleRange(e)}
            ranges={{
              '2 days': [
                moment().subtract(2, 'days').startOf('day'),
                moment().endOf('day')
              ]
            }}
            value={dataPickerFilter}
            allowClear={true}
          />
        </Col>
      </Row>
    );
  };

  const columns = [
    {
      title: 'Rider',
      dataIndex: ['rider', 'name'],
      key: 'rider',
      fixed: 'left',
      ...searchFilter(['rider', 'name'])
    },
    {
      title: t('pages.commissionsByRider.roadmapNumber'),
      dataIndex: ['roadmap', 'roadmapNumber'],
      key: 'roadmap',
      ...searchFilter(['roadmap', 'roadmapNumber'])
    },
    {
      title: t('pages.commissionsByRider.totalKilometer'),
      dataIndex: 'totalKilometer',
      key: 'totalKilometer',
      sorter: sorterData('totalKilometer'),
      ...searchFilter('totalKilometer')
    },
    {
      title: t('pages.commissionsByRider.ordersDelivered'),
      dataIndex: 'ordersDelivered',
      key: 'ordersDelivered',
      sorter: sorterData('ordersDelivered'),
      ...searchFilter('ordersDelivered')
    },
    {
      title: t('pages.commissionsByRider.approximateKilometer'),
      dataIndex: ['route', 'approximateKilometer'],
      key: 'approximateKilometer',
      sorter: sorterData('approximateKilometer'),
      ...searchFilter(['route', 'approximateKilometer'])
    },
    {
      title: t('pages.commissionsByRider.paymentByOrdersDelivered'),
      dataIndex: 'paymentByOrdersDelivered',
      key: 'paymentByOrdersDelivered',
      sorter: sorterData('paymentByOrdersDelivered'),
      ...searchFilter('paymentByOrdersDelivered'),
      render: (value) => <>{formatPriceToDollar(value)}</>
    },
    {
      title: t('pages.commissionsByRider.commission'),
      dataIndex: 'commission',
      key: 'commission',
      sorter: sorterData('commission'),
      ...searchFilter('commission'),
      render: (value) => <>{formatPriceToDollar(value)}</>
    },
    {
      title: 'Total',
      key: 'total',
      render: (_, record) => (
        <>
          {formatPriceToDollar(
            record.commission + record.paymentByOrdersDelivered
          )}
        </>
      )
    },
    {
      title: t('pages.commissionsByRider.createAt'),
      dataIndex: 'createAt',
      key: 'createAt',
      filterDropdown: (props) => <RangePicker {...props} />,
      sorter: sorterData('createAt'),
      render: (element) => {
        const date = new Date(element?.seconds * 1000);
        const createAt = moment(date).format('DD-MM-YYYY HH:mm');
        if (element) {
          return <>{createAt}</>;
        }
      }
    }
  ];

  const totalCommissions = (records) => {
    const resData = records;
    const { Text } = Typography;

    const totalKm = resData
      .map((record) => record.totalKilometer)
      .reduce((acc, curr) => acc + parseFloat(curr), 0);

    const totalCommissions = resData
      .map((record) => record.commission)
      .reduce((acc, curr) => acc + parseFloat(curr), 0);

    const totalPaymentByOrderDelivered = resData
      .map((record) => record.paymentByOrdersDelivered)
      .reduce((acc, curr) => acc + parseFloat(curr), 0);

    return (
      <>
        <Row>
          <Col span={8}>
            <Text style={{ fontSize: '26px' }} code>
              Total
            </Text>
          </Col>
          <Col span={8}>
            <Statistic title="Total KM" value={totalKm} />
          </Col>
          <Col span={8}>
            <Statistic
              title={t('pages.commissionsByRider.totalCommissions')}
              value={formatPriceToDollar(totalCommissions)}
            />
          </Col>
          <Col span={8} offset={8}>
            <Statistic
              title={`Total ${t(
                'pages.commissionsByRider.paymentByOrdersDelivered'
              )}`}
              value={formatPriceToDollar(totalPaymentByOrderDelivered)}
            />
          </Col>
          <Col>
            <Statistic
              title={`Total ( ${t(
                'pages.commissionsByRider.totalCommissions'
              )} + ${t('pages.commissionsByRider.paymentByOrdersDelivered')} )`}
              value={formatPriceToDollar(
                totalPaymentByOrderDelivered + totalCommissions
              )}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Row>
      <Col span={24}>
        <Table
          pagination={commissionsByRiderData.length || 0}
          dataSource={commissionsByRider}
          columns={columns}
          loading={isLoading}
          footer={(records) => totalCommissions(records)}
          scroll={{ x: 1500, y: 300 }}
        />
      </Col>
    </Row>
  );
};

export default CommissionsByRider;
