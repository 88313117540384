const BANKS_JSON = {
  result: {
    bancos: [
      { codigo: '0172', nombre: 'BANCA AMIGA' },
      { codigo: '0171', nombre: 'BANCO ACTIVO' },
      { codigo: '0007', nombre: 'BANCO BICENTENARIO' },
      { codigo: '0114', nombre: 'BANCO CARIBE' },
      { codigo: '0128', nombre: 'BANCO CARONI' },
      { codigo: '0102', nombre: 'BANCO DE VENEZUELA' },
      { codigo: '0157', nombre: 'BANCO DEL SUR' },
      { codigo: '0115', nombre: 'BANCO EXTERIOR' },
      { codigo: '0151', nombre: 'BANCO FONDO COMUN' },
      { codigo: '0105', nombre: 'BANCO MERCANTIL' },
      { codigo: '0191', nombre: 'BANCO NACIONAL CREDITO' },
      { codigo: '0116', nombre: 'BANCO OCCIDENTAL DE DESCUENTO' },
      { codigo: '0138', nombre: 'BANCO PLAZA' },
      { codigo: '0108', nombre: 'BANCO PROVINCIAL' },
      { codigo: '0137', nombre: 'BANCO SOFITASA' },
      { codigo: '0163', nombre: 'BANCO TESORO' },
      { codigo: '0168', nombre: 'BANCRECER' },
      { codigo: '0134', nombre: 'BANESCO' },
      { codigo: '0177', nombre: 'BANFANB' },
      { codigo: '0174', nombre: 'BANPLUS' },
      { codigo: '0169', nombre: 'MI BANCO' },
      { codigo: '0104', nombre: 'VENEZOLANO DE CREDITO' },
      { codigo: '0156', nombre: '100% BANCO' }
    ],
    cantidadBancos: '23'
  }
};
export default BANKS_JSON;
