import React from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

const PaymentType = ({ data, handlePaymentCash = () => null }) => {
  const payment = data?.payment;
  const paymentType = payment?.type;
  const hasMixedPayment =
    payment && Object.prototype.hasOwnProperty.call(payment, 'methods');
  const renderPaymentMethod = (item) => {
    if (item.type === 'cash') {
      return (
        <Tag
          onClick={() => handlePaymentCash(data)}
          icon={<LinkOutlined />}
          color="success"
          style={{ cursor: 'pointer' }}
        >
          {item.type}
        </Tag>
      );
    } else if (item.type === 'binance') {
      return (
        <Tag
          color="#FEF6D8"
          style={{ color: '#000000', fontWeight: 400, borderColor: '#FCD535' }}
        >
          {item.type}
        </Tag>
      );
    } else {
      return <Tag color={item.type === "motopos" ? "purple" : "geekblue"}>{item.type}</Tag>;
    }
  };
  return hasMixedPayment ? (
    payment.methods.map((item, index) => {
      return <div key={index}>{renderPaymentMethod(item)}</div>;
    })
  ) : paymentType === 'cash' ? (
    <Tag
      onClick={() => handlePaymentCash(data)}
      icon={<LinkOutlined />}
      color="success"
      style={{ cursor: 'pointer' }}
    >
      {paymentType}
    </Tag>
  ) : (
    <Tag color={paymentType === "motopos" ? "purple" : "geekblue"}>{paymentType}</Tag>
  );
};

export default PaymentType;
