import {
  getAllUsers,
  getCommerceToPerson,
  getOrderByIds,
  getOrders,
  getOrdersByCoupon,
  getOrdersByRange,
  getOrdersByUser,
  getRiders,
  updateOrder,
  getOrdersWithoutRoadmap,
  getOrdersIdWallet,
  getOrdersIdRei,
  getUserByIdsPower
} from 'firebase/api';
import { Timestamp } from 'firebase/firestore';
import { parseUserDataInOrders } from 'stores/selectors/orders';
import { getNonEmptyOrders } from 'utils/utils';
import { statusResponse } from './helpers';

const {
  createApi,
  fakeBaseQuery
} = require('@reduxjs/toolkit/dist/query/react');

export const ordersApi = createApi({
  reducerPath: '@orders',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Order'],
  endpoints: (build) => ({
    orders: build.query({
      async queryFn() {
        try {
          const users = await getAllUsers();
          const orders = await getOrders();

          const output = orders.map((order) => {
            const partner =
              users.find((user) => user.id === order.clientId) || '';
            return {
              ...parseUserDataInOrders(order),
              client: {
                id: partner?.id,
                name: partner?.name || '-',
                dni: partner?.dni || '-',
                phone: partner?.phone || '-',
                email: partner?.email || '-'
              }
            };
          });

          return { data: output };
        } catch (error) {
          return { error };
        }
      }
    }),
    ordersByRange: build.query({
      async queryFn({ from, to, excludeStatus = null }) {
        try {
          const result = await getOrdersByRange(
            Timestamp.fromDate(from),
            Timestamp.fromDate(to)
          );

          // Filter orders based on excludeStatus (if provided)
          const filteredOrders = excludeStatus
            ? result.filter((order) => order.statusCode !== excludeStatus)
            : result;

          // Filter orders based on excludeRider (if true)
          // const finalOrders = excludeRider
          //   ? filteredOrders.filter((order) => !order.riderId)
          //   : filteredOrders;

          const riders = await getRiders();

          // Comenté esta linea porque si asignaban una orden a un RM esta desaparecia del billying, asi que lo dejé como estaba
          // const resultParsed = filteredOrders.map((orders) => {
          const resultParsed = filteredOrders.map((order) => {
            // Include rider data  only if excludeRider is false
            const rider =
              riders.find((riderItem) => riderItem.id === order.riderId) || '';
            return {
              ...parseUserDataInOrders(order),
              rider
            };
          });

          return { data: resultParsed };
        } catch (e) {
          return { error: e };
        }
      },
      providesTags: ['Order']
    }),
    ordersByIdWallet: build.query({
      async queryFn({ from, to }) {
        /// Should receive date with this format with => " new Date( yyyy-mm-dd ) "
        try {
          const result = await getOrdersIdWallet(
            Timestamp.fromDate(from),
            Timestamp.fromDate(to)
          );
          if (result) {
            let orderIds = [];
            const userIds = [];
            result.forEach((r) => {
              orderIds.push(r.orderRef);
            });
            orderIds = orderIds.filter((f) => f !== undefined);
            let orders = await getOrderByIds(orderIds);
            orders = orders.filter(
              (x) => x.statusCode === 3 || x.statusCode === 5
            );
            orders.forEach((r) => {
              userIds.push(r.clientId);
            });
            const users = await getUserByIdsPower(userIds);
            const orderResult = orders.map((order) => {
              const user = users.find((u) => u.id === order.clientId) || '';
              return {
                ...order,
                user
              };
            });
            return { data: orderResult };
          }
          return null;
        } catch (e) {
          return { error: e };
        }
      },
      providesTags: ['Order']
    }),
    ordersByIdRei: build.query({
      async queryFn({ from, to }) {
        /// Should receive date with this format with => " new Date( yyyy-mm-dd ) "
        try {
          const result = await getOrdersIdRei(
            Timestamp.fromDate(from),
            Timestamp.fromDate(to)
          );
          if (result) {
            let orderIds = [];
            const userIds = [];
            result.forEach((r) => {
              orderIds.push(r.orderRef);
            });
            orderIds = orderIds.filter((f) => f !== undefined);
            let orders = await getOrderByIds(orderIds);
            orders = orders.filter(
              (x) => x.statusCode === 3 || x.statusCode === 5
            );
            orders.forEach((r) => {
              userIds.push(r.clientId);
            });
            const users = await getUserByIdsPower(userIds);
            const orderResult = orders.map((order) => {
              const user = users.find((u) => u.id === order.clientId) || '';
              return {
                ...order,
                user
              };
            });
            return { data: orderResult };
          }
          return null;
        } catch (e) {
          return { error: e };
        }
      },
      providesTags: ['Order']
    }),
    ordersWhatsaap: build.query({
      async queryFn({ from, to }) {
        /// Should receive date with this format with => "new Date( yyyy-mm-dd ) "
        try {
          const result = await getOrdersByRange(
            Timestamp.fromDate(from),
            Timestamp.fromDate(to)
          );
          const users = await getAllUsers();
          const riders = await getRiders();
          const resultParsed = result
            .map((order) => {
              const partner =
                users.find((user) => user.id === order.clientId) || '';
              const rider =
                riders.find((riderItem) => riderItem.id === order.riderId) ||
                '';
              return {
                ...parseUserDataInOrders(order),
                partner,
                rider
              };
            })
            .filter((order) => Number(order?.statusCode) !== 7)
            .filter((order) => order.partner.support);
          return { data: resultParsed };
        } catch (e) {
          return { error: e };
        }
      }
    }),
    commerceToPerson: build.query({
      async queryFn({ justAdmin, from, to }) {
        try {
          const hasRange = !!from && !!to;
          let commerceToPersonOrders;
          if (hasRange) {
            commerceToPersonOrders = await getCommerceToPerson(
              justAdmin,
              from,
              to
            );
          } else {
            commerceToPersonOrders = await getCommerceToPerson(justAdmin);
          }
          return { data: commerceToPersonOrders };
        } catch (e) {
          return { error: e };
        }
      }
    }),
    getOrdersByUser: build.query({
      async queryFn(clientId) {
        try {
          const result = await getOrdersByUser(clientId);
          const resultParsed = result.map((order) => ({
            ...parseUserDataInOrders(order)
          }));
          return { data: resultParsed };
        } catch (e) {
          return { error: e };
        }
      }
    }),
    orderByIds: build.query({
      async queryFn(orderIds = []) {
        try {
          if (orderIds.length === 0) return { data: [] };
          const result = (await getOrderByIds(orderIds)) || [];
          const resultParsed = result.map((el) => parseUserDataInOrders(el));
          const nonEmptyOrders = getNonEmptyOrders(resultParsed);
          return { data: nonEmptyOrders };
        } catch (e) {
          return { error: e };
        }
      }
    }),
    updateOrder: build.mutation({
      async queryFn({ id, body }) {
        try {
          await updateOrder(id, body);
          return statusResponse.DONE;
        } catch (err) {
          return { error: err };
        }
      },
      invalidatesTags: ['Order']
    }),
    ordersByCoupon: build.query({
      async queryFn(couponId) {
        try {
          const result = await getOrdersByCoupon(couponId);
          const resultParsed = result.map((order) => ({
            ...parseUserDataInOrders(order)
          }));
          return { data: resultParsed };
        } catch (e) {
          return { error: e };
        }
      }
    }),
    ordersWithoutRoadmap: build.query({
      async queryFn() {
        try {
          const result = await getOrdersWithoutRoadmap();
          return { data: result };
        } catch (e) {
          return { error: e };
        }
      }
    })
  })
});

export const {
  useOrdersByRangeQuery,
  useOrdersWhatsaapQuery,
  useCommerceToPersonQuery,
  useGetOrdersByUserQuery,
  useOrdersQuery,
  useOrderByIdsQuery,
  useUpdateOrderMutation,
  useOrdersByCouponQuery,
  useOrdersWithoutRoadmapQuery
} = ordersApi;
