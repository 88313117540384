import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from '../../firebase';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getCategoryBox = createAsyncThunk('@categoryBox/get', async () =>
  firebase.getCategoriesBox()
);

export const createCategoryBox = createAsyncThunk(
  '@categoryBox/create',
  async (data) => firebase.createCategoryBox(data)
);

export const updateCategoryBox = createAsyncThunk(
  '@categoryBox/update',
  async ({ id, data }) => firebase.updateCategoryBox(id, data)
);

export const deleteCategoryBox = createAsyncThunk(
  '@categoryBox/delete',
  async (id) => firebase.deleteCategoryBox(id)
);

export const categoryBoxSlide = createSlice({
  name: 'categoryBox',
  initialState,
  reducers: {
    setCategoryBoxLoading: (state) => {
      state.loading = true;
    },
    setCategoryBox: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setCategoryBoxError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getCategoryBox.pending]: (state) => {
      state.loading = true;
    },
    [getCategoryBox.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getCategoryBox.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createCategoryBox.pending]: (state) => {
      state.loading = true;
    },
    [createCategoryBox.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createCategoryBox.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteCategoryBox.pending]: (state) => {
      state.loading = true;
    },
    [deleteCategoryBox.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteCategoryBox.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateCategoryBox.pending]: (state) => {
      state.loading = true;
    },
    [updateCategoryBox.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateCategoryBox.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setCategoryBoxLoading, setCategoryBox, setCategoryBoxError } =
  categoryBoxSlide.actions;

export default categoryBoxSlide.reducer;
