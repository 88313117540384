const defaultPermissions = ['create', 'read', 'update', 'delete'];

export const HOME = 'home';
export const MY_ACCOUNT = 'home';
export const PRODUCTS = 'products';
export const USERS = 'users';
export const ROLES = 'roles';
export const INVOICES = 'invoices';
export const NOTIFICATIONS = 'notifications';
export const COMMISSIONS = 'commissions';
export const MESSAGES = 'messages';
export const DEPARTMENTS = 'departments';
export const ROADMAPS = 'roadmaps';
export const ROUTES = 'routes';
export const ORDERS = 'orders';
export const ORDERS_PARENT = 'orders-parent';
export const RIDERS_PARENT = 'riders-parent';
export const RIDERS = 'riders';
export const PROCOS = 'procos';
export const FINANCE = 'finance';
export const COUPONS = 'coupons';
export const SPECIAL_CATEGORIES = 'special-categories';
export const CATEGORY_BOX = 'category-box';
export const CATEGORY_PRODUCT = 'category-product';
export const BILLING = 'billing';
export const PHARMACY = 'pharmacy';
export const STORAGE = 'storage';
export const PAGOMOVIL = 'finance-pago-movil';

export default [
  {
    name: 'home',
    permissions: [...defaultPermissions]
  },
  {
    name: 'myAccount',
    permissions: [...defaultPermissions]
  },
  {
    name: 'products',
    permissions: [...defaultPermissions]
  },
  {
    name: 'roles',
    permissions: [...defaultPermissions]
  },
  {
    name: 'invoices',
    permissions: [...defaultPermissions]
  },
  {
    name: 'notifications',
    permissions: [...defaultPermissions]
  },
  {
    name: 'routes',
    permissions: [...defaultPermissions]
  },
  {
    name: 'riders',
    permissions: [...defaultPermissions]
  },
  {
    name: 'finance',
    permissions: [...defaultPermissions]
  }
];
