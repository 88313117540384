import { Button, Form, Input, Select, Space } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { numericMask } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCustomer,
  setInvoiceData,
  updateStep
} from 'stores/actions/checkout';
import { CheckOutlined } from '@ant-design/icons';
import { getSteps, getCustomer } from 'stores/selectors/checkout';
import styles from '../CreateOrder.module.scss';
import { checkoutSteps } from '../constants';

const { Option } = Select;
const { Item } = Form;

const identificationTypes = ['V', 'E', 'J', 'P', 'G'];
const numberPrefix = ['0414', '0424', '0416', '0426', '0412'];
export default function BillingData({ user }) {
  const [form] = Form.useForm();
  const [type, setType] = useState('V');
  const [numberPhone, setNumberPhone] = useState(
    user?.invoiceData?.prefix ?? '04--'
  );
  const dispatch = useDispatch();
  const steps = useSelector(getSteps);
  const customer = useSelector(getCustomer);

  const handleFinish = (v) => {
    dispatch(
      setCustomer({
        customer: {
          ...v,
          email: v.email ? v.email : user?.email,
          dniType: type,
          phone: numberPhone + v.phone,
          clientName: user?.name,
          clientId: user?.id
        }
      })
    );
    dispatch(
      setInvoiceData({
        ...v,
        email: v.email ? v.email : user?.email,
        dniType: type,
        phone: numberPhone + v.phone
      })
    );
    dispatch(updateStep({ mode: 'add', stepName: checkoutSteps[0] }));
  };

  const reEdit = () => {
    dispatch(updateStep({ mode: 'remove', stepName: checkoutSteps[0] }));
  };

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex'
      }}
    >
      <p className={styles.titles}>
        Datos de facturacion
        {steps.includes(checkoutSteps[0]) && (
          <CheckOutlined
            className={[
              styles.billingDataSaved,
              styles.appearingAnimation
            ].join(' ')}
          />
        )}
      </p>
      {steps.includes(checkoutSteps[0]) && (
        <div className={[styles.noEdit, styles.noEdit__noMarginTop].join(' ')}>
          <p>Nombre de la factura: {customer?.fullname}</p>
          <p>Cedula: {customer?.dni}</p>
          <Button className={styles.confirmButton__accept} onClick={reEdit}>
            <p className={styles.text}>Editar</p>
          </Button>
        </div>
      )}
      {!steps.includes(checkoutSteps[0]) && (
        <Form
          className={styles.formContainer}
          form={form}
          name="cash-payment"
          onFinish={handleFinish}
          scrollToFirstError
          initialValues={{
            fullname: user?.invoiceData ? user?.invoiceData?.fullname : '',
            prefix: user?.invoiceData ? user?.invoiceData?.prefix : '04--',
            phone: user?.invoiceData
              ? user?.invoiceData?.phone.substring(4, 20)
              : '',
            dni: user?.invoiceData ? user?.invoiceData.dni : '',
            email: user?.invoiceData?.email || ''
          }}
        >
          <div className={styles.formcontainer}>
            <Item name="fullname">
              <Input
                className={styles.fullInput}
                placeholder="Nombre Completo"
                required
                maxLength={30}
              />
            </Item>
            <div
              style={{
                display: 'flex',
                maxHeight: 47,
                columnGap: 18,
                marginBottom: 18,
                justifyContent: 'flex-start',
                flexDirection: 'row'
              }}
            >
              <Item name="prefix" className={styles.prefix}>
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className={styles.input}
                  style={{ width: 84, minWidth: 84, height: 48 }}
                  onChange={(e) => setNumberPhone(e)}
                  value={numberPhone}
                >
                  {numberPrefix.map((prefix, index) => (
                    <Option key={index} value={prefix}>
                      {prefix}
                    </Option>
                  ))}
                </Select>
              </Item>
              <Item name="phone" style={{ flex: 1 }}>
                <Input
                  name="phone"
                  onKeyPress={numericMask}
                  maxLength={7}
                  required
                  minLength={7}
                  className={`${styles.fullInput} ${styles.fullInput__leftBorder}`}
                  placeholder="Numero de telefono"
                />
              </Item>
            </div>
            <Item name="email">
              <Input
                className={styles.fullInput}
                placeholder="Email"
                type="email"
                maxLength={30}
                required
              />
            </Item>
            <div style={{ display: 'flex', columnGap: 18, maxHeight: 47 }}>
              <Select
                className={styles.input}
                onChange={(e) => setType(e)}
                value={type}
              >
                {identificationTypes.map((t) => (
                  <Option value={t} key={t}>{`${t}`}</Option>
                ))}
              </Select>
              <Item name="dni" style={{ flex: 1 }}>
                <Input
                  required
                  onKeyPress={numericMask}
                  className={`${styles.fullInput} ${styles.fullInput__leftBorder}`}
                  placeholder="Numero de cedula"
                  maxLength={type === 'V' ? 8 : 9}
                  minLength={6}
                />
              </Item>
            </div>
          </div>
          {/* <Item
          name="saveData"
          valuePropName="checked"
          className={styles.checkbox}
        >
          <Checkbox>{t('checkout.billingData.saveData')}</Checkbox>
        </Item> */}
          <div className={styles.buttonsContainer}>
            <Button className={styles.confirmButton} htmlType="submit">
              <p className={styles.text}>Guardar</p>
            </Button>
          </div>
        </Form>
      )}
    </Space>
  );
}

BillingData.defaultProps = {
  user: null
};

BillingData.propTypes = {
  user: PropTypes.shape({
    invoiceData: PropTypes.object
  })
};
