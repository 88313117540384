import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders as getOrdersX } from 'stores/selectors/orders';
import {
  getOrders as getOrdersAction,
  getOrdersByStatusCode,
  getOneOrder
} from 'stores/actions/orders';
import { getPharmaOrders } from 'firebase/api';

const useGetOrdersHook = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(getOrdersX);
  const [pharmacy, setPharmacy] = React.useState([]);

  const getOrders = async () => await dispatch(getOrdersAction());

  const getOrderByStatusCode = async (statusCode) => {
    dispatch(getOrdersByStatusCode(statusCode));
  };

  const findOneOrder = async (orderName) => {
    const res = await dispatch(getOneOrder(orderName));
    return res.payload[0];
  };

  const getPharmacyOrders = async () => {
    const orders = await getPharmaOrders();
    setPharmacy(orders);
  };

  return {
    getOrders,
    getOrderByStatusCode,
    ordersList: data,
    loadingOrdersList: loading,
    findOneOrder,
    pharmacyOrders: pharmacy,
    getPharmacyOrders
  };
};

export default useGetOrdersHook;
