const observations = [
  {
    name: 'FACT',
    code: 'A03',
    description: 'ATRASO POR FACTURACION'
  },
  {
    name: 'VALPM',
    code: 'A01',
    description: 'ATRASO POR VALIDACION DE PAGO MOVIL'
  },
  {
    name: 'BANC',
    code: 'B01',
    description: 'BANCO CAIDO'
  },
  {
    name: 'CDCL',
    code: 'C04',
    description: 'CAMBIO DE DIRECCION POR EL CLIENTE'
  },
  {
    name: 'CDP',
    code: 'P01',
    description: 'CAMBIO DE PRODUCTO'
  },
  {
    name: 'SKUERR',
    code: 'Q04',
    description: 'CANTIDAD ERRADA DE PRODUCTO'
  },
  {
    name: 'CIVR',
    code: 'T01',
    description: 'CIERRE DE VIAS RAPIDAS'
  },
  {
    name: 'CAUS',
    code: 'C01',
    description: 'CLIENTE AUSENTE'
  },
  {
    name: 'CCMP',
    code: 'C02',
    description: 'CLIENTE CAMBIA METODO DE PAGO'
  },
  {
    name: 'CCVV',
    code: 'C03',
    description: 'CLIENTE COMPRA VARIAS VECES'
  },
  {
    name: 'CNRPM',
    code: 'C06',
    description: 'CLIENTE NO REALIZO PM'
  },
  {
    name: 'CSP',
    code: 'C05',
    description: 'CLIENTE SIN PIN'
  },
  {
    name: 'CEC',
    code: 'C08',
    description: 'COMPRA HERRADA CANCELADA'
  },
  {
    name: 'CHFS',
    code: 'H01',
    description: 'COMPRA FUERA DE HORARIO DE SERVICIO'
  },
  {
    name: 'CINT',
    code: 'I01',
    description: 'COMPRA INTERNA'
  },
  {
    name: 'CCSKU',
    code: 'C07',
    description: 'CONSULTA A CLIENTE POR SKU (COLOR/MODELO/PROMO/FV)'
  },
  {
    name: 'DMRIDR',
    code: 'D01',
    description: 'DESPACHO MANUAL'
  },
  {
    name: 'DEVPRO',
    code: 'P06',
    description: 'DEVOLUCION DE PRODUCTO'
  },
  {
    name: 'DELC',
    code: 'D02',
    description: 'DISTURBIOS EN LA CIUDAD'
  },
  {
    name: 'EEDLO',
    code: 'R08',
    description: 'ENTREGA ERRADA DE LA ORDEN'
  },
  {
    name: 'ENIR',
    code: 'R05',
    description: 'ENTREGA INCOMPLETA RIDER'
  },
  {
    name: 'ENPC',
    code: 'E01',
    description: 'ENTREGA PROGRAMADA'
  },
  {
    name: 'CEF',
    code: 'E02',
    description: 'ENTREGAS EMPRESA HERMANA'
  },
  {
    name: 'EAD',
    code: 'E03',
    description: 'EVENTO ADAN'
  },
  {
    name: 'EEC',
    code: 'E04',
    description: 'EVENTO EN LA CIUDAD'
  },
  {
    name: 'FAPALM',
    code: 'F01',
    description: 'FALLAS APLIC ALMACENISTA'
  },
  {
    name: 'FAPR',
    code: 'F02',
    description: 'FALLAS EN LA APP RIDER'
  },
  {
    name: 'FEXT',
    code: 'F03',
    description: 'FALLAS EXTERNAS (INT, LINEAS TELF, ELECT)'
  },
  {
    name: 'APRM',
    code: 'F04',
    description: 'FALTA DE APROBACION DE PREINSCRIPCION MEDICA'
  },
  {
    name: 'FEOW',
    code: 'W01',
    description: 'FOTO ERRADA ODOO/WEB'
  },
  {
    name: 'IFIFO',
    code: 'R09',
    description: 'INCUMPLIMIENTO FIFO'
  },
  {
    name: 'LLUVF',
    code: 'T02',
    description: 'LLUVIAS FUERTES'
  },
  {
    name: 'NTLFE',
    code: 'C09',
    description: 'NUMERO DE TELEFONO ERRADO'
  },
  {
    name: 'ORCC',
    code: 'O01',
    description: 'ORDEN CANCELADA'
  },
  {
    name: 'OEXR',
    code: 'R06',
    description: 'ORDEN CANCELADA'
  },
  {
    name: 'OFDR',
    code: 'O03',
    description: 'ORDEN FUERA DE ROADMAP'
  },
  {
    name: 'ORG',
    code: 'O02',
    description: 'ORDEN GRANDE'
  },
  {
    name: 'PKATR',
    code: 'Q05',
    description: 'PICKING ATRASADO'
  },
  {
    name: 'FPLI',
    code: 'P01',
    description: 'PLANIFICACION INTERNA'
  },
  {
    name: 'PAPAD',
    code: 'F05',
    description: 'PROBLEMAS CON APLICACIÓN ADAN'
  },
  {
    name: 'PADODD',
    code: 'F06',
    description: 'PROBLEMAS CON EL ADMIN/ODDO'
  },
  {
    name: 'PDR',
    code: 'P03',
    description: 'PRODUCTO DAÑADO RIDER'
  },
  {
    name: 'PERR',
    code: 'P04',
    description: 'PRODUCTO ERRADO'
  },
  {
    name: 'PVEN',
    code: 'P05',
    description: 'PRODUCTO VENCIDO'
  },
  {
    name: 'RSTOCK',
    code: 'Q01',
    description: 'QUIEBRE DE STOCK'
  },
  {
    name: 'RDPC',
    code: 'R10',
    description: 'REDESPACHO'
  },
  {
    name: 'RPPF',
    code: 'R11',
    description: 'REPOSICION POR FALTANTE'
  },
  {
    name: 'RACCD',
    code: 'R03',
    description: 'RIDER ACCIDENTADO'
  },
  {
    name: 'RNL',
    code: 'R04',
    description: 'RIDER NO LIBERADO (SOPORTE)'
  },
  {
    name: 'RDND',
    code: 'R01',
    description: 'RIDERS NO DISPONIBLE'
  },
  {
    name: 'RDSG',
    code: 'R07',
    description: 'RIDER SIN GASOLINA'
  },
  {
    name: '2DAR',
    code: 'P02',
    description: '2DA RUTA (PLANIFICACION)'
  },
  {
    name: 'SKUPC',
    code: 'Q02',
    description: 'SKU EN POSC COMPARTIDA'
  },
  {
    name: 'SKUMP',
    code: 'Q03',
    description: 'SKU MAL POSICIONADO'
  },
  {
    name: 'SPG',
    code: 'G01',
    description: 'SOLO PERSONAL DE GUARDIA'
  },
  {
    name: 'UCP',
    code: 'C10',
    description: 'USO DE CUPONES'
  }
];

export default observations;
