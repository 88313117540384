import { parseUserDataInOrders } from 'stores/selectors/orders';

export const billingFactory = (orders = [], printed = false) => {
  const nonEmptyOrders = orders.filter(
    (order) =>
      order?.hasOwnProperty('odooDataOrder') &&
      Object.keys(order?.odooDataOrder).length > 0
  );

  return nonEmptyOrders
    .map((order) => {
      return {
        ...parseUserDataInOrders(order)
      };
    })
    .filter((order) => {
      if (printed) return order?.printedInvoice;
      return !order?.printedInvoice;
    });
};
