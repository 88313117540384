import {
  BarcodeOutlined,
  PrinterOutlined,
  EditOutlined,
  CheckSquareOutlined,
  ReloadOutlined,
  CloseSquareOutlined
} from '@ant-design/icons';
import { Divider, Table, Tabs, Tooltip, Button, Popconfirm } from 'antd';
import { httpsCallable } from 'firebase/functions';
import useColumnSearch from 'hooks/useColumnSearch';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sorterData, sorterDate } from 'utils/table';
import { updateOrder } from 'stores/actions/orders';
import { firebaseDateFormatter, formatPriceToBsF } from 'utils/utils';
import services from 'services/billing';
import { showNotification } from 'stores/actions/notification';
import DiscountDetails from 'pages/Orders/Orders/DiscountDetails';
import PaymentType from 'components/PaymentType/PaymentType';
import { AnonymousUser } from 'components/AnonymousUser';
import RangeDateFilter from 'components/RangeDateFilter';
import useRangeDateFilter from 'hooks/useRangeDateFilter';
import { useOrdersByRangeQuery } from 'stores/query/ordersQuery';
import { closeModal, openModal } from 'stores/actions/modal';
import EditOrder from 'components/EditOrder/EditOrder';
import { functions } from 'firebase/firebaseConfig';
import { billingFactory } from './helpers';
import PrintInvoiceValidationModal from './PrintInvoiceValidationModal';
import PrintCreditNoteModal from './PrintCreditNoteModal';

const { TabPane } = Tabs;

const Billing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchFilter = useColumnSearch();
  const { handleRange, lastSevenDaysDefault } = useRangeDateFilter();
  const rangeFiltered = {
    ...lastSevenDaysDefault,
    excludeRider: true,
    excludeStatus: 7
  };
  const { data: ordersData, refetch } = useOrdersByRangeQuery(rangeFiltered);
  const [showDiscountDetails, setShowDiscountDetails] = useState(false);
  const [discountDetail, setDiscountDetail] = useState(null);
  const [foundPrinter, setFoundPrinter] = useState(false);
  const [loading, setLoading] = useState(false);

  const unprintedOrders = billingFactory(ordersData, false);
  const printedOrders = billingFactory(ordersData, true);

  const printInvoice = async (order) => {
    try {
      const orderInfo = {
        id: order.id,
        body: {
          printedInvoice: true,
          printedInvoiceDate: new Date()
        }
      };
      if (order.printedInvoice) {
        openPrintModal(order);
        return;
      }
      setLoading(true);
      const completeOrder = httpsCallable(functions, 'odoo-completeOrder');
      const invoiceData = await completeOrder({ orderId: order.id });
      dispatch(
        showNotification({
          type: 'success',
          message: 'Exito',
          content: 'Orden completada, imprimiendo factura...'
        })
      );
      await services.printInvoice(invoiceData.data);
      await dispatch(updateOrder(orderInfo));
      setLoading(false);
      refetch();
    } catch (err) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: err.message
        })
      );
    }
  };

  const openPrintModal = (order) => {
    const modalContent = {
      component: PrintInvoiceValidationModal,
      props: {
        cancel: () => dispatch(closeModal()),
        order,
        refetch
      }
    };
    dispatch(
      openModal({
        content: modalContent,
        buttons: [],
        title: 'Validacion de reimpresion de factura'
      })
    );
  };

  const openCreditNoteModal = (order) => {
    const modalContent = {
      component: PrintCreditNoteModal,
      props: {
        cancel: () => dispatch(closeModal()),
        order,
        refetch
      }
    };
    dispatch(
      openModal({
        content: modalContent,
        buttons: [],
        title: 'Nota de crédito'
      })
    );
  };

  const printCreditNote = async (order) => {
    if (order.printedInvoice) {
      openCreditNoteModal(order);
    }
  };

  const handleDiscountDetails = (record) => {
    setDiscountDetail(record);
    setShowDiscountDetails(!showDiscountDetails);
  };

  const openEditOrderModal = (order) => {
    if (order) {
      const modalContent = {
        component: EditOrder,
        props: {
          cancel: () => dispatch(closeModal()),
          order,
          markAsPrinted
        }
      };
      dispatch(
        openModal({
          content: modalContent,
          buttons: [],
          title: 'Editar Productos'
        })
      );
    }
  };

  const markAsPrinted = async (order) => {
    try {
      const orderInfo = {
        id: order.id,
        body: {
          printedInvoice: true,
          printedInvoiceDate: new Date(),
          printedManually: true
        }
      };
      await dispatch(updateOrder(orderInfo));
      refetch();
    } catch (err) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: err.message
        })
      );
    }
  };

  const columns = [
    {
      title: t('pages.orders.table.num'),
      dataIndex: 'name',
      key: 'name',
      sorter: sorterData('name'),
      ...searchFilter('name')
    },
    {
      title: t('pages.orders.table.client'),
      dataIndex: 'clientName',
      key: 'clientName',
      sorter: sorterData('clientName'),
      ...searchFilter('clientName'),
      render: (_, record) => (
        <AnonymousUser
          user={record}
          customizedInvoice={record.customizedInvoice}
        />
      )
    },
    {
      title: t('pages.orders.table.date'),
      dataIndex: 'date',
      key: 'date',
      sorter: sorterDate('date'),
      ...searchFilter('date'),
      render: (element) => firebaseDateFormatter(element)
    },
    {
      title: t('pages.orders.table.paymentType'),
      dataIndex: 'paymentType',
      key: 'paymentType',
      sorter: sorterData('paymentType'),
      ...searchFilter('paymentType'),
      render: (_, record) => <PaymentType data={record} />
    },
    {
      title: t('pages.orders.table.total'),
      dataIndex: 'total',
      key: 'total',
      sorter: sorterData('amount_total'),
      ...searchFilter('amount_total'),
      render: (value) => formatPriceToBsF(value)
    },
    {
      title: t('pages.orders.table.discount'),
      dataIndex: 'discount',
      key: 'discount',
      align: 'center',
      render: (record, order) =>
        record?.code ? (
          <BarcodeOutlined onClick={() => handleDiscountDetails(order)} />
        ) : (
          'Sin Descuento'
        )
    }
  ];

  const columnsWithAction = [
    ...columns,
    {
      title: 'Accion',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, order) => (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {foundPrinter && (
            <>
              <>
                <Tooltip placement="top" title="Editar Orden">
                  <Button
                    type="text"
                    onClick={() => openEditOrderModal(order)}
                    disabled={loading}
                    icon={<EditOutlined style={{ fontSize: '1.2rem' }} />}
                  />
                </Tooltip>
              </>
              <>
                <Tooltip placement="top" title="Imprimir Factura">
                  <Button
                    type="text"
                    onClick={() => printInvoice(order)}
                    disabled={loading}
                    icon={<PrinterOutlined style={{ fontSize: '1.2rem' }} />}
                  />
                </Tooltip>
              </>
              <>
                <Tooltip placement="top" title="Mover a impresas">
                  <Popconfirm
                    title="Imprimió la factura manualmente?"
                    onConfirm={() => markAsPrinted(order)}
                    onCancel={() => null}
                    okText="Si"
                    cancelText="No"
                  >
                    <Button
                      type="text"
                      disabled={loading}
                      icon={
                        <CheckSquareOutlined style={{ fontSize: '1.2rem' }} />
                      }
                    />
                  </Popconfirm>
                </Tooltip>
              </>
            </>
          )}
        </div>
      )
    }
  ];

  const columnsWithPrint = [
    ...columns,
    {
      title: 'Accion',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, order) => (
        <div>
          {foundPrinter && (
            <div>
              <>
                <Tooltip placement="top" title="Imprimir Factura">
                  <Button
                    type="text"
                    onClick={() => printInvoice(order)}
                    disabled={loading}
                    icon={<PrinterOutlined style={{ fontSize: '1.2rem' }} />}
                  />
                </Tooltip>
                {
                  // if user not has credit note
                  !order?.hasCreditNote && (
                    <Tooltip placement="top" title="Nota de crédito">
                      <Button
                        type="text"
                        onClick={() => printCreditNote(order)}
                        disabled={loading}
                        icon={
                          <CloseSquareOutlined style={{ fontSize: '1.2rem' }} />
                        }
                      />
                    </Tooltip>
                  )
                }
              </>
            </div>
          )}
        </div>
      )
    }
  ];

  useEffect(() => {
    const ping = async () => {
      const response = await services.pingPrinter();

      if (response.status === 200) {
        dispatch(
          showNotification({
            type: 'success',
            content: 'Conexion establecida con impresora'
          })
        );
        setFoundPrinter(true);
      } else {
        dispatch(
          showNotification({
            type: 'error',
            message: 'Conexion con impresora no establecida',
            content: 'Intente nuevamente o revise el estado de la impresora '
          })
        );
      }
    };

    ping();
  }, [dispatch]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <RangeDateFilter
          onRefresh={() => refetch()}
          hasDefault
          onChange={(arg) => handleRange(arg)}
        />
        <Button
          style={{ marginLeft: '15px' }}
          icon={<ReloadOutlined style={{ fontSize: '15px' }} />}
          type="primary"
          onClick={refetch}
          shape="circle"
        />
      </div>
      <Tabs>
        <TabPane tab="Por imprimir" key="1">
          <Divider orientation="left">
            <h3>Por imprimir</h3>
          </Divider>
          <Table
            rowKey="id"
            dataSource={unprintedOrders}
            columns={columnsWithAction}
          />
        </TabPane>
        <TabPane tab="Impresas" key="2">
          <Divider orientation="left">
            <h3>Impresas</h3>
          </Divider>
          <Table
            rowKey="id"
            dataSource={printedOrders}
            columns={columnsWithPrint}
          />
        </TabPane>
      </Tabs>
      {showDiscountDetails && (
        <DiscountDetails
          visible={showDiscountDetails}
          onClose={handleDiscountDetails}
          data={discountDetail}
        />
      )}
    </>
  );
};

export default Billing;
