import React, { useCallback, useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import styles from './ForgotPasswordForm.module.scss';
import runTranslate from './formRules';
import { MailOutlined } from '@ant-design/icons';

const ForgotPasswordForm = ({ onSubmit, handlerGoBack }) => {
  const [form] = Form.useForm();
  const [formRules] = useState(() => runTranslate());

  const onFinish = useCallback(
    async (formFieldValues) => {
      const { email } = formFieldValues;
      await onSubmit(email);
    },
    [onSubmit]
  );

  return (
    <Form
      className={styles.form_forgotpass_container}
      form={form}
      name="forgotPassword"
      onFinish={onFinish}
      scrollToFirstError
      labelCol={{ xs: { span: 24 }, sm: { span: 8 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
    >
      <Form.Item
        name="email"
        rules={formRules.emailRules}
        className={styles.form_item}
      >
        <Input placeholder="Email" prefix={<MailOutlined />} />
      </Form.Item>

      <Form.Item className={styles.form_item}>
        <Space>
          <Button
            className={styles.button_reset}
            type="primary"
            htmlType="submit"
          >
            Reset
          </Button>
          <Button
            onClick={handlerGoBack}
            className={styles.button_reset}
            type="primary"
          >
            Go Back
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ForgotPasswordForm;
