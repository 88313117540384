/* eslint-disable no-param-reassign  */

import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    title: '',
    open: false,
    content: undefined,
    buttons: [],
    showOkButton: false,
    width: undefined
  },
  reducers: {
    setButtons: (state, action) => {
      state.buttons = action.payload.buttons;
    },
    setContent: (state, action) => {
      state.content = action.payload.content;
    },
    openModal: (state, action) => {
      state.content = action.payload.content;
      state.buttons = action.payload.buttons;
      state.open = true;
      state.title = action.payload.title;
      state.showOkButton = action.payload.showOkButton;
      state.width = action.payload.width;
    },
    closeModal: (state) => {
      state.open = false;
      state.content = undefined;
      state.buttons = [];
      state.title = '';
      state.showOkButton = false;
      state.width = undefined;
    }
  }
});

export const {
  openModal,
  closeModal,
  setButtons,
  setContent,
  initialState
} = modalSlice.actions;

export default modalSlice.reducer;
