import React, { createContext } from 'react';
import useGetOrdersFromToday from 'hooks/useGetOrdersFromToday';

export const NewOrdersContext = createContext({
  orders: [],
  payments: [],
  paymentsCount: 0,
  count: 0
});

const NewOrdersProvider = ({ children }) => {
  const { data = [] } = useGetOrdersFromToday();
  const orders = data;
  const count = orders.length || 0;
  return (
    <NewOrdersContext.Provider value={{ orders, count }}>
      {children}
    </NewOrdersContext.Provider>
  );
};

export default NewOrdersProvider;
