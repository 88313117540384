import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import firebase from '../../firebase';

export const initialState = {
  data: [],
  pricesBackup: [],
  loading: false,
  error: false
};

export const getProductPrices = createAsyncThunk(
  '@finance/getPrices',
  async (body = { offset: 1, limit: 10 }) => {
    const res = await axios.post(
      'https://us-central1-adan-farmacia.cloudfunctions.net/adan_pricing_tool',
      body
    );

    return res.data.data || [];
  }
);

export const getPricesBackup = createAsyncThunk(
  '@finance/getPricesBackup',
  async () => await firebase.getPricesBackup()
)

export const createPriceBackup = createAsyncThunk(
  '@finance/createPriceBackup',
  async (newPriceBackup) => {
    await firebase.createPriceBackup(newPriceBackup)
  }
)

export const updatePriceBackup = createAsyncThunk(
  '@finance/updatePriceBackup',
  async ({ id, ...body }) => await firebase.updatePriceBackup(id, body)
)

export const pricesSlide = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: {
    [getProductPrices.pending]: (state) => {
      state.loading = true;
    },
    [getProductPrices.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getProductPrices.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getPricesBackup.pending]: (state) => {
      state.loading = true;
    },
    [getPricesBackup.fulfilled]: (state, action) => {
      state.pricesBackup = action.payload;
      state.loading = false;
    },
    [getPricesBackup.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createPriceBackup.pending]: (state) => {
      state.loading = true;
    },
    [createPriceBackup.fulfilled]: (state) => {
      state.loading = false;
    },
    [createPriceBackup.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updatePriceBackup.pending]: (state) => {
      state.loading = true;
    },
    [updatePriceBackup.fulfilled]: (state) => {
      state.loading = false;
    },
    [updatePriceBackup.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export default pricesSlide.reducer;
