import { createSelector } from '@reduxjs/toolkit';
import { getOrdersData } from './orders';
import { getUsersData } from './users';

const selectSelf = (state) => state;

export const getConfig = createSelector(selectSelf, (state) => state?.config);

export const getConfigData = createSelector(
  getConfig,
  (config) => config?.data || {}
);

export const isConfigLoading = createSelector(
  getConfig,
  (config) => config.loading
);

export const hasConfigError = createSelector(
  getConfig,
  (config) => config.error
);

export const getPaymentMethods = createSelector(getConfigData, (config) => {
  const paymentMethods =
    config?.paymentMethods?.methods?.filter((method) => method.available) || [];
  const mixPayment = {
    name: 'Mixto',
    value: 'mix'
  };
  const newPaymentMethods = paymentMethods.map((paymentMethod, index) =>
    paymentMethod.value === 'creditCard'
      ? {
          ...mixPayment,
          ...paymentMethod,
          name: 'Tarjeta Internacional',
          value: 'stripe'
        }
      : { ...paymentMethod }
  );
  return [...newPaymentMethods, mixPayment];
});

export const getOrderStatus = createSelector(getConfigData, (config) => {
  const ordersStatusParsed = config?.orders?.statusCode.map((record) => ({
    label: record.label,
    value: record.code
  }));
  return ordersStatusParsed || [];
});

export const getCommerceToPersonData = createSelector(
  getConfigData,
  getOrdersData,
  getUsersData,
  (config, orders, clients) => {
    const pagoMovilData = config?.PagoMovil;
    const resCommerceToPerson =
      pagoMovilData && pagoMovilData?.commerceToPerson;

    return resCommerceToPerson
      ?.filter((record) => record?.fromAdmin === true)
      .map((record) => {
        const order = orders.find((order) => order.id === record?.orderId);
        const client = clients.find((client) => client.id === order?.clientId);
        return {
          ...record,
          order: order?.odooDataOrder?.name,
          client: {
            name: client?.name,
            dni: client?.dni,
            phone: client?.phone,
            email: client?.email
          }
        };
      });
  }
);

export const getAllCommerceToPersonData = createSelector(
  getConfigData,
  getOrdersData,
  getUsersData,
  (config, orders, clients) => {
    const pagoMovilData = config?.PagoMovil;
    const resCommerceToPerson =
      pagoMovilData && pagoMovilData?.commerceToPerson;

    return resCommerceToPerson?.map((record) => {
      const order = orders.find((order) => order.id === record?.orderId);
      const client = clients.find((client) => client.id === order?.clientId);
      return {
        ...record,
        order: order?.odooDataOrder?.name,
        totalAmount: order?.total || 0,
        client: {
          name: client?.name,
          dni: client?.dni,
          phone: client?.phone,
          email: client?.email
        }
      };
    });
  }
);

export const hasConfigData = createSelector(
  getConfigData,
  (config) => config.length > 0
);
export const isConfigLoaded = createSelector(
  isConfigLoading,
  hasConfigData,
  (isLoading) => !isLoading
);
