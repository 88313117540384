import React from 'react';
import { Button, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { loadingWithSpinner, loaded } from 'stores/actions/loader';
import { showNotification } from 'stores/actions/notification';
import { updateOrder } from 'firebase/api';
import observations from './observationList';
import PropTypes from 'prop-types';

const Observations = ({ cancelForm, order, getOrders }) => {
  const [value, setValue] = React.useState([]);
  const dispatch = useDispatch();

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const confirm = async () => {
    try {
      dispatch(loadingWithSpinner());
      const body = value.map((item) => {
        const observation = observations.find((obs) => obs.name === item.value);
        return {
          name: item.value || item.name,
          description: item.label || item.description || item.value,
          code: observation ? observation.code : item.value
        };
      });

      await updateOrder(order.id, { observations: body });
      await getOrders();
      dispatch(
        showNotification({ type: 'success', message: 'Orden actualizada' })
      );
      dispatch(loaded());
      cancelForm();
    } catch (error) {
      dispatch(showNotification({ type: 'error', message: error.message }));
    }
  };

  React.useEffect(() => {
    if (order?.observations) {
      const newObservations = order.observations.map((item) => {
        return {
          value: item.name,
          label: item.description
        };
      });
      setValue(newObservations);
    }
  }, [order]);

  return (
    <div>
      <Select
        mode="tags"
        style={{ width: '100%' }}
        placeholder="Observaciones"
        options={observations.map((obs) => ({
          description: obs.description,
          name: obs.name,
          code: obs.code
        }))}
        fieldNames={{ label: 'description', value: 'name' }}
        value={value}
        labelInValue
        allowClear
        onClear={() => setValue([])}
        onChange={(newVal) => onChange(newVal)}
      />

      {(order?.observations !== undefined || value.length >= 1) && (
        <Button
          style={{ marginTop: '15px', width: '100%' }}
          disabled={value?.length === 0 && !order?.observations?.length > 0}
          type="primary"
          onClick={confirm}
        >
          Confirmar
        </Button>
      )}
    </div>
  );
};

Observations.propTypes = {
  cancelForm: PropTypes.func.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
    observations: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        code: PropTypes.string
      })
    )
  }).isRequired,
  getOrders: PropTypes.func.isRequired
};

export default Observations;
