/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Row, Col, Button } from 'antd';
import { showNotification } from 'stores/actions/notification';
import { useTranslation } from 'react-i18next';
import useGetRidersHook from 'hooks/componentHooks/useGetRidersHook';
import RoadmapAssignScan from './RoadmapAssignScan';

const RoadmapsAssign = ({ roadmap, cancel, assignRider }) => {
  const [selectedRider, setSelectedRider] = useState('');
  const { ridersData: riders } = useGetRidersHook();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const onSubmit = () => {
    if (!selectedRider) {
      dispatch(
        showNotification({
          type: 'error',
          content: 'Necesita seleccionar un rider'
        })
      );
      return;
    }
    const newRoadmap = {
      log: [
        ...roadmap.log,
        {
          id: uuidv4(),
          type: 'start',
          description: 'riderAssignedManually',
          target: selectedRider,
          date: moment().unix()
        },
        {
          id: uuidv4(),
          type: 'delivery',
          description: 'inProgress',
          target: selectedRider,
          date: moment().unix()
        }
      ],
      status: 'inprogress',
      riderId:
        riders.find((rider) => rider.uid === selectedRider.uid).uid ||
        roadmap.riderId
    };

    const newRider = {
      status: 'busy'
    };
    assignRider(roadmap, newRoadmap, newRider);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <RoadmapAssignScan
            setSelectedRider={setSelectedRider}
            riders={riders}
            selectedRider={selectedRider}
          />
        </Col>
      </Row>
      <Row>
        <Col span={4} offset={16}>
          <Button onClick={cancel}>{t('common.button.cancel')}</Button>
        </Col>
        <Col span={4}>
          <Button
            disabled={selectedRider?.status !== 'free'}
            onClick={onSubmit}
            type="primary"
            data-testid="assign-rider-submit-btn"
          >
            {t('pages.roadmaps.button.assign')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default RoadmapsAssign;
