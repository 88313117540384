import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const COMMISSIONS_BY_RIDERSlide = createSlice({
  name: 'commissionsByRider',
  initialState,
  reducers: {
    setCommissionsByRiderLoading: (state) => {
      state.loading = true;
    },
    setCommissionsByRider: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setCommissionsByRiderError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setCommissionsByRiderLoading,
  setCommissionsByRider,
  setCommissionsByRiderError
} = COMMISSIONS_BY_RIDERSlide.actions;

export default COMMISSIONS_BY_RIDERSlide.reducer;
