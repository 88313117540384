import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Table } from 'antd';
import useGetUsersProcoStorage from 'hooks/useGetUsersProcoStorage';

const ProcoStorageDetails = ({ onClose, isOpen, procoStorageId }) => {
  const { data } = useGetUsersProcoStorage(procoStorageId);
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'CI',
      dataIndex: 'ci',
      key: 'ci'
    },
    {
      title: 'Telefono',
      dataIndex: 'phone',
      key: 'phone'
    }
  ];
  return (
    <div>
      <Drawer
        title="Almacenista"
        width={500}
        onClose={onClose}
        visible={isOpen}
      >
        <Table columns={columns} dataSource={data} />
      </Drawer>
    </div>
  );
};

ProcoStorageDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  procoStorageId: PropTypes.string
};

ProcoStorageDetails.defaultProps = {
  procoStorageId: ''
};

export default ProcoStorageDetails;
