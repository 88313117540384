import React, { useCallback } from 'react';
import ForgotPasswordForm from 'components/Forms/ForgotPasswordForm/ForgotPasswordForm';
import { SIGNIN } from 'constants/routes';
import { useDispatch } from 'react-redux';
import { showNotification } from 'stores/actions/notification';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { resetPassword } from 'firebase/api';
import styles from './ForgotPassword.module.scss';
import image from '../../assets/images/logo.svg';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { info } = Modal;

  const showInfoSuccess = useCallback(() => {
    info({
      title:
        'Se ha enviado un correo para el restablecimiento de su contraseña',
      icon: <ExclamationCircleOutlined />,
      content: 'Por favor verifique en la carpeta de "SPAM"'
    });
  }, [info]);

  const handleFormSubmit = useCallback(
    async (email) => {
      try {
        await resetPassword(email);
        showInfoSuccess();
      } catch (error) {
        dispatch(
          showNotification({
            type: 'error',
            message: 'Error',
            content: error.message
          })
        );
      }
    },
    [dispatch, showInfoSuccess]
  );

  const handlerGoBack = useCallback(() => {
    history.push(SIGNIN);
  }, [history]);

  return (
    <div className={styles.container_forgetpass}>
      <img src={image} className={styles.logo} alt="logo" />
      <div className={styles.form_container}>
        <ForgotPasswordForm
          handlerGoBack={handlerGoBack}
          onSubmit={handleFormSubmit}
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
