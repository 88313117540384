import React from 'react';
import useGetRoadmapsHook from 'hooks/componentHooks/useGetRoadmapsHook';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROADMAPS } from 'constants/routes';
import { Tag, Space, Tooltip, Table, Button, Input } from 'antd';
import Can from 'components/Can';
import useColumnSearch from 'hooks/useColumnSearch';
import { PrinterOutlined, ReloadOutlined } from '@ant-design/icons';
import { ROADMAPS as ROADMAPS_MODULE } from 'components/Can/modules';
import Utils from '../utils';

const FinishedRoadmaps = () => {
  const { t } = useTranslation();
  const columnSearch = useColumnSearch();
  const { doneRoadmaps, loadingDoneRoadmaps, getDoneRoadmaps, getOneRoadmap } =
    useGetRoadmapsHook();

  const { printInvoice, statuses } = Utils();

  const [roadmapResult, setRoadmapResult] = React.useState();

  const getColumns = () => [
    {
      title: t('pages.roadmaps.table.roadmap'),
      dataIndex: 'roadmapNumber',
      key: 'roadmapNumber',
      ...columnSearch('roadmapNumber'),
      render: (text, record) => (
        <Link
          to={{
            pathname: `${ROADMAPS}/${record.roadmapNumber}`
          }}
        >
          {text}
        </Link>
      )
    },
    {
      title: t('pages.roadmaps.table.rider'),
      key: 'rider',
      ...columnSearch(['rider', 'name']),
      render: (roadmap) => roadmap?.rider?.name || 'Rider no asignado'
    },
    {
      title: t('pages.riders.table.proco'),
      key: 'proco',
      ...columnSearch('proco'),
      render: (roadmap) => roadmap?.rider?.proco || '-'
    },
    {
      title: t('pages.roadmaps.table.route'),
      key: 'route',
      ...columnSearch(['route', 'name']),
      render: (roadmap) => roadmap?.routeName || ''
    },
    {
      title: t('pages.roadmaps.table.orders'),
      key: 'orders',
      render: (roadmap) => roadmap?.ordersIds?.length
    },
    {
      title: t('pages.roadmaps.table.status'),
      key: 'status',
      ...columnSearch('status'),
      render: (roadmap) => (
        <Tag
          icon={statuses[roadmap.status]?.icon}
          color={statuses[roadmap.status]?.color}
        >
          {t(`pages.roadmaps.messages.${statuses[roadmap.status]?.text}`)}
        </Tag>
      )
    },
    {
      title: t('pages.roadmaps.table.actions'),
      key: 'action',
      render: (roadmap) => (
        <>
          <Can I="update" a={ROADMAPS_MODULE}>
            <Tooltip placement="top" title="Imprimir Order de Entrega">
              <PrinterOutlined
                style={{ fontSize: '1.2rem' }}
                onClick={() => printInvoice(roadmap)}
              />
            </Tooltip>
          </Can>
        </>
      )
    }
  ];

  const findOneRoadmap = async (roadmapNumber) => {
    const result = doneRoadmaps.find(
      (roadmap) => roadmap.roadmapNumber === roadmapNumber
    );
    if (result === undefined) {
      const roadmapResponse = await getOneRoadmap(roadmapNumber);
      setRoadmapResult(roadmapResponse[0]);
    } else {
      setRoadmapResult(result);
    }
  };

  React.useEffect(() => {
    if (doneRoadmaps.length === 0) getDoneRoadmaps();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Space direction="horizontal" style={{ marginBottom: '15px' }}>
        <Input.Search
          allowClear
          placeholder="Nombre del Roadmap"
          onSearch={(e) => findOneRoadmap(e)}
          enterButton="Buscar"
        />
        <Button type="primary" shape="circle" onClick={getDoneRoadmaps}>
          <ReloadOutlined />
        </Button>
      </Space>
      {roadmapResult ? (
        <Table
          columns={getColumns()}
          dataSource={[roadmapResult]}
          rowKey={(record) => record.id}
        />
      ) : (
        <Table
          columns={getColumns()}
          dataSource={doneRoadmaps}
          loading={loadingDoneRoadmaps}
          rowKey={(record) => record.id}
        />
      )}
    </>
  );
};

export default FinishedRoadmaps;
