import API from './config';
import {
  CREATE_USER, CREATE_ADMIN, DELETE_ADMIN
} from './urls';

const createAdmin = (values) => API.post(CREATE_ADMIN, values);

const deleteAdmin = (id) => API.delete(`${DELETE_ADMIN}/${id}`);

const createUser = (values) => API.post(CREATE_USER, values);

export default {
  createAdmin,
  deleteAdmin,
  createUser,
};
