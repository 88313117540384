import React, { useState } from 'react';
import { Col, Divider, Drawer, Image, Row, Table, Tag } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useOrdersQuery } from 'stores/query/ordersQuery';
import { cashReportFactory } from 'pages/CommerceToPerson/utils';
import CashReportFilter from './CashReportFilter';
import useColumnSearch from 'hooks/useColumnSearch';
import PaymentType from 'components/PaymentType/PaymentType';
import moment from 'moment';

const CashReport = () => {
  const [isActiveDrawer, setIsActiveDrawer] = useState(false);
  const searchFilter = useColumnSearch();
  const [billsPhoto, setBillsPhoto] = useState([]);
  const { data: ordersData } = useOrdersQuery();
  const cashReportStore = cashReportFactory(ordersData);
  const [cashReportData, setCashReportData] = useState(cashReportStore);
  const handleBillsPhotoDrawer = (bills) => {
    setIsActiveDrawer(!isActiveDrawer);
    setBillsPhoto(bills);
  };

  const handleBillsCloseDrawer = () => {
    setIsActiveDrawer(!isActiveDrawer);
    setBillsPhoto([]);
  };

  const getColumns = () =>
    columns.map((item) => {
      if (item.key === 'billsPhoto') {
        return {
          ...item,
          render: (bills) =>
            bills.length > 0 && (
              <InfoCircleTwoTone
                onClick={() => handleBillsPhotoDrawer(bills)}
              />
            )
        };
      }
      return item;
    });
  const columns = [
    {
      title: 'N° de Orden',
      dataIndex: 'order',
      key: 'order',
      fixed: 'left',
      sorter: (a, b) =>
        Number(a.order?.replace(/\D/g, '')) -
        Number(b.order?.replace(/\D/g, ''))
    },
    {
      title: 'Tipo de Pago',
      dataIndex: 'payment',
      key: 'payment',
      fixed: 'left',
      width: '130px',
      render: (_, record) => <PaymentType data={record} />
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      fixed: 'left',
      ...searchFilter('client')
    },
    {
      title: 'Fecha',
      dataIndex: 'operationDate',
      key: 'operationDate',
      width: '130px',
      sorter: (a, b) => {
        return (
          moment(a.operationDate, 'dd/mm/yyyy').unix() -
          moment(b.operationDate, 'dd/mm/yyyy').unix()
        );
      }
    },
    {
      title: 'Tasa',
      dataIndex: 'rate',
      key: 'rate'
    },
    {
      title: 'Monto total Bs',
      dataIndex: 'amountTotalBs',
      key: 'amountTotalBs'
    },
    {
      title: 'Monto total USD',
      dataIndex: 'amountTotalUsd',
      key: 'amountTotalUsd'
    },
    {
      title: 'IGTF',
      dataIndex: 'igtf',
      key: 'igtf'
    },
    {
      title: 'Monto pagado en Cash',
      dataIndex: 'amountPaidCash',
      key: 'amountPaidCash'
    },
    {
      title: 'Diferencia en USD',
      dataIndex: 'differenceInUsd',
      key: 'differenceInUsd'
    },
    {
      title: 'Diferencia en Bolivares',
      dataIndex: 'differenceInBs',
      key: 'differenceInBs'
    },
    {
      title: 'Foto Billetes',
      dataIndex: 'billsPhoto',
      key: 'billsPhoto',
      align: 'center'
    },
    {
      title: 'Denominacion billetes',
      dataIndex: 'billsDenomination',
      key: 'billsDenomination',
      render: (bills) => {
        return (
          <>
            {bills.map((bill, index) => (
              <Tag key={index} color="success">
                {bill}
              </Tag>
            ))}
          </>
        );
      }
    },
    {
      title: 'Codigo billetes',
      dataIndex: 'billsCode',
      key: 'billsCode',
      render: (codes) => {
        return (
          <>
            {codes.map((code, index) => (
              <Tag key={index} color="geekblue">
                {code}
              </Tag>
            ))}
          </>
        );
      }
    }
  ];
  const handleFilter = (record) => {
    const {
      order,
      client,
      minTotalAmountUsd,
      maxTotalAmountUsd,
      minTotalAmountBs,
      maxTotalAmountBs
    } = record;
    const dataFiltered = cashReportStore
      .filter((item) => {
        if (!order) return true;
        return item.orderId === order;
      })
      .filter((item) => {
        if (!client) return true;
        return item.clientId === client;
      })
      .filter((item) => {
        const amountTotalUsd = Number(
          item.amountTotalUsd.replace('$', '').replaceAll(',', '.')
        );

        if (!!minTotalAmountUsd && !maxTotalAmountUsd) {
          return amountTotalUsd >= minTotalAmountUsd;
        }
        if (!minTotalAmountUsd && !!maxTotalAmountUsd) {
          return amountTotalUsd <= maxTotalAmountUsd;
        }
        if (!!minTotalAmountUsd && !!maxTotalAmountUsd) {
          return (
            amountTotalUsd >= minTotalAmountUsd &&
            amountTotalUsd <= maxTotalAmountUsd
          );
        }
        return true;
      })
      .filter((item) => {
        const amountTotalBs = Number(
          item.amountTotalBs.replace('BsF', '').replaceAll(',', '.')
        );

        if (!!minTotalAmountBs && !maxTotalAmountBs) {
          return amountTotalBs >= minTotalAmountBs;
        }
        if (!minTotalAmountBs && !!maxTotalAmountBs) {
          return amountTotalBs <= maxTotalAmountBs;
        }
        if (!!minTotalAmountBs && !!maxTotalAmountBs) {
          return (
            amountTotalBs >= minTotalAmountBs &&
            amountTotalBs <= maxTotalAmountBs
          );
        }
        return true;
      });

    setCashReportData(dataFiltered);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <CashReportFilter
            handleFilter={handleFilter}
            cashReportData={cashReportData}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={getColumns()}
            dataSource={cashReportData}
            scroll={{ x: 1300 }}
            rowKey="idCashReport"
          />
        </Col>
      </Row>
      <Drawer
        visible={isActiveDrawer}
        onClose={() => handleBillsCloseDrawer()}
        title="Billetes"
      >
        {billsPhoto.map((billSrc, idx) => (
          <div key={`billSrc-${idx}`}>
            <Image width={100} height={80} src={billSrc} />
            <Divider />
          </div>
        ))}
      </Drawer>
    </>
  );
};

export default CashReport;
