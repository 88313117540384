import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { showNotification } from 'stores/actions/notification';
import { cleanProfile } from 'stores/actions/profile';
import { loading, loadingWithSpinner, loaded } from 'stores/actions/loader';
import i18next from 'config/i18n';
import { getToken, cleanSessionStorage } from '../firebase/utils/token';
import API from './config';

const interceptorRequest = async (request, store) => {
  const method = request.method.toUpperCase();

  store.dispatch(method === 'GET' ? loading() : loadingWithSpinner());

  if (request.data && request.data.signin) {
    return request;
  }

  const accessToken = getToken();
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  request.cancelToken = source.token;
  let validToken = false;
  let isExpired = false;

  try {
    const token = await jwtDecode(accessToken);
    if (Date.now() >= token.exp * 1000.1) {
      validToken = false;
      isExpired = true;
    } else {
      validToken = true;
    }
  } catch {
    validToken = false;
  }

  const errorMessage = isExpired ? 'tokenExpired' : 'invalidToken';

  if (accessToken && validToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    // source.cancel(errorMessage); TODO:  Ver por que esto esta haciendo que falle
    cleanSessionStorage();
    store.dispatch(
      showNotification({
        type: 'error',
        message: 'Error Session',
        content: i18next.t(`serverMessages.${errorMessage}`)
      })
    );
    store.dispatch(cleanProfile());
  }
  return request;
};

const interceptorResponse = (response, store) => {
  if (response.status === 201) {
    store.dispatch(
      showNotification({
        type: 'success',
        message: 'Info',
        content: i18next.t(`serverMessages.${response.data.message}`)
      })
    );
  }

  store.dispatch(loaded());

  return response;
};

const handleErrorResponse = async (error, store) => {
  store.dispatch(
    showNotification({
      type: 'error',
      message: `Error ${error.response?.status}`,
      content: i18next.t(
        `serverMessages.${error.response?.data.message || error.message}`
      )
    })
  );

  store.dispatch(loaded());
  return error;
};

const Interceptor = (store) => {
  API.interceptors.request.use((request) => interceptorRequest(request, store));

  API.interceptors.response.use(
    (response) => interceptorResponse(response, store),
    (error) => handleErrorResponse(error, store)
  );
};

export default {
  Interceptor
};
