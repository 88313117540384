import React, { useEffect, useCallback, Fragment, useState } from 'react';
import { Button, Col, Drawer, Row, Space, Table, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import { getBanks } from 'stores/actions/config';
import { useTranslation } from 'react-i18next';
import { showNotification } from 'stores/actions/notification';
import { closeModal, openModal } from 'stores/actions/modal';
import CommerceToPersonForm from 'components/Forms/CommerceToPersonForm/CommerceToPersonForm';
import useSetHeaderProps from 'hooks/useSetHeaderProps';
import { RedoOutlined } from '@ant-design/icons';
import { ordersApi, useCommerceToPersonQuery } from 'stores/query/ordersQuery';
import { createCommerceToPerson } from 'firebase/api';
import useDisclosure from 'hooks/useDisclousure';
import Bank from 'components/Bank/Bank';
import { FcInfo } from 'react-icons/fc';
import OrderDetail from 'components/OrderDetail';

const CommerceToPersonOperation = () => {
  const dispatch = useDispatch();
  const { data, isLoading, refetch } = useCommerceToPersonQuery({
    justAdmin: true
  });
  const { t } = useTranslation();
  const setHeaderProps = useSetHeaderProps();
  const { isOpen: orderDetailVisible, onToggle: orderDetailToggle } =
    useDisclosure();
  const [orderDetail, setOrderDetail] = useState({});

  const handleSave = useCallback(
    async (body) => {
      try {
        await createCommerceToPerson(body);
        await refetch();
      } catch (error) {
        dispatch(
          showNotification({
            type: 'error',
            message: 'Error',
            content: error.message
          })
        );
      }
    },
    [dispatch, refetch]
  );

  const openFormModal = useCallback(() => {
    const modalContent = {
      component: CommerceToPersonForm,
      props: {
        onCancel: () => dispatch(closeModal()),
        onSubmit: (values) => handleSave(values),
        isLoading
      }
    };

    dispatch(
      openModal({
        content: modalContent,
        buttons: []
      })
    );
  }, [dispatch, handleSave, isLoading]);

  const handleOrderDetail = async (orderId) => {
    const { data: resData } =
      (await dispatch(ordersApi.endpoints.orderByIds.initiate([orderId]))) ||
      {};
    if (Object.keys(resData).length === 0) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: 'Orden no existe'
        })
      );
      return;
    }
    orderDetailToggle();
    setOrderDetail(resData);
  };

  const columns = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      render: (code) => <Bank code={code} />
    },
    {
      title: 'Cedula',
      dataIndex: 'cedula',
      key: 'cedula'
    },
    {
      title: 'Telefono',
      dataIndex: 'telefono',
      key: 'telefono'
    },
    {
      title: 'Monto Devuelto',
      dataIndex: 'montoDevuelto',
      key: 'monto'
    },
    {
      title: 'Orden',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (orderId) => (
        <>
          <Button
            type="text"
            onClick={() => handleOrderDetail(orderId)}
            icon={<FcInfo />}
          />
        </>
      )
    },
    {
      title: 'Estado',
      dataIndex: 'success',
      key: 'success',
      render: (status, record) => {
        const hasStatus = record.hasOwnProperty('success');
        return hasStatus && status ? (
          <Tag color="success">Exitoso</Tag>
        ) : hasStatus && !status ? (
          <Tag color="error">Fallido</Tag>
        ) : (
          <Tag color="processing">En Proceso...</Tag>
        );
      }
    },
    {
      title: 'Detalle Banco',
      dataIndex: 'numeroReferencia',
      key: 'numeroReferencia'
    },
    {
      title: 'Motivo',
      dataIndex: 'motivo',
      key: 'motivo'
    }
  ];

  useEffect(() => {
    dispatch(getBanks());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHeaderProps({
      title: 'Commerce To Person',
      extraButtons: [
        <Fragment key={0}>
          <Space>
            <Button type="primary" onClick={() => openFormModal()}>
              {t('common.button.create')}
            </Button>
            <Button
              icon={<RedoOutlined />}
              type="secondary"
              onClick={() => refetch()}
            >
              {t('common.button.update')}
            </Button>
          </Space>
        </Fragment>
      ]
    });
    // eslint-disable-next-line
  }, [setHeaderProps]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
          />
        </Col>
      </Row>
      <Drawer
        onClose={orderDetailToggle}
        visible={orderDetailVisible}
        title="Detalle de la orden"
        placement="bottom"
      >
        <OrderDetail data={orderDetail} />
      </Drawer>
    </>
  );
};

export default CommerceToPersonOperation;
