// @ts-nocheck
import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useContext
} from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { GoogleMapsContext } from 'contexts/GoogleMapsContext';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const OPTIONS = {
  disableDefaultUI: true,
  zoomControl: true
};

const BaseMap = ({ children, zoom, handlePlaces }) => {
  const { setMap } = useContext(GoogleMapsContext);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ['drawing']
  });

  const [position, setPosition] = useState({
    lat: 0,
    lng: 0
  });

  useEffect(() => {
    setTimeout(() => {
      setPosition({
        lat: 10.497,
        lng: -66.8853
      });
    }, 1000);
  }, []);

  const mapRef = useRef();
  const prevMarkersRef = useRef();
  const onLoad = useCallback(
    (newMap) => {
      // @ts-ignore
      window.google_map = window.google.maps;
      const bounds = new window.google_map.LatLngBounds();
      newMap.fitBounds(bounds);
      mapRef.current = newMap;
      setMap(newMap);
    },
    [setMap]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, [setMap]);

  const handleCenter = () => {
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  };

  return isLoaded ? (
    <GoogleMap
      ref={prevMarkersRef}
      mapContainerStyle={containerStyle}
      options={OPTIONS}
      onDragEnd={handleCenter}
      center={{ ...position }}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      id="map"
      onClick={(e) => handlePlaces({ lat: e.latLng.lat(), lng: e.latLng.lng() })}
    >
      {children && children.length > 0 && children.map((child) => child)}
    </GoogleMap>
  ) : (
    <></>
  );
};

BaseMap.propTypes = {
  zoom: PropTypes.number,
  children: PropTypes.node.isRequired
};

BaseMap.defaultProps = {
  zoom: 8
};

export default BaseMap;
