import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { getOrderById } from 'stores/selectors/orders';

const CancellOrderWarning = ({
  cancelOrder = {},
  setCancelOrder,
  cancelOrderAction,
  active,
  onClose
}) => {
  const order = useSelector(getOrderById(cancelOrder.orderId));
  const orderName = order?.odooDataOrder?.name;
  const [fetching, setFetching] = useState(false);
  const buttonDisabled = cancelOrder.reason.length === 0 || fetching;
  const handleCancelOrder = async () => {
    setFetching(true);
    await cancelOrderAction();
    setFetching(false);
  };

  React.useEffect(() => {
    setCancelOrder({
      reason: ''
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      title={`¿Desea cancelar la orden ${orderName || ''}?`}
      visible={active}
      onCancel={() => {
        setCancelOrder({ orderId: '', reason: '' });
        onClose();
      }}
      onOk={() => handleCancelOrder()}
      okButtonProps={{
        disabled: buttonDisabled
      }}
    >
      <Input.TextArea
        placeholder="Ingrese el motivo de la cancelacion de la orden"
        onChange={(e) =>
          setCancelOrder({ ...cancelOrder, reason: e.target.value })
        }
      />
    </Modal>
  );
};

CancellOrderWarning.propTypes = {
  cancelOrderAction: PropTypes.func.isRequired,
  setCancelOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.shape({
    orderId: PropTypes.string,
    reason: PropTypes.string
  }).isRequired,
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CancellOrderWarning;
