import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CashReport, CommerceToPersonReport } from '..';
import MobilePayment from './MobilePayment/MobilePayment';

const { TabPane } = Tabs;
const Reports = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Tabs>
        <TabPane tab={t('menu.commerce-to-person-report')} key={0}>
          <CommerceToPersonReport />
        </TabPane>
        <TabPane tab={t('pages.commerceToPerson.entries.cashReportUsd')} key={1}>
          <CashReport />
        </TabPane>
        <TabPane tab={t('pages.commerceToPerson.entries.mobilePayment')} key={2}>
          <MobilePayment />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};

export default Reports;