import { Button, Modal, notification, Space, Table, Tooltip } from 'antd';
import useColumnSearch from 'hooks/useColumnSearch';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sorterData, sorterDate } from 'utils/table';
import { formatPriceToBsF } from 'utils/utils';
import Prescription from 'components/Prescription';
import { FcBadDecision, FcGoodDecision, FcInfo } from 'react-icons/fc';

import { loaded, loadingWithSpinner } from 'stores/actions/loader';
import { isOrdersLoading } from 'stores/selectors/orders';
import { PHARMACY } from 'components/Can/modules';
import Can from 'components/Can';
import CancellOrderWarning from './CancellOrderWarning';
import RemoveProductsForm from './RemoveProductsForm';
import useDisclosure from 'hooks/useDisclousure';
import { AnonymousUser } from 'components/AnonymousUser';
import AnonymousPhone from 'components/AnonymousUser/AnonymousPhone';
import { pharmacyOrdersFactory } from './helpers';
import { getUsersData } from 'stores/selectors/users';
import { getRidersData } from 'stores/selectors/riders';
import { removeProductsByOrder, updateOrder } from 'firebase/api';
import useGetOrdersHook from 'hooks/componentHooks/useGetOrdersHook';

const PrescriptionMemorized = React.memo(Prescription);

const RemoveProductsFormMemo = React.memo(RemoveProductsForm);

const Pharmacy = () => {
  const searchFilter = useColumnSearch();
  const { t } = useTranslation();
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const { getPharmacyOrders, pharmacyOrders } = useGetOrdersHook();
  const users = useSelector(getUsersData);
  const riders = useSelector(getRidersData);
  const { isOpen: removeProductsActive, onToggle: CloseProductsCancelForm } =
    useDisclosure();
  const { isOpen: cancelOrderActive, onToggle: cancelOrderToggle } =
    useDisclosure();

  const resPharmacy = pharmacyOrdersFactory(pharmacyOrders, users, riders);
  const loading = useSelector(isOrdersLoading);

  const [productsIdSelected, setProductsIdSelected] = useState([]);
  const [productSelected, setProductsSelected] = useState([]);
  const [order, setOrder] = useState({});
  const [currentOrder, setCurrentOrder] = useState({
    id: '',
    name: ''
  });
  const [cancelOrder, setCancelOrder] = useState({
    orderId: '',
    reason: ''
  });
  const hasSelected = productsIdSelected.length > 0;

  const onActionOrder = (orderId) => {
    const body = {
      statusCode: 0,
      status: { text: 'ordered', type: order?.status?.type || '' }
    };
    const action = async () => {
      dispatch(loadingWithSpinner());
      await updateOrder(orderId, body);
      getPharmacyOrders();
      notification.info({
        type: 'success',
        message: 'Exito',
        description: 'Orden aceptada exitosamente',
        placement: 'topRight'
      });
      dispatch(loaded());
    };
    confirm({
      title: '¿Desea aprobar la orden?',
      icon: <FcInfo size={30} />,
      content:
        'Recuerde haber verificado todas las prescripciones por producto',
      onOk() {
        action();
      }
    });
  };

  const cancelOrderAction = async () => {
    dispatch(loadingWithSpinner());
    const body = {
      statusCode: 7,
      cancellationReason: cancelOrder.reason,
      'status.text': 'cancelled'
    };
    await updateOrder(cancelOrder.orderId, body);
    dispatch(loaded());
    setCancelOrder({
      orderId: '',
      reason: ''
    });
    notification.info({
      type: 'success',
      message: 'Exito',
      description: 'Orden cancelada exitosamente',
      placement: 'topRight'
    });
    getPharmacyOrders();
    setCancelOrder({
      orderId: '',
      reason: ''
    });
    cancelOrderToggle();
  };

  const handleExpandProducts = (expanded, record) => {
    /// handle expand to avoid watch products with the same id selected
    const orderId = expanded ? record.id : '';
    setOrder(record);
    setCurrentOrder({ ...currentOrder, id: orderId });
    setProductsIdSelected([]);
    setProductsSelected([]);
  };

  const handleDeleteProducts = async (products) => {
    const action = async () => {
      dispatch(loadingWithSpinner());
      try {
        const res = await removeProductsByOrder({
          products: products,
          orderId: currentOrder.id
        });

        setProductsIdSelected([]);
        setProductsSelected([]);
        setCurrentOrder({ id: '', name: '' });
        getPharmacyOrders();
        dispatch(loaded());
        if (res.data?.code) {
          notification.error({
            type: 'error',
            message: 'Error',
            description: 'La orden quedara sin productos, Cancele la Orden',
            placement: 'topRight'
          });
          return;
        }
        notification.info({
          type: 'success',
          message: 'Exito',
          description: 'Producto eliminado exitosamente',
          placement: 'topRight'
        });
      } catch (error) {
        notification.error({
          type: 'error',
          message: 'Error',
          placement: 'topRight'
        });
      }
    };

    await action();
    CloseProductsCancelForm();
  };

  React.useEffect(() => {
    getPharmacyOrders();
    // eslint-disable-next-line
  }, []);

  const rowSelection = {
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, record) => {
      const lastProductSelected = record.slice(-1);
      const orderSelected =
        lastProductSelected.length > 0 && lastProductSelected[0];
      const productParsed = record.map((product) => ({
        id: product?.id,
        reason: '',
        name: product?.name
      }));

      setProductsSelected(productParsed);
      setProductsIdSelected(selectedRowKeys);
      setCurrentOrder({
        id: orderSelected.orderId,
        name: orderSelected.orderName
      });
    },
    selectedRowKeys: productsIdSelected
  };
  const getColumns = () => [
    {
      title: t('pages.orders.table.num'),
      dataIndex: ['odooDataOrder', 'name'],
      key: 'name',
      fixed: 'left',
      width: '110px',
      sorter: sorterData('odooDataOrder.name'),
      ...searchFilter('odooDataOrder.name')
    },
    {
      title: t('pages.orders.table.client'),
      dataIndex: 'clientName',
      key: 'clientName',
      width: '180px',
      sorter: sorterData('clientName'),
      ...searchFilter('clientName'),
      render: (_, record) => (
        <AnonymousUser
          user={record}
          customizedInvoice={record.customizedInvoice}
        />
      )
    },
    {
      title: t('pages.orders.table.date'),
      dataIndex: 'date',
      key: 'date',
      width: '140px',
      sorter: sorterDate('date'),
      ...searchFilter('date'),
      render: (element) => {
        if (element) {
          const date = element.toDate();
          const createAt = moment(date).format('DD-MM-YYYY HH:mm');
          return <>{createAt}</>;
        }
        return '';
      }
    },
    {
      title: 'Telefono',
      dataIndex: ['partner', 'phone'],
      key: 'phone',
      width: '120px',
      render: (_, order) => <AnonymousPhone order={order} />
    },
    {
      title: 'Action',
      width: '110px',
      key: 'type',
      render: (record) => {
        const {
          odooDataOrder: { products = [] }
        } = record;
        const emptyProducts = products.length === 0;
        return (
          <>
            <Space>
              {emptyProducts ? (
                'Sin productos, cancele la orden'
              ) : (
                <Can I="update" a={PHARMACY}>
                  <Tooltip title="Aprobar">
                    <FcGoodDecision
                      onClick={() => onActionOrder(record.id)}
                      size={30}
                      style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                </Can>
              )}
              <Can I="update" a={PHARMACY}>
                <Tooltip title="Cancelar">
                  <FcBadDecision
                    onClick={() => {
                      setCancelOrder({
                        ...cancelOrder,
                        orderId: record.id,
                        reason: ''
                      });
                      setCancelOrder({
                        ...cancelOrder,
                        orderId: record.id,
                        reason: ''
                      });
                      cancelOrderToggle();
                    }}
                    size={30}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              </Can>
            </Space>
          </>
        );
      }
    }
  ];
  const expandedRowRender = (record) => {
    const columns = [
      {
        title: t('pages.orders.table.name'),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: t('pages.orders.table.quantity'),
        dataIndex: 'quantity',
        key: 'quantity'
      },
      {
        title: t('pages.orders.table.priceUnit'),
        dataIndex: 'price_unit',
        key: 'price_unit',
        render: (value) => formatPriceToBsF(value)
      },
      {
        title: t('pages.orders.table.priceSubtotal'),
        dataIndex: 'price_total',
        key: 'price_total',
        render: (value) => formatPriceToBsF(value)
      },
      {
        title: t('pages.orders.table.prescription'),
        dataIndex: 'prescriptions',
        key: 'prescriptions',
        render: (_, product) => (
          <PrescriptionMemorized order={record} product={product} />
        )
      }
    ];
    return (
      <Table
        columns={columns}
        dataSource={record.products}
        rowKey="id"
        pagination={false}
        rowSelection={rowSelection}
      />
    );
  };

  return (
    <>
      <Space direction="horizontal">
        <Tooltip title="Eliminar producto de la orden">
          <Can I="delete" a={PHARMACY}>
            <Button
              type="primary"
              onClick={() => CloseProductsCancelForm()}
              shape="round"
              disabled={!hasSelected}
              style={{ marginBottom: '10px' }}
            >
              Eliminar Productos
            </Button>
          </Can>
        </Tooltip>
      </Space>
      <Table
        columns={getColumns()}
        dataSource={resPharmacy}
        loading={loading}
        rowKey="id"
        expandable={{
          expandedRowRender,
          expandedRowKeys: [currentOrder?.id],
          onExpand: (expanded, record) => handleExpandProducts(expanded, record)
        }}
        scroll={{ x: 1300 }}
      />
      <CancellOrderWarning
        cancelOrder={cancelOrder}
        setCancelOrder={setCancelOrder}
        cancelOrderAction={cancelOrderAction}
        active={cancelOrderActive}
        onClose={cancelOrderToggle}
      />
      <RemoveProductsFormMemo
        active={removeProductsActive}
        products={productSelected}
        onClose={() => {
          CloseProductsCancelForm();
        }}
        handleDeleteProducts={handleDeleteProducts}
      />
    </>
  );
};

export default Pharmacy;
