import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const cartPrices = createSelector(
  selectSelf,
  (state) => state.cartValidation
);

export const realCartData = createSelector(
  cartPrices,
  (record) => record.data || []
);

export const realCartLoading = createSelector(
  cartPrices,
  (record) => record.loading
);

export const realCartError = createSelector(
  cartPrices,
  (record) => record.error
);
