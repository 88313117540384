import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  multiOperation,
  getRiderById,
  getRiders,
  getRiderProcos
} from 'firebase/api';
import { NOTIFICATIONS, RIDERS } from '../../firebase/collections';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const setRiderNotification = createAsyncThunk(
  '@riders/setRiderNotification',
  async ({ ids, content }) => {
    const riders = ids.map((id) => ({
      id,
      model: RIDERS,
      children: {
        model: NOTIFICATIONS,
        operation: 'set',
        body: content
      }
    }));

    return multiOperation(riders);
  }
);

export const getAllRiders = createAsyncThunk('@riders/getRiders', async () => {
  const data = await getRiders();
  const procos = await getRiderProcos();
  const newData = data.map((rider) => ({
    ...rider,
    proco: procos.find((proco) => proco.riders.includes(rider?.id))?.name || ''
  }));
  return newData;
});

export const getOneRider = createAsyncThunk(
  '@riders/getOneRider',
  async (id) => {
    const data = await getRiderById(id);
    return data;
  }
);

export const ridersSlide = createSlice({
  name: 'riders',
  initialState,
  reducers: {
    setRidersLoading: (state) => {
      state.loading = true;
    },
    setRiders: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setRidersError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [setRiderNotification.pending]: (state) => {
      state.loading = true;
    },
    [setRiderNotification.fulfilled]: (state) => {
      state.loading = false;
    },
    [setRiderNotification.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getAllRiders.pending]: (state) => {
      state.loading = true;
    },
    [getAllRiders.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getAllRiders.rejected]: (state) => {
      state.loading = false;
    },
    [getOneRider.pending]: (state) => {
      state.loading = true;
    },
    [getOneRider.fulfilled]: (state, action) => {
      state.data = [...action.payload, ...state.data];
      state.loading = false;
    },
    [getOneRider.rejected]: (state) => {
      state.loading = false;
    }
  }
});

export const { setRidersLoading, setRiders, setRidersError } =
  ridersSlide.actions;

export default ridersSlide.reducer;
