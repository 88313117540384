import { useDispatch, useSelector } from 'react-redux';
import { getRiders as getRidersX } from 'stores/selectors/riders';
import { getAllRiders, setRiders } from 'stores/actions/riders';
import { RIDERS } from 'firebase/collections';
import { onSnapshot, collection, query } from 'firebase/firestore';
import { db } from 'firebase/firebaseConfig';
import { getRiderProcos } from 'firebase/api';

const useGetRidersHook = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(getRidersX);

  const getRidersY = async () => await dispatch(getAllRiders());

  const getRiders = async () => {
    const procos = await getRiderProcos();
    const listen = query(collection(db, RIDERS));
    return onSnapshot(listen, (querySnapshot) => {
      const output = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        proco:
          procos.find((proco) => proco.riders.includes(doc.data()?.uid))
            ?.name || ''
      }));
      dispatch(setRiders(output));
    });
  };

  const getFreeRiders = () => data.filter((rider) => rider.status === 'free');

  return {
    getRiders,
    getRidersY,
    ridersData: data,
    ridersLoading: loading,
    getFreeRiders
  };
};

export default useGetRidersHook;
