/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Image, Row, Typography } from 'antd';
import styles from './BannerPreview.module.scss';

const BannerPreview = ({ src }) => {
  const { Title } = Typography;
  const isVideo = src.match(/mp4/g)?.length > 0 || false;

  return (
    <div className={styles.banner_preview_container}>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <Title>Banner Preview</Title>
          <div className={styles.imageContainer}>
            <Card className={styles.cardContainer}>
              {isVideo ? (
                <video style={{ maxWidth: '850px' }} autoPlay controls>
                  <source src={src} />
                </video>
              ) : (
                <Image
                  fallback={
                    !src &&
                    'https://firebasestorage.googleapis.com/v0/b/adan-farm.appspot.com/o/banners%2Fcbimage.png?alt=media&token=c03d774e-61ce-4118-9712-b5fa3c677e0d'
                  }
                  style={{ maxWidth: '850px' }}
                  height="200px"
                  src={src}
                />
              )}
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

BannerPreview.propTypes = {
  src: PropTypes.string
};

export default BannerPreview;
