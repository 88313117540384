import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const getOrdersIncomingStore = createSelector(
  selectSelf,
  (state) => state.ordersIncoming
);

export const getOrdersIncoming = createSelector(
  getOrdersIncomingStore,
  (state) => state.orders
);

export const getOrdersIncomingCount = createSelector(
  getOrdersIncomingStore,
  (state) => state.count
);

export const getNewOrdersIncomingCount = createSelector(
  getOrdersIncoming,
  getOrdersIncomingCount,
  (orders, prevCount) => {
    const ordersCount = orders.length;
    return ordersCount - prevCount;
  }
);
