import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Marker as MarkerComponent, InfoWindow } from '@react-google-maps/api';

const Marker = ({ position, name, Editable, setNewAddress, icon }) => {
  const [showInfo, setShowInfo] = useState(false);

  const setInstance = (instance) => {
    if (!Editable) return;
    setNewAddress(instance);
  };

  return (
    <MarkerComponent
      icon={icon}
      position={position}
      onLoad={setInstance}
      onClick={() => setShowInfo(true)}
      draggable={Editable}
      onDragEnd={setInstance}
    >
      {showInfo && (
        <InfoWindow position={position} onCloseClick={() => setShowInfo(false)}>
          <div>
            <h1>{name}</h1>
          </div>
        </InfoWindow>
      )}
    </MarkerComponent>
  );
};

Marker.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }).isRequired,
  name: PropTypes.string.isRequired,
  Editable: PropTypes.bool,
  setNewAddress: PropTypes.func.isRequired
};

Marker.defaultProps = {
  Editable: false
};

export default Marker;
