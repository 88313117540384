/*eslint-disable */

const PAYMENT_DICTIONARY = {
  creditCard: 'Tarjeta de Credito',
  cash: 'Cash',
  paypal: 'PayPal',
  zelle: 'Zelle',
  koroto: 'Koroto',
  pagomovil: 'Pago Movil',
  puntoventa: 'Punto de Venta',
  bolivarCash: 'Bolivares en Efectivo',
  bankTransfer: 'Transferencia Bancaria',
  stripe: 'Tarjeta de Credito'
};

export default PAYMENT_DICTIONARY;
