import { useDispatch, useSelector } from 'react-redux';
import {
  getUsers as getUsersAction,
  getOneUser,
  getAdmins as getAdminsX
} from 'stores/actions/users';
import { getUsersList, getAdminsList } from 'stores/selectors/users';

const useGetUsersHook = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(getUsersList);
  const { data: adminsData, loading: loadingAdminsData } =
    useSelector(getAdminsList);

  const getUsers = async () => await dispatch(getUsersAction());

  const getAdmins = async () => await dispatch(getAdminsX());

  const findUser = async (email) => {
    const x = await dispatch(getOneUser(email));
    return x.payload;
  };

  return {
    getUsers,
    getAdmins,
    adminsData,
    loadingAdminsData,
    loadingUserList: loading,
    usersList: data,
    findUser
  };
};

export default useGetUsersHook;
