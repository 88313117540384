import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const getProfileState = createSelector(
  selectSelf,
  (state) => state.profile
);

export const getProfileId = createSelector(
  getProfileState,
  (profile) => profile.id
);

export const getProfilePermissions = createSelector(
  getProfileState,
  (profile) => profile.role.permissions
);

export const getUserRole = createSelector(
  getProfileState,
  (profile) => profile.role
);

export const getProfileName = createSelector(
  getProfileState,
  (profile) => profile.name
);

export const getProfileEmail = createSelector(
  getProfileState,
  (profile) => profile.email
);

export const getProfileProco = createSelector(
  getProfileState,
  (profile) => profile.proco
);

export const getProfileParsed = createSelector(getProfileState, (profile) => {
  return {
    fullName: profile.name,
    dni: profile.dni,
    email: profile.email,
    phone: profile.phone,
    department: profile.department,
    country: profile.address.country,
    state: profile.address.state,
    city: profile.address.city,
    street: profile.address.street,
    srcImgProfile: profile.avatar
  };
});

export const getIsUserPacking = createSelector(getProfileState, (profile) =>
  profile.roleId === '3f3dRpjvOxGHR54lnzHF' ? true : false
);
