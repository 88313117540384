import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createAddress, deleteAddress as deleteAddressFb, getAddresses as getAddressesFb } from 'firebase/api';
import { omit } from 'lodash';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getAddresses = createAsyncThunk(
  '@addresses/getAddresses',
  async () => getAddressesFb()
);

export const createAddresses = createAsyncThunk(
  '@addresses/createAddresses',
  async (address) => {
    const body = {
      ...omit(address, ['userId']),
      type: 'delivery',
      default: false,
      feedbackRider: {
        exactLocation: '',
        feedback: '',
        isCertificated: false,
        riderID: ''
      }
    };

    const { userId } = address;

    await createAddress(userId, body);
  }
);

export const deleteAddresses = createAsyncThunk(
  '@addresses/deleteAddresses',
  async (address) => {
    await deleteAddressFb(address.parent, address.id);
  }
);

export const addressesSlide = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setAddressesLoading: (state) => {
      state.loading = true;
    },
    setAddresses: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setAddressesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getAddresses.pending]: (state) => {
      state.loading = true;
    },
    [getAddresses.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getAddresses.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createAddresses.pending]: (state) => {
      state.loading = true;
    },
    [createAddresses.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createAddresses.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setAddressesLoading,
  setAddresses,
  setAddressesError
} = addressesSlide.actions;

export default addressesSlide.reducer;
