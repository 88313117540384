/* eslint-disable no-throw-literal */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, message, Table } from 'antd';
import { getAllRoadmaps } from 'stores/selectors/roadmaps';
import useFirebaseContext from 'hooks/useFirebaseContext';
import { sorterData } from 'utils/table';
import { firebaseDateFormatter } from 'utils/utils';
import { closeModal, openModal } from 'stores/actions/modal';
import { loaded, loadingWithSpinner } from 'stores/actions/loader';
import { setProfile } from 'stores/actions/profile';
import useGetPackingOrders from 'hooks/useGetPackingOrders';
import { getProcoByUserId, getOrderById, updateOrder } from 'firebase/api';
import useGetRoadmapsHook from 'hooks/componentHooks/useGetRoadmapsHook';
import useColumnSearch from 'hooks/useColumnSearch';
import OrderPacking from './OrderPacking';

const Packing = () => {
  const { t } = useTranslation();
  const firebaseInstance = useFirebaseContext();
  const { ordersData, getPackingOrders } = useGetPackingOrders();
  const userId = useSelector((state) => state.profile.id);
  const roadmaps = useSelector(getAllRoadmaps);
  const dispatch = useDispatch();
  const { getRoadmaps, roadmapsData } = useGetRoadmapsHook();
  const searchFilter = useColumnSearch();

  const openPackingOrder = (order, packingStartTime) => {
    const modalContent = {
      component: OrderPacking,
      props: {
        close: () => {
          dispatch(closeModal());
          sessionStorage.removeItem('orderByPacking');
        },
        order,
        packingStartTime
      }
    };

    dispatch(
      openModal({
        content: modalContent,
        buttons: [],
        width: 1200
      })
    );
    sessionStorage.setItem('orderByPacking', order?.id);
  };

  const runSelectionOrder = async (orderId) => {
    try {
      message.loading({ content: 'Validando orden...', key: '1' });
      const { 0: data } = await getOrderById(orderId);
      if (data.onPacking === 'unpacked') {
        const packingStartTime = new Date();
        const body = {
          ...data,
          onPacking: 'packing',
          packagingDetail: {
            packingStartTime,
            packingEndTime: null,
            warehouseman: null,
            proco: null
          }
        };
        await updateOrder(data?.id, body);
        openPackingOrder(data, packingStartTime);
        message.success({ content: 'Orden Seleccionada', key: '1' });
      } else {
        message.error({
          content: 'La orden ya fue tomada por alguien mas.',
          key: '1'
        });
      }
    } catch (err) {
      message.error({ content: 'Error al encontrar la orden.', key: '1' });
    }
    // getPackingOrders();
  };

  const getColumns = () => [
    {
      title: t('pages.orders.table.num'),
      dataIndex: ['odooDataOrder', 'name'],
      key: 'name',
      ...searchFilter('odooDataOrder.name'),
      render: (text, record) => (
        <Button onClick={() => runSelectionOrder(record.odooDataOrder.name)}>
          {text}
        </Button>
      )
    },
    {
      title: t('pages.orders.table.date'),
      dataIndex: 'date',
      key: 'date',
      render: (element) => firebaseDateFormatter(element)
    },
    {
      title: t('pages.orders.table.client'),
      dataIndex: ['customizedInvoice', 'fullName'],
      key: 'client',
      sorter: sorterData('customizedInvoice.fullName'),
      ...searchFilter('customizedInvoice.fullName'),
      render: (text) => text
    },
    {
      title: t('pages.orders.table.roadmap'),
      dataIndex: 'id',
      key: 'id',
      render: (record) =>
        roadmaps?.find((roadmap) => roadmap?.ordersIds?.includes(record))
          ?.roadmapNumber || 'Pick Up'
    }
  ];
  useEffect(() => {
    const getProco = async () => {
      const proco = await getProcoByUserId(userId);
      dispatch(setProfile({ proco: proco[0].name }));
    };
    getProco();
  }, [userId, dispatch]);

  useEffect(() => {
    const orderId = sessionStorage.getItem('orderByPacking');
    if (orderId) {
      dispatch(loadingWithSpinner());
      firebaseInstance.firestore.runTransaction((tx) =>
        tx
          .get(firebaseInstance.firestore.collection('orders').doc(orderId))
          .then((doc) => {
            const data = doc.data();
            const body = {
              ...data,
              onPacking: 'unpacked'
            };
            tx.update(
              firebaseInstance.firestore.collection('orders').doc(orderId),
              body
            );
          })
          .then(() => {
            sessionStorage.removeItem('orderByPacking');
            dispatch(loaded());
          })
      );
    }
  }, [firebaseInstance, dispatch]);

  useEffect(() => {
    if (ordersData.length === 0) getPackingOrders();
    if (roadmapsData.length === 0) getRoadmaps();
    // eslint-disable-next-line
  }, []);

  return <Table columns={getColumns()} dataSource={ordersData} rowKey="id" />;
};

export default Packing;
