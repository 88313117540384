import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DirectionsRenderer, DirectionsService } from '@react-google-maps/api';
import {
  CheckCircleTwoTone,
  ClockCircleOutlined,
  DeleteOutlined,
  CommentOutlined
} from '@ant-design/icons';
import {
  Row,
  Col,
  Timeline,
  Card,
  Divider,
  Space,
  Tooltip,
  Popconfirm
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ROADMAPS } from 'constants/routes';
import Table from 'components/Table/Table';
import BaseMap from 'components/BaseMap/BaseMap';
import Marker from 'pages/Addresses/Marker';
import { formatPriceToBsF } from 'utils/utils';
import { farmaCoords, riderCoordsExample } from 'utils/routesCoords';
import RiderIcon from 'assets/images/food-delivery.svg';
import { ROADMAPS as ROADMAPS_MODULE } from 'components/Can/modules';
import Can from 'components/Can';
import styles from 'pages/Roadmaps/RoadmapsView/RoadmapsView.module.scss';
import { showNotification } from 'stores/actions/notification';
import { loaded, loadingWithSpinner } from 'stores/actions/loader';
import { removeOrderFromRoadmap } from 'stores/actions/roadmaps';
import useGetRidersHook from 'hooks/componentHooks/useGetRidersHook';
import { getOrderByIds } from 'firebase/api';
import Observations from './Observations';
import { openModal, closeModal } from 'stores/actions/modal';

const Details = ({ roadmapData = {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { ridersData } = useGetRidersHook();

  const { log, steps = [], status, rider, riderId } = roadmapData;

  const [orders, setOrders] = React.useState([]);

  const getOrders = async () => {
    const res = await getOrderByIds(roadmapData?.ordersIds);
    const complete = res.map((order) => ({
      ...order,
      subtotal: order?.total - order?.tax
    }));

    setOrders(complete);
  };

  React.useEffect(() => {
    getOrders();
    // eslint-disable-next-line
  }, [roadmapData.ordersIds]);

  const riderData = [...log, ...(steps || [])];

  const riderRT = ridersData.find((r) => r.id === riderId);

  const lastCoordOrder =
    orders.length > 0 && orders[orders.length - 1].addressDetails?.position;
  const waypoints = orders.map((order) => ({
    location: order?.addressDetails?.position,
    stopover: true
  }));
  const pathCoordsTracker = {
    origin: farmaCoords,
    destination: lastCoordOrder,
    travelMode: 'DRIVING',
    waypoints
  };
  const [tracker, setTracker] = useState(null);
  const [destinations] = useState(pathCoordsTracker);
  const { t } = useTranslation();

  const removeOrderFromRM = async (order) => {
    try {
      dispatch(loadingWithSpinner());
      dispatch(
        // @ts-ignore
        removeOrderFromRoadmap({
          order: order,
          roadmap: roadmapData,
          orders: orders
        })
      );
      dispatch(loaded());
      if (orders.length <= 1) history.push('/roadmaps');
    } catch (error) {
      dispatch(loaded());
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };

  const openObservationsModal = (order) => {
    const modalContent = {
      component: Observations,
      props: {
        cancelForm: () => dispatch(closeModal()),
        order,
        getOrders
      }
    };

    dispatch(
      openModal({ content: modalContent, buttons: [], title: 'Observaciones' })
    );
  };

  const handleDirectionService = useCallback((response) => {
    if (response !== null) {
      if (response.status === 'OK') {
        setTracker({
          directions: response
        });
      }
    }
  }, []);

  const types = {
    start: {
      color: 'gold',
      hasLink: false
    },
    delivery: {
      color: 'cyan',
      hasLink: false
    },
    delivered: {
      color: 'purple',
      hasLink: false
    },
    completed: {
      color: 'green',
      hasLink: false
    },
    close: {
      color: 'red',
      hasLink: false
    }
  };

  const renderTimeline = () =>
    riderData.map((register) => {
      const target = types[register.type]?.hasLink ? (
        <Link to={`${ROADMAPS}/${register.target}`}>
          {register?.target?.name || register.target}
        </Link>
      ) : (
        register?.target?.name || register.target
      );
      return (
        <Timeline.Item
          label={moment.unix(register.date).format('MM/DD/YYYY h:mm:ss a')}
          color={types[register.type].color}
          key={register.id}
        >
          {t(`pages.roadmaps.messages.${register.description}`, {
            target: target.name || target
          })}
        </Timeline.Item>
      );
    });

  const renderAddresses = () =>
    orders.map((order) => (
      <Marker
        key={order?.addressId}
        position={order?.addressDetails?.position}
        name={`#${order?.odooDataOrder?.name}`}
      />
    ));

  const renderRider = () => {
    const instanceGoogle = window?.google?.maps;
    if (instanceGoogle) {
      const lastestLocation = riderRT?.latestLocation || {};
      const hasCoords = Object.keys(lastestLocation).length > 0 || false;
      const riderCoords = hasCoords
        ? {
            lat: riderRT?.latestLocation?.lat,
            lng: riderRT?.latestLocation?.lng
          }
        : riderCoordsExample;

      return (
        <Marker
          icon={RiderIcon}
          key={1}
          position={riderCoords}
          name={rider?.name || ''}
        />
      );
    }
  };

  const showPendingMessage = status === 'inprogress';

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: t('pages.orders.table.name'),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: t('pages.orders.table.quantity'),
        dataIndex: 'quantity',
        key: 'quantity'
      },
      {
        title: t('pages.orders.table.subtotal'),
        dataIndex: 'price_total',
        key: 'price_total',
        render: (value) => formatPriceToBsF(value)
      }
    ];

    return (
      <Table
        loading={false}
        columns={columns}
        data={record.odooDataOrder.products}
        rowKey="order_id"
        pagination={false}
      />
    );
  };

  const getColumns = () => [
    {
      title: t('pages.orders.table.num'),
      dataIndex: ['odooDataOrder', 'name'],
      key: 'odooDataOrder.name',
      render: (_, { odooDataOrder }) => <span>{odooDataOrder?.name}</span>
    },
    {
      title: t('pages.orders.table.client'),
      dataIndex: 'partner_id',
      key: 'partner_id',
      render: (text, { clientName }) => <span>{clientName}</span>
    },
    {
      title: t('pages.orders.table.date'),
      dataIndex: 'date_order',
      key: 'date_order',
      render: (text, { date }) => moment.unix(date).format('MM/DD/YYYY')
    },
    {
      title: t('pages.orders.table.subtotal'),
      dataIndex: 'subtotal',
      key: 'subtotal',
      render: (value) => formatPriceToBsF(value)
    },
    {
      title: t('pages.orders.table.total'),
      dataIndex: 'total',
      key: 'total',
      render: (value) => formatPriceToBsF(value)
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render: (text, { statusCode }) => (
        <Space>
          {statusCode !== 3 ? (
            <Tooltip placement="top" title="No entregada">
              <ClockCircleOutlined />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title="Entregada">
              <CheckCircleTwoTone twoToneColor="#52C41A" />
            </Tooltip>
          )}
        </Space>
      )
    },
    {
      title: t('pages.orders.table.actions'),
      key: 'actions',
      width: 50,
      align: 'center',
      render: (order) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {order?.state !== 'finished' && !order?.isFinished && (
            <Space size="middle" key={order.id}>
              <Can I="update" a={ROADMAPS_MODULE}>
                <Popconfirm
                  title="Seguro que quiere sacar la orden del roadmap?"
                  onConfirm={() => removeOrderFromRM(order)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip placement="top" title="Eliminar Orden del Roadmap">
                    <DeleteOutlined style={{ fontSize: '1.2rem' }} />
                  </Tooltip>
                </Popconfirm>
              </Can>
            </Space>
          )}
          <Can I="update" a={ROADMAPS_MODULE}>
            <Tooltip placement="top" title="Añadir observaciones">
              <CommentOutlined
                onClick={() => openObservationsModal(order)}
                style={{ fontSize: '1.2rem' }}
              />
            </Tooltip>
          </Can>
        </div>
      )
    }
  ];

  return (
    <>
      <Row gutter={16} align="middle">
        <Col span={12}>
          <Card style={{ padding: '' }} className={styles.card}>
            <Timeline
              mode="left"
              pending={
                showPendingMessage && t('pages.roadmaps.messages.inProgress')
              }
            >
              {log.length > 0 && renderTimeline()}
            </Timeline>
          </Card>
        </Col>
        <Col span={12} style={{ height: '18.75rem' }}>
          <BaseMap zoom={10}>
            {orders.length > 0 && (
              <DirectionsService
                options={destinations}
                callback={handleDirectionService}
              />
            )}
            {orders.length > 0 && tracker !== null && (
              <DirectionsRenderer options={tracker} />
            )}
            {orders.length > 0 && renderRider()}
            {orders.length > 0 && renderAddresses()}
          </BaseMap>
        </Col>
      </Row>
      <Divider orientation="left">{t('menu.orders')}</Divider>
      <Row>
        <Col span={24}>
          <Table
            loading={false}
            columns={getColumns()}
            expandable={{ expandedRowRender }}
            data={orders}
            rowKey="id"
          />
        </Col>
      </Row>
    </>
  );
};

export default Details;
