import React from 'react';
import { useDispatch } from 'react-redux';
import { Table, Tooltip, Space, Popconfirm, Button } from 'antd';
import { formatPriceToBsF } from 'utils/utils';
import { useUpdateOrderMutation } from 'stores/query/ordersQuery';
import moment from 'moment';
import Can from 'components/Can';
import {
  EditOutlined,
  CloseSquareOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { ROADMAPS, ORDERS } from 'components/Can/modules';
import { getOrdersWithoutRoadmap } from 'firebase/api';
import { openModal } from 'stores/actions/modal';
import ReassignOrderToRoadmap from './ReassignOrderToRoadmap';

const OrdersWithoutRoadmap = ({ roadmaps = [] }) => {
  const dispatch = useDispatch();
  const [updateOrderFb] = useUpdateOrderMutation();
  const [ordersData, setOrdersData] = React.useState([]);
  const [mergeableRoadmaps, setMergeableRoadmaps] = React.useState([]);

  const fetchOrders = async () => {
    const result = await getOrdersWithoutRoadmap();
    setOrdersData(
      result.filter((order) => {
        const accionableOrderStatus = [0, 1, 2, 6, 2.5, 0, 5];
        const pasaONo = accionableOrderStatus.includes(order.statusCode);
        return pasaONo;
      })
    );
  };

  const cancelOrder = async (order) => {
    const orderInfo = {
      id: order?.id,
      body: {
        statusCode: 7,
        waitingForUser: false,
        cancellationReason: 'Client didnt answer the delivery',
        status: {
          type: order?.status?.type,
          text: 'cancelled'
        }
      }
    };
    await updateOrderFb(orderInfo);
    await fetchOrders();
  };

  const openReassignOrder = (order) => {
    const modalContent = {
      component: ReassignOrderToRoadmap,
      props: {
        mergeableRoadmaps,
        orderToUpdate: order,
        onRefreshOrders: () => fetchOrders()
      }
    };
    dispatch(
      openModal({
        content: modalContent,
        buttons: [],
        title: 'Reasignar orden a un Roadmap.'
      })
    );
  };

  const getColumns = () => [
    {
      title: 'Orden',
      key: 'id',
      dataIndex: ['odooDataOrder', 'name']
    },
    {
      title: 'Cliente',
      key: 'client',
      dataIndex: 'clientName'
    },
    {
      title: 'Fecha',
      key: 'orderDate',
      dataIndex: 'date',
      render: (element) => {
        if (element) {
          const date = element.toDate();
          const createAt = moment(date).format('DD-MM-YYYY HH:mm');
          return <>{createAt}</>;
        }
        return '';
      }
    },
    {
      title: 'Total Pagado',
      key: 'total',
      dataIndex: 'total',
      render: (value) => formatPriceToBsF(value)
    },
    {
      title: 'Acciones',
      key: 'action',
      render: (order) => (
        <div key={order.id}>
          {((order?.statusCode >= 0 && order.statusCode < 3) ||
            order?.statusCode === 6) && (
            <div>
              <Space size="middle">
                <Can I="update" a={ROADMAPS}>
                  <Tooltip
                    placement="top"
                    title="Reasignar a un Roadmap Activo"
                  >
                    <EditOutlined
                      style={{ fontSize: '1.2rem', marginRight: '10px' }}
                      onClick={() => openReassignOrder(order)}
                    />
                  </Tooltip>
                </Can>
              </Space>
              <Space size="middle" key={order?.id}>
                <Can I="update" a={ORDERS}>
                  <Popconfirm
                    title="Seguro que desea cancelar esta orden?"
                    onConfirm={() => cancelOrder(order)}
                  >
                    <Tooltip placement="top" title="Cancelar Orden">
                      <CloseSquareOutlined
                        style={{ fontSize: '1.2rem', marginLeft: '10px' }}
                      />
                    </Tooltip>
                  </Popconfirm>
                </Can>
              </Space>
            </div>
          )}
        </div>
      )
    }
  ];

  React.useEffect(() => {
    fetchOrders();
  }, []);

  React.useEffect(() => {
    setMergeableRoadmaps(
      roadmaps.filter(
        (roadmap) =>
          roadmap.isOnDelivery === false && roadmap.status !== 'completed'
      )
    );
  }, [roadmaps]);

  return (
    <>
      <Button
        shape="circle"
        type="primary"
        onClick={fetchOrders}
        style={{ marginBottom: '20px' }}
      >
        <ReloadOutlined />
      </Button>
      <Table
        columns={getColumns()}
        dataSource={ordersData}
        id="orders"
        rowKey={(record) => record.id}
      />
    </>
  );
};

export default OrdersWithoutRoadmap;
