import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllProcos } from 'firebase/api';

export const initialState = {
  data: [],
  loading: false,
  error: false
};

export const getProcos = createAsyncThunk('@procos/getProcos', async () => {
  const procos = await getAllProcos();
  return procos;
});

export const procosSlide = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setProcosLoading: (state) => {
      state.loading = true;
    },
    setProcos: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setProcosError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: {
    [getProcos.pending]: (state) => {
      state.loading = true;
    },
    [getProcos.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getProcos.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setProcosLoading, setProcos, setProcosError } =
  procosSlide.actions;

export default procosSlide.reducer;
