import React, { useCallback, useState } from 'react';
import { Button, Col, DatePicker, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import moment from 'moment';
import ExcelFile from 'react-data-export/dist/ExcelPlugin/components/ExcelFile';
import ExcelSheet from 'react-data-export/dist/ExcelPlugin/elements/ExcelSheet';
import useAsyncDownload from 'hooks/useAsyncDownload';
import useDisclosure from 'hooks/useDisclousure';
import { ordersApi } from 'stores/query/ordersQuery';
import { getRoadmaps } from 'firebase/api';
import useGetRoutesHook from 'hooks/componentHooks/useGetRoutesHook';
import { getRidersData } from 'stores/selectors/riders';
import { deliveryOrdersReportFactory } from './utils';

const border = {
  top: { style: 'thin' },
  bottom: { style: 'thin' },
  left: { style: 'thin' },
  right: { style: 'thin' }
};

const alignment = { horizontal: 'center', wrapText: true };

const DeliveryOrdersReport = () => {
  const {
    isOpen: fetching,
    onOpen: fetchingOn,
    onClose: fetchingOff
  } = useDisclosure();
  const { isReady, asyncDownloadToggle } = useAsyncDownload();
  const dispatch = useDispatch();
  const { getRoutes, routesData: routes } = useGetRoutesHook();
  const riders = useSelector(getRidersData);
  const [roadmaps, setRoadmaps] = useState([]);
  const [dataPickerFilter, setDatePickerFilter] = useState([]);
  const [filteredOrdersData, setFilteredOrdersData] = useState([]);
  const hasFilters = dataPickerFilter.length === 2;

  const summarizeData = useCallback(
    (data) => [
      ...sortBy(data, ['order']),
      {
        date: '',
        order: '',
        roadmap: '',
        route: '',
        client: '',
        cantSku: '',
        totalProducts: ' ',
        paymentType: ' ',
        totalBs: ' ',
        totalDl: ' ',
        orderTime: ' ',
        invoiceTime: '', // while empty
        rider: '',
        departureDate: '',
        deliverDate: ''
      }
    ],
    []
  );

  const handleChange = (dates) => {
    if (!dates) {
      setDatePickerFilter([]);
      return;
    }
    setDatePickerFilter([
      moment(dates[0]?.toString()),
      moment(dates[1]?.toString())
    ]);
  };

  const getAllRoadmaps = async () => {
    const Rms = await getRoadmaps();
    setRoadmaps(Rms);
  };

  React.useEffect(() => {
    getAllRoadmaps();
    getRoutes();
    // eslint-disable-next-line
  }, []);

  const getColumns = () => [
    {
      title: 'FECHA',
      key: 'date',
      dataIndex: 'date'
    },
    {
      title: 'ORDEN',
      key: 'order',
      dataIndex: 'order'
    },
    {
      title: 'ROADMAPS',
      key: 'roadmap',
      dataIndex: 'roadmap'
    },
    {
      title: 'RUTA',
      key: 'route',
      dataIndex: 'route'
    },
    {
      title: 'CLIENTE',
      key: 'client',
      dataIndex: 'client'
    },
    {
      title: 'CANTIDAD DE SKU',
      key: 'cantSku',
      dataIndex: 'cantSku'
    },
    {
      title: 'TOTAL PRODUCTOS',
      key: 'totalProducts',
      dataIndex: 'totalProducts'
    },
    {
      title: 'TIPO DE PAGO',
      key: 'paymentType',
      dataIndex: 'paymentType'
    },
    {
      title: 'TOTAL BS',
      key: 'totalBs',
      dataIndex: 'totalBs'
    },
    {
      title: 'TOTAL DL',
      key: 'totalDl',
      dataIndex: 'totalDl'
    },
    {
      title: 'HORA ORDEN',
      key: 'orderTime',
      dataIndex: 'orderTime'
    },
    {
      title: 'HORA DE FACTURA',
      key: 'invoiceTime',
      dataIndex: 'invoiceTime'
    },
    {
      title: 'RIDER',
      key: 'rider',
      dataIndex: 'rider'
    },
    {
      title: 'HORA DE SALIDA',
      key: 'departureDate',
      dataIndex: 'departureDate'
    },
    {
      title: 'HORA DE ENTREGA',
      key: 'deliverDate',
      dataIndex: 'deliverDate'
    }
  ];
  const dataSet = [
    {
      columns: getColumns().map((column) => ({
        title: column.title,
        width: { wch: column.title.length + 10 },
        style: {
          fill: { fgColor: { rgb: 'FFC3D69B' } },
          font: { bold: true },
          alignment,
          border
        }
      })),
      data: filteredOrdersData.map((row) =>
        Object.values(row).map((value) => ({
          value: String(value),
          style: {
            alignment,
            border
          }
        }))
      )
    }
  ];

  const handleDownload = useCallback(async () => {
    if (dataPickerFilter.length !== 2) return;
    fetchingOn();

    const selectedFrom = new Date(dataPickerFilter[0]).setHours(0, 0, 0, 0);
    const selectedTo = new Date(dataPickerFilter[1]).setHours(23, 59, 59, 59);
    const from = new Date(selectedFrom);
    const to = new Date(selectedTo);

    const { data: orders } =
      (await dispatch(
        ordersApi.endpoints.ordersByRange.initiate({ from, to })
      )) || {};
    const ordersData = deliveryOrdersReportFactory(
      roadmaps,
      routes,
      riders,
      orders
    );

    fetchingOff();
    setFilteredOrdersData(summarizeData(ordersData));
    asyncDownloadToggle();
  }, [
    dataPickerFilter,
    dispatch,
    roadmaps,
    routes,
    riders,
    summarizeData,
    fetchingOn,
    fetchingOff,
    asyncDownloadToggle
  ]);

  return (
    <>
      <Row>
        <Col span={6}>
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            placeholder={['from', 'to']}
            onChange={(e) => handleChange(e)}
            ranges={{
              '2 days': [
                moment().subtract(2, 'days').startOf('day'),
                moment().endOf('day')
              ]
            }}
            value={dataPickerFilter}
            allowClear
          />
        </Col>
        <Col span={6}>
          <Button
            disabled={!hasFilters}
            loading={fetching}
            onClick={() => handleDownload()}
          >
            Descargar Reporte
          </Button>
          {isReady && (
            <ExcelFile hideElement filename="Reporte Ordenes">
              <ExcelSheet dataSet={dataSet} name="Ordenes" />
            </ExcelFile>
          )}
        </Col>
        {/* <Col span={6}>
          <ExcelFile
            filename="Reporte Ordenes"
            element={<Button loading={loading}>Descargar Reporte</Button>}
          >
            <ExcelSheet dataSet={dataSet} name="Ordenes" />
          </ExcelFile>
        </Col> */}
      </Row>
    </>
  );
};

export default DeliveryOrdersReport;
