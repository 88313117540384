import users from './users';
import dashboard from './dashboard';
import riders from './riders';
import odoo from './odoo';
import banks from './banks';

export default {
  users,
  dashboard,
  riders,
  odoo,
  banks
};
