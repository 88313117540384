import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Typography
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersByIds } from 'stores/selectors/orders';
import { getRoadmapByOrder } from 'stores/selectors/roadmaps';
import { useTranslation } from 'react-i18next';
import { MinusCircleOutlined } from '@ant-design/icons';
import { transferRoadmaps } from 'stores/actions/roadmaps';
import RoadmapAssignScan from '../RoadmapsAssign/RoadmapAssignScan';
import Utils from '../utils';

const { Search } = Input;
const { Title, Text } = Typography;

const RoadmapTranferOrders = ({
  riders,
  setSelectedRider,
  selectedRider,
  handlerDrawer
}) => {
  const { t } = useTranslation();
  const [selectedOrder, setSelectedOrder] = useState('');
  const [roadmapParent, setRoadmapParent] = useState(null);
  const [ordersIds, setOrdersIds] = useState([]);
  const [roadmapsToUpdate, setRoadmapsToUpdate] = useState([]);

  const { statuses } = Utils();

  const dispatch = useDispatch();
  const inputOrderRef = useRef(null);
  const roadmapByOrderId = useSelector(getRoadmapByOrder(selectedOrder));
  const ordersByIds = useSelector(getOrdersByIds(ordersIds));

  const removeOrder = (order) => {
    const orderRemove = ordersIds.filter((orderId) => orderId !== order.id);
    setOrdersIds(orderRemove);
  };

  const getRoadmapsToUpdate = useCallback(() => {
    const foundedRM =
      roadmapsToUpdate.find((rm) => rm.id === roadmapByOrderId.id) || {};
    const isInRoadmapsToUpdate = Object.keys(foundedRM).length > 0;
    let prevRMs = [];
    if (isInRoadmapsToUpdate) {
      const newPrevRM = {
        ...foundedRM,
        ordersLeft: foundedRM.ordersLeft.filter(
          (orderId) => orderId !== selectedOrder
        )
      };
      prevRMs = [
        ...roadmapsToUpdate.filter((rm) => rm.id !== roadmapByOrderId.id),
        newPrevRM
      ];
    } else {
      prevRMs = [
        ...roadmapsToUpdate,
        {
          id: roadmapByOrderId.id,
          log: roadmapByOrderId.log,
          ordersLeft: roadmapByOrderId.ordersIds.filter(
            (orderId) => orderId !== selectedOrder
          )
        }
      ];
    }

    return prevRMs;
  }, [roadmapByOrderId, roadmapsToUpdate, selectedOrder]);

  const submit = async () => {
    const rmParent = {
      id: roadmapParent.id,
      body: {
        ordersIds: ordersByIds.map((orders) => orders.id),
        riderId: selectedRider.id,
        status: 'inprogress'
      }
    };

    const riderToUpdate = {
      id: selectedRider.id,
      body: {
        status: 'busy'
      }
    };

    /* excluding roadmap parent */
    const roadmapsToUpdateWithoutParent =
      roadmapParent &&
      roadmapsToUpdate.filter((roadmap) => roadmap.id !== roadmapParent.id);

    await dispatch(
      transferRoadmaps({
        rmParent,
        roadmapsToUpdate: roadmapsToUpdateWithoutParent,
        riderToUpdate
      })
    );
    handlerDrawer();
  };

  const columnsByOrders = () => [
    {
      title: 'Orders',
      dataIndex: ['odooDataOrder', 'name'],
      key: ['odooDataOrder', 'name']
    },
    {
      title: 'Borrar',
      key: 'borrar',
      render: (order) =>
        !roadmapParent?.ordersIds.includes(order.id) && (
          <MinusCircleOutlined onClick={() => removeOrder(order)} />
        )
    }
  ];

  const handlerOrder = (order) => {
    const orderId = order.trim();
    setSelectedOrder(orderId);
  };

  // load orders from parent roadmap (main)
  useEffect(() => {
    if (roadmapParent) {
      const parentOrdersIds = roadmapParent?.ordersIds.map((orderId) =>
        orderId.trim()
      );
      setOrdersIds(parentOrdersIds);
    }
  }, [roadmapParent]);

  /* Set Roadmaps  */
  useEffect(() => {
    if (roadmapByOrderId && !roadmapParent) {
      setRoadmapParent(roadmapByOrderId);
    } else if (roadmapByOrderId) {
      setRoadmapsToUpdate(getRoadmapsToUpdate());
      if (ordersIds.length > 0) setOrdersIds([...ordersIds, selectedOrder]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roadmapByOrderId, roadmapParent]);

  useEffect(() => {
    if (inputOrderRef?.current) {
      inputOrderRef.current.focus();
    }
  }, [inputOrderRef]);

  const isRiderFree = selectedRider?.status === 'free' || false;

  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical">
        <Card>
          <RoadmapAssignScan
            setSelectedRider={setSelectedRider}
            riders={riders}
            selectedRider={selectedRider}
          />
        </Card>
        {selectedRider && (
          <Card>
            <Row justify="center">
              {!isRiderFree ? (
                <Col style={{ width: '100%' }}>
                  <Alert
                    message="Cuidado"
                    description="Este Rider ya posee una hoja de ruta asignada."
                    type="error"
                    showIcon
                  />
                </Col>
              ) : (
                <Col style={{ width: '100%' }}>
                  <Title level={3}>Ordenes</Title>
                  <Search
                    placeholder="Escanea la orden o ingresa manualmente"
                    ref={inputOrderRef}
                    width="300px"
                    size="large"
                    onChange={(e) => handlerOrder(e.target.value)}
                    allowClear
                  />
                </Col>
              )}
            </Row>
          </Card>
        )}
        {roadmapParent && isRiderFree && (
          <Card>
            <Row justify="center">
              <Col style={{ width: '100%' }}>
                <Title level={4}>
                  Roadmap Principal:{' '}
                  <Text type="secondary">{roadmapParent.roadmapNumber}</Text>
                </Title>
                <Title level={4}>
                  Status:{' '}
                  <Tag
                    icon={statuses[roadmapParent.status].icon}
                    color={statuses[roadmapParent.status].color}
                  >
                    {t(
                      `pages.roadmaps.messages.${
                        statuses[roadmapParent.status].text
                      }`
                    )}
                  </Tag>
                </Title>
              </Col>
            </Row>
            <Row>
              <Col style={{ width: '100%' }}>
                <Table columns={columnsByOrders()} dataSource={ordersByIds} />
              </Col>
            </Row>
          </Card>
        )}
        <Card>
          <Button disabled={!isRiderFree} type="primary" onClick={submit}>
            Transferir Ordenes
          </Button>
        </Card>
      </Space>
    </>
  );
};

export default RoadmapTranferOrders;
