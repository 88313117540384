import React, { useCallback, useState } from 'react';
import ReactExport from 'react-data-export';
import { Col, Row, DatePicker, Button } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { sortBy } from 'lodash-es';
import { ordersApi } from 'stores/query/ordersQuery';
import useDisclosure from 'hooks/useDisclousure';
import useAsyncDownload from 'hooks/useAsyncDownload';
import { ordersReportWalletFactory } from './utils';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const WalletReports = () => {
  const {
    isOpen: fetching,
    onOpen: fetchingOn,
    onClose: fetchingOff
  } = useDisclosure();
  const { isReady, asyncDownloadToggle } = useAsyncDownload();
  const dispatch = useDispatch();
  const summarizeData = useCallback(
    (data) => [
      ...sortBy(data, ['order']),
      {
        date: 'TOTAL',
        order: '',
        dni: '',
        orderTime: '',
        client: '',
        phone: '',
        totalProducts: data.reduce((prev, next) => {
          prev += next.totalProducts;
          return prev;
        }, 0),
        amount: '',
        paymentMethod: '',
        addressDetails: '',
        wallet: '',
        walletTop: '',
        payCredit: '',
        payPM: '',
        payCash: '',
        payBs: '',
        tasa: '',
        dollars: ''
      }
    ],
    []
  );

  const [filteredOrdersData, setFilteredOrdersData] = useState([]);

  const [dataPickerFilter, setDatePickerFilter] = useState([]);
  const hasFilters = dataPickerFilter.length === 2;

  const handleChange = (dates) => {
    if (!dates) {
      setDatePickerFilter([]);
      return;
    }
    setDatePickerFilter([
      moment(dates[0]?.toString()),
      moment(dates[1]?.toString())
    ]);
  };

  const getColumns = () => [
    {
      title: 'Fecha',
      key: 'date',
      dataIndex: 'date'
    },
    {
      title: 'Orden',
      key: 'order',
      dataIndex: 'order'
    },
    {
      title: 'Hora de la orden',
      key: 'orderTime',
      dataIndex: 'orderTime'
    },
    {
      title: 'Cliente',
      key: 'client',
      dataIndex: 'client'
    },
    {
      title: 'CI',
      key: 'dni',
      dataIndex: 'dni'
    },
    {
      title: 'Telefono',
      key: 'phone',
      dataIndex: 'phone'
    },
    {
      title: 'Total de productos por orden',
      key: 'totalProducts',
      dataIndex: 'totalProducts'
    },
    {
      title: 'Monto',
      key: 'amount',
      dataIndex: 'amount'
    },
    {
      title: 'Metodo de Pago',
      key: 'paymentMethod',
      dataIndex: 'paymentMethod'
    },
    {
      title: 'Pago en Wallet',
      key: 'payCredit',
      dataIndex: 'payCredit'
    },
    {
      title: 'Pago en Cash',
      key: 'payCash',
      dataIndex: 'payCash'
    },
    {
      title: 'Pago en PagoMovil',
      key: 'payPM',
      dataIndex: 'payPM'
    },
    {
      title: 'Pago en Bs Efectivo',
      key: 'payBs',
      dataIndex: 'payBs'
    },
    {
      title: 'Direccion',
      key: 'addressDetails',
      dataIndex: 'addressDetails'
    },
    {
      title: 'Monto wallet',
      key: 'wallet',
      dataIndex: 'wallet'
    },
    {
      title: 'Compañia',
      key: 'company',
      dataIndex: 'company'
    },
    {
      title: 'Monto Total en Dolares',
      key: 'dollars',
      dataIndex: 'dollars'
    },
    {
      title: 'Tasa Dolar',
      key: 'tasa',
      dataIndex: 'tasa'
    },
    {
      title: 'Facturacion - Nombre',
      key: 'factuName',
      dataIndex: 'factuName'
    },
    {
      title: 'Facturacion - Telefono',
      key: 'factuPhone',
      dataIndex: 'factuPhone'
    },
    {
      title: 'Facturacion - Cedula',
      key: 'factuId',
      dataIndex: 'factuId'
    }
  ];

  const border = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' }
  };

  const alignment = { horizontal: 'center', wrapText: true };

  const dataSet = [
    {
      columns: getColumns().map((column) => ({
        title: column.title,
        width: { wch: column.title.length + 12 },
        style: {
          fill: { fgColor: { rgb: 'FFC3D69B' } },
          font: { bold: true },
          alignment,
          border
        }
      })),
      data: filteredOrdersData.map((row) =>
        Object.values(row).map((value) => ({
          value: String(value),
          style: {
            alignment,
            border
          }
        }))
      )
    }
  ];

  const handleDownload = useCallback(async () => {
    if (dataPickerFilter.length !== 2) return;
    fetchingOn();
    const selectedFrom = new Date(dataPickerFilter[0]).setHours(0, 0, 0, 0);
    const selectedTo = new Date(dataPickerFilter[1]).setHours(23, 59, 59, 59);
    const from = new Date(selectedFrom);
    const to = new Date(selectedTo);
    const { data: orderIds } =
      (await dispatch(
        ordersApi.endpoints.ordersByIdWallet.initiate({ from, to })
      )) || {};

    const ordersData = ordersReportWalletFactory(orderIds);

    fetchingOff();
    setFilteredOrdersData(summarizeData(ordersData));
    asyncDownloadToggle();
  }, [
    dataPickerFilter,
    dispatch,
    summarizeData,
    fetchingOn,
    fetchingOff,
    asyncDownloadToggle
  ]);

  return (
    <>
      <Row>
        <Col span={6}>
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            placeholder={['from', 'to']}
            onChange={(e) => handleChange(e)}
            ranges={{
              '2 days': [
                moment().subtract(2, 'days').startOf('day'),
                moment().endOf('day')
              ]
            }}
            value={dataPickerFilter}
            allowClear
          />
        </Col>
        <Col span={6}>
          <Button
            disabled={!hasFilters}
            loading={fetching}
            onClick={() => handleDownload()}
          >
            Descargar Reporte
          </Button>
          {isReady && (
            <ExcelFile hideElement filename="Reporte Wallet">
              <ExcelSheet dataSet={dataSet} name="Ordenes" />
            </ExcelFile>
          )}
        </Col>
      </Row>
    </>
  );
};

export default WalletReports;
