import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getOrdersDataWithUsers } from 'stores/selectors/orders';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Button, InputNumber } from 'antd';
import CardContainer from 'components/CardContainer';
import { headers, ordersFilterDropdown } from './helpers';
import { CSVLink } from 'react-csv';
import useGetOrdersHook from 'hooks/componentHooks/useGetOrdersHook';

const MobilePaymentFilter = ({ mobilePaymentData, handleFilter }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { getOrders } = useGetOrdersHook();
  const ordersData = useSelector(getOrdersDataWithUsers);
  const ordersOption = ordersData
    ?.filter((order) => order?.odooDataOrder?.name)
    .map((order) => {
      return {
        id: order?.id,
        name: order?.odooDataOrder?.name || '-'
      };
    });

  const onFinish = (values) => {
    handleFilter(values);
  };

  React.useEffect(() => {
    if (ordersData.length === 0) getOrders();
    // eslint-disable-next-line
  }, []);
  return (
    <CardContainer>
      <Form
        form={form}
        name="mobilePaymentFilter"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row>
          <Col span={12}>
            <Row gutter={[48, 8]}>
              <Col span={8}>
                <Form.Item name="order" label={t('common.fields.order')}>
                  {ordersFilterDropdown(ordersOption)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[8, 8]}>
              <Col span={9}>
                <Form.Item name="minTotalAmountBs" label="Min Bs">
                  <InputNumber
                    style={{ width: 135 }}
                    size="small"
                    addonBefore="+"
                    addonAfter="Bs"
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name="maxTotalAmountBs" label="Max Bs">
                  <InputNumber
                    style={{ width: 135 }}
                    size="small"
                    addonBefore="+"
                    addonAfter="Bs"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={2}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item>
              <Button type="primary">
                <CSVLink
                  filename={'Reporte Informe de Pago Movil.csv'}
                  data={mobilePaymentData || []}
                  headers={headers}
                >
                  Descargar Reporte
                </CSVLink>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CardContainer>
  );
};

MobilePaymentFilter.propTypes = {
  mobilePaymentData: PropTypes.array.isRequired,
  handleFilter: PropTypes.func
};

export default MobilePaymentFilter;
