import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderProps } from 'stores/actions/header';

const useSetHeaderProps = () => {
  const dispatch = useDispatch();
  return useCallback(
    (newProps) => {
      dispatch(setHeaderProps(newProps));
    },
    [dispatch]
  );
};

export default useSetHeaderProps;
