import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Menu, Button, Badge } from 'antd';
import { NotificationOutlined, AuditOutlined } from '@ant-design/icons';

import { ORDERS } from 'constants/routes';
import { getNewOrdersIncomingCount } from 'stores/selectors/ordersIncoming';
import { resetOrdersIncomingCount } from 'stores/actions/ordersIncoming';

const Alerts = ({ mode = 'static' }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isShown, setIsShown] = useState(false);
  const newOrdersCount = useSelector(getNewOrdersIncomingCount);
  const [hideBadge, setHideBadge] = useState(true);

  const goToRoute = (route) => () => {
    history.push(route);
    dispatch(resetOrdersIncomingCount());
    setHideBadge(true);
  };
  const dropdownAlerts = (
    <>
      <Menu>
        <Menu.Item
          icon={<AuditOutlined />}
          key={ORDERS}
          onClick={goToRoute(ORDERS)}
          hidden={hideBadge}
        >
          <Badge size="small" count={newOrdersCount} overflowCount={2000}>
            Ordenes Entrantes
          </Badge>
        </Menu.Item>
      </Menu>
    </>
  );

  const showDot = newOrdersCount > 0;

  const isFloatMode = mode === 'float';

  return (
    <div>
      <Dropdown
        placement={isFloatMode ? 'topLeft' : 'bottomLeft'}
        overlay={() => dropdownAlerts}
        trigger={['click']}
      >
        <Button
          onMouseEnter={() => setIsShown(true)}
          onClick={() => setHideBadge(false)}
          onMouseLeave={() => setIsShown(false)}
          style={
            isFloatMode
              ? {
                  backgroundColor: '#2fc9af',

                  width: '80px',
                  height: '80px',
                  position: 'fixed',
                  bottom: isShown ? '0px' : '-30px',
                  right: isShown ? '0px' : '-30px'
                }
              : {}
          }
          shape="circle"
          icon={
            <Badge
              style={{ width: '20px', height: '20px' }}
              dot={showDot}
              offset={isFloatMode ? [-40, -15] : [0, 0]}
            >
              <NotificationOutlined />
            </Badge>
          }
        />
      </Dropdown>
    </div>
  );
};

export default Alerts;
