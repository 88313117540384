import React, { useCallback, useState } from 'react';
import ReactExport from 'react-data-export';
import { Col, Row, DatePicker, Button } from 'antd';
import moment from 'moment';
import { getRoadmaps } from 'firebase/api';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash-es';
import { ordersApi } from 'stores/query/ordersQuery';
import useGetRoutesHook from 'hooks/componentHooks/useGetRoutesHook';
import { getRidersData } from 'stores/selectors/riders';
import useDisclosure from 'hooks/useDisclousure';
import useAsyncDownload from 'hooks/useAsyncDownload';
import { ordersReportFactoryG } from './utils';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const OrdersReports = () => {
  const {
    isOpen: fetching,
    onOpen: fetchingOn,
    onClose: fetchingOff
  } = useDisclosure();
  const { isReady, asyncDownloadToggle } = useAsyncDownload();
  const dispatch = useDispatch();
  const [roadmaps, setRoadmaps] = useState([]);
  const [loadingRoadmaps, setloadingRoadmaps] = useState(false);
  const riders = useSelector(getRidersData);
  const { getRoutes, routesData: routes } = useGetRoutesHook();

  const summarizeData = useCallback(
    (data) => [
      ...sortBy(data, ['order']),
      {
        date: 'TOTAL',
        riderAssignedDate: '',
        departureDate: '',
        deliverDate: '',
        rider: '',
        dni: '',
        proco: '',
        route: '',
        roadmap: '',
        order: '',
        orderTime: '',
        client: '',
        phone: '',
        totalProducts: data.reduce((prev, next) => {
          prev += next.totalProducts;
          return prev;
        }, 0),
        cantSku: '',
        amount: '',
        totalDL: '',
        couponCode: '',
        couponAmount: '',
        paymentMethod: '',
        paymentValidation: '',
        delivered: data.filter((order) => order.delivered === 'X').length,
        crossdocking: data.filter((order) => order.crossdocking === 'X').length,
        noDelivered: data.filter((order) => order.noDelivered === 'X').length,
        partialOrder: data.filter((order) => order.partialOrder === 'X').length,
        excludedProduts: '',
        crossDockingMotive: '',
        noDeliveredMotive: '',
        feedback: '',
        distance: '',
        observations: '',
        printDate: '',
        printedManually: '',
        company: ''
      }
    ],
    []
  );

  const [filteredOrdersData, setFilteredOrdersData] = useState([]);

  const [dataPickerFilter, setDatePickerFilter] = useState([]);
  const hasFilters = dataPickerFilter.length === 2;

  const handleChange = (dates) => {
    if (!dates) {
      setDatePickerFilter([]);
      return;
    }
    setDatePickerFilter([
      moment(dates[0]?.toString()),
      moment(dates[1]?.toString())
    ]);
  };

  const getColumns = () => [
    {
      title: 'Fecha',
      key: 'date',
      dataIndex: 'date'
    },
    {
      title: 'Hora de asignacion de rider',
      key: 'riderAssignedDate',
      dataIndex: 'riderAssignedDate'
    },
    {
      title: 'Hora de salida',
      key: 'departureDate',
      dataIndex: 'departureDate'
    },
    {
      title: 'Hora de entrega',
      key: 'deliverDate',
      dataIndex: 'deliverDate'
    },
    {
      title: 'Rider',
      key: 'rider',
      dataIndex: 'rider'
    },
    {
      title: 'CI',
      key: 'dni',
      dataIndex: 'dni'
    },
    {
      title: 'Proco',
      key: 'proco',
      dataIndex: 'proco'
    },
    {
      title: 'Ruta',
      key: 'route',
      dataIndex: 'route'
    },
    {
      title: 'RM',
      key: 'roadmap',
      dataIndex: 'roadmap'
    },
    {
      title: 'Orden',
      key: 'order',
      dataIndex: 'order'
    },
    {
      title: 'Hora de la orden',
      key: 'orderTime',
      dataIndex: 'orderTime'
    },
    {
      title: 'Cliente',
      key: 'client',
      dataIndex: 'client'
    },
    {
      title: 'Telefono',
      key: 'phone',
      dataIndex: 'phone'
    },
    {
      title: 'Total de productos por orden',
      key: 'totalProducts',
      dataIndex: 'totalProducts'
    },
    {
      title: 'Cantidad de SKU por orden',
      key: 'cantSku',
      dataIndex: 'cantSku'
    },
    {
      title: 'Monto',
      key: 'amount',
      dataIndex: 'amount'
    },
    {
      title: 'Total Monto en USD',
      key: 'totalDl',
      dataIndex: 'totalDl'
    },
    {
      title: 'Codigo Cupon',
      key: 'couponCode',
      dataIndex: 'couponCode'
    },
    {
      title: 'Monto Cupon',
      key: 'couponAmount',
      dataIndex: 'couponAmount'
    },
    {
      title: 'Metodo de Pago',
      key: 'paymentMethod',
      dataIndex: 'paymentMethod'
    },
    {
      title: 'Pago Validado',
      key: 'paymentValidation',
      dataIndex: 'paymentValidation'
    },
    {
      title: 'Entregado',
      key: 'delivered',
      dataIndex: 'delivered'
    },
    {
      title: 'Crossdocking',
      key: 'crossdocking',
      dataIndex: 'crossdocking'
    },
    {
      title: 'No Entregado',
      key: 'noDelivered',
      dataIndex: 'noDelivered'
    },
    {
      title: 'Orden Parcial',
      key: 'partialOrder',
      dataIndex: 'partialOrder'
    },
    {
      title: 'Productos Excluidos',
      key: 'excludedProduts',
      dataIndex: 'excludedProduts'
    },
    {
      title: 'Motivo Crossdocking',
      key: 'crossDockingMotive',
      dataIndex: 'crossDockingMotive'
    },
    {
      title: 'Motivo NO entrega',
      key: 'noDeliveredMotive',
      dataIndex: 'noDeliveredMotive'
    },
    {
      title: 'Feedback',
      key: 'feedback',
      dataIndex: 'feedback'
    },
    {
      title: 'Km recorrido',
      key: 'distance',
      dataIndex: 'distance'
    },
    {
      title: 'Observaciones',
      key: 'observations',
      dataIndex: 'observations'
    },
    {
      title: 'Hora de impresion',
      key: 'printDate',
      dataIndex: 'printDate'
    },
    {
      title: 'Impreso manualmente',
      key: 'printedManually',
      dataIndex: 'printedManually'
    },
    {
      title: 'Compañia',
      key: 'company',
      dataIndex: 'company'
    },
    {
      title: 'Email',
      key: 'reportEmail',
      dataIndex: 'email'
    }
  ];

  const border = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' }
  };

  const alignment = { horizontal: 'center', wrapText: true };

  const dataSet = [
    {
      columns: getColumns().map((column) => ({
        title: column.title,
        width: {
          wch:
            column.title === 'Email'
              ? column.title.length + 30
              : column.title.length + 10
        },
        style: {
          fill: { fgColor: { rgb: 'FFC3D69B' } },
          font: { bold: true },
          alignment,
          border
        }
      })),
      data: filteredOrdersData.map((row) =>
        Object.values(row).map((value) => ({
          value: String(value),
          style: {
            alignment,
            border
          }
        }))
      )
    }
  ];

  const getAllRoadmaps = async () => {
    const Rms = await getRoadmaps();
    setRoadmaps(Rms);
  };

  React.useEffect(() => {
    getAllRoadmaps().then(() => {
      setloadingRoadmaps(true);
    });
    getRoutes();
    // eslint-disable-next-line
  }, []);

  const handleDownload = useCallback(async () => {
    if (dataPickerFilter.length !== 2) return;
    fetchingOn();
    const selectedFrom = new Date(dataPickerFilter[0]).setHours(0, 0, 0, 0);
    const selectedTo = new Date(dataPickerFilter[1]).setHours(23, 59, 59, 59);
    const from = new Date(selectedFrom);
    const to = new Date(selectedTo);
    const { data: orders } =
      (await dispatch(
        ordersApi.endpoints.ordersByRange.initiate({ from, to })
      )) || {};

    const ordersData = ordersReportFactoryG(roadmaps, routes, riders, orders);

    fetchingOff();
    setFilteredOrdersData(summarizeData(ordersData));
    asyncDownloadToggle();
  }, [
    dataPickerFilter,
    dispatch,
    roadmaps,
    routes,
    riders,
    summarizeData,
    fetchingOn,
    fetchingOff,
    asyncDownloadToggle
  ]);

  return (
    <>
      <Row>
        <Col span={6}>
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            placeholder={['from', 'to']}
            onChange={(e) => handleChange(e)}
            ranges={{
              '2 days': [
                moment().subtract(2, 'days').startOf('day'),
                moment().endOf('day')
              ]
            }}
            value={dataPickerFilter}
            allowClear
          />
        </Col>
        <Col span={6}>
          <Button
            disabled={!hasFilters || !loadingRoadmaps}
            loading={fetching || !loadingRoadmaps}
            onClick={() => handleDownload()}
          >
            Descargar Reporte
          </Button>
          {isReady && (
            <ExcelFile hideElement filename="Reporte Ordenes">
              <ExcelSheet dataSet={dataSet} name="Ordenes" />
            </ExcelFile>
          )}
        </Col>
      </Row>
    </>
  );
};

export default OrdersReports;
