import { useDispatch, useSelector } from 'react-redux';
import { getRoutes as getRoutesAction } from 'stores/actions/routes';
import { getRoutes as getRoutesX } from 'stores/selectors/routes';

const useGetRoutesHook = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(getRoutesX);

  const getRoutes = async () => await dispatch(getRoutesAction());

  return {
    getRoutes,
    routesData: data,
    routesLoading: loading
  };
};

export default useGetRoutesHook;
