import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import DashBoard from 'pages/DashBoard/DashBoard';

import Roadmaps, { RoadmapsView } from 'pages/Roadmaps';
import Departments from 'pages/Departments/Departments';
import Messages from 'pages/Messages/Messages';
import Notifications from 'pages/Notifications';
import CommissionsByRider from 'pages/CommissionsByRider';
import Commissions from 'pages/Commissions';
import Reports from 'pages/Reports/Reports';
import ReportsG from 'pages/ReportsG/ReportsG';
import Banners from 'pages/Banners/BannersTabs';
import SpecialCategories from 'pages/SpecialCategories/SpecialCategories';
import { CommerceToPersonOperation } from 'pages/CommerceToPerson';
import FinanceReports from 'pages/CommerceToPerson/Report/Reports';
import CartValidation from 'pages/CartValidation/CartValidation';

import Finance from 'pages/Finance/Finance';

import AppModal from 'components/Modal/Modal';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import AnnouncementConfig from 'pages/Marketing/AnnouncementConfig';
import { Pharmacy } from 'pages/Storage';
import StorageProco from 'pages/Storage/Proco';
import NewOrdersProvider from 'contexts/NewOrdersContext/NewOrdersContext';

const Home = React.lazy(() => import('pages/Home/Home'));
const Account = React.lazy(() => import('pages/Account/Account'));
const DashOrders = React.lazy(() => import('pages/DashOrders/DashOrders'));
const Roles = React.lazy(() => import('pages/Roles/Roles'));

// Products
const Products = React.lazy(() => import('pages/Products/Products'));
const ProductsView = React.lazy(() => import('pages/Products/ProductsView'));
const MinMax = React.lazy(() => import('pages/MinMax/MinMax'));
const Referred = React.lazy(() => import('pages/Referred/Referred'));

// RoutesMap
const RoutesMapView = React.lazy(() => import('pages/RoutesMap/RoutesMap'));
const RoutesView = React.lazy(() => import('pages/RoutesMap/RouteTable'));

// Orders
const Orders = React.lazy(() => import('pages/Orders/Orders'));
const PickUpOrders = React.lazy(() => import('pages/Orders/PickUp'));
const ConfirmationOrderPayments = React.lazy(() =>
  import('pages/Orders/ConfirmationOrderPayments')
);

// Riders
const Riders = React.lazy(() => import('pages/Riders/Riders'));
const Procos = React.lazy(() => import('pages/ProcosRiders/Procos'));
const ProcoView = React.lazy(() => import('pages/ProcosRiders/ProcoView'));

// Addresses
const Addresses = React.lazy(() => import('pages/Addresses/Addresses'));
const AddressesMap = React.lazy(() => import('pages/Addresses/AddressesMap'));

// Users
const Users = React.lazy(() => import('pages/Users/Users'));
const UserCreate = React.lazy(() => import('pages/Users/UserCreate'));
const UserUpdate = React.lazy(() => import('pages/Users/UserUpdate'));
const UsersView = React.lazy(() => import('pages/Users/UsersView'));

// Invoice
const Invoices = React.lazy(() => import('pages/Invoices/Invoices'));
const InvoicesView = React.lazy(() => import('pages/Invoices/InvoicesView'));
const InvoicesDetail = React.lazy(() =>
  import('pages/Invoices/InvoicesDetail')
);

// Storage
const Packing = React.lazy(() => import('pages/Storage/Packing/Packing'));
const Billing = React.lazy(() => import('pages/Storage/Billing/Billing'));
const StatusPicking = React.lazy(() =>
  import('pages/Storage/StatusPicking/StatusPicking')
);

// Coupons
const Coupons = React.lazy(() => import('pages/Coupons/Coupons'));
const CouponsDetails = React.lazy(() => import('pages/Coupons/CouponsDetails'));

// Marketing
const Marketing = React.lazy(() => import('pages/Marketing'));
const Campaigns = React.lazy(() =>
  import('pages/Marketing/Campaigns/Campaigns')
);
const CategoryBox = React.lazy(() =>
  import('pages/CategoryBoxes/CategoryBoxes')
);

// Finances
const PagoMovil = React.lazy(() => import('pages/Finance/PagoMovil/PagoMovil'));
const BankDetail = React.lazy(() =>
  import('pages/Finance/PagoMovil/BankDetail')
);
const ValidatePay = React.lazy(() =>
  import('pages/Finance/ValidatePay/ValidatePay')
);
const Wallet = React.lazy(() => import('pages/Finance/Wallet/WalletPage'));

const Rei = React.lazy(() => import('pages/Finance/Rei/ReiPage'));

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// Pictures
const Pictures = React.lazy(() => import('pages/Pictures/Pictures'));
const UploadPictures = React.lazy(() =>
  import('pages/Pictures/UploadPictures')
);
const RemovePictures = React.lazy(() =>
  import('pages/Pictures/RemovePictures')
);
const ExchangePictures = React.lazy(() =>
  import('pages/Pictures/ExchangePictures')
);

/**  Documentation about how to add a new module
 * 1) In this file import and add the component of the module
 * 2) In the file routes.js you need to make a constant with the name of the path
 * 3) You need to add the object of the module in menu.js (to show the component)
 * 4) Use the same name of the label in the object in the translations files and the routes
 * 5) In the collection roles, add to the Admin role the permission of the new module
 * (have to be the same name that you use in the object)
 */
const DashBoardRoutes = () => (
  <DashBoard>
    <NewOrdersProvider>
      <Suspense fallback={<Spin indicator={antIcon} />}>
        <AppModal />
        <Switch>
          <Route exact path={ROUTES.HOME} component={Home} />
          <Route exact path={ROUTES.ACCOUNT} component={Account} />
          <Route exact path={ROUTES.PRODUCTS} component={Products} />
          <Route exact path={ROUTES.MIN_MAX} component={MinMax} />
          <Route exact path={ROUTES.REFERRED} component={Referred} />
          <Route exact path={ROUTES.ROLES} component={Roles} />
          <Route exact path={ROUTES.USERS} component={Users} />
          <Route exact path={ROUTES.INVOICES} component={Invoices} />
          <Route exact path={ROUTES.USER_CREATE} component={UserCreate} />
          <Route exact path={ROUTES.ADDRESSES} component={Addresses} />
          <Route exact path={ROUTES.ROADMAPS} component={Roadmaps} />
          <Route exact path={`${ROUTES.REPORTS}`} component={Reports} />
          <Route exact path={`${ROUTES.REPORTSG}`} component={ReportsG} />
          <Route exact path={ROUTES.DEPARTMENTS} component={Departments} />
          <Route exact path={ROUTES.DASH_ORDERS} component={DashOrders} />
          <Route
            exact
            path={ROUTES.CART_VALIDATION}
            component={CartValidation}
          />
          <Route
            exact
            path={ROUTES.SPECIAL_CATEGORIES}
            component={SpecialCategories}
          />
          {/* Finances */}
          <Route exact path={ROUTES.FINANCE} component={Finance} />
          <Route exact path={ROUTES.WALLET} component={Wallet} />
          <Route exact path={ROUTES.REI} component={Rei} />
          <Route exact path={ROUTES.FINANCE_PAGO_MOVIL} component={PagoMovil} />
          <Route
            exact
            path={ROUTES.FINANCE_VALIDATE_PAY}
            component={ValidatePay}
          />
          <Route
            exact
            path={`${ROUTES.FINANCE_PAGO_MOVIL}/:bankName`}
            component={BankDetail}
          />
          {/* Coupons */}
          <Route exact path={ROUTES.COUPONS} component={Coupons} />
          <Route
            exact
            path={`${ROUTES.COUPONS}/:couponId`}
            component={CouponsDetails}
          />
          <Route
            exact
            path={`${ROUTES.ADDRESSESMAPS}/:addressId`}
            component={AddressesMap}
          />
          <Route
            exact
            path={`${ROUTES.ROADMAPS}/:roadmapId`}
            component={RoadmapsView}
          />
          <Route exact path={ROUTES.ROUTES} component={RoutesView} />
          <Route
            exact
            path={`${ROUTES.ROUTESMAPS}/:routeId`}
            component={RoutesMapView}
          />
          <Route exact path={ROUTES.RIDERS} component={Riders} />
          <Route exact path={ROUTES.RIDERS_PROCOS} component={Procos} />
          <Route
            exact
            path={`${ROUTES.RIDERS_PROCOS}/:procoId`}
            component={ProcoView}
          />
          <Route exact path={ROUTES.ORDERS} component={Orders} />
          <Route
            exact
            path={ROUTES.ORDERS_PICKUP_CONFIRMATION}
            component={PickUpOrders}
          />
          <Route
            exact
            path={ROUTES.ORDERS_PAYMENTS_CONFIRMATION}
            component={ConfirmationOrderPayments}
          />
          <Route exact path={ROUTES.NOTIFICATIONS} component={Notifications} />
          <Route
            exact
            path={`${ROUTES.USER_UPDATE}/:userId`}
            component={UserUpdate}
          />
          <Route
            exact
            path={`${ROUTES.USER_UPDATE}/:userId/:userType/view`}
            component={UsersView}
          />
          <Route
            exact
            path={`${ROUTES.USER}/:userId/:userType/view`}
            component={UsersView}
          />
          <Route exact path={ROUTES.USER_UPDATE} component={UserUpdate} />
          <Route
            exact
            path={`${ROUTES.PRODUCTS}/:productId`}
            component={ProductsView}
          />
          <Route
            exact
            path={`${ROUTES.INVOICES}/:invoiceId/view`}
            component={InvoicesView}
          />
          <Route
            exact
            path={`${ROUTES.INVOICE_DETAIL}/:invoiceId`}
            component={InvoicesDetail}
          />
          <Route
            exact
            path={`${ROUTES.CONFIG_MESSAGES}`}
            component={Messages}
          />
          <Route
            exact
            path={`${ROUTES.COMMISSIONS_BY_RIDER}`}
            component={CommissionsByRider}
          />
          <Route
            exact
            path={`${ROUTES.CONFIG_COMMISSIONS}`}
            component={Commissions}
          />
          <Route exact path={`${ROUTES.BANNERS}`} component={Banners} />
          <Route
            exact
            path={`${ROUTES.COMMERCE_TO_PERSON}`}
            component={CommerceToPersonOperation}
          />
          <Route
            exact
            path={`${ROUTES.COMMERCE_TO_PERSON_REPORT}`}
            component={FinanceReports}
          />
          {/* MARKETING ROUTES */}
          <Route
            exact
            path={`${ROUTES.MARKETING_EMAIL}`}
            component={Marketing}
          />
          <Route exact path={`${ROUTES.MARKETING_SMS}`} component={Campaigns} />
          <Route
            exact
            path={`${ROUTES.MARKETING_CATEGORY_BOX}`}
            component={CategoryBox}
          />
          <Route exact path={`${ROUTES.STORAGE_PACKING}`} component={Packing} />
          <Route
            exact
            path={`${ROUTES.STORAGE_PROCOS}`}
            component={StorageProco}
          />
          <Route
            exact
            path={`${ROUTES.CONFIG_ANNOUNCEMENTS}`}
            component={AnnouncementConfig}
          />

          <Route exact path={`${ROUTES.STORAGE_BILLING}`} component={Billing} />
          <Route
            exact
            path={`${ROUTES.STORAGE_PHARMACY}`}
            component={Pharmacy}
          />
          <Route
            exact
            path={`${ROUTES.STORAGE_STATUS_PICKING}`}
            component={StatusPicking}
          />
          <Route exact path={ROUTES.PICTURES} component={Pictures} />
          <Route
            exact
            path={ROUTES.PICTURES_UPLOADING}
            component={UploadPictures}
          />
          <Route
            exact
            path={ROUTES.PICTURES_REMOVAL}
            component={RemovePictures}
          />
          <Route
            exact
            path={ROUTES.PICTURES_EXCHANGE}
            component={ExchangePictures}
          />
        </Switch>
      </Suspense>
    </NewOrdersProvider>
  </DashBoard>
);
export default DashBoardRoutes;
