import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Splash from 'pages/Splash/Splash';

export const AuthContext = createContext({
  isLogged: false,
  accessToken: '',
  permissions: []
});

const AuthProvider = ({ children }) => {
  const {
    isLogged,
    accessToken,
    role: { permissions }
  } = useSelector((state) => state.profile);

  const auth = useSelector((state) => state.auth);

  return (
    (auth.login.loading ? (
      <Splash />
    ) : (
      <AuthContext.Provider value={{ isLogged, accessToken, permissions }}>
        {children}
      </AuthContext.Provider>
    ))
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default AuthProvider;
