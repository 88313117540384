import { createSelector } from '@reduxjs/toolkit';
import { getConfigData } from './config';

export const getBanksData = createSelector(getConfigData, (config) => {
  const adminData = config?.admin;
  const banks = adminData?.banks || [];
  const output = banks.map((bank) => ({
    code: bank.codigo,
    name: bank.nombre
  })) || [];
  return output;
});

export const getBankByCode = (bankCode) =>
  createSelector(getBanksData, (banks) => {
    const bank = banks.find((record) => record.code === bankCode) || null;
    return bank;
  });
